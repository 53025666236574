import React, { isValidElement, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Summarybar from '../../components/Summarybar';
import { InputField } from '../../components/InputField';
import DropDown from '../../components/AppDropDown'
import './style.scss';
import { Button, Form, Col, FormGroup, Label, Row, CustomInput, Input ,Modal, ModalBody, ModalFooter } from 'reactstrap';
import MyEditor from '../../components/MyEditor/MyEditor';
import { EditorButtonType} from "../../data/comman";
import { sendingContactInfo} from "./Redux";
import { sendContactDetails } from "../../service/common";
import { logout } from "../../pages/login/Redux";

const reasonlist = [
  { value: 1, label: 'General inquiries' },
  { value: 2, label: 'Affiliate programs' },
  { value: 3, label: 'Commissions' },
  { value: 4, label: 'Billing' },
  { value: 5, label: 'Troubleshooting' },
  { value: 6, label: 'Feedback' },
  { value: 7, label: 'Other' },
]

const ContactUs = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isError, setError] = React.useState("");
  const [isFromTos, setstatusbar] = React.useState(props.isFromTos === true ? true : false );
  const [contactData, setContactData] = useState({
    subject: props.isSignupTos ? 'Sign up tos decline' : '',
    reason_id: [],
    content:''
  });
  const [modal, setModal] = useState(false);
  const [isOpenSucceesModal, setSucceesModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    modalClassName = `contact-us-confirm ${isFromTos? 'isFromTos' : ''}`,
  } = props;

  const validateForm = () => {
    const errors = {};
    var valid = true ;
    if (contactData.subject.length === 0) {
      errors.subject = "Mandatory Field";
      valid = false
    } else if (contactData.subject.length <= 2) {
      errors.subject = "Subject must be more that 2 characters!";
      valid = false
    }

    if (contactData.reason_id.length === 0) {
      errors.reason_id = "Mandatory Field";
      valid = false
    }

    if (contactData.content.length === 0) {
      errors.content = "Mandatory Field";
      valid = false
    }

    return {
      errors,
      valid: valid,
    };
  };

  const toggle = (event) => {
    const isValid = validateForm();
    if(isValid.valid){
      setModal(!modal);
      setError("")
    }else{
      setError(isValid.errors);
    }
  }

  const toggleSuccessModal = (event)=>{
    setContactData({  
      subject:'',
      reason_id: [],
      content:''
    })
    setSucceesModal(!isOpenSucceesModal)
  }

  const handleChange = (field , val) =>{

    if(field === 'content' || field === 'reason_id'){
      setContactData({
        ...contactData,
        [field]: val
      })
    }else{
      setContactData({
        ...contactData,
        [field]: val.target.value
      })
    }
  }

  const handleSubmit = async () => {
    let params ={
      subject:contactData.subject,
      reason_id:contactData.reason_id.value,
      content:contactData.content
    }
    try {
      const response = await sendContactDetails(params);
      if(response.status=== 200){
        
        setError("");
        toggle();
        toggleSuccessModal();
        if(isFromTos){
          dispatch(logout())
          history.push('/');
        }
      }
      
    } catch (err) {
      setError(err.errors);
      toggle();
    }
  };


  return (
    <div>
      {!isFromTos &&
        <Summarybar />
      }
      <div className="conatct__main__screen">
        <div className="conatct__inner">
          <h4>Contact Us</h4>
          <Form>
            <div className="conatct-row">
              <FormGroup>
                <label>Subject</label>
                <div className="form-group-with-error w-100">
                  {isError && <span className="text-danger">{isError['subject']}</span>}
                  <InputField type="text"  className={isError && isError['subject'] ? 'invalid-field w-100' : 'w-100'} name="subject" onChange={(e) => {handleChange('subject',e)}} value={contactData.subject}/>
                </div>
              </FormGroup>
              <FormGroup>
                <label>Reason</label>
                <div className="form-group-with-error w-100">
                  {isError && <span className="text-danger">{isError['reason_id']}</span>}
                  <DropDown
                    className={`${isError && isError['reason_id'] ? 'invalid-field' : ''} react-select-container bg_white `}
                    classNamePrefix="react-select"
                    options={reasonlist}
                    onChange={(val) => {handleChange('reason_id' , val)}}
                    isSearchable={false}
                    placeholder={''}
                    value={contactData.reason_id}
                  />
                </div>
              </FormGroup>
            </div>
            <div className="editor-row">
              <FormGroup>
                <Label>Content</Label>
                <div className={`${isError && isError['content'] ? 'invalid-field' : ''} form-group-with-error w-100`}>
                  {isError && <span className="text-danger">{isError['content']}</span>}
                  <MyEditor
                      className="w-100"
                      placeholder="Type here"
                      isMultipleFile={true}
                      value={contactData.content}
                      onChange={(html) => {handleChange('content' , html)}}
                      buttonType={[
                        EditorButtonType.TextFormat,
                        EditorButtonType.File,
                        EditorButtonType.Link,
                        EditorButtonType.Indent,
                      ]}
                    />
                  </div>
              </FormGroup>
            </div>
            <div className="editor-row">
            <FormGroup>
                <Label></Label>
                <div className="form-group-with-error w-100">
                  <Button type="button" color="primary" disabled={isDisabled} className="mw205" outline onClick={()=>toggle()} >Send Message</Button>
                </div>
              </FormGroup>
            </div>
          </Form>
          <Modal isOpen={modal} toggle={toggle} centered={true} wrapClassName={ modalClassName }>
            <ModalBody>
              <h4>Your message will be sent to Thalamus, and shortly after a representative will contact you.</h4>
              <p>Would you like to proceed?</p>
            </ModalBody>
            <ModalFooter>
              <Button  type="button" color="primary" outline  onClick={handleSubmit}>Send Message</Button>
              <Button type="button" color="danger" outline onClick={toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isOpenSucceesModal} toggle={toggleSuccessModal} className={isFromTos ? 'isFromTos' : ''} centered={true} wrapClassName={modalClassName}>
            <ModalBody>
              <h4>Thank you for contact us. Our representative contact you shortly</h4>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="danger" outline onClick={toggleSuccessModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

    </div>

  );
}

export default ContactUs;