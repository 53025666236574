import { createSlice } from "@reduxjs/toolkit";
import { contactUsInitialState } from "./initialState";

const sendingContactInfo = (state) => {
  state.loading = true;
};

const sendingContactInfoSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
};

const sendingContactInfoFailure = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
  state.btnLoading = false
};

const contactUsSlice = createSlice({
  name: "contactUsSlice",
  initialState: contactUsInitialState,
  reducers: {
    sendingContactInfo,
    sendingContactInfoSuccess,
    sendingContactInfoFailure,
    reset,
  },
});

export default contactUsSlice;
