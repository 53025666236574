import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { loginUser, newUserPassword , updateUserLoginProfile , updateTos, resetPassword} from "../../../service/login";
import {
  loginLoding,
  loginFailure,
  loginSuccess,
  reset,
  setToken,
  setPwdLoading,
  setPwdData,
  setPwdError,
  updateUserProfileLoading,
  updateUserProfileSuccess,
  updateTosDataSuccess,
  updateTosDataFailure,
  updateusertos,
  setResetPwdLoading,
  logout
} from "./index";
import { UPDATE_USER , LOUGOUT} from "./actions";

export function* loginWatcher(params) {
  try {
    const { username, password , secret } = params.payload;
    const response = yield call(loginUser,username, password, secret);
    yield put(loginSuccess(response.data));
    yield put(setToken(response.data.token_info.access_token));
    yield put(reset());
  } catch (err) {
    yield put(loginFailure(err));
  }
}

export function* newPasswordWatcher(params) {
  try {
    const { password, repassword } = params.payload;
    const response = yield call(newUserPassword, password, repassword);
    yield put(setPwdData({ data: response.data, status: response.status }));
  } catch (err) {
    yield put(setPwdError({ message: err.message, status: err.status }));
  }
}

export function* resetPasswordWatcher(params) {
  try {
    const { password, repassword , token} = params.payload;
    const response = yield call(resetPassword, password, repassword , token);
    yield put(setPwdData({ data: response.data, status: response.status }));
  } catch (err) {
    yield put(setPwdError({ message: err.message, status: err.status }));
  }
}

export function* updateUserProfileWatcher(params) {
  try {
    // const data = yield call(updateUserLoginProfile, params.payload);
    yield put(updateUserProfileSuccess(params.payload));
  } catch (err) {
    yield  put(loginFailure(err));
  }
}

export function* updateTosDataWatcher(params) {
  try {
    // const data = yield call(updateTos, params.payload);
    yield put(updateTosDataSuccess(params.payload));
    yield put(reset());
  } catch (err) {
    yield put(updateTosDataFailure(err));
  }
}

export function* userlogoutWatcher(params) {
  try {
    yield put(logout());
  } catch (err) {
    yield  put(loginFailure(err));
  }
}

export default function* loginSaga() {
  yield all([
    yield takeLatest(loginLoding.type, loginWatcher),
    yield takeLatest(setPwdLoading.type, newPasswordWatcher),
    yield takeLatest(setResetPwdLoading.type, resetPasswordWatcher),
    yield takeEvery(updateUserProfileLoading.type, updateUserProfileWatcher),
    yield takeLatest(updateusertos.type, updateTosDataWatcher),
    yield takeEvery(LOUGOUT.type, userlogoutWatcher),
  ]);
}