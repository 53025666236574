export const loginInitialState = {
  error: "",
  loading: false,
  data: {
    permissions:[]
  },
  token: "",
  pwdData: {
    status: 400,
    data: [],
  },
  pwdLoading: false,
  pwdError: {},
  btnLoading:false
};
