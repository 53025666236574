import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";
import { PER_PAGE } from "./common";

const baseUrl = global.domain;

export const getInvoicesWebstream = async (PAGE_NUM, searchedText = "",  paymentStatus='' , issue_year = '' , issue_month = '' , paymentmethod = '') => {
  try {
    const url =
      baseUrl +
      `/affiliate/invoice?per_page=${PER_PAGE}&page=${PAGE_NUM}&reference_no=${searchedText}&status=${paymentStatus}&year=${issue_year}&month=${issue_month}&payment_method=${paymentmethod}`;
    const data = await axios.get(url);
    return data.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getInvoiceDetails = async (id) => {
    try {
      const url = baseUrl + `/affiliate/invoice/${id}`;
      const { data } = await axios.get(url);
      return data.data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
  };

  export const downloadInvoices = async (invoice_id, type, download_type) => {
    let params ={
      invoice_id:invoice_id,
      type:type,
      download_type:download_type
    }
    try {
      const url = baseUrl + `/affiliate/invoice/download`;
      const  data  = await axios.post(url,params,{responseType: invoice_id.length > 1 ? 'blob' : null});
      return data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
  };