import React,{ useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Alert, Button, Input, Label, FormGroup,Col,Row,Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { loadingSelectedInvoice } from "./Redux";
import Countryselect from '../../../components/Countryselect';
import AppDropDown from '../../../components/AppDropDown'

import thalamuslogo from '../../../images/thalamus-logo-white.png';
import callicon from '../../../images/call-icon.svg';
import mailicon from '../../../images/mail-icon.svg';
import locationicon from '../../../images/location-icon.svg';
import MasterCard from '../../../images/MasterCard.svg';
import VisaCard from '../../../images/VisaCard.svg';
import amex from '../../../images/amex.svg';
import discover from '../../../images/discover.svg';
import BackCVV from '../../../images/BackCVV.svg';
import clockicon from '../../../images/clock-icon.svg';
import {downloadInvoices} from '../../../service/Invoices'
import moment from 'moment';
import Loader from "../../../components/Loader";
import LoadingButton from '../../../components/LoadingButton';
import { Document, Page, pdfjs } from "react-pdf";
import { ACTIVEFORMAT } from "../../../data/comman";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ActiveWebstreamInvoiceDetails = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [btnLoading, setbtnLoading] = React.useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const selectedInvoiceInfo = useSelector(
    (state) => state.activeInvoiceWebStream.selectedInvoice
  );

  const [isError, setError] = React.useState("");
  const [selectedInvoices, setSelectedInvoices] = React.useState([id]);

  
  const loading = useSelector(
    (state) => state.activeInvoiceWebStream.loadingSelectedInvoice
  );

  const [states, setState] = React.useState({
    active: 0,
    isOpen: false,
    isOpenSubModal: false,
    isOpenDownloadModal:false
  });
  
  const toggleModal = (state) => {
    setState({ isOpen: state });
  };

  const toggleDownloadModal = (state) => {
    setState({ isOpenDownloadModal: state });
  };

  

  const togglePayNow = (state) => {
    setState({ isOpenSubModal: state });
  };

  const onEmailInvoices =  async (download_type) =>{
    setbtnLoading(true);
    try {
      const response = await downloadInvoices(selectedInvoices,'webstream', download_type );
      if(response.status == 200){
        toggleEmailSuccessModal(true);
      }
    } catch (err) {
          setError(err);
    }
    setbtnLoading(false);
  }

  const toggleEmailSuccessModal = (state) => {
    setState({ isOpenEmailSuccessModal: state });
  };

  const onDownloadInvoices =  async (download_type) =>{
    // setbtnLoading(true);
    // try {

    //   const response = await downloadInvoices(selectedInvoices,'webstream', download_type );

    //   if(download_type == 1){
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download',`${moment().unix()}.${response.headers['content-type'] == "application/octet-stream" ? "zip" : "pdf"}`);
    //     document.body.appendChild(link);
    //     link.click();
    //     toggleModal(false);
    //   }
     
    // } catch (err) {
    //       setError(err);
    // }
    const linkSource = `data:application/pdf;base64,${selectedInvoiceInfo.invoice_data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${moment().unix()}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    toggleModal(false);

    // setbtnLoading(false);
  }

  React.useEffect(() => {
    dispatch(loadingSelectedInvoice(id));
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getStatus = (id) => {
    switch (id) {
      case 1:
        return ["color-yellow", "Pending"];
      case 2:
        return ['color-green', "Paid"];
      case 3:
        return ['color-red', "Declined"];
      default:
        return ['color-green', "Pending"];
    }
  }

  const { reference_no ,billed_name,address, total  , invoice_date , payment_type , status } = selectedInvoiceInfo;
  return (
    <>
      <Summarybar />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <Row>
            <Col lg="9">
                <div className="card py-5">
                    <div className="card-box">
                        <div className="card__header card__header__filter">
                            <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                                <div className="card__title d-flex align-items-center flex-wrap">
                                    <Button  type="button" color="primary link" onClick={() => history.goBack()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
                                    <h3 className="m-0 mr-4">Invoice no. {reference_no}</h3>
                                    <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"  onClick={() => toggleDownloadModal(true)} ><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Button>
                                    {/* <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"   onClick={() => onEmailInvoices(2)}><Icon className="mr-4" icon="mail-icon" disableFill="true" size={16}></Icon>Email Invoices</Button> */}
                                    <LoadingButton type="button" size="md" disabled={btnLoading} color="primary link" onClick={() => onEmailInvoices(2)} className="mx-3 px-5 link-btn" loading={btnLoading} outline ><Icon className="mr-4" icon="mail-icon" disableFill="true" size={16}></Icon>Email Invoices</LoadingButton>

                                </div>
                            </div>
                        </div>
                        <div className=" px-5 card-body">
                            <Document
                                file={`data:application/pdf;base64,${selectedInvoiceInfo.invoice_data}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                renderMode={'svg'}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            {/* <div className="pl-5">
                                <div className="affilate-report-bg">
                                    <div className="affilate-report-sec affilate-dark-report">
                                        <Row>
                                            <Col lg="4">
                                                <div className="affilate-report-left px-5 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <img  src={thalamuslogo}/>
                                                        </div>
                                                        <div className="py-4">
                                                            <ul className="thalasum-info">
                                                                <li>
                                                                    <img src={locationicon}/>
                                                                    <span>249-67 Mowat Ave, Toronto, ON, M6K 3L5</span>
                                                                </li>
                                                                <li>
                                                                    <img src={callicon}/>
                                                                    <span><a href='tel:1(888)575-7932'>1(888) 575-7932</a></span>
                                                                </li>
                                                                <li>
                                                                    <img src={mailicon}/>
                                                                    <span><a href="mailto:sales@thalamusmedia@.com">sales@thalamusmedia@.com</a></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul className="thalasum-info">
                                                            <li>
                                                                <img src={clockicon}/>
                                                                <span>
                                                                    <h3 className="mb-o">Invoice</h3>
                                                                    <p className="mb-5">#CAON123456-01</p>
                                                                    <p>All Price are in US dollars.</p>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="affilate-report-right px-4 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <div className="affilate-report-header d-flex justify-content-between">
                                                                <h4>Billed to</h4>
                                                                <h3>{billed_name}</h3>
                                                            </div>
                                                            <div className="py-3 d-flex justify-content-between">
                                                                <div>
                                                                    <p>{invoice_date}</p>
                                                                </div>
                                                                <div className="text-right">
                                                                    {address}
                                                                </div>
                                                            </div>
                                                            <div className="divider my-5"></div>
                                                            <table className="w-100 table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Description</th>
                                                                        <th className="text-right">Price</th>
                                                                        <th className="text-right">Quantity</th>
                                                                        <th className="text-right">Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="">
                                                                {selectedInvoiceInfo && selectedInvoiceInfo.items && selectedInvoiceInfo.items.length > 0 &&
                                                                    selectedInvoiceInfo.items.map((item, index) => {
                                                                    const {item_name , price, qty ,total } = item;
                                                                    return (
                                                                        <tr key={index} className="">
                                                                            <td className="pl-0">${item_name}</td>
                                                                            <td className="pl-0 text-right">{price}</td>
                                                                            <td className="pl-0 text-right">{qty}</td>
                                                                            <td className="pl-0 text-right">${total}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </table>
                                                            <div className="divider my-5"></div>
                                                            
                                                            <table className="w-100 table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Grand Total</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="">
                                                                    <tr>
                                                                        <td></td>
                                                                        <td className="text-right">${total}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg="3">
                <div className="py-5">
                    <div className="py-5">
                        <h3>Summary</h3>
                    </div>
                    <ul className="summary-list pb-3">
                        <li>
                            <span><strong>Invoice No.:</strong></span>
                            <span>{selectedInvoiceInfo.reference_no || ''}</span>
                        </li>
                        <li>
                            <span><strong>Country:</strong></span>
                            <span>{selectedInvoiceInfo&& selectedInvoiceInfo.affiliate && selectedInvoiceInfo.affiliate.head_office_address.country.name || ''}</span>
                        </li>
                        <li>
                            <span><strong>Amount due:</strong></span>
                            <span>${selectedInvoiceInfo.total || ''}</span>
                        </li>
                        <li>
                            <span><strong>Issue date:</strong></span>
                            <span>{moment(selectedInvoiceInfo.invoice_date).format(ACTIVEFORMAT) || ''}</span>
                        </li>
                        <li>
                            <span><strong>Payment method:</strong></span>
                            <span>{selectedInvoiceInfo.payment_method && selectedInvoiceInfo.payment_method.name || ''}</span>
                        </li>
                        <li>
                            <span><strong>Payment status:</strong></span>
                            <span className={`pl-0 ${getStatus(selectedInvoiceInfo.status)[0]}`}>{getStatus(selectedInvoiceInfo.status)[1]}</span>
                        </li>
                    </ul>
                    <div className="py-5 mb-5">
                        <p className="mb-5"><strong>Banking Details</strong></p>
                        <p className="tos__content" dangerouslySetInnerHTML={{__html: selectedInvoiceInfo.affiliate && selectedInvoiceInfo.affiliate.banking_detail && selectedInvoiceInfo.affiliate.banking_detail || ''}}></p>
                    </div>
                    {/* <Link type="button" className="btn btn-xl outline-primary bg-white w-100"  onClick={() => toggleModal(true)} >Pay now with credit card</Link> */}
                </div>
            </Col>
        </Row>
     )}
    <Modal isOpen={states.isOpen} className="paymentmodal px-4" toggle={() => toggleModal(false)} >
        <ModalBody className="text-left">
            <div className="px-5 py-5 pb-0">
                <div className="px-5 py-3">
                    <Row>
                        <Col sm="8">
                            <h3 className="mb-5">Invoice No. IDHS00302</h3>
                            <FormGroup row className="align-items-center invoice-input">
                                <Label for="state" className="pr-lg-0" sm={3}>Pay with</Label>
                                <Col lg={9}>
                                    <div className="custom-control custom-checkbox custom-control-inline mr-4">
                                        <Input type="checkbox" className="custom-control-input" id="savedcard"/>
                                        <Label className="custom-control-label" for="savedcard">Saved credit card</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input" id="newcreditcard" />
                                        <Label className="custom-control-label" for="newcreditcard">New credit card</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg="4">
                            <div className="text-right">
                                <h3 className="mb-5">Total: $8,000.00</h3>
                                <ul className="list-inline ml-4">
                                    <li className="list-inline-item m-0"><img width="35px" src={MasterCard} className="paycard_icon mr-3" /></li>
                                    <li className="list-inline-item m-0"><img width="35px" src={VisaCard} className="paycard_icon mr-3" /></li>
                                    <li className="list-inline-item m-0"><img width="35px" src={amex} className="paycard_icon mr-3" /></li>
                                    <li className="list-inline-item m-0"><img width="35px" src={discover} className="paycard_icon mr-3" /></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="px-5 py-5 card pb-0">
                <div className="px-5 py-3">
                    <Row>
                        <Col lg="7">
                            <FormGroup row className="align-items-center">
                                <Label for="card number" className="pr-lg-0" sm={3}>Credit card no.</Label>
                                <Col sm={9}>
                                    <Input type="text" name="card number" placeholder="-"  required />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="card number" className="pr-lg-0" sm={3}>Name on card</Label>
                                <Col sm={9}>
                                    <Input type="text" name="card number" placeholder="-"  required />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm="5">
                            <FormGroup row className="align-items-center">
                                <Label for="Expiry date" className="pr-lg-0" sm={4}>Expiry date</Label>
                                <Col sm={6}>
                                    <Input type="text" name="Expiry date" placeholder="MM / YY" value="31601" required />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="Security code" className="pr-lg-0" sm={4}>Security code</Label>
                                <Col sm={6}>
                                    <Input type="text" name="Security code" placeholder="3 or 4 (AMEX) digits" value="31601" required />
                                </Col>
                                <Col sm={2}>
                                    <img src={BackCVV} className="paycard_icon" />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="px-5 py-5">
                <div className="px-5 py-3">
                    <Row>
                        <Col lg="7">
                            <FormGroup row className="align-items-center invoice-input">
                                <Label for="state" className="pr-lg-0" sm={3}>Billing address</Label>
                                <Col lg={9}>
                                    <div className="custom-control custom-checkbox custom-control-inline mr-4">
                                        <Input type="checkbox" className="custom-control-input" id="headeoffice"/>
                                        <Label className="custom-control-label" for="headeoffice">Same as Head Office</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input" id="opratoroffice" />
                                        <Label className="custom-control-label" for="opratoroffice">Same as Operat. Office</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center">
                                <Label for="affilatename" className="pr-lg-0" sm={3}>Country</Label>
                                <Col sm={9}>
                                    <div className="country-select">
                                        <Countryselect  />
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="City-Town" className="pr-lg-0" sm={3}>City / Town</Label>
                                <Col sm={9}>
                                    <Input type="text" name="City-Town" placeholder="-"  required />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="streeno" className="pr-lg-0" sm={3}>Street and No.</Label>
                                <Col sm={9}>
                                    <Input type="text" name="streeno" placeholder="Street and number, P.O. box, c/o." required value="2291 Locust Street" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="citytown" className="pr-lg-0" sm={3}></Label>
                                <Col sm={9}>
                                    <Input type="text" name="apprtmentno" placeholder="Appartment, suite, unit, building, floor" required  value="Appartment, suite, unit, building, floor" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="citytown" className="pr-lg-0" sm={3}>State / Province</Label>
                                <Col sm={9}>
                                <AppDropDown 
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    options={states}
                                    placeholder={'-'}
                                    // onChange={(val)=> this.props.handleChange('states', val, "dropdown") }
                                />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="postacode" className="pr-lg-0" sm={3}>Zip / Postal code</Label>
                                <Col sm={9}>
                                    <Input type="text" name="postacode" placeholder="Appartment, suite, unit, building, floor" value="31601" required />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </ModalBody>
        <ModalFooter className="justify-content-start px-5">
            <Link type="button" className="btn btn-md outline-primary bg-white mx-5 mr-4" onClick={() => togglePayNow(true)} >Pay invoice</Link>
            <button type="button" className="btn btn-md outline-danger bg-white" onClick={() => toggleModal(false)}>Cancel</button>
        </ModalFooter>
    </Modal>
    <Modal isOpen={states.isOpenSubModal} onClick={() => togglePayNow(false)}>
        <ModalBody className="text-center">
                <p className="mb-0"><strong>Invoice no. IDHS00302 has been paid. Thank you!</strong></p>
                <p>Would you like to permanently change your invoice payment method from Wire Transfer to Credit Card?</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <Link type="button" className="btn btn-xl w-100 outline-primary bg-white mb-4" onClick={() => togglePayNow(false)}    >Yes, using the credit card just provided</Link>
            <Link type="button" className="btn btn-xl w-100 outline-primary bg-white mb-4" onClick={() => togglePayNow(false)}   >Yes, using the credit card in my profile</Link>
            <Link type="button" className="btn btn-xl w-100 outline-primary bg-white mb-4" onClick={() => togglePayNow(false)}    >No, continue with current payment method.</Link>
        </ModalFooter>
    </Modal>

    <Modal isOpen={states.isOpenDownloadModal}  size="sm" toggle={() => toggleDownloadModal(false)}  >
        <ModalBody className="text-center">
            <p><strong>Before downloading a PDF of the selected invoice(s), you can opt for either a dark or light version. </strong></p>
            {/* <FormGroup className="align-items-center text-left px-5 py-4 mb-0 invoice-input">
                    <Label className="mr-3"><strong>Version:</strong></Label>
                    <div className="custom-control custom-checkbox custom-control-inline mr-3  ">
                        <Input type="checkbox" className="custom-control-input" id="dark" />
                        <Label className="custom-control-label" for="dark ">Dark</Label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-inline   ">
                        <Input type="checkbox" className="custom-control-input" id="light" />
                        <Label className="custom-control-label" for="light ">Light (Printer friendly)</Label>
                    </div>
            </FormGroup> */}
        </ModalBody>
        <ModalFooter className="justify-content-between">
            {/* <Link type="button" className="btn btn-md outline-primary bg-white mr-4"  onClick={() => onDownloadInvoices(1)}  >Download Invoices</Link> */}
            <LoadingButton type="button" size="md" disabled={btnLoading} label="Download Invoices" color="primary" onClick={() => onDownloadInvoices(1)} className="mr-4" loading={btnLoading} outline />
            <button type="button" className="btn btn-md outline-danger bg-white"   onClick={() => toggleDownloadModal(false)} >Cancel</button>
        </ModalFooter>
        
    </Modal>

    <Modal isOpen={states.isOpenEmailSuccessModal}  size="sm" toggle={() => toggleEmailSuccessModal(false)}  >
        <ModalBody className="text-center">
            <p><strong>Affiliate invoice send successfully</strong></p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <button type="button" className="btn btn-md outline-danger bg-white"   onClick={() => toggleEmailSuccessModal(false)} >Close</button>
        </ModalFooter>
    </Modal>
    </>
  );
};

export default ActiveWebstreamInvoiceDetails;
