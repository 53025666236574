import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../../icon";

function Webstreams(props) {
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
    const { setDefaultId } = props;
  return (
    <div className="help__inner__questions">
        <div className="d-flex align-items-center mb-5 pb-4 justify-content-between">
            <Button className="px-2 py-1 mr-auto" color="primary" onClick={()=>{ setDefaultId() }} >
            <Icon
                icon="back"
                disableFill="true"
                size={14}
                color="#fff"
            ></Icon>
            </Button>
            <h4 className="m-0 d-flex align-items-center"><Icon icon="webstreams" className="me-4" disableFill="true" size={24} color="#343434" ></Icon>Webstreams</h4>
            <div></div>
        </div>
        <div className="question-row">
            <Card>
            <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                <h5>What can I find on the webstreams page?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                <CardBody>
                <p>The webstream page shows all of the authorized practice domains that have access to 50 Thalamus Dental Education Videos. This page is also where you can add additional domains</p>
                </CardBody>
            </Collapse>
            </Card>

            <Card>
            <CardHeader onClick={() => setToggequestion(3)} className={toggleQuestion === 3 ? 'question-open' : ''}>
                <h5>What are Webstreams?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 3 ? true : false}>
                <CardBody>
                <p>Webstreams are the name given to the video player, or the individual video titles, that display our patient education videos on a dental practice website.  After a URL or URL’s have been authorised by Thalamus to display the video player and/or individual titles, the person who manages or develops the practice’s website embeds a piece of code that we send you.</p>
                </CardBody>
            </Collapse>
            </Card>
        </div>
    </div>
  );
}

export default Webstreams;