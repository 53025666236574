import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "../pages/login/Redux";
import dashboardReducer from "../pages/dashboard/Redux";
import overViewReducer from "../pages/Overview/Redux";
import pagesReducer from "../pages/Redux";
import activeWebStreamReducer from "../pages/webstream/Activewebstream/Redux";
import inActiveWebstreamReducer from "../pages/webstream/Inactivewebstream/Redux";
import userRightReducer from "../pages/users-rights/Redux";
import activeInvoiceWebStreamReducer from "../pages/Invoices/Webstreams/Redux";
import invitation from '../pages/invitation/Redux';
import sendContactDetails  from "../pages/ContactUs/Redux";
// import updateUserLoginProfile  from "../components/UserProfileModal/Redux";
import reports from '../pages/Reports/Webstream/Redux';
import referrals from '../pages/ReferralsPage/Redux';
export function actionCreator(type) {
  function action(payload) {
    return {
      type,
      payload,
    };
  }
  action.toString = () => type;
  action.type = type;
  return action;
}
const appReducer = combineReducers({
  /* your app’s top-level reducers */
  login: loginReducer,
  dashboard: dashboardReducer,
  overview: overViewReducer,
  pagesData: pagesReducer,
  activeWebStream: activeWebStreamReducer,
  activeInvoiceWebStream: activeInvoiceWebStreamReducer,
  inactiveWebStream: inActiveWebstreamReducer,
  userRights: userRightReducer,
  invitationData: invitation,
  contactDetails:sendContactDetails,
  reports:reports,
  referrals:referrals
})

const rootReducer = (state, action) => {
  if (action.type === 'loginSlice/logout') {
    localStorage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer;