export const overvierInitialState = {
  error: {},
  data: {},
  expiredPromocode:{},
  loading: true,
  btnLoading:false,
  editOverView: {},
  getHtmlCode:'',
  errorEditOverView: {},
};
