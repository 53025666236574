import { all, fork } from "redux-saga/effects";
import loginSaga from "../pages/login/Redux/saga";
import dashboardSaga from "../pages/dashboard/Redux/saga";
import overViewSaga from "../pages/Overview/Redux/saga";
import pagesSaga from "../pages/Redux/saga";
import activeWebStreamSaga from "../pages/webstream/Activewebstream/Redux/saga";
import inActiveWebStreamSaga from "../pages/webstream/Inactivewebstream/Redux/saga";
import userRightSaga from "../pages/users-rights/Redux/saga";

import activeInvoiceWebStreamSaga from "../pages/Invoices/Webstreams/Redux/saga";
import invitationSaga from "../pages/invitation/Redux/saga";
import contactSaga from "../pages/ContactUs/Redux/saga";
import reportSaga from "../pages/Reports/Webstream/Redux/saga";
import referralSaga from "../pages/ReferralsPage/Redux/saga";
// import updateUsersaga from "../components/UserProfileModal/Redux/saga";

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(dashboardSaga),
    fork(overViewSaga),
    fork(pagesSaga),
    fork(activeWebStreamSaga),
    fork(inActiveWebStreamSaga),
    fork(userRightSaga),
    fork(activeInvoiceWebStreamSaga),
    fork(invitationSaga),
    fork(contactSaga),
    fork(reportSaga),
    fork(referralSaga)
    // fork(updateUsersaga),
  ]);
}
