import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Icon from "../../icon";

const DeleteModal = ({ isOpen, toggleFunction, handleRemove, username }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleFunction}>
      <ModalBody
        className="text-center d-flex justify-content-evenly"
        style={{ minHeight: "80vh", flexDirection: "column", width: "100%" }}
      >
        <div className="user-profile">
          <div style={{ marginBottom: "20px" }}>
            <Icon
              icon="user-icon"
              color="#343434"
              disableFill="true"
              size={"10rem"}
            />
          </div>
          <h4>{username}</h4>
        </div>
        <div>
          <div
            style={{
              background: "#BA4640",
              color: "white",
              padding: "20px",
              marginBottom: "10px",
              marginTop: "-80px",
            }}
          >
            ATTENTION
          </div>
          <div>
            By Removing this profile , it's user(s) will no longer have access
            to the affiliate system by using {username}'s profile password.
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <button
          type="button"
          onClick={handleRemove}
          className="btn btn-md outline-danger bg-white mr-4"
        >
          Remove Profile
        </button>
        <button
          type="button"
          onClick={toggleFunction}
          className="btn btn-md outline-primary bg-white"
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
