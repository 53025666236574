import React from "react";
import { Link } from "react-router-dom";
import Summarybar from "../../components/Summarybar";
import Icon from "../../icon";
import { Filter, FilterInput } from "../../components/Filter";
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getReferrals } from "./Redux";
import { months , years,ThalamusPlans, payment_method , payment_status , ACTIVEFORMAT , getFilterStartEndDate} from '../../data/comman';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import './style.scss';
import ReactCountryFlag from "react-country-flag";
import Countryselect from '../../components/Countryselect'
import DefaultPlaceholder from '../../components/DefaultPlaceholder'
import Loader from "../../components/Loader";

const ReferralsIndex = (props) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.referrals.data);
  const country = useSelector((state) => state.pagesData.data.countries.country);
  const { data } = reportData;
  const [searchedText, setSearchedText] = React.useState("");
  const [sortBy, setsortBy] = React.useState('');
  const [orderBy, setorderBy] = React.useState('');
  const [selectedMonth, setMonth] = React.useState('');
  const [selectedYear, setYear] = React.useState('');
  const [selectedPlan, setPlan] = React.useState(0);
  const [selectedCountry, setCountry] = React.useState('');
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [isError, setError] = React.useState("");
  const loading = useSelector((state) => state.referrals.loading);
  // const [loading, setLoading] = React.useState(false);

  const [states, setState] = React.useState({
    active: 0,
    isOpen: false,
    isOpenSubModal: false
  });

  React.useEffect(() => {
    dispatch(getReferrals({pagenum: 1, searchedText , sortBy , orderBy }));
  }, []);
  
  React.useEffect(() => {
    if(searchedText != '' || sortBy!= '' || orderBy!= '' || selectedCountry != '' || selectedPlan != '' || selectedYear != '' || selectedMonth!= ''){
      dispatch(getReferrals({ pagenum: 1, searchedText ,selectedCountry,selectedPlan,selectedYear,selectedMonth, sortBy , orderBy }));
    }
  }, [searchedText , sortBy , orderBy ,selectedCountry, selectedPlan, selectedYear,selectedMonth]);

  const handleNext = () => {
    const nextUrl = reportData.next_page_url;
    if (nextUrl !== null) {
      const pagenum = parseInt(nextUrl.split("=").pop());
      dispatch(getReferrals({ pagenum, searchedText  }));
    }
  };

  const handlePrev = () => {
    const prevUrl = reportData.prev_page_url;
    if (prevUrl !== null) {
      const pagenum = parseInt(prevUrl.split("=").pop());
      dispatch(getReferrals({ pagenum, searchedText }));
    }
  };

  const toggleCollapse = () => {
      setState({ ...states, isOpen: !states.isOpen });
  };

  const toggleModal = (state) => {
    setState({ isOpen: state });
  };

  const onSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  };

  const onChangeCountry = (e) =>{
      setCountry(e.id)
  }

  const onChangesortBy = async (value, order) =>{
    
      if(sortBy == value && orderBy == order){
          setsortBy(value)
          setorderBy('desc')
          
      }else{
        console.log(value,'value>>',order)
          setsortBy(value)
          setorderBy('asc')
      }
  }
  
  const onChangeorderBy = async (e) =>{
      var orderBy  = e.value;
      setorderBy(orderBy)
  }

  const onChangeMonth = async (e) =>{
      setMonth(e.value)
  }

  const onChangeYear = async (e) =>{
      setYear(e.value)
  }

  const onChangePlan = async (e) => {
    console.log(e,'plan')
      setPlan(e.value)
  }
  
  const handleSearch = () => {
      dispatch(getReferrals({ pagenum: 1, searchedText }));
  };

  const getStatus = (id) => {
      switch (id) {
      case 1:
          return ["color-yellow", "Pending"];
      case 2:
          return ['color-green', "Paid"];
      case 3:
          return ['color-red', "Declined"];
      default:
          return ['color-green', "Pending"];
      }
  }

  const Authyear = [
      { value: '1', label: 'All' },
      { value: '2', label: 'Canada' },
      { value: '3', label: 'United States' },
  ]

  const ThalamusPlans = [
      { value: '0', label: 'All' },
      { value: '1', label: 'Master Suite' },
      { value: '2', label: 'Waiting Room' },
  ]

  const SignUpYear = [
      { value: '1', label: 'All' },
      { value: '2', label: '2021' },
  ]

  const SignUpMonth = [
      { value: '1', label: 'All' },
      { value: '2', label: 'December' },
      { value: '3', label: 'November' },
      { value: '4', label: 'October' },
      { value: '5', label: 'September' },
      { value: '6', label: 'August' },
      { value: '7', label: 'July' },
      { value: '8', label: 'June' },
      { value: '9', label: 'May' },
      { value: '10', label: 'April' },
      { value: '11', label: 'March' },
      { value: '12', label: 'February' },
      { value: '13', label: 'January' },
  ]

  return (
    <>
      <Summarybar />
      {loading  && 
          <Loader />
      }
      <div className="card flex-fill">
         <div className="card-box pt-5">
          <div className="card__header card__header__filter lieacence-header flex">
            <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
              <div className="card__title d-flex align-items-center w-auto">
                <h3 className="m-0 mr-4">Referrals</h3>
              </div>
              <div className="ml-auto d-flex align-items-center w-auto flex-wrap">
                <div className="help__inner__search mb-0 webstream-search mx-4">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by keyword"
                      onChange={onSearchTextChange}
                    ></input>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={handleSearch}
                    >
                      <Icon icon="search-icon" color="#357BCF" size={18} />
                    </Button>
                  </div>
                </div>

                <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5 flex-wrap">
                  <p className="m-0">
                    <strong>
                      Showing {reportData.from}-{reportData.to} of{" "}
                      {reportData.total}
                    </strong>
                  </p>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item  border-0">
                      <a className="disabled" href="#">
                        <Icon
                          icon="Left-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handlePrev}
                        />
                      </a>
                    </li>
                    <li className="list-group-item  border-0">
                      <a className="" href="#">
                        <Icon
                          icon="Right-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handleNext}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <button className={`btn-filter btn  ml-auto collapsed`} type="button"  onClick={toggleCollapse} >
                  <i className="mr-3">
                    <Icon
                      icon="Down-arrow"
                      color="#157CF7"
                      disableFill="true"
                      size={15}
                    />
                  </i>{" "}
                  Filter
                </button>
              </div>
            </div>
            <Filter isOpen={states.isOpen}>
              <FilterInput
                type="countrydropDown"
                label="Country"
                options={country}
                placeholder=""
                onChange={(option)=> onChangeCountry(option)}
              />
              <FilterInput
                type="dropDown"
                label="Thalamus plan"
                options={ThalamusPlans}
                onChange={onChangePlan}
                placeholder=""
              />
              <FilterInput
                type="dropDown"
                label="Sign up year"
                options={years}
                onChange={onChangeYear}
                placeholder=" "
              />
              <FilterInput
                type="dropDown"
                label="Sign up month"
                options={months}
                onChange={onChangeMonth}
                placeholder=""
              />
            </Filter>
          </div>
        </div>
        <div className="sub-page__table">
          <PerfectScrollbar>
            <div className="px-5">
              <table className="table mb-0">
                <thead className="sticky">
                  <tr>
                    <th className="pl-0  sticky-column">
                        Practice name
                        <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'name' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('name', 'asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Country
                        <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'country_id' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('country_id', 'asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Region / Province / State
                        <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'state_id' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('state_id','asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Thalamus plan
                        <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'plan_id' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('plan_id','asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column ">
                        Payment amount
                        <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'payment_method' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('payment_method','asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column col-1">
                        Sign up date
                        <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'created_at' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('created_at','asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(reportData).length > 0 &&
                    data.length > 0 ? data.map((item, index) => {
                      const status = getStatus( payment_status )
                      return (
                        <tr key={index} className="">
                            <td className="pl-0"><ReactCountryFlag className="flag-img mr-4" countryCode={item.country && item.country.code} svg style={{ width: '28px', height: '20px', }} title={item.country && item.country.code} />{item.name}</td>
                            <td className="pl-0">{item.country && item.country.name}</td>
                            <td className="pl-0">{item.state && item.state.name}</td>
                            <td className="pl-0">{item.plan && item.plan.name || 'Trial Account'}</td>
                            <td className="pl-0">{item.payment_customer && item.payment_customer.subscription_amount}</td>
                            <td className={``}>{moment(item.created_at).format(ACTIVEFORMAT)}</td>
                        </tr>
                      );
                    })
                    :<tr style={{border:'none'}}><td colSpan={6}><DefaultPlaceholder text={'The practices that you refer will show up here.'}/></td></tr>
                  }
                </tbody>
              </table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export default ReferralsIndex;
