import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Summarybar from '../../components/Summarybar';
import Icon from "../../icon";
import { Filter, FilterInput } from '../../components/Filter';
import ReactCountryFlag from "react-country-flag";
import { Button, Input, Label, CustomInput } from 'reactstrap';
import './style.scss';


const LicenseType = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Bundle' },
    { value: '3', label: 'Waiting Room' },
]
const SignUpYear = [
    { value: '1', label: 'All' },
    { value: '2', label: '2021' },
]
const SignUpMonth = [
    { value: '1', label: 'All' },
    { value: '2', label: 'December' },
    { value: '3', label: 'November' },
    { value: '4', label: 'October' },
    { value: '5', label: 'September' },
    { value: '6', label: 'August' },
    { value: '7', label: 'July' },
    { value: '8', label: 'June' },
    { value: '9', label: 'May' },
    { value: '10', label: 'April' },
    { value: '11', label: 'March' },
    { value: '12', label: 'February' },
    { value: '13', label: 'January' },
]

let practiceId;
class Activewebstream extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            active: 0,
            isOpen: false,
            activeButtonTab: '1',
            ticketsData: this.props.tickets,
            isChecked: false,
            selectAll: false,
            ShowPutonhold: false

        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
    }

    toggleChange(event, ticket) {
        var ticketState = this.state.ticketsData;
        var ticketIndex = ticketState.indexOf(ticket);

        ticket.isChecked = event.target.checked;
        ticketState[ticketIndex] = ticket
        this.setState({
            ticketsData: ticketState
        });

        var selectedinvices = Object.keys(ticketState).filter(k => ticketState[k].isChecked);
        if (selectedinvices.length == Object.keys(ticketState).length) {
            this.selectAll(null, true);
        }
        else {
            this.selectAll(null, false);
        }
        this.setState({
            ShowPutonhold: false
        })
        if (event.target.checked) {
            this.setState({
                ShowPutonhold: true
            })
        }
    }
    toggleCollapse() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    toggleButtons(tab) {
        if (this.state.activeButtonTab !== tab) {
            this.setState({
                activeButtonTab: tab,
            });
        }
    }

    render() {
        const { isOpen } = this.state;
        return (
            <div>
                <Summarybar />
                <div className="card licenses-card">
                    <div className="card-box">
                        <div className="card__header card__header__filter">
                            <div className="row d-flex justify-content-between align-items-center card__header__filter--button mb-4">
                                <div className="card__title d-flex align-items-center w-auto">
                                    <h3 className="m-0 mr-4">Licenses</h3>
                                    <Link to='/licenses-request' className="link-btn outline-primary"><Icon className="mr-4" icon="Plus-square" disableFill="true" size={16}></Icon>Licenses Request</Link>
                                    <Link to='/licenses-extension' className="link-btn outline-primary"><Icon className="mr-4" icon="Plus-square" disableFill="true" size={16}></Icon>License Extension</Link>
                                </div>
                                <div className="d-flex align-items-center col">
                                    <div className="help__inner__search">
                                        <div className="search-box">
                                            <input type="search" className="form-control" placeholder="Search by license no. or practice name"></input>
                                            <Button color="link primary" className="p-0"><Icon icon="search-icon" color="#357BCF" size={18} /></Button>
                                        </div>
                                    </div>
                                    <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5">
                                        <p className="m-0"><strong>Showing 1-50,<span> of 120</span></strong></p>
                                        <ul className="list-group list-group-horizontal">
                                            <li className="list-group-item  border-0"><a className="disabled" href="#" ><Icon icon="Left-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                            <li className="list-group-item  border-0"><a className="" href="#"><Icon icon="Right-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                        </ul>
                                    </div>
                                    <button className={`btn-filter btn  ml-auto collapsed`} type="button" onClick={this.toggleCollapse} >
                                        <i className="mr-3"><Icon icon="Down-arrow" color="#157CF7" disableFill="true" size={15} /></i> Filter
                                    </button>
                                </div>
                            </div>
                            <Filter isOpen={isOpen} >
                                <FilterInput type="dropDown" label="License type" options={LicenseType} placeholder="-" />
                                <FilterInput type="dropDown" label="Start year" options={SignUpYear} placeholder="-" />
                                <FilterInput type="dropDown" label="Start month" options={SignUpMonth} placeholder="-" />
                                <FilterInput type="dropDown" label="Deactivation year" options={SignUpYear} placeholder="-" />
                                <FilterInput type="dropDown" label="Deactivation month" options={SignUpMonth} placeholder="-" />
                            </Filter>
                        </div>
                    </div>
                    <div className="card-box-body">
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th width="50%">License no.</th>
                                        <th>License type</th>
                                        <th>Start date</th>
                                        <th>Expiry date</th>
                                        <th>Inactive since</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>20 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>20 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>20 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>20 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Waiting Room</td>
                                        <td>17 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>17 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>16 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Waiting Room</td>
                                        <td>16 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>15 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>15 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>15 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>15 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>14 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>14 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>14 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                    <tr>
                                        <td><CustomInput className="sm" type="checkbox" id="exampleCustomCheckbox" label="ADSL01872020" /></td>
                                        <td>Bundle</td>
                                        <td>14 Aug 2020</td>
                                        <td>n/a</td>
                                        <td className="text-danger">14 Dec 2020</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Activewebstream;
