import axios from "../utilities/interceptor/axiosconfig";
import { useSelector } from "react-redux";
import global from "./global";

const baseUrl = global.domain;

export const loginUser = async (username, password , secret) => {
  try {
    const data = {username, password,secret};
    const url = baseUrl + "/affiliate/login";
    let response = await axios.post(url, data);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const logoutUser = async () => {
  try {
    const url = baseUrl + "/affiliate/logout";
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const newUserPassword = async (password, repassword) => {
  try {
    const data = { password, password_confirmation: repassword };
    const url = baseUrl + "/affiliate/user/create/password";
    let response = await axios.post(url, data);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const resetPassword = async (password, repassword, token) => {
  try {
    const data = { password, password_confirmation: repassword , token:token};
    const url = baseUrl + "/affiliate/user-reset-password";
    let response = await axios.post(url, data);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const sendLoginEmail = async (email) => {
  try {
    const reqbody = { email };
    const url = baseUrl + "/affiliate/user-change-password";
    let { data } = await axios.post(url);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const userForgetPassword = async (email) => {
  try {
    const reqbody = { email };
    const url = baseUrl + "/affiliate/user-forget-password";
    let { data } = await axios.post(url,reqbody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const updateUserLoginProfile = async (reqbody) => {
  try {
    const url = baseUrl + `/affiliate/user/profile`;
    const { data } = await axios.put(url, reqbody);
    return data;
  } catch (err) {
    if (err) {
      throw err.response.data;
    }
  }
};

export const updateTos = async (reqbody) => {
  try {
    const url = baseUrl + `/affiliate/tos?status=${reqbody.status}&need_send_email=${reqbody.need_send_email}`;
    let response = await axios.post(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response;
    }
  }
};

export const updateSignUpTos = async (reqbody) => {
  try {
    const url = baseUrl + `/affiliate/tos?signup_tos_status=${reqbody}`;
    let response = await axios.post(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response;
    }
  }
}