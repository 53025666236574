import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";
import { PER_PAGE } from "./common";

const baseUrl = global.domain;

export const getReferralApiCall = async (PAGE_NUM, searchedText = "", selectedCountry='',selectedPlan='',selectedYear='',selectedMonth='',sort_by='',order_by = '') => {
  try {
    const url =
      baseUrl + `/affiliate/referrals?search=${searchedText}&per_page=${PER_PAGE}&page=${PAGE_NUM}&sign_up_year=${selectedYear}&sign_up_month=${selectedMonth}&country_id=${selectedCountry}&plan_id=${selectedPlan}&sort_by=${sort_by}&order_by=${order_by}`;
    const data = await axios.get(url);
    return data.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};
