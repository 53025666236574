let object = {
  domain: "",
};

if (process.env.NODE_ENV === "production") {
  object.domain = process.env.REACT_APP_BASE_URL.substring(0, process.env.REACT_APP_BASE_URL.length - 1);//"https://api.thalamusmedia.com";
} else {
  object.domain = process.env.REACT_APP_BASE_URL.substring(0, process.env.REACT_APP_BASE_URL.length - 1);
}
export default object;
