import React from "react";
import Summarybar from "../../components/Summarybar";
import { useDispatch, useSelector } from "react-redux";
import { getExpiredPromocodeLoading } from "./Redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import Icon from "../../icon";
import { Link, useHistory } from "react-router-dom";
import { ACTIVEFORMAT } from "../../data/comman";
import Loader from "../../components/Loader";
import { Button } from "reactstrap";

const WebstreamReport = (props) => {
    const history = useHistory();
  
  const dispatch = useDispatch();
  const expiredPromocodeData = useSelector((state) => state.overview.expiredPromocode);
  const loading = useSelector((state) => state.overview.expiredPromocode.loading);
  const [sortBy, setsortBy] = React.useState('');
  const [orderBy, setorderBy] = React.useState('desc');
  const d = useSelector((state) => state.overview);
  const { data } = expiredPromocodeData;
 
  const [states, setState] = React.useState({
    active: 0,
    isOpen: false,
    isOpenSubModal: false
  });

  const onChangesortBy = async (value, order) =>{
    if(sortBy == value && orderBy == order){
        setsortBy(value)
        setorderBy('desc')
        
    }else{
      console.log(value,'value>>',order)
        setsortBy(value)
        setorderBy('asc')
    }
  }

  React.useEffect(() => {
    dispatch(getExpiredPromocodeLoading({ pagenum: 1, sortBy,orderBy}));
  }, [sortBy,orderBy]);

  React.useEffect(() => {
    dispatch(getExpiredPromocodeLoading({ pagenum: 1, sortBy,orderBy}));
  }, []);

  return (
    <>
     {/* {console.log(data,'data')} */}
      <Summarybar />
      {/* {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : ( */}
      <div className="card flex-fill">
        <div className="card-box px-5 pt-5">
            <div className="card__body px-5">
                <div className="card__header card__header__filter lieacence-header flex">
                    <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                        <div className="card__title d-flex align-items-center w-auto">
                            <Button  type="button" color="primary link" onClick={() => history.goBack()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
                            <h3 className="m-0 mr-4">Expired Promo Codes</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sub-page__table px-5">
          <PerfectScrollbar>
            <div className="px-5">
              <table className="table mb-0">
                <thead className="sticky">
                  <tr>
                    <th className="pl-0 w-50 sticky-column">
                      Name
                        <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'title' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('title', 'asc')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                    Code
                    <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'code' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('code', 'asc')}>
                        <Icon
                        icon="Down-arrow"
                        color="#157CF7"
                        disableFill="true"
                        size={15}
                        />
                    </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                    Plans
                    </th>
                    {/* <th className="pl-0 sticky-column">
                    Program
                    </th> */}
                    <th className="pl-0 sticky-column">
                    Description
                      <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'description' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('description', 'asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column col-1">
                    Start date
                      <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'start_date' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('start_date', 'asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column col-1">
                    Expiry date
                      <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'end_date' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('end_date', 'asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                 
                  {
                  Object.keys(expiredPromocodeData).length > 0 &&
                  data && data.length > 0 && data.map((item, index) => {
                      const { title,code,plan_id,description,start_date,end_date, plugins} = item;
                      return (
                        <tr key={index} className="">
                            <td className="pl-0">{title}</td>
                            <td className="pl-0">{code}</td>
                            <td className="pl-0">{plugins?.map((x, index) => `${x.name}${index != plugins.length - 1 ? ", " : ""}`)}</td>
                            {/* <td className="pl-0">{plan_id}</td> */}
                            <td className="pl-0"><span dangerouslySetInnerHTML={{ __html: description }}/></td>
                            <td className="pl-0">{moment(start_date).format(ACTIVEFORMAT)}</td>
                            <td className="pl-0 color-red">{moment(end_date).format(ACTIVEFORMAT)}</td>
                        </tr>
                      );
                    })}
                 
                </tbody>
              </table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default WebstreamReport;
