import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";
import { PER_PAGE } from "./common";

const baseUrl = global.domain;

export const getReportsApiCall = async (PAGE_NUM, searchedText = "",  sort_by='' , order_by = '', reportType = '') => {
  console.log(reportType,'reportType')
  try {
    const url =
      baseUrl + `/affiliate/reports?type=${reportType}&per_page=${PER_PAGE}&page=${PAGE_NUM}&report_no=${searchedText}&sort_by=${sort_by}&order_by=${order_by}`;
    const data = await axios.get(url);
    return data.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

  export const getReportDetails = async (id) => {
    try {
      const url = baseUrl + `/affiliate/reports/${id}`;
      const { data } = await axios.get(url);
      return data.data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
  };

  export const downloadInvoices = async (invoice_id, type, download_type) => {
    let params ={
      affiliate_report_id:invoice_id,
      type:type,
    }
    try {
      const url = baseUrl + `/affiliate/reports/download`;
      const  data  = await axios.post(url,params);
      return data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
  };