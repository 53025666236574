import getReportsSlice from "./slice";

export const {
  getReports,
  getReportsFailure,
  getReportsSuccess,
  reset,
  setSelectedReport,
  loadingSelectedReport,
  loadingDownloadInvoice,
  setDownloadInvoice
} = getReportsSlice.actions;

export default getReportsSlice.reducer;
