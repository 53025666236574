import React, { useState, useEffect } from 'react';
import {
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Logo from '../../images/affilate-logo.svg'
import './style.scss'

const Invitation =  () => {
  const history = useHistory();
  return (
    <>
    <div className="min-vh-100 d-flex flex-column justify-content-center ">
        <div className='linkExpiredPage text-center'>
            <div className='flex-grow-1 justify-content-center d-flex flex-column align-items-center'>
                <h1 className='mb-5'>Link Expired</h1>
                <p>This link is not active any more. The form has been filled. Your affiliate account has been created. 
                    If you have any questions please contact us at <a href="mailto:support@thalamusmedia.com">support@thalamusmedia.com</a></p>
                <Button size="sm" color="primary" className='brandBtn' onClick={() => history.push('/')}>Sign in</Button>
            </div>
        </div>
    </div>
    </>
  );
};
export default Invitation;
