import React,{useEffect} from "react";
import AppDropDown from "../../components/AppDropDown";
import Summarybar from "../../components/Summarybar";
import MyEditor from "../../components/MyEditor/MyEditor"
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import HttpUtility, { HELPERURI } from "../../utilities/HttpUtility";
import DatePicker from "react-datepicker";
import Icon from "../../icon";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDataAccordingToID } from ".";
import { prepareReqBodyForEditOverView } from "./helper";
import { sendLoginEmail } from "../../service/login";
import { updateOverviewData , uploadBankingDetailFile } from "../../service/overview";
import { EditorButtonType} from "../../data/comman";
import { InputField } from "../../components/InputField";
import { EDIT_USER } from "./Redux/actions";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { loadingEditOverview , loadingOverview} from "./Redux";

import LoadingButton from '../../components/LoadingButton';
import { handleValidations } from "../../utilities/validations";
import moment from "moment";

const paymentfrq = [
  { id: 1, name: "Monthly" },
  { id: 2, name: "Yearly" },
];
const paymentmethod = [
  { id: 0, name: "Cash" },
  { id: 1, name: "Cheque" },
  { id: 2, name: "Direct deposit (ACH/PAP)" },
];


const convertDataIntoDropDownForm = (dropDownData) => {
  return dropDownData.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};


const EditOverview = () => {
  const history = useHistory();
  const overViewData = useSelector((state) => state.overview.data);
  const {
    admin_user,
    company_name,
    industries_id,
    accounting_email,
    referral_link,
    pre_live_url,
    territories_id,
    created_at,
    address,
    banking_detail,
    payment_method,
    payment_type,
    last_payment_amount,
    tier,
  } = overViewData;

  const industryData = useSelector(
    (state) => state.pagesData.data.industries.industries
  );
  // const btnLoading = useSelector((state) => state.overview.btnLoading);
  const error = useSelector((state) => state.overview.errorEditOverView);
  const [operatioOfficeState, setOperatioOfficeState] = React.useState("");
  const [headOfficeState, setheadOfficeState] = React.useState("");
  const [btnLoading, setbtnLoading] = React.useState(false);
  const [iscopyAddress, setCopyAddress] = React.useState(false);
  const [backModal, setBackModal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [fileStates, setFileState] = React.useState({
    src: '',
    bfile: '',
    errors:''
  });
  const countryData = useSelector(
    (state) => state.pagesData.data.countries.country
  );
  const [dataState, setState] = React.useState({
    isOpen: false,
  });
  const [btnEmailState, setBtnEmailState] = React.useState({
    color:'primary',
    message: 'Send Email',
    loading:false
  });

  useEffect(()=>{
    window.history.pushState(null , null ,window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate' , onBackButtonEvent);
  },[]);

  const onBackButtonEvent = (event) =>{
    event.preventDefault();
    goBackButtonHandler();
  }
  
  function showModal(modal) {
    setState({ ...dataState, [modal]: true });
  }

  function closeModal(modal) {
    setState({ ...dataState, [modal]: false });
  }

  React.useEffect(() => {
    async function fetchMyAPI() {
      getState(overViewData.address[0].country_id , true);
      getState(overViewData.address[1].country_id , false);
    }
    fetchMyAPI()
  }, [overViewData])

  

  const paymentMethodData = useSelector(
    (state) => state.pagesData.data.paymentMethods["affiliate_payment_method"]
  );
  
  const dispatch = useDispatch();
  const [isError, setError] = React.useState("");

  const [overviewReqData, setOverViewReqData] = React.useState({
    name: admin_user.full_name,
    company_name: company_name,
    industries: convertDataIntoDropDownForm(
      getDataAccordingToID(industryData, [...industries_id])
    ),
    phone: admin_user.phone,
    email: admin_user.email,
    accounting_email: accounting_email,
    username: admin_user.username,
    referral_link: referral_link,
    pre_live_url: pre_live_url,
    territories_id: convertDataIntoDropDownForm(
      getDataAccordingToID(countryData, [...territories_id])
    ),
    affiliateDate: new Date(moment(created_at)),

    headOffice: {
      country: convertDataIntoDropDownForm(
        getDataAccordingToID(countryData, [address[0].country_id])
      ),
      zip: address[0].zip,
      city: address[0].city,
      address_line1: address[0].address_line_1,
      address_line2: address[0].address_line_2,
      selectedState:address[0].state
    },
    operatioOffice: {
      country: convertDataIntoDropDownForm(
        getDataAccordingToID(countryData, [address[1].country_id])
      ),
      zip: address[1].zip,
      city: address[1].city,
      address_line1: address[1].address_line_1,
      address_line2: address[1].address_line_2,
      selectedState:address[1].state
    },
    banking_detail:banking_detail,
    payment_method:convertDataIntoDropDownForm(
      getDataAccordingToID(paymentMethodData, [payment_method])
    ),
    payment_type:convertDataIntoDropDownForm(
      getDataAccordingToID(paymentfrq, [payment_type])
    ),
    last_payment_amount:last_payment_amount
  });
  // const {}

  const handleOverViewChangeData = (e) => {
    setOverViewReqData({ ...overviewReqData, [e.target.name]: e.target.value });
    setShowAlert(true)
  };

  const handleSubmit = async () => {
    
    const reqbody = prepareReqBodyForEditOverView(overviewReqData);
    // dispatch(loadingEditOverview(reqbody) );

    var errors = {};
    var isError = false;
    if (reqbody) {
      console.log(reqbody,'reqbody')
      if (reqbody.name == "") {
        errors['name'] = "The affiliate is required.";
        isError = true;
      }
      if (reqbody.company_name == "") {
        errors['company_name'] = "The company name is required.";
        isError = true;
      }

      if (reqbody.industries_id == "") {
        errors['industries_id'] = "Please select any of these industries.";
        isError = true;
      }

      if (reqbody.phone == "") {
        errors['phone'] = "Please enter phone number.";
        isError = true;
      }

      if (reqbody.username == "") {
        errors['username'] = "Please enter username.";
        isError = true;
      }

      
      if (reqbody.address.head_office.country_id == "") {
        errors['address.head_office.country_id'] = "Please select head office country.";
        isError = true;
      }

      if (reqbody.address.head_office.city == "") {
        errors['address.head_office.city'] = "Please select head office city/town.";
        isError = true;
      }

      if (reqbody.address.head_office.address_line_1 == "") {
        errors['address.head_office.address_line_1'] = "Please enter head office street and no.";
        isError = true;
      }

      if (reqbody.address.head_office.state_id == "") {
        errors['address.head_office.state_id'] = "Please select head office state/province.";
        isError = true;
      }

      if (reqbody.address.head_office.zip == "") {
        errors['address.head_office.zip'] = "Please enter head office zip / postal code.";
        isError = true;
      }

      // if (reqbody.pre_live_url == "") {
      //   errors['pre_live_url'] = "Please enter valid domain name.";
      //   isError = true;
      // }else{
      //   const isValidated = handleValidations(reqbody.pre_live_url, "domain");
      //   if(isValidated != true) { 
      //     errors['pre_live_url'] = "Please enter valid domain name.";
      //     isError = true;
      //   }
      // }

      if (reqbody.payment_method == "") {
        errors['payment_method'] = "Please select payment method";
        isError = true;
      }
      
    }
    if (isError) {
      setError(errors);
      return false
    }
    setbtnLoading(true)
    try {
      const response = await updateOverviewData(reqbody);
      if(response.status=== 200){
        setbtnLoading(false);
        showModal("savechanges")
        dispatch(loadingOverview());
        setShowAlert(false);
      }
      setError("");
    } catch (err) {
        setbtnLoading(false)
        setError(err.errors);
    }
  };

  const handleIndustries = (val) => {
    setOverViewReqData({ ...overviewReqData, industries: val });
    setShowAlert(true);
  };

  const handleTerritories = (val) => {
    setOverViewReqData({ ...overviewReqData, territories_id: val });
    setShowAlert(true);
  };

  const handleChangeAddress = (e, isHeadOffice = false) => {
    setCopyAddress(!iscopyAddress);
    setShowAlert(true);
    if (!isHeadOffice) {
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleChangeCountryForAddress = (val, isHeadOffice = false) => {
    setCopyAddress(!iscopyAddress);
    setShowAlert(true);
    if (val && val.value) getState(val.value , isHeadOffice);

    if (!isHeadOffice) {
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          selectedState:[],
          country: [val],
        },
      });
      
    } else {
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          selectedState:[],
          country: [val],
        },
      });
    }
   
  };

  const getState = (countryId , isHeadOffice)  =>{
    if(countryId){
      HttpUtility.getRequest(HELPERURI + countryId + "/state").then(
        (response) => {
          if (response.status == 200) {
            var countryState = response.data
            if (!isHeadOffice) {
              setOperatioOfficeState(countryState)
            }else{
              setheadOfficeState(countryState)
            }
          }
        }
      );
    }
  }

  const copyAddress = async () =>{
    setCopyAddress(!iscopyAddress);
    setShowAlert(true);
    setOverViewReqData({
      ...overviewReqData,
      operatioOffice: {
        ...overviewReqData.headOffice,
      },
    });
  }



  const handleEmailSending = async () => {
    try {
      setBtnEmailState({
        message: 'Send Email',
        color:'primary',
        loading:true
      })
      const data = await sendLoginEmail(overviewReqData.email);
      if(data.status === 200){
        setBtnEmailState({
          message:'Email Sent',
          color:'secondry',
          loading:false
        })
      }
      
      
    } catch (err) {
      closeModal("resetpassword")
    }
    setTimeout(() => {
        closeModal("resetpassword");
        setBtnEmailState({
          color:'primary',
          message: 'Send Email',
          loading:false
        })
    }, 1500);
  };

  const handleChange = (field , val , countryForNumber = null) =>{
    setShowAlert(true);
    if(field === 'headOfficeStates'){
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          selectedState:val
        },
      });
    }else if(field === 'operatioOffice'){
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          selectedState:val
        },
      });
    }else if(field === 'payment_type' || field === 'payment_method'){
      setOverViewReqData({
        ...overviewReqData,
        [field]: [val],
      });
    }else if(field === 'phone'){
      var phn = `${val.split(" ")[0]}-${(val.replace(val.split(" ")[0],'')).replace(/[^\w]/gi, '')}`
      setOverViewReqData({
        ...overviewReqData,
        [field]: phn,
      });
    }
    else{
      setOverViewReqData({
        ...overviewReqData,
        [field]: val,
      });
    }
  }

  const goBackButtonHandler = () =>{
    if(showAlert){
      toggleBackModal(true);
    }else{
      history.goBack()
    }
  }
  

  const toggleBackModal = (state) => {
    setBackModal(state);
  };

  const validateFile = (file)=> {
    var isError = false;
    var errors = ''

    const validTypes = ['image/jpeg', 'image/jpg', 'image/png' , 'application/pdf'];
    
    if (validTypes.indexOf(file.type) === -1) {
        isError = true;
        errors = {
            fileType: 'Format not accepted',
        }
        
    }else if(file.size > 10485760){
        isError = true;
        errors = {
            fileSize: 'Max 10Mb',
        }
    }
    if(isError){ 
      setFileState({errors});
        return;
    }else{
        return true;
    }
  }

  const onChangeFile = async (e) =>{
   
    if (validateFile(e.target.files[0])) {
      if (e.target.files && e.target.files.length > 0) {
        setFileState({
          bfile:e.target.files[0].name
        });
        const formData = new FormData();
        formData.append( "banking_detail_file", e.target.files[0]);
        try {
          const response = await uploadBankingDetailFile(formData );
          console.log(response,'response')
          if(response.status=== 200){
            setOverViewReqData({
              ...overviewReqData,
              banking_detail_file:response.data.file_name
            });
            setFileState({
              bfile: ''
            });
          }
          setError("");
        } catch (err) {
            setError(err.errors);
        }
        
      }
    }
  }

  return (
    <div>
      <Summarybar />
      <div className="card py-4">
        <div className="card-box px-5">
          <div className="card__body  px-5">
            <div className="card__title card__header card__header__filter d-flex align-items-center py-4 mb-4">
              <Button  type="button" color="primary link" onClick={() => goBackButtonHandler()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
              <h3 className="m-0 mr-4">Business Details</h3>
              {error.errors && Object.values(error.errors)[0] && <span className="text-danger">{Object.values(error.errors)[0]}</span>}
            </div>
            <Row>
              <div className="col-lg-5 pr-lg-5">
                <FormGroup row className="align-items-center">
                  <Label for="name" className="" sm={3}>
                    Affilate Name
                  </Label>
                  <Col sm={9}>
    
                    <div className="form-group-with-error">
                      <InputField type="text"  className={isError && isError['name'] ? 'invalid-field' : ''} name="name" isError={isError && isError['name'] } onChange={handleOverViewChangeData} value={overviewReqData.name}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="name" className="" sm={3}>
                    Company Name
                  </Label>
                  <Col sm={9}>
    
                    <div className="form-group-with-error">
                      <InputField type="text"  className={isError && isError['company_name'] ? 'invalid-field' : ''} name="company_name" isError={isError && isError['company_name'] } onChange={handleOverViewChangeData} value={overviewReqData.company_name}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="industries" className="" sm={3}>
                    Industries
                  </Label>
                  <Col sm={9}>
                  <div className="form-group-with-error">
                  {isError && isError['industries_id'] && <span className="text-danger">{isError['industries_id']}</span>}
                  <AppDropDown
                    className={`react-select-container ${isError && isError['industries_id'] ? 'select-border-danger' : ''}`}
                    classNamePrefix="react-select"
                    options={convertDataIntoDropDownForm(industryData)}
                    isMulti={true}
                    placeholder={"-"}
                    defaultValue={overviewReqData.industries}
                    onChange={(val) => handleIndustries(val)}
                  />
                  </div>
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center">
                  <Label for="phone" className="" sm={3}>
                    Phone
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      {/* <InputField type="text"  className={isError && isError['phone'] ? 'invalid-field' : ''} name="phone" isError={isError && isError['phone'] } onChange={handleOverViewChangeData} value={overviewReqData.phone}/> */}
                      <PhoneInput defaultMask={'-...........'} countryCodeEditable={false} alwaysDefaultMask={true}  inputProps={{ name: 'phone',required: true, }}  preferredCountries={['ca','us','gb','au','de']} onChange={(value, country, e, formattedValue) => { handleChange("phone" , formattedValue, country, e, value)}} value={overviewReqData.phone} />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="email" className="" sm={3}>
                    Email
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      <InputField type="email"  className={isError && isError['email'] ? 'invalid-field' : ''} name="email" isError={isError && isError['email'] } onChange={handleOverViewChangeData} value={overviewReqData.email}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="accounting_email" className="" sm={3}>
                    Accounting email
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      <InputField type="email"  className={isError && isError['accounting_email'] ? 'invalid-field' : ''} name="accounting_email" isError={isError && isError['accounting_email'] } onChange={handleOverViewChangeData} value={overviewReqData.accounting_email}/>
                    </div>
                  </Col>
                </FormGroup>
              </div>
              <div className="col-lg-5 pl-lg-5">
                <FormGroup row className="align-items-center">
                  <Label for="username" className="" sm={3}>
                    Username
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      <InputField type="text"  className={isError && isError['username'] ? 'invalid-field' : ''} name="username" isError={isError && isError['username'] } onChange={handleOverViewChangeData} value={overviewReqData.username}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="password" className="" sm={3}>
                    Password
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="password"
                      name="password"
                      placeholder="-"
                      value="123123123"
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="referral_link" className="" sm={3}>
                    Referral link
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      {/* <span>thalamusmedia.com/affiliates/</span> */}
                      <InputField type="text" disabled  className={isError && isError['referral_link'] ? 'invalid-field' : ''} name="referral_link" isError={isError && isError['referral_link'] } onChange={handleOverViewChangeData}  value={`${overviewReqData.referral_link}`}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="pre_live_url" className="" sm={3}>
                   Webstream Pre-live Domain
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      <InputField type="text"  className={isError && isError['pre_live_url'] ? 'invalid-field' : ''} placeholder="Available when Webstreams is added" name="pre_live_url" isError={isError && isError['pre_live_url'] } onChange={handleOverViewChangeData}  value={overviewReqData.pre_live_url}/>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="territories" className="" sm={3}>
                    Territories
                  </Label>
                  <Col sm={9}>
                    <div className="form-group-with-error">
                      {isError && isError['territories_id'] && <span className="text-danger">{isError['territories_id']}</span>}
                      <AppDropDown
                        className={`react-select-container ${isError && isError['territories_id'] ? 'select-border-danger' : ''}`}
                        classNamePrefix="react-select"
                        options={convertDataIntoDropDownForm(countryData)}
                        placeholder={"-"}
                        isMulti={true}
                        value={overviewReqData.territories_id}
                        onChange={(val) => handleTerritories(val)}
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="tostype" className="" sm={3}>
                    Affiliate since
                  </Label>
                  <Col sm={9}>
                    <DatePicker
                      className="custom-datepicker form-control mb-2"
                      selected={overviewReqData.affiliateDate}
                      // onChange={(date) => this.handleChange(date, "activeDate")}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Click the icon to select a date"
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div className="col-lg-2 pr-lg-2">
                <FormGroup row className="align-items-center">
                  <Label for="username" className="" sm={12}></Label>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Link
                    sm={12}
                    className="btn outline-primary py-2 px-5"
                    onClick={() => showModal("resetpassword")}
                  >
                    Send a password reset email
                  </Link>
                </FormGroup>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <div className="affilate-address-sec px-5 py-4">
        <div className="px-5 py-4">
          <Row>
            <div className="col-lg-6 pr-lg-5">
              <FormGroup row className="align-items-center">
                <Label for="affilatename" className="" sm={4}>
                  Head Office address
                </Label>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="affilatename" className="" sm={3}>
                  Country
                </Label>
                <Col sm={9}>
                  <div className="country-select">
                    <AppDropDown
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={convertDataIntoDropDownForm(countryData)}
                      placeholder={"-"}
                      isMulti={false}
                      value={overviewReqData.headOffice.country}
                      onChange={(val) =>
                        handleChangeCountryForAddress(val, true)
                      }
                      // onChange={(val)=> this.props.handleChange('industries', val, "dropdown") }
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="city" className="" sm={3}>
                  City / Town
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.head_office.city'] ? 'invalid-field' : ''}  name="city" isError={isError && isError['address.head_office.city'] } onChange={(e) => handleChangeAddress(e, true)} value={overviewReqData.headOffice.city}/>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className="align-items-center">
                <Label for="address_line1" className="" sm={3}>
                  Street and No.
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.head_office.address_line_1'] ? 'invalid-field' : ''}  name="address_line1" isError={isError && isError['address.head_office.address_line_1'] } onChange={(e) => handleChangeAddress(e, true)} value={overviewReqData.headOffice.address_line1}/>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="address_line2" className="" sm={3}></Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.head_office.address_line_2'] ? 'invalid-field' : ''}  name="address_line2" isError={isError && isError['address.head_office.address_line_2'] } onChange={(e) => handleChangeAddress(e, true)} value={overviewReqData.headOffice.address_line2}/>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="citytown" className="" sm={3}>
                  State / Province
                </Label>
                <Col sm={9}>
                  <AppDropDown
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={headOfficeState}
                    getOptionLabel={(x) => `${x.name}`}
                    getOptionValue={(x) => `${x.name} ${x.id}`}
                    value={overviewReqData.headOffice.selectedState}
                    placeholder={"-"}
                    onChange={(val)=> handleChange('headOfficeStates', val) }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="zip" className="" sm={3}>
                  Zip / Postal code
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.head_office.zip'] ? 'invalid-field' : ''}  name="zip" isError={isError && isError['address.head_office.zip'] } onChange={(e) => handleChangeAddress(e, true)} value={overviewReqData.headOffice.zip}/>
                  </div>
                </Col>
              </FormGroup>
            </div>
            <div className="col-lg-6 pr-lg-5">
              <FormGroup row className="align-items-center">
                <Label for="affilatename" className="" sm={4}>
                  Operations Office address
                </Label>
                <Col sm={8} className="text-right">
                  <div className="custom-control custom-checkbox custom-control-inline ml-4">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="sam-address"
                      checked={iscopyAddress}
                      onChange={copyAddress} 
                    />
                    <Label className="custom-control-label" for="sam-address">
                      Same as Head Office
                    </Label>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="affilatename" className="" sm={3}>
                  Country
                </Label>
                <Col sm={9}>
                  <div className="country-select">
                    <div className="form-group-with-error">
                      {isError && isError['address.opration_office.country_id'] && <span className="text-danger">{isError['address.opration_office.country_id']}</span>}
                      <AppDropDown
                        className={`react-select-container ${isError && isError['address.opration_office.country_id'] ? 'select-border-danger' : ''}`}
                        classNamePrefix="react-select"
                        options={convertDataIntoDropDownForm(countryData)}
                        placeholder={"-"}
                        isMulti={false}
                        value={overviewReqData.operatioOffice.country}
                        onChange={(val) =>
                          handleChangeCountryForAddress(val, false)
                        }
                      />
                    </div>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="city" className="" sm={3}>
                  City / Town
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.opration_office.city'] ? 'invalid-field' : ''}  name="city" isError={isError && isError['address.opration_office.city'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.city}/>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className="align-items-center">
                <Label for="address_line1" className="" sm={3}>
                  Street and No.
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.opration_office.address_line_1'] ? 'invalid-field' : ''}  name="address_line1" isError={isError && isError['address.opration_office.address_line_1'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.address_line1}/>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="address_line2" className="" sm={3}></Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.opration_office.address_line_2'] ? 'invalid-field' : ''}  name="address_line2" isError={isError && isError['address.opration_office.address_line_2'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.address_line2}/>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="citytown" className="" sm={3}>
                  State / Province
                </Label>
                <Col sm={9}>
                  <AppDropDown
                    className="react-select-container"
                    classNamePrefix="react-select"
                    getOptionLabel={(x) => `${x.name}`}
                    getOptionValue={(x) => `${x.name} ${x.id}`}
                    options={operatioOfficeState}
                    value={overviewReqData.operatioOffice.selectedState}
                    onChange={(val)=> handleChange('operatioOffice', val) }
                    placeholder={"-"}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="zip" className="" sm={3}>
                  Zip / Postal code
                </Label>
                <Col sm={9}>
                  <div className="form-group-with-error">
                    <InputField type="text"  className={isError && isError['address.opration_office.zip'] ? 'invalid-field' : ''}  name="zip" isError={isError && isError['address.opration_office.zip'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.zip}/>
                  </div>
                </Col>
              </FormGroup>
            </div>
          </Row>
        </div>
      </div>
      <div className="card py-4">
        <div className="card-box py-4 px-5">
          <div className="card__body py-4 px-5">
          <Row>
              {tier && tier.map((item)=>{
              return  <Col lg={4} className="d-flex flex-column">
                <label className="mb-4">
                  <strong className="text-capitalize">{item.tier_type}</strong>
                </label>
                <div className="tier-header">
                  <h4>{item.title}</h4>
                </div>
                <div className="tier-desc flex-grow-1">
                  <ul>
                    {item.value.map((value)=>
                      <li>
                        <p>{value.title}</p>
                        { value.include.map((fee)=>
                            <div>
                              <p className="text-capitalize m-0 fw-bold">{fee.text}</p>
                              <span>{fee.charge_type == 'fixed' && '$'}{fee.charge}{fee.charge_type == 'percentage' && '%'}</span>
                            </div>
                          )}
                      </li>
                    )}
                  </ul>
                </div>
                </Col>
              })}
            </Row>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="py-4 px-4">
          <div className="py-4 px-4">
            <div className="d-flex align-items-center mb-5">
              <h3 className="m-0 mr-4">Commissions</h3>
            </div>
            <Row>
              <Col lg="6">
                {/* <FormGroup row className="align-items-center">
                  <Label for="Last payment" className="" lg={4}>
                    Last payment
                  </Label>
                  <Col lg={8}>
                  <div className="form-group-with-error">
                    <InputField type="text" disabled  className={isError && isError['last_payment_amount'] ? 'invalid-field' : ''}  name="last_payment_amount" isError={isError && isError['last_payment_amount'] } onChange={handleOverViewChangeData} value={overviewReqData.last_payment_amount}/>
                  </div>
                  </Col>
                </FormGroup> */}
                {/* <FormGroup row className="align-items-center">
                  <Label for="Payment freq." className="" lg={4}>
                    Payment freq.
                  </Label>
                  <Col lg={8}>
                    <AppDropDown
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={convertDataIntoDropDownForm(paymentfrq)}
                      placeholder={"-"}
                      isDisabled={true}
                      value={overviewReqData.payment_type}
                      onChange={(val)=> handleChange('payment_type', val, "dropdown") }
                    />
                  </Col>
                </FormGroup> */}
                <FormGroup row className="align-items-center">
                  <Label for="Payment method" className="" lg={4}>
                    Payment method
                  </Label>
                  <Col lg={8}>
                    <div className="form-group-with-error">
                    {isError && isError['payment_method'] && <span className="text-danger">{isError['payment_method']}</span>}
                    <AppDropDown
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={convertDataIntoDropDownForm(paymentMethodData)}
                      placeholder={"-"}
                      value={overviewReqData.payment_method}
                      onChange={(val)=> handleChange('payment_method', val, "dropdown") }
                    />
                    </div>
                  </Col>
                </FormGroup>
              </Col>
              
              {/* <Col lg="12">
                <div className="d-flex flex-wrap my-4">
                  <Label className="">
                    <strong>
                      Credit card - Optionally stored for invoice payment
                    </strong>
                  </Label>
                  <ul className="list-inline ml-4">
                    <li className="list-inline-item m-0">
                      <img
                        width="35px"
                        src={MasterCard}
                        className="paycard_icon mr-3"
                      />
                    </li>
                    <li className="list-inline-item m-0">
                      <img
                        width="35px"
                        src={VisaCard}
                        className="paycard_icon mr-3"
                      />
                    </li>
                    <li className="list-inline-item m-0">
                      <img
                        width="35px"
                        src={amex}
                        className="paycard_icon mr-3"
                      />
                    </li>
                    <li className="list-inline-item m-0">
                      <img
                        width="35px"
                        src={discover}
                        className="paycard_icon mr-3"
                      />
                    </li>
                  </ul>
                </div>
                <div className="my-4">
                  <Link
                    to=""
                    className="card__link card--acct_date__link d-flex align-items-center justify-content-start btn btn-link"
                  >
                    <Icon
                      className="mr-3"
                      icon="Plus-square"
                      color="#157CF7"
                      disableFill="true"
                      size={15}
                    />
                    <span>Add new credit card</span>
                  </Link>
                </div>
                <Row>
                  <Col lg={8}>
                    <div className="credit__cards__list__item pb-4">
                      <div className="d-flex align-items-center pb-4 my-1">
                        <div className="col-1">
                          <img
                            width="35px"
                            src={MasterCard}
                            className="paycard_icon"
                          />
                        </div>
                        <div className="col">
                          <label className="col-form-label">Carla Smith</label>
                        </div>
                        <div className="col">EXP 09/22</div>
                        <div className="col">
                          <label className="col-form-label nowrap">
                            XXXX XXXX XXXX 7483
                          </label>
                        </div>
                        <div className="col text-right">
                          <Link
                            to="/Billinginfo/CardDetails"
                            className="btn btn-link p-0 mr-4"
                          >
                            <Icon
                              icon="edit"
                              color="#007bff"
                              disableFill="true"
                              size={18}
                            />
                          </Link>
                          <Link
                            to="/Billinginfo/CardDetails"
                            className="btn btn-link p-0"
                          >
                            <Icon
                              icon="Plus-square"
                              color="#007bff"
                              disableFill="true"
                              size={18}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup row className="align-items-center">
                      <Label for="card number" className="" lg={3}>
                        Credit card no.
                      </Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="card number"
                          placeholder="-"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="card number" className="" lg={3}>
                        Name on card
                      </Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="card number"
                          placeholder="-"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="state" className="" lg={3}>
                        Billing address
                      </Label>
                      <Col lg={9}>
                        <div className="custom-control custom-checkbox custom-control-inline mr-4">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="headeoffice"
                          />
                          <Label
                            className="custom-control-label"
                            for="headeoffice"
                          >
                            Same as Head Office
                          </Label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="opratoroffice"
                          />
                          <Label
                            className="custom-control-label"
                            for="opratoroffice"
                          >
                            Same as Operat. Office
                          </Label>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="affilatename" className="" lg={3}>
                        Country
                      </Label>
                      <Col lg={9}>
                        <div className="country-select">
                          <Countryselect onChange={(val) => countryChangeHandler(val)}/>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="City-Town" className="" lg={3}>
                        City / Town
                      </Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="City-Town"
                          placeholder="-"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="streeno" className="" lg={3}>
                        Street and No.
                      </Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="streeno"
                          placeholder="Street and number, P.O. box, c/o."
                          required
                          value="2291 Locust Street"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="citytown" className="" lg={3}></Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="apprtmentno"
                          placeholder="Appartment, suite, unit, building, floor"
                          required
                          value="Appartment, suite, unit, building, floor"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="citytown" className="" lg={3}>
                        State / Province
                      </Label>
                      <Col lg={9}>
                        <AppDropDown
                          className="react-select-container"
                          classNamePrefix="react-select"
                          getOptionLabel={(x) => `${x.name}`}
                          getOptionValue={(x) => `${x.name} ${x.id}`}
                          // options={countryState}
                          placeholder={"-"}
                          // onChange={(val)=> this.props.handleChange('states', val, "dropdown") }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="postacode" className="" lg={3}>
                        Zip / Postal code
                      </Label>
                      <Col lg={9}>
                        <Input
                          type="text"
                          name="postacode"
                          placeholder="Appartment, suite, unit, building, floor"
                          value="31601"
                          required
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup row className="align-items-center">
                      <Label for="Expiry date" className="" lg={3}>
                        Expiry date
                      </Label>
                      <Col lg={7}>
                        <Input
                          type="text"
                          name="Expiry date"
                          placeholder="MM / YY"
                          value="31601"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="Security code" className="" md={3}>
                        Security code
                      </Label>
                      <Col md={7}>
                        <Input
                          type="text"
                          name="Security code"
                          placeholder="3 or 4 (AMEX) digits"
                          value="31601"
                          required
                        />
                      </Col>
                      <Col md={2}>
                        <img src={BackCVV} className="paycard_icon" />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <button
                  type="button"
                  className="btn btn-md outline-primary bg-white mr-4"
                >
                  Add card
                </button>
                <button
                  type="button"
                  className="btn btn-md outline-danger bg-white"
                >
                  Cancel
                </button>
              </Col> */}
            </Row>
            <Row>
              <Col lg="6">
                  <FormGroup row className="align-items-start">
                    <Label  className="" lg={4}>
                      Banking details
                      <div className='notes'>(Optional)</div>
                    </Label>
                    <Col lg={8}>
                      <div className='cusome-file-upload form-control mb-4 d-flex align-items-center justify-content-center' style={{color:'#A9A9A9'}}>
                      <label htmlFor="uploadFiles">
                        <Icon 
                          className="mx-3"
                          icon={'cloud-upload'}
                          color="#9D9D9D"
                          disableFill="true"
                          size={30}
                        />
                      </label>
                        {fileStates.bfile == ''?
                        <> Drop files to attach, or <label className="btn-link m-0 ps-2 primary" htmlFor="uploadFiles">Browse</label>. </>
                        :
                        <>{fileStates.bfile}</>
                        }
                      <input  onClick={e => (e.target.value = null)} type="file" id="uploadFiles"  style={{position: 'absolute' , top: '0' , bottom: '0' ,right: '0' ,left: '0' ,opacity: '0', zIndex:'-1'}}   onChange={(e)=>{onChangeFile(e)}} />
                      </div>
                      <p className='note' style={{color:'#A9A9A9'}}>*Please attach a PDF with direct deposit info and/or wire transfer info. You can enter this information now or update it later.</p>
                    </Col>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <LoadingButton type="button" size="md" disabled={btnLoading} label="Save Changes" color="primary" onClick={() => handleSubmit()} className="mr-4" loading={btnLoading} outline />
      </div>
      <Modal
        isOpen={dataState.resetpassword}
        toggle={() => closeModal("resetpassword")}
      >
        <ModalBody className="text-center">
          <strong>
            An email containing a link to reset your password will be sent
            to the affiliate’s registered email.
          </strong>
          <p>Would you like to proceed?</p>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {/* <Button type="button" size="md" color={`${btnEmailState.color}`} className={` mr-4`} outline onClick={() => handleEmailSending()}
          >
            {btnEmailState.message}
          </Button> */}
          <LoadingButton type="button" size="md" disabled={btnEmailState.loading} label={btnEmailState.message} color={btnEmailState.color} onClick={() => handleEmailSending()} className="mr-4" loading={btnEmailState.loading}  outline/>
          <button
            type="button"
            className="btn btn-md outline-danger bg-white"
            onClick={() => closeModal("resetpassword")}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal className="" isOpen={dataState.savechanges} toggle={() => closeModal("savechanges")} >
          <ModalBody className="text-center py-5">
            <p> <strong> Your changes saved.</strong></p>
          </ModalBody>
          <ModalFooter className="justify-content-center mb-4">
            <button
              type="button"
              className="btn btn-sm outline-danger bg-white"
              onClick={() => closeModal("savechanges")}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={backModal} toggle={() => toggleBackModal(false)}>
          <ModalBody className="text-center">
            <p className="mb-0">
              <strong>You havent't saved your changes yet.</strong>
            </p>
            <p>Are you sure you want to go back?</p>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button  type="button" size="md" outline color="primary link" onClick={() => history.goBack(1)} className="mr-4">Go Back</Button>
            <button
              type="button"
              className="btn btn-md outline-danger bg-white"
              onClick={() => toggleBackModal(false)}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
    </div>
  );
};
export default EditOverview;
