import { Route, Redirect } from "react-router";
import React from "react";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const usertoken = useSelector((state) => state.login.token);
  const login = useSelector((state) => state.login.data);
  return (
    <Route
      {...rest}
      render={(props) => {
        const combinedProps = { ...props, ...rest };
        return usertoken !== ""? (
          <Component {...combinedProps} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default PrivateRoute;
