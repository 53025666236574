import { createSlice } from "@reduxjs/toolkit";
import { loginInitialState } from "./initialState";

const loginLoding = (state) => {
  state.loading = true;
  state.btnLoading1 = true;
  state.loading = true;
  
};

const updateusertos = (state) => {
  state.loading = true;
  state.btnLoading = true;
};



const reset = (state) => {
  state.loading = false;
  state.btnLoading = false;
  state.error = "";
};

const loginSuccess = (state, action) => {
  state.data = action.payload;
  state.btnLoading1 = false;
  state.loading = false;
};

const updateUserProfileLoading = (state) =>{
  state.loading = true;
  state.btnLoading = true;
}
const updateUserProfileSuccess = (state , action) =>{
  var nedata = action.payload
  var newD = {...state.data , ...nedata}
  state.error = {};
  state.data = newD;
  state.btnLoading = false;
}

const updateTosDataSuccess = (state, action)=>{
  var nedata = action.payload.data
  var newD = {...state.data , ...nedata}
  state.data = newD;
  state.btnLoading = false;
}

const updateTosDataFailure = (state, action)=>{
  state.error = action.payload;
  state.btnLoading = false;
  state.loading = false;
}

const setToken = (state, action) => {
  state.token = action.payload;
};

const loginFailure = (state, action) => {
  state.error = action.payload;
  state.btnLoading1 = false;
  state.loading = false;
};

const setPwdData = (state, action) => {
  state.pwdData = action.payload;
  state.pwdLoading = false;
  state.pwdError = {};
};

const setPwdLoading = (state) => {
  state.pwdLoading = true;
};

const setResetPwdLoading = (state) =>{
  state.pwdLoading = true
}

const setPwdError = (state, action) => {
  state.pwdError = action.payload;
  state.pwdLoading = false;
};

const logout = (state, action) => {
  state.data = {};
  state.token = "";
  state.pwdError = {};
  state.pwdData = {
    status: 400,
    data: [],
  };
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: loginInitialState,
  reducers: {
    loginLoding,
    updateusertos,
    reset,
    loginSuccess,
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateTosDataSuccess,
    updateTosDataFailure,
    loginFailure,
    setToken,
    setPwdData,
    setPwdLoading,
    setResetPwdLoading,
    setPwdError,
    logout,
  },
});

export default loginSlice;
