import React from "react";
import { Link } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Filter, FilterInput } from "../../../components/Filter";
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { loadingActiveInvoiceWebStream, loadingDownloadInvoice } from "./Redux";
import { months , years, payment_method , payment_status , ACTIVEFORMAT , getFilterStartEndDate} from '../../../data/comman';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import DefaultPlaceholder from '../../../components/DefaultPlaceholder'

import {downloadInvoices} from '../../../service/Invoices'
import Loader from "../../../components/Loader";
import LoadingButton from '../../../components/LoadingButton';

const InvoicesWebstreams = (props) => {
  const [streamState, setStreamState] = React.useState({
    active: 0,
    isOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
  });

  
  const dispatch = useDispatch();
  const activeInvoiceStreamData = useSelector((state) => state.activeInvoiceWebStream.data);
  const loading = useSelector((state) => state.activeInvoiceWebStream.loading);

  // const downloadInvoices = useSelector((state) => state.activeInvoiceWebStream.downloadInvoices);
  const { data } = activeInvoiceStreamData;
  const [searchedText, setSearchedText] = React.useState("");
  const [issue_year, setIssueYear] = React.useState('');
  const [issue_month, setIssueMonth] = React.useState('');
  const [paymentmethod, setPaymentMethod] = React.useState('');
  const [paymentStatus, setPaymentStatus] = React.useState('');
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [isError, setError] = React.useState("");
  const [btnLoading, setbtnLoading] = React.useState(false);

  const toggleCollapse = () => {
    setStreamState({ ...streamState, isOpen: !streamState.isOpen });
  };

  React.useEffect(() => {
    dispatch(loadingActiveInvoiceWebStream({pagenum: 1, searchedText}));
  }, []);
  
  React.useEffect(() => {
    if(searchedText != '' || paymentStatus != '' || issue_year != '' || issue_month != '' || paymentmethod != '')
    dispatch(loadingActiveInvoiceWebStream({ pagenum: 1, searchedText , paymentStatus , issue_year , issue_month , paymentmethod}));
  }, [searchedText , issue_year , paymentStatus , issue_month , paymentmethod]);

  const handleNext = () => {
    const nextUrl = activeInvoiceStreamData.next_page_url;
    if (nextUrl !== null) {
      const pagenum = parseInt(nextUrl.split("=").pop());
      dispatch(loadingActiveInvoiceWebStream({ pagenum, searchedText  }));
    }
  };

  const handlePrev = () => {
    const prevUrl = activeInvoiceStreamData.prev_page_url;
    if (prevUrl !== null) {
      const pagenum = parseInt(prevUrl.split("=").pop());
      dispatch(loadingActiveInvoiceWebStream({ pagenum, searchedText }));
    }
  };

  const onSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  };

  const onChangeYear = async (e) =>{
    var issue_year  = e.value;
    setIssueYear(issue_year)
  }
  
  const onChangeMonth = async (e) =>{
    var issue_month  = e.value;
    setIssueMonth(issue_month)
  }

  const onChangeStatus = async (e) =>{
    var paymentStatus  = e.value;
    setPaymentStatus(paymentStatus)
  }

  const onChnagePaymentMethod = async (e)=> {
    var paymentmethod = e.value;
    setPaymentMethod(paymentmethod)
  }
  
  const handleSearch = () => {
    dispatch(loadingActiveInvoiceWebStream({ pagenum: 1, searchedText }));
  };

  const onDownloadInvoices =  async (download_type) =>{
    setbtnLoading(true);
    try {

      const response = await downloadInvoices(selectedInvoices,'webstream', download_type  );

      if(selectedInvoices.length == 1){
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${moment().unix()}.pdf`;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        toggleModal(false);
      }else{
        if(download_type == 1){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',`${moment().unix()}.${response.headers['content-type'] == "application/zip" ? "zip" : "pdf"}`);
          document.body.appendChild(link);
          link.click();
          toggleModal(false);
        }
      }

      
      console.log(response,'response')
     
    } catch (err) {
          setError(err);
    }
    setbtnLoading(false);
  }

  const onEmailInvoices =  async (download_type) =>{
    setbtnLoading(true);
    try {
      const response = await downloadInvoices(selectedInvoices,'webstream', download_type );
      if(response.status == 200){
        toggleEmailSuccessModal(true);
      }
    } catch (err) {
          setError(err);
    }
    setbtnLoading(false);
  }

  const getStatus = (id) => {
    switch (id) {
      case 1:
        return ["color-yellow", "Pending"];
      case 2:
        return ['color-green', "Paid"];
      case 3:
        return ['color-red', "Declined"];
      default:
        return ['color-green', "Pending"];
    }
  }

  const getPaymentMethod = (id) => {
    switch (id) {
      case 1:
        return ["Credit card"];
      case 2:
        return ["Direct deposit"];
      case 3:
        return [ "Cheque"];
      default:
        return ["Credit card"];
    }
  }

  const [states, setState] = React.useState({
    active: 0,
    isOpen: false,
    isOpenSubModal: false
  });

  const toggleChange = (e , invoice) => {
    var checkedBoxes = []
    checkedBoxes = [...selectedInvoices];
    if(e.target.checked) {
      checkedBoxes.push(invoice.id)
    } else {
      const index = checkedBoxes.findIndex((ch) => ch.id === invoice.id);
      checkedBoxes.splice(index, 1);
    }
    setSelectedInvoices(checkedBoxes);
  }

  const toggleModal = (state) => {
    setState({ isOpen: state });
  };

  const toggleEmailSuccessModal = (state) => {
    setState({ isOpenSubModal: state });
  };

  return (
    <>
      <Summarybar />
      {loading &&  (
        <Loader />
      )}
      <div className="card flex-fill">
        <div className="card-box">
          <div className="card__header card__header__filter lieacence-header flex">
            <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
              <div className="card__title d-flex align-items-center w-auto col">
                <h3 className="m-0 mr-4">Invoices</h3>
                {selectedInvoices.length > 0 &&
                  <>
                  <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"  onClick={() => toggleModal(true)} ><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Button>
                  {/* <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"   onClick={() => onEmailInvoices(2)}></Button> */}
                  <LoadingButton type="button" size="md" disabled={btnLoading} color="primary link" onClick={() => onEmailInvoices(2)} className="mx-3 px-5 link-btn" loading={btnLoading} outline ><Icon className="mr-4" icon="mail-icon" disableFill="true" size={16}></Icon>Email Invoices</LoadingButton>
                  </>
                }
                </div>

              <div className="ml-auto d-flex align-items-center w-auto flex-wrap">
                <div className="help__inner__search mb-0 webstream-search mx-4">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by invoice no."
                      onChange={onSearchTextChange}
                    ></input>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={handleSearch}
                    >
                      <Icon icon="search-icon" color="#357BCF" size={18} />
                    </Button>
                  </div>
                </div>

                <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5 flex-wrap">
                {/* <h3 className="mb-0">2020</h3> */}
                  <p className="m-0">
                    <strong>
                      Showing {activeInvoiceStreamData.from}-{activeInvoiceStreamData.to} of{" "}
                      {activeInvoiceStreamData.total}
                    </strong>
                  </p>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item  border-0">
                      <a className="disabled" href="#">
                        <Icon
                          icon="Left-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handlePrev}
                        />
                      </a>
                    </li>
                    <li className="list-group-item  border-0">
                      <a className="" href="#">
                        <Icon
                          icon="Right-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handleNext}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  className={`btn-filter btn  ml-auto collapsed`}
                  type="button"
                  onClick={toggleCollapse}
                >
                  <i className="mr-3">
                    <Icon
                      icon="Down-arrow"
                      color="#157CF7"
                      disableFill="true"
                      size={15}
                    />
                  </i>{" "}
                  Filter
                </button>
              </div>
            </div>
            <Filter isOpen={streamState.isOpen}>
              <FilterInput
                type="dropDown"
                label="Issue year"
                options={years}
                placeholder="-"
                onChange={onChangeYear}
              />
              <FilterInput
                type="dropDown"
                label="Issue month"
                options={months}
                onChange={onChangeMonth}
                placeholder="-"
              />
              <FilterInput
                type="dropDown"
                label="Payment method"
                options={payment_method}
                placeholder="-"
                onChange={onChnagePaymentMethod}
              />
              <FilterInput
                type="dropDown"
                label="Payment status"
                options={payment_status}
                onChange={onChangeStatus}
                placeholder="-"
              />
            </Filter>
          </div>
        </div>
        <div className="sub-page__table">
          <PerfectScrollbar>
            <div className="px-5">
              <table className="table mb-0">
                <thead className="sticky">
                  <tr>
                    <th className="pl-0 w-50 sticky-column">Invoice no.</th>
                    <th className="pl-0 sticky-column">Amount due</th>
                    <th className="pl-0 sticky-column">Issue date</th>
                    <th className="pl-0 sticky-column">Payment method</th>
                    <th className="pl-0 sticky-column col-1">Payment status</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(activeInvoiceStreamData).length > 0 &&
                   data.length > 0 ? data.map((item, index) => {
                      const { reference_no , total  , invoice_date , payment_type , status , id} = item;
                      const invoice_status = getStatus( status )
                      const paymentmethod = getPaymentMethod(payment_type)
                      return (
                        <tr key={index} className="">
                            <td>
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <Input type="checkbox" className="custom-control-input ml-0"   name={'check'+item.id}  onChange={(event)=>toggleChange(event,item)}  id={'check'+item.id}/>
                                    <Label style={{width:'1.5rem',height:'1.5rem'}} htmlFor={'check'+item.id} className="link-black custom-control-label reports-checkbox"></Label>
                                </div> 
                                <Link to={`/app/invoices/webstreams/${id}`} className="link-black pl-5">{reference_no}</Link>
                            </td>
                            <td className="pl-0">${total}</td>
                            <td className="pl-0">{moment(invoice_date).format(ACTIVEFORMAT)}</td>
                            <td className="pl-0">{paymentmethod}</td>
                            <td className={`pl-0 ${invoice_status[0]}`}>{invoice_status[1]}</td>
                        </tr>
                      );
                    })
                    :<tr style={{border:'none'}}><td colSpan={6}><DefaultPlaceholder text={'Webstreams and Licenses invoices will show up here.'}/></td></tr>
                    }
                </tbody>
              </table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
        <Modal isOpen={states.isOpen}  size="sm" toggle={() => toggleModal(false)}  >
            <ModalBody className="text-center">
                <p><strong>Are you sure want to download invoice(s)</strong></p>
                {/* <FormGroup className="align-items-center text-left px-5 py-4 mb-0 invoice-input">
                        <Label className="mr-3"><strong>Version:</strong></Label>
                        <div className="custom-control custom-checkbox custom-control-inline mr-3  ">
                            <Input type="checkbox" className="custom-control-input" id="dark" />
                            <Label className="custom-control-label" for="dark ">Dark</Label>
                        </div>
                        <div className="custom-control custom-checkbox custom-control-inline   ">
                            <Input type="checkbox" className="custom-control-input" id="light" />
                            <Label className="custom-control-label" for="light ">Light (Printer friendly)</Label>
                        </div>
                </FormGroup> */}
            </ModalBody>
            <ModalFooter className="justify-content-between">
                {/* <Link type="button" className="btn btn-md outline-primary bg-white mr-4"  onClick={() => onDownloadInvoices(1)}  >Download Invoices</Link> */}
                <LoadingButton type="button" size="md" disabled={btnLoading} label="Download Invoices" color="primary" onClick={() => onDownloadInvoices(1)} className="mr-4" loading={btnLoading} outline />
                <button type="button" className="btn btn-md outline-danger bg-white"   onClick={() => toggleModal(false)} >Cancel</button>
            </ModalFooter>
            
        </Modal>

        <Modal isOpen={states.isOpenSubModal}  size="sm" toggle={() => toggleEmailSuccessModal(false)}  >
            <ModalBody className="text-center">
                <p><strong>Affiliate invoice send successfully</strong></p>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <button type="button" className="btn btn-md outline-danger bg-white"   onClick={() => toggleEmailSuccessModal(false)} >Close</button>
            </ModalFooter>
        </Modal>
    </>
  );
};

export default InvoicesWebstreams;
