import React from "react";

import { InputField } from "../../components/InputField";

import "./style.scss";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CustomInput,
} from "reactstrap";

import BrandIcon from "../../img/logo.svg";
import MainProfileAvtar from "../../img/UserProfileMain.svg";
import UserProfileAvtar from "../../img/UserProfileAvtar.svg";
import { useHistory, useLocation } from "react-router";
import { sendLoginEmail } from "../../service/login";

function PasswordRecoveryEmailResetPage() {
  const [emailAddress, setEmailAddress] = React.useState("");
  const query = new URLSearchParams(useLocation().search);
  const [timer, setTimer] = React.useState(60);
  const [error, setError] = React.useState("");
  const history = useHistory();

  React.useEffect(() => {
    const time = setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
    if (timer === 0) {
      clearTimeout(time);
    }
    return () => clearTimeout(time);
  }, [timer]);

  React.useEffect(() => {
    setEmailAddress(query.get("email"));
  }, []);

  const handleResetBtn = async () => {
    try {
      const response = await sendLoginEmail(emailAddress);
      setTimer(60);
    } catch (err) {
      setError(Object.values(Object.values(err.errors)[0][0]));
    }
  };
  return (
    <div className="login__main__screen">
      <div className="login__inner">
        <div className="login__inner__wc-text col">
          <div className="wc-text-box">
            Welcome to the <span>Affiliate Partner Programs</span>
          </div>
        </div>
        <div className="login__inner__form">
          <div className="brand-icon">
            <img src={BrandIcon}></img>
          </div>
          <div className="text-red">{error}</div>
          <div className="form-body">
            <div className="pr-email-reset">
              <Row>
                <Col md={12}>
                  <p className="pr-title">
                    The password reset email was sent to <b>{emailAddress}</b>
                  </p>
                </Col>
              </Row>
              <Row className="mt-auto mb-5" >
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <label>Didn’t receive it?</label>
                    <Button
                      type="button"
                      className="w-100"
                      color="black"
                      disabled={timer === 0 ? false : true}
                      onClick={handleResetBtn}
                    >
                      {timer === 0 ? "Resend Mail" : `Resend Email in ${timer}`}
                    </Button>
                    <Col md={12} className="mt-3">
                      <Button
                        type="button"
                        className="w-100"
                        color="black"
                        onClick={() => history.push("/")}
                      >
                        Return
                      </Button>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRecoveryEmailResetPage;
