import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  getInvoicesWebstream,
  getInvoiceDetails,
  downloadInvoices
} from "../../../../service/Invoices";
import {
  loadingActiveInvoiceWebStream,
  activeInvoiceWebStreamFailure,
  activeInvoicesWebStreamsSuccess,
  reset,
  setSelectedInvoice,
  loadingSelectedInvoice,
  loadingDownloadInvoice,
  setDownloadInvoice
} from "./index";

export function* activeInvoiceWebStreamWatcher(params) {
  try {
    const { pagenum, searchedText ,  paymentStatus , issue_year , issue_month , paymentmethod} = params.payload;
    const data = yield call(getInvoicesWebstream ,  pagenum, searchedText,  paymentStatus , issue_year , issue_month , paymentmethod);
    yield put(activeInvoicesWebStreamsSuccess(data));
    yield put(reset());
  } catch (err) {
    yield put(activeInvoiceWebStreamFailure(err));
  }
}

export function* selectedInvoice(param) {
  try {
    const data = yield call(getInvoiceDetails, param.payload);
    yield put(setSelectedInvoice(data));
    yield put(reset());
  } catch (err) {
    yield put(activeInvoiceWebStreamFailure(err));
  }
}

export function* downloadInvoice(params) {
  const { invoice_id, type , download_type } = params.payload;
  try {
    const data = yield call(downloadInvoices, invoice_id, type , download_type);
    yield put(setDownloadInvoice(data));
    yield put(reset());
  } catch (err) {
    yield put(activeInvoiceWebStreamFailure(err));
  }
}


export default function* activeWebStreamSaga() {
  yield all([
    takeLatest(loadingActiveInvoiceWebStream.type, activeInvoiceWebStreamWatcher),
    takeLatest(loadingSelectedInvoice.type, selectedInvoice),
    takeLatest(loadingDownloadInvoice.type, downloadInvoice),
  ]);
}
