import { createSlice } from "@reduxjs/toolkit";
import { activeInvoiceWebStreamInitialState } from "./initialState";

const loadingActiveInvoiceWebStream = (state) => {
  state.loading = true;
};

const activeInvoicesWebStreamsSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
};

const activeInvoiceWebStreamFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const setSelectedInvoice = (state, action) => {
  state.selectedInvoice = action.payload;
  state.loadingSelectedInvoice = false;
};
const setDownloadInvoice = (state, action) => {
  state.downloadInvoices = action.payload;
};

const loadingSelectedInvoice = (state) => {
  state.loadingSelectedInvoice = true;
};

const loadingDownloadInvoice = (state) => {
  state.loadingDownloadInvoice = true;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const activeInvoiceWebstreamSlice = createSlice({
  name: "activeInvoiceWebstreamSlice",
  initialState: activeInvoiceWebStreamInitialState,
  reducers: {
    loadingActiveInvoiceWebStream,
    activeInvoicesWebStreamsSuccess,
    activeInvoiceWebStreamFailure,
    reset,
    setSelectedInvoice,
    loadingSelectedInvoice,
    loadingDownloadInvoice,
    setDownloadInvoice
  },
});

export default activeInvoiceWebstreamSlice;
