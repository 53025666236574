import axios from "axios";
import store from "../../Redux/store";

axios.interceptors.request.use(function (config) {
  const token = store.store.getState().login.token;
  if (token !== "") {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axios;
