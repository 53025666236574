import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";

const baseUrl = global.domain;


export const getInviteDetails = async (params) => {
    try {
      const url = baseUrl + `/affiliate/get-details?invitation_token=${params}`;
      let response = await axios.get(url);
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
};

export const updateInviteDetails = async (reqbody , params) => {
    try {
      const url = baseUrl + `/affiliate/save-details?invitation_token=${params}`;
      const { data } = await axios.post(url, reqbody);
      return data;
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }
    }
};

export const checkUserInfo = async (reqbody, params) => {
  try {
    const url = baseUrl + `/affiliate/check-unique?invitation_token=${params}`;
    const { data } = await axios.post(url, reqbody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const uploadBankingDetailFile = async (formData , params) => {
  try {
    const url = baseUrl + `/affiliate/file/upload?invitation_token=${params}`;
    const { data } = await axios.post(url, formData,{
      headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};