import { createSlice } from "@reduxjs/toolkit";
import { invitationInitialState } from "./initialState";

const loadingInvitation = (state) => {
  state.loading = true;
};

const invitationSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
  state.btnLoading = false
};

const invitationFailure = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
  state.btnLoading = false
};

const invitationSlice = createSlice({
  name: "invitationSlice",
  initialState: invitationInitialState,
  reducers: {
    loadingInvitation,
    invitationSuccess,
    invitationFailure,
    reset,
  },
});

export default invitationSlice;
