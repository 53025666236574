import userRightSlice from "./slice";

export const {
  loadingUserRights,
  successUserRights,
  failureUserRights,
  reset,
  setModuleData,
  setModuleError,
  setPermissions,
} = userRightSlice.actions;

export default userRightSlice.reducer;
