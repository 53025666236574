import activeInvoiceWebstreamSlice from "./slice";

export const {
  loadingActiveInvoiceWebStream,
  activeInvoiceWebStreamFailure,
  activeInvoicesWebStreamsSuccess,
  reset,
  setSelectedInvoice,
  loadingSelectedInvoice,
  loadingDownloadInvoice,
  setDownloadInvoice
} = activeInvoiceWebstreamSlice.actions;

export default activeInvoiceWebstreamSlice.reducer;
