import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  getOneDomainDetails,
  getInactiveDomains,
} from "../../../../service/webstream";
import {
  loadingInActiveWebStream,
  inActiveWebStreamFailure,
  inActiveWebStreamsSuccess,
  reset,
  setSelectedDomain,
  loadingSelectedDomain,
} from "./index";

export function* inActiveWebStreamWatcher(params) {
  try {
    const { pagenum, searchedText ,  deactivation_year , deactivation_month , authorization_year , authorization_month, sortBy ,orderBy } = params.payload;
    const data = yield call(getInactiveDomains, pagenum, searchedText ,  deactivation_year , deactivation_month , authorization_year , authorization_month, sortBy ,orderBy );
    yield put(inActiveWebStreamsSuccess(data));
    yield put(reset());
  } catch (err) {
    yield put(inActiveWebStreamFailure(err));
  }
}

export function* selectedDomain(param) {
  try {
    const data = yield call(getOneDomainDetails, param.payload);
    yield put(setSelectedDomain(data));
    yield put(reset());
  } catch (err) {
    yield put(inActiveWebStreamFailure(err));
  }
}

export default function* inActiveWebStreamSaga() {
  yield all([
    takeLatest(loadingInActiveWebStream.type, inActiveWebStreamWatcher),
    takeLatest(loadingSelectedDomain.type, selectedDomain),
  ]);
}
