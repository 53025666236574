import { createSlice } from "@reduxjs/toolkit";
import { getReferralsInitialState } from "./initialState";

const getReferrals = (state) => {
  state.loading = true;
};

const getReferralsSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
};

const getReferralsFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const getReferralsSlice = createSlice({
  name: "getReferralsSlice",
  initialState: getReferralsInitialState,
  reducers: {
    getReferrals,
    getReferralsSuccess,
    getReferralsFailure,
    reset,
  },
});

export default getReferralsSlice;
