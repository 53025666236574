import React from 'react'


class Reports extends React.Component{

  render(){
    const { isOpen } = this.state;
    return(
        <div>
            
        </div>
    );
  }
}
export default Reports;
