import React, { useState }  from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { months , years, payment_method , payment_status , ACTIVEFORMAT , getFilterStartEndDate} from '../../../data/comman';

import { Alert, Button, Input, Label, FormGroup,Col,Row,Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { loadingSelectedReport } from "./Redux";
import Loader from "../../../components/Loader";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ActiveReportDetails = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
    const selectedReportInfo = useSelector( (state) => state.reports.selectedReport );

    const loading = useSelector(  (state) => state.reports.loadingSelectedReport );
    const getStatus = (id) => {
        switch (id) {
          case 1:
            return ["color-yellow", "Pending"];
          case 2:
            return ['color-green', "Paid"];
          case 3:
            return ['color-red', "Declined"];
          default:
            return ['color-green', "Pending"];
        }
    }

  React.useEffect(() => {
    dispatch(loadingSelectedReport(id));
  }, []);

  

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onDownloadInvoices =  async (download_type) =>{
    const linkSource = `data:application/pdf;base64,${selectedReportInfo.report_data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${moment().unix()}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
        <Summarybar />
        {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
        <Row>
            <Col lg="9">
            <div className="card py-5">
                <div className="card-box">
                    <div className="card__header card__header__filter">
                        <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                            <div className="card__title d-flex align-items-center">
                                <Button  type="button" color="primary link" onClick={() => history.goBack()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
                                <h3 className="m-0 mr-4">Report no. {selectedReportInfo.report_no} </h3>
                                <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"  onClick={() => onDownloadInvoices()} ><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Button>
                            </div>
                        </div>
                    </div>
                    <div className=" px-5 card-body">
                    <Document
                        file={`data:application/pdf;base64,${selectedReportInfo.report_data}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode={'svg'}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    </div>
                </div>
            </div>
            </Col>
            <Col lg="3">
                <div className="py-5">
                    <div className="py-5">
                        <h3>Summary</h3>
                    </div>
                    <ul className="summary-list pb-3 border-none">
                        <li>
                            <span><strong>Report No.:</strong></span>
                            <span>{selectedReportInfo.report_no}</span>
                        </li>
                        <li>
                            <span><strong>Program:</strong></span>
                            <span>{selectedReportInfo.program_list}</span>
                        </li>
                        <li>
                            <span><strong>Payment type:</strong></span>
                            <span>{selectedReportInfo.payment}</span>
                        </li>
                        <li>
                            <span><strong>Amount due:</strong></span>
                            <span>{selectedReportInfo.total}</span>
                        </li>
                        <li>
                            <span><strong>Due date:</strong></span>
                            <span>{moment(selectedReportInfo.due_date).format(ACTIVEFORMAT)}</span>
                        </li>
                        <li>
                            <span><strong>Payment method:</strong></span>
                            <span>{selectedReportInfo.payment_method}</span>
                        </li>
                        <li>
                            <span><strong>Payment status:</strong></span>
                            <span>{getStatus(selectedReportInfo.payment_status)[1]}</span>
                        </li>
                    </ul>
                </div>
            </Col>
        </Row>
        )}
    </>
  );
};

export default ActiveReportDetails;
