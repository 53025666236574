import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import PublicRoute from "../components/Routes/PublicRoute";
import Dashboard from "../pages/dashboard";
import PrivateRoute from "../components/Routes/PrivateRoute";
import Overview from "./Overview";
import { useDispatch } from "react-redux";
import { loadingPages } from "./Redux";
import EditOverview from "./Overview/EditOverview";
import Activewebstream from "./webstream/Activewebstream";
import ActivewebstreamDetails from "./webstream/Activewebstream/ActivewebstreamDetails";
import Inactivewebstream from "./webstream/Inactivewebstream";
import InactivewebstreamDetails from "./webstream/Inactivewebstream/InactivewebstreamDetails";
import DomainAuthrization from "./webstream//Activewebstream/DomainAuthrization";
import UserRights from "./users-rights";
import ReferralsPage from './ReferralsPage'
import Licenses from "./Licenses";
import LicensesUnsued from "./Licenses/Unused";
import GeneralReport from "./Reports/General";
import WebstreamReport from "./Reports/Webstream";
import LicensesReport from "./Reports/Licenses";
import InvoicesWebstreams from "./Invoices/Webstreams";
import InvoicesLicenses from "./Invoices/Licenses";
import WebstreamInvoice from "./Invoices/Webstreams/WebstreamInvoice";
import ReportsGeneralInvoice from "./Reports/General/ReportsGeneralInvoice";
import ReportsDetail from "./Reports/Webstream/reportsDetail";

import ContactUs from "../pages/ContactUs";
import Help from "../pages/Help";
import ExpiredPromoCodes from "./Overview/ExpiredPromoCodes";
// import Tos from '../pages/Tos'

const nestedData = [
  {
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "overview",
    component: <Overview />,
  },
  {
    path: "editoverview",
    component: <EditOverview />,
  },
  {
    path: "Overview/ExpiredPromoCodes",
    component: <ExpiredPromoCodes />,
  },
  {
    path: "webstreams/active",
    component: <Activewebstream />,
  },
  {
    path: "webstreams/active/:id",
    component: <ActivewebstreamDetails />,
  },
  {
    path: "webstreams/inactive",
    component: <Inactivewebstream />,
  },
  {
    path: "webstreams/inactive/:id",
    component: <InactivewebstreamDetails />,
  },
  {
    path: "webstreams/domainAuthrization",
    component: <DomainAuthrization />,
  },
  {
    path: "userRights",
    component: <UserRights />,
  },
  {
    path: "Sales",
    component: <ReferralsPage />,
  },
  {
    path: "licenses/active",
    component: <Licenses />,
  },
  {
    path: "licenses/inactive",
    component: <Licenses />,
  },
  {
    path: "licenses/unused",
    component: <LicensesUnsued />,
  },
  {
    path: "reports/general",
    component: <GeneralReport />,
  },
  {
    path: "reports/webstreams",
    component: <WebstreamReport type="webstream"/>,
  },
  {
    path: "reports/referral",
    component: <WebstreamReport type="referral"/>,
  },
  {
    path: "reports/licenses",
    component: <LicensesReport />,
  },
  {
    path: "invoices/webstreams",
    component: <InvoicesWebstreams />,
  },
  {
    path: "invoices/webstreams/:id",
    component: <WebstreamInvoice />,
  },
  {
    path: "invoices/licenses",
    component: <InvoicesLicenses />,
  },
  {
    path: "InvoicesLicenses/WebstreamInvoice",
    component: <WebstreamInvoice />,
  },
  {
    path: "GeneralReport/ReportsGeneralInvoice",
    component: <ReportsGeneralInvoice />,
  },
  {
    path: "contact-us",
    component: <ContactUs />,
  },
  {
    path: "help",
    component: <Help />,
  },
  {
    path: "reports/:id",
    component: <ReportsDetail />,
  },
  // {
  //   path: "tos",
  //   component: <Tos />,
  // },
];

const DashboardRoutes = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadingPages());
  }, []);

  return (
    <div >
        <Sidebar />
        <div style={{ width: "calc(100% - 23.8rem)" , float:'right',padding:'2.6rem' , display:'flex', flexDirection:'column', height:'100vh' }}>
          <Switch>
            {nestedData.map((nestedRoute, index) => {
              return (
                <PrivateRoute
                  exact
                  path={`${path}/${nestedRoute.path}`}
                  key={index}
                >
                  {nestedRoute.component}
                </PrivateRoute>
              );
            })}
            <Redirect to="/app/dashboard" />
          </Switch>
        </div>
      </div>
  );
};

export default DashboardRoutes;
