import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Filter, FilterInput } from "../../../components/Filter";
import ReactCountryFlag from "react-country-flag";
import AppDropDown from "../../../components/AppDropDown";
import {
  Button,
  Input,
  Label,
  FormGroup,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadingSelectedDomain, setSelectedDomain } from "./Redux";
import { activateDeactivateDomain } from "../../../service/webstream";
import moment from 'moment';
import { CheckPermission, DateDDMMMYYY, WEBSTREAMS } from "../../../data/comman";
import LoadingButton from '../../../components/LoadingButton';
import Loader from "../../../components/Loader";


const ActivewebstreamDetails = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formError, setFormError] = React.useState("");

  const selectedDomainInfo = useSelector( (state) => state.activeWebStream.selectedDomain );

  const loading = useSelector( (state) => state.activeWebStream.loadingSelectedDomain);
  const [btnLoading, setbtnLoading] = React.useState(false);

  const [sampleState, setSampleState] = React.useState({
    active: 0,
    isOpen: false,
    finalModalOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
  });

  const [domainInfo, setDomainInfo] = React.useState({
    name: selectedDomainInfo.practice_name,
    email: selectedDomainInfo.practice_email,
    domain: selectedDomainInfo.domain,
    date:
      selectedDomainInfo.authorization_at !== undefined
        ? moment(selectedDomainInfo.authorization_at).toDate()
        : moment(selectedDomainInfo.authorization_at).toDate(),
  });

  const [deactivatedDate, setDeactivatedDate] = React.useState(
      moment(new Date()).toDate()
  );

  const onChangeDeactivatedDate = (date) =>{
    setDeactivatedDate(date)
  }

  React.useEffect(() => {
    setDomainInfo({
      ...domainInfo,
      name: selectedDomainInfo.practice_name,
      email: selectedDomainInfo.practice_email,
      domain: selectedDomainInfo.domain,
      date: moment(selectedDomainInfo.authorization_at).toDate(),
    });
    // setDeactivatedDate(moment(new Date()).toDate());
  }, [selectedDomainInfo]);

  React.useEffect(() => {
    dispatch(loadingSelectedDomain(id));
  }, []);

  const toggleModal = (state) => {
    setSampleState({ ...sampleState, isOpen: state });
  };

  const goBack = () => {
    history.push("/app/webstreams/active");
  };

  const handleFinalModal = (state, toggleBothModal = false, goback =false) => {
    if (toggleBothModal) {
      setSampleState({ ...sampleState, finalModalOpen: state, isOpen: false });
      if(goback){
        history.push("/app/webstreams/active");
      }
      
    } else {
      setSampleState({ ...sampleState, finalModalOpen: state });
    }
  };
  const handleDeactivate = async (id) => {
    setbtnLoading(true);
    try {
      const data = await activateDeactivateDomain(
        "deactive",
        moment(deactivatedDate).format("YYYY-MM-DD"),
        selectedDomainInfo.id
      );
      handleFinalModal(true, true);
    } catch (err) {
      setFormError(Object.values(err.errors)[0]);
    }

    setbtnLoading(false)
  };

  const handleChange = (event) => {
    setDomainInfo({ ...domainInfo, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Summarybar />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
      <div className="card flex-fill">
        <div className="card-box">
          <div className="card__body">
            <div className="card__header card__header__filter pt-0 pb-0 pr-0">
              <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                <div className="w-100">
                    <Row>
                      <Col sm="9" className="py-4">
                        <div className="card__title d-flex align-items-center py-4">
                          <Button  type="button" color="primary link" onClick={() => history.goBack()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
                          <h3 className="m-0 mr-4">
                            {selectedDomainInfo.domain}
                          </h3>
                        </div>
                        <form className="newpromocode-form">
                          <Row>
                            <Col lg="9">
                              <FormGroup row className="align-items-center">
                                <Label
                                  for="practicename"
                                  className="pr-lg-0"
                                  sm={4}
                                >
                                  Practice name
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="text"
                                    name="name"
                                    placeholder="-"
                                    value={domainInfo.name}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="align-items-center">
                                <Label
                                  for="practiceemail"
                                  className="pr-lg-0"
                                  sm={4}
                                >
                                  Practice email
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="email"
                                    name="email"
                                    placeholder="-"
                                    value={domainInfo.email}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="align-items-center">
                                <Label
                                  for="authdoamin"
                                  className="pr-lg-0"
                                  sm={4}
                                >
                                  Authorized domain
                                </Label>
                                <Col sm={8}>
                                  <Input
                                    type="text"
                                    name="domain"
                                    placeholder="-"
                                    value={domainInfo.domain}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="align-items-center">
                                <Label
                                  for="authdate"
                                  className="pr-lg-0"
                                  sm={4}
                                >
                                  Authorization date{" "}
                                </Label>
                                <Col sm={8}>
                                  <DatePicker
                                    className="custom-datepicker form-control mb-2"
                                    selected={domainInfo.date}
                                    dateFormat={DateDDMMMYYY}
                                    placeholderText="Click the icon to select a date"
                                    disabled
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                          {CheckPermission(WEBSTREAMS.id,'write') &&
                            <div className="py-4">
                              <Link
                                className="btn outline-danger m-3 btn-md"
                                onClick={() => toggleModal(true)}
                              >
                                Deactivate Domain
                              </Link>
                            </div>
                          }
                        </form>
                      </Col>
                    </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
      }
      <Modal isOpen={sampleState.isOpen} toggle={() => toggleModal(false)}>
        <ModalBody className="text-center">
          <p className="mb-0">
            <strong>Select a date to deactivate this domain</strong>
          </p>
          <p>
            The Thalamus Video Player will no longer be available within the
            domain once it’s been deactivated.
          </p>
          <FormGroup row className=" px-4" disabled>
            <Label className="text-left">
              <strong>Domain deactivation date</strong>
            </Label>
            <DatePicker
              className="custom-datepicker form-control mb-2"
              selected={deactivatedDate}
              minDate={new Date()}
              dateFormat={DateDDMMMYYY}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={5}
              scrollableYearDropdown
              placeholderText="06 Dec 2020"
              onChange={(date) => onChangeDeactivatedDate(date)}
            />
          </FormGroup>
          {formError && (
            <p style={{ color: "red" }}>{formError}</p>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {/* <Link
            type="button"
            className="btn btn-md outline-primary bg-white mr-4"
            onClick={() => handleDeactivate()}
          >
            
          </Link> */}
          <LoadingButton type="button" size="md" disabled={btnLoading} label="Deactivate Domain" color="primary" onClick={() => handleDeactivate()} className="mr-4" loading={btnLoading} outline />
          <button
            type="button"
            className="btn btn-md outline-danger bg-white"
            onClick={() => toggleModal(false)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={sampleState.finalModalOpen}
        toggle={() => handleFinalModal(false)}
      >
        <ModalBody className="text-center">
          <p>
            <strong>
              The domain “{domainInfo.domain}” has been deactivated.
            </strong>
          </p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Link
            type="button"
            className="btn btn-md outline-primary bg-white mr-4"
            onClick={() => {
              handleFinalModal(false, true, true);
            }}
          >
            Close
          </Link>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ActivewebstreamDetails;
