import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  loadingDashboard,
  dashboardError,
  dashboardSuccess,
  reset,
} from "./index";
import { getDashboardDetails } from "../../../service/dashboard";
export function* dashboardWatcher(params) {
  try {
    const data = yield call(getDashboardDetails);
    yield put(dashboardSuccess(data.data));
    yield put(reset());
  } catch (err) {
    yield put(dashboardError(err));
  }
}

export default function* dashboardSaga() {
  yield takeLatest(loadingDashboard.type, dashboardWatcher);
}
