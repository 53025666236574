import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Col, FormGroup, Label, Row, CustomInput, Input ,Modal, ModalBody, ModalFooter } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContactUs from '../ContactUs'
import { Link, useHistory } from "react-router-dom";
import {updateTos, updateSignUpTos} from '../../service/login'
import {updateUserProfileWatcher} from '../login/Redux/saga'
import { updateusertos } from "../login/Redux";
import Logo from '../../images//affilate-logo.svg'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';
import LoadingButton from '../../components/LoadingButton';
var decode = require('decode-html');

const SignUpTos = (props) => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login.data);
  const [isError, setError] = React.useState("");
  const [modalDecline, setDeclineModal] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const history = useHistory();
  

  const toggleDeclineModal = (state) => {
    setDeclineModal(state);
  }

    React.useEffect(() => {
        if(login.signup_tos_status == 1){ 
            history.push('/tos');
        }
    }, [])

    React.useEffect(() => {
        // if(login.tos_status == 1){
        //     history.push('/');
        // }
    }, [login])

  const onHandalSubmit = async (status) => {
    try {
        setBtnLoading(true);
        const response = await updateSignUpTos(status);
        if(response.status=== 200){
            setBtnLoading(false);
            dispatch(updateusertos(response));
            setError("");
            if(response.data.signup_tos_status == 1){
                history.push('/tos');
            }else{
                setBtnLoading(false);
            }
        }else{
            setBtnLoading(false);
        }
      } catch (err) {
            setError(err);
            setBtnLoading(false);
      }
  }
  const onDecline = async (status) => {
    toggleDeclineModal(true);
    // onHandalSubmit(status)
  }

  const showContactUs = async (status) =>{
    setShowContact(true)
    setDeclineModal(false);
  }

  return (
      
    <div className="d-flex flex-column min-vh-100 justify-content-center w-100">
         <div className='invite_header p-5 text-center'>
            <img src={Logo}/>
        </div>
        {/* <Summarybar /> */}
        {showContact ? 
            <div className="tos__contact__screen w-100">
                <ContactUs isFromTos={true} isSignupTos={true}/>
            </div>
        :
            <div className={`${'tos__main__screen d-flex flex-column w-100 fullHeight'}`}>
                <div className=" flex-grow-1 mt-5">
                    <div className="tos__body px-0 "> 
                        <PerfectScrollbar>
                            <div className="tos__inner">
                                <div className="tos__content" dangerouslySetInnerHTML={{__html: decode(login.signup_tos_data)}}></div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>

                <div className="tos__footer justify-content-between d-flex my-5">
                    <LoadingButton loading={btnLoading} type="button" size="md" onClick={() => onHandalSubmit('agree')} color="primary">Agree</LoadingButton>
                    <Button type="button" size="md" color="danger" outline onClick={() => onDecline('decline')} >Decline</Button>
                </div>
            </div>
        }
        <Modal isOpen={modalDecline} toggle={() => toggleDeclineModal(false)} >
            <ModalBody className="text-center">
            <p><strong> If you decline the terms and confitions you will not be able<br/> to participate in the Thalamus Affiliate Program.
            </strong></p>
            <p>Please contact us if you have any concerns. </p>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button type="button" size="md" onClick={() => toggleDeclineModal(false)} color="primary" className={`mr-4`} outline >
                    Back
                </Button>
                {/* onClick={() => history.push('/app/contact-us')} */}
                <Button type="button"  size="md"  color="danger" onClick={() => showContactUs(true)}  className="" outline>
                    Contact Us
                </Button>
            </ModalFooter>
        </Modal>
        <div className='invite_footer p-5 border-top'>
            <div class="foot-legal text-center">
            <p className='mb-0'>Copyright © 2022 Thalamus Medical Media Inc. &nbsp;|&nbsp; <a target="_blank" rel="noreferrer" href="https://dental.thalamusmedia.com/legal">Legal</a></p>
            </div>
        </div>
    </div>
  );
}

export default SignUpTos;