import { createSlice } from "@reduxjs/toolkit";
import { inactiveInitialState } from "./initialState";

const loadingInActiveWebStream = (state) => {
  state.loading = true;
};

const inActiveWebStreamsSuccess = (state, action) => {
  state.data = action.payload;
};

const inActiveWebStreamFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const setSelectedDomain = (state, action) => {
  state.selectedDomain = action.payload;
  state.loadingSelectedDomain = false;
};

const loadingSelectedDomain = (state) => {
  state.loadingSelectedDomain = true;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const inActiveWebstreamSlice = createSlice({
  name: "inActiveWebstreamSlice",
  initialState: inactiveInitialState,
  reducers: {
    loadingInActiveWebStream,
    inActiveWebStreamsSuccess,
    inActiveWebStreamFailure,
    reset,
    setSelectedDomain,
    loadingSelectedDomain,
  },
});

export default inActiveWebstreamSlice;
