import React from "react";
import { Link } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Filter, FilterInput } from "../../../components/Filter";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingActiveWebStream } from "./Redux";
import { CheckPermission, months , WEBSTREAMS, years,} from '../../../data/comman';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import DefaultPlaceholder from '../../../components/DefaultPlaceholder'
import Loader from "../../../components/Loader";

const Activewebstream = (props) => {
  const [streamState, setStreamState] = React.useState({
    active: 0,
    isOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
  });

  const [searchedText, setSearchedText] = React.useState("");
  const [authorization_year, setAuthorizationYear] = React.useState('');
  const [authorization_month, setAuthorizationMonth] = React.useState('');
  const [currentSort, setCurrentSort] = React.useState('default');
  const [sortBy, setsortBy] = React.useState('authorization_at');
  const [orderBy, setorderBy] = React.useState('desc');
  const [loader, setLoader] = React.useState(false);

  const dispatch = useDispatch();

  const activeStreamData = useSelector((state) => state.activeWebStream.data);
  const loading = useSelector((state) => state.activeWebStream.loading);

  const { data } = activeStreamData;

  const toggleCollapse = () => {
    setStreamState({ ...streamState, isOpen: !streamState.isOpen });
  };

  React.useEffect(() => {
    dispatch(loadingActiveWebStream({ pagenum: 1, searchedText , authorization_year , authorization_month , sortBy , orderBy }));
  }, []);
  
  React.useEffect(() => {
    if(authorization_year != '' || sortBy!= 'authorization_at' || orderBy!= 'desc' || authorization_month != '' || searchedText != '' ){
      dispatch(loadingActiveWebStream({ pagenum: 1, searchedText , authorization_year , authorization_month , sortBy , orderBy}));
    }
  }, [authorization_year , authorization_month , searchedText , sortBy , orderBy]);

  const handleNext = async () => {
    const nextUrl = activeStreamData.next_page_url;
    if (nextUrl !== null) {
      const pagenum = parseInt(nextUrl.split("=").pop());
      dispatch(loadingActiveWebStream({ pagenum, searchedText , authorization_year }));
    }
  };

  const handlePrev = async () => {
    const prevUrl = activeStreamData.prev_page_url;
    if (prevUrl !== null) {
      const pagenum = parseInt(prevUrl.split("=").pop());
      dispatch(loadingActiveWebStream({ pagenum, searchedText , authorization_year }));
    }
  };

  const onSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  };

  const onChangeYear = async (e) =>{
    var authorization_year  = e.value;
    setAuthorizationYear(authorization_year)
  }

  const onChangeMonth = async (e) =>{
    var authorization_month  = e.value;
    setAuthorizationMonth(authorization_month)
  }

  
  const handleSearch = () => {
    dispatch(loadingActiveWebStream({ pagenum: 1, searchedText }));
  };

  const onChangeorderBy = async (value) =>{
    
    if(orderBy == 'asc'){
      setorderBy('desc')
    }else{
      setorderBy('asc')
    }
  }

  const onChangesortBy = async (value, order) => {
      if(sortBy == value && orderBy == order){
        setsortBy(value)
        setorderBy('desc')
      }else{
        setsortBy(value)
        setorderBy('asc')
      }
  }

  return (
    <>
      <Summarybar />
      {loading  && (
          <Loader />
      )}
      <div className="card flex-fill">
        <div className="card-box">
          <div className="card__header card__header__filter lieacence-header flex">
            <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
              <div className="card__title d-flex align-items-center w-auto">
                <h3 className="m-0 mr-4">Webstreams</h3>
                {CheckPermission(WEBSTREAMS.id,'write') &&
                <Link
                  className="link-btn outline-primary mx-3 px-5"
                  to="/app/webstreams/domainAuthrization"
                >
                  <Icon
                    className="mr-4"
                    icon="Plus-square"
                    disableFill="true"
                    size={16}
                  ></Icon>
                  Add Domains
                </Link>
                }
              </div>

              <div className="ml-auto d-flex align-items-center w-auto flex-wrap">
                <div className="help__inner__search mb-0 webstream-search mx-4">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by keyword"
                      onChange={onSearchTextChange}
                    ></input>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={handleSearch}
                    >
                      <Icon icon="search-icon" color="#357BCF" size={18} />
                    </Button>
                  </div>
                </div>

                <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5 flex-wrap">
                  <p className="m-0">
                    <strong>
                      Showing {activeStreamData.from}-{activeStreamData.to} of{" "}
                      {activeStreamData.total}
                    </strong>
                  </p>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item  border-0">
                      <a className="disabled" href="#">
                        <Icon
                          icon="Left-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handlePrev}
                        />
                      </a>
                    </li>
                    <li className="list-group-item  border-0">
                      <a className="" href="#">
                        <Icon
                          icon="Right-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handleNext}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  className={`btn-filter btn  ml-auto collapsed`}
                  type="button"
                  onClick={toggleCollapse}
                >
                  <i className="mr-3">
                    <Icon
                      icon="Down-arrow"
                      color="#157CF7"
                      disableFill="true"
                      size={15}
                    />
                  </i>{" "}
                  Filter
                </button>
              </div>
            </div>
            <Filter isOpen={streamState.isOpen}>
              <FilterInput
                type="dropDown"
                label="Authorization year"
                options={years}
                placeholder="-"
                onChange={onChangeYear}
              />
              <FilterInput
                type="dropDown"
                label="Authorization month"
                options={months}
                onChange={onChangeMonth}
                placeholder="-"
              />
            </Filter>
          </div>
        </div>
        <div className="sub-page__table">
          <PerfectScrollbar>
            <div className="px-5">
              <table className="table mb-0">
                <thead className="sticky">
                  <tr>
                    <th className="pl-0 w-75 sticky-column">
                      Authorized domains
                      <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'domain' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('domain', 'asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                      Practice name
                      <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'practice_name' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('practice_name', 'asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                    <th className="pl-0 col-1 sticky-column">
                      Authorization date
                      <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'authorization_at' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('authorization_at','asc')}>
                          <Icon
                          icon="Down-arrow"
                          color="#157CF7"
                          disableFill="true"
                          size={15}
                          />
                      </i>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                  Object.keys(activeStreamData).length > 0 &&
                  data.length > 0 ? data.map((item, index) => {
                      const { practice_name, domain, authorization_at, id } = item;
                      return (
                        <tr key={index} className="">
                          <td className="pl-0">
                            <Link
                              className="link-black"
                              to={`/app/webstreams/active/${id}`}
                            >
                              {domain}
                            </Link>
                          </td>
                          <td className="pl-0">{practice_name}</td>
                          <td className="pl-0">{authorization_at}</td>
                        </tr>
                      );
                    })
                    :<tr style={{border:'none'}}><td colSpan={6}><DefaultPlaceholder text={'The domains that you authorize will show up here.'}/></td></tr>
                    }
                </tbody>
              </table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export default Activewebstream;
