import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../../icon";

function Referrals(props) {
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
    const { setDefaultId } = props;
  return (
    <div className="help__inner__questions">
        <div className="d-flex align-items-center mb-5 pb-4 justify-content-between">
            <Button className="px-2 py-1 mr-auto" color="primary" onClick={()=>{ setDefaultId() }} >
            <Icon
                icon="back"
                disableFill="true"
                size={14}
                color="#fff"
            ></Icon>
            </Button>
            <h4 className="m-0 d-flex align-items-center"><Icon icon="Referrals" className="me-4" disableFill="true" size={24} color="#343434" ></Icon>Referrals</h4>
            <div></div>
        </div>
        <div className="question-row">
            <Card>
                <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                    <h5>What can I find on the Referrals page?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                    <CardBody>
                    <p>The referrals page is where you can find a list of all the referrals that you’ve signed up. You can sort the referrals by country, plan, sign up year, or sign up month by clicking the filter button.</p>
                    </CardBody>
                </Collapse>
            </Card>
            <Card>
                <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
                    <h5>What are Referrals?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                    <CardBody>
                    <p>Referrals are the dental practices who have signed up for Thalamus who have either used your own specific promo code/s or clicked on your specific referral link. It’s the way of tracking the accounts that are associated with your marketing or outreach efforts.</p>
                    </CardBody>
                </Collapse>
            </Card>
        </div>
    </div>
  );
}

export default Referrals;