export const prepareReqBodyForEditOverView = (overViewData) => {
  console.log(overViewData.payment_type,'overViewData.payment_type')
  let overViewReqBody = {
    accounting_email:overViewData.accounting_email,
    territories_id: overViewData.territories_id.map((item) =>
      item.value.toString()
    ),
    name: overViewData.name,
    company_name: overViewData.company_name,
    email: overViewData.email,
    phone: overViewData.phone,
    username: overViewData.username,
    referral_link: overViewData.referral_link,
    pre_live_url: overViewData.pre_live_url,
    industries_id: overViewData.industries.map((item) => item.value.toString()),
    affiliate_type: 1,
    address: {
      opration_office: {
        country_id: overViewData?.operatioOffice?.country[0]?.value || '',
        address_line_1: overViewData?.operatioOffice?.address_line1 || '',
        address_line_2: overViewData?.operatioOffice?.address_line2 || '',
        zip: overViewData?.operatioOffice?.zip || '',
        city: overViewData?.operatioOffice?.city,
        state_id: overViewData?.operatioOffice?.selectedState?.id || '',
      },
      head_office: {
        country_id: overViewData?.headOffice?.country[0]?.value,
        address_line_1: overViewData?.headOffice?.address_line1,
        address_line_2: overViewData?.headOffice?.address_line2,
        zip: overViewData?.headOffice?.zip,
        city: overViewData?.headOffice?.city,
        state_id: overViewData?.headOffice?.selectedState?.id,
      },
    },
    banking_detail:overViewData.banking_detail || '',
    banking_detail_file:overViewData.banking_detail_file || '',
    payment_method:overViewData.payment_method[0] && overViewData.payment_method[0].value || '',
    // payment_type:overViewData.payment_type[0].value,
    // last_payment_amount:overViewData.last_payment_amount
  };
  return overViewReqBody;
};
