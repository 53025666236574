export const TOKEN_KEY = "access_token"

export const setToken = function(token){
    localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = function(){
    return localStorage.getItem(TOKEN_KEY)
}

export const clearStorage = function(){
    localStorage.clear();
    localStorage.removeItem('persist:root')
}