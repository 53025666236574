import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { getInviteDetails } from "../../../service/invitation";
import {
  loadingInvitation,
  invitationFailure,
  invitationSuccess,
  reset,
} from "./index";
import {history} from "../../index";
export function* invitationWatcher(params) {
  try {
    const data = yield call(getInviteDetails, params.payload);
    yield put(invitationSuccess(data.data));
  } catch (err) {
    // history.push('/')
    setTimeout(() => {
       put(invitationFailure(err));
    }, 500);
    
  }
}

export default function* invitationSaga() {
  yield all([
    takeLatest(loadingInvitation.type, invitationWatcher),
  ]);
}
