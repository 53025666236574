import React from 'react';
import { Collapse } from 'reactstrap';

export class Filter extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render(){
        const { isOpen } = this.props;
        return(
            <Collapse isOpen={ isOpen } className="filter__body">
                <div className="p-4">
                    <form>
                        <div className="form-row custom-form">
                            {this.props.children}
                        </div> 
                    </form>
                </div>
            </Collapse>
        );
    }
}
