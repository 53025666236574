import React, { useState } from 'react'

import { Link } from 'react-router-dom';
import Summarybar from '../../components/Summarybar';


import './style.scss';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';

import Icon from "../../icon";

function Index() {
  const [toggleQuestion, setToggequestion] = useState(1); //1 is the default id to be opened by default
  return (
    <div>
      <Summarybar />
      <div className="help__main__screen">
        <div className="help__inner">
          <div className="help__inner__search">
            <h4>How can we help you?</h4>
            <div className="search-box">
              <input type="search" className="form-control" placeholder="Search by keyword"></input>
              <Button color="link primary" className="p-0"><Icon icon="search-icon" color="#357BCF" size={18} /></Button>
            </div>
          </div>
          <div className="help__inner__category">
            <h4><Button color="primary"><Icon icon="Left-arrow" color="#FFFFFF" size={14} /></Button> Browse by categories</h4>
            <div className="category-details">
                <div className="category-details-icon">
                  <div><Icon icon="home" color="#FFF" size={44} /></div>
                  <h5>Overview</h5>
                </div>
                <div className="category-details-content">
                    <ul>
                        <li><a href="#">• Lorem Ipsum</a></li>
                        <li><a href="#">• Nullam Lacus Tellus</a></li>
                        <li><a href="#">• Dolor Sit Amet Consectetur </a></li>
                        <li><a href="#">• Imperdiet Ut Leo Efficitur</a></li>
                        <li><a href="#">• Adipiscing Elit</a></li>
                        <li><a href="#">• Facilisis.</a></li>
                    </ul>
                </div>
            </div>
          </div>
          <div className="help__inner__questions">
            <h4>Lorem Ipsum</h4>
            <div className="question-row">

              <Card>
                <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                  <h5>Q. Lorem ipsum dolor sit amet?</h5>
                  <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                  <CardBody>
                  A. Consectetur adipiscing elit. Nullam lacus tellus, imperdiet ut leo efficitur, facilisis tincidunt orci. Etiam vehicula molestie porta. Aenean sed turpis facilisis, vehicula urna non, placerat quam. Donec feugiat neque est, feugiat feugiat dui volutpat in. Etiam turpis odio, fermentum ut eleifend sed, condimentum at turpis. Nam dictum pellentesque massa eu tincidunt. Nulla faucibus magna et molestie tempus.
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
                  <h5>Q. Lorem ipsum dolor sit amet?</h5>
                  <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                  <CardBody>
                  A. Consectetur adipiscing elit. Nullam lacus tellus, imperdiet ut leo efficitur, facilisis tincidunt orci. Etiam vehicula molestie porta. Aenean sed turpis facilisis, vehicula urna non, placerat quam. Donec feugiat neque est, feugiat feugiat dui volutpat in. Etiam turpis odio, fermentum ut eleifend sed, condimentum at turpis. Nam dictum pellentesque massa eu tincidunt. Nulla faucibus magna et molestie tempus.
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader onClick={() => setToggequestion(3)} className={toggleQuestion === 3 ? 'question-open' : ''}>
                  <h5>Q. Lorem ipsum dolor sit amet?</h5>
                  <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 3 ? true : false}>
                  <CardBody>
                  A. Consectetur adipiscing elit. Nullam lacus tellus, imperdiet ut leo efficitur, facilisis tincidunt orci. Etiam vehicula molestie porta. Aenean sed turpis facilisis, vehicula urna non, placerat quam. Donec feugiat neque est, feugiat feugiat dui volutpat in. Etiam turpis odio, fermentum ut eleifend sed, condimentum at turpis. Nam dictum pellentesque massa eu tincidunt. Nulla faucibus magna et molestie tempus.
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader onClick={() => setToggequestion(4)} className={toggleQuestion === 4 ? 'question-open' : ''}>
                  <h5>Q. Lorem ipsum dolor sit amet?</h5>
                  <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 4 ? true : false}>
                  <CardBody>
                  A. Consectetur adipiscing elit. Nullam lacus tellus, imperdiet ut leo efficitur, facilisis tincidunt orci. Etiam vehicula molestie porta. Aenean sed turpis facilisis, vehicula urna non, placerat quam. Donec feugiat neque est, feugiat feugiat dui volutpat in. Etiam turpis odio, fermentum ut eleifend sed, condimentum at turpis. Nam dictum pellentesque massa eu tincidunt. Nulla faucibus magna et molestie tempus.
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader onClick={() => setToggequestion(5)} className={toggleQuestion === 5 ? 'question-open' : ''}>
                  <h5>Q. Lorem ipsum dolor sit amet?</h5>
                  <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                </CardHeader>
                <Collapse isOpen={toggleQuestion === 5 ? true : false}>
                  <CardBody>
                  A. Consectetur adipiscing elit. Nullam lacus tellus, imperdiet ut leo efficitur, facilisis tincidunt orci. Etiam vehicula molestie porta. Aenean sed turpis facilisis, vehicula urna non, placerat quam. Donec feugiat neque est, feugiat feugiat dui volutpat in. Etiam turpis odio, fermentum ut eleifend sed, condimentum at turpis. Nam dictum pellentesque massa eu tincidunt. Nulla faucibus magna et molestie tempus.
                  </CardBody>
                </Collapse>
              </Card>

            </div>
          </div>
          <div className="help__inner__contact-us">
            <h4>Can’t find what you need?</h4>
            <Button color="primary" outline>Contact Us</Button>
          </div>
        </div>
      </div>

    </div>

  );
}

export default Index;