import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";
import { PER_PAGE } from "./common";

const baseUrl = global.domain;

export const getOverviewDetails = async () => {
  try {
    const url = baseUrl + "/affiliate";
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getOverviewHtmlCode = async () => {
  try {
    const url = baseUrl + "/affiliate/webstream/get-html-code";
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const createContact = async (name, title, phone, email) => {
  try {
    const url = baseUrl + "/affiliate/contact";
    const reqBody = { name, title, phone, email };
    const { data } = await axios.post(url, reqBody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const updateContact = async (userId, name, title, phone, email) => {
  try {
    const url = baseUrl + `/affiliate/contact/${userId}`;
    const reqBody = { name, title, phone, email };
    const { data } = await axios.post(url, reqBody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const deleteContact = async (userId) => {
  try {
    const url = baseUrl + `/affiliate/contact/${userId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const updateOverviewData = async (reqbody) => {
  try {
    const url = baseUrl + "/affiliate";
    const { data } = await axios.post(url, reqbody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const sendIndividualVideoCode = async (reqbody) => {
  try {
    const url = baseUrl + "/affiliate/send-email-script-code-list";
    const { data } = await axios.post(url, reqbody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const downloadTos = async () => {
  try {
    const url = baseUrl + "/affiliate/download-tos";
    const { data } = await axios.post(url);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getExpiredPromocodeApiCall = async (PAGE_NUM,sort_by='',order_by = '') => {
  try {
    const url = baseUrl + `/affiliate/promo-code?expired=true&per_page=${PER_PAGE}&page=${PAGE_NUM}&sort_by=${sort_by}&order_by=${order_by}`;
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const uploadBankingDetailFile = async (formData) => {
  try {
    const url = baseUrl + `/affiliate/file/upload`;
    const { data } = await axios.post(url, formData,{
      headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};