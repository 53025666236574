import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";

const baseURL = global.domain;

export const PER_PAGE = 12;

export const helperApi = async (helperIndustry) => {
  try {
    const url = baseURL + `/helper?types=${helperIndustry}`;
    let response = await axios.get(url);
    return response.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const sendContactDetails = async (reqbody) => {
  try {
    const url = baseURL + `/affiliate/contact-us`;
    const { data } = await axios.post(url, reqbody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};



export const updateTos = async (reqbody) => {
  try {
    const url = baseURL + `/affiliate/tos?status=${reqbody}`;
    let response = await axios.get(url);
    return response.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};