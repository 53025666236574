import { createSlice } from "@reduxjs/toolkit";
import { dashboardInitialState } from "./initialState";

const loadingDashboard = (state) => {
  state.loading = true;
};

const dashboardSuccess = (state, action) => {
  state.data = action.payload;
};

const dashboardError = (state, action) => {
  state.error = action.payload;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: dashboardInitialState,
  reducers: {
    loadingDashboard,
    dashboardSuccess,
    dashboardError,
    reset,
  },
});

export default dashboardSlice;
