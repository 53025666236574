import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { sendContactDetails } from "../../../service/common";
import {
  sendingContactInfo,
  sendingContactInfoFailure,
  sendingContactInfoSuccess,
  reset,
} from "./index";

export function* sendContactWatcher(params) {
  try {
    const data = yield call(sendContactDetails, params.payload);
    yield put(sendingContactInfoSuccess(data.data));
  } catch (err) {
    yield  put(sendingContactInfoFailure(err));
    
  }
}

export default function* invitationSaga() {
  yield all([
    takeLatest(sendingContactInfo.type, sendContactWatcher),
  ]);
}
