import React, { useState } from 'react'

import { Link } from 'react-router-dom';
import Summarybar from '../../components/Summarybar';

import { InputField } from '../../components/InputField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import right_arrow from '../../images/right_arrow.svg';

import './style.scss';
import { Button, Form, Col, FormGroup, Label, Row, FormText, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';

import Icon from "../../icon";
// import { history } from '../../pages/index';
let practiceId;

class RequestExtension extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            active: 0,
            isOpen: false,
            activeButtonTab: '1',
            ticketsData: this.props.tickets,
            isChecked: false,
            selectAll: false,
            ShowPutonhold: false

        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }

    toggleChange(event, ticket) {
        var ticketState = this.state.ticketsData;
        var ticketIndex = ticketState.indexOf(ticket);

        ticket.isChecked = event.target.checked;
        ticketState[ticketIndex] = ticket
        this.setState({
            ticketsData: ticketState
        });

        var selectedinvices = Object.keys(ticketState).filter(k => ticketState[k].isChecked);
        if (selectedinvices.length == Object.keys(ticketState).length) {
            this.selectAll(null, true);
        }
        else {
            this.selectAll(null, false);
        }
        this.setState({
            ShowPutonhold: false
        })
        if (event.target.checked) {
            this.setState({
                ShowPutonhold: true
            })
        }
    }
    toggleCollapse() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    toggleButtons(tab) {
        if (this.state.activeButtonTab !== tab) {
            this.setState({
                activeButtonTab: tab,
            });
        }
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
    }
    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    goBack() {
        // if (!this.state.isSave)
        //     history.goBack()
        // else
        //     this.setState({ leavePageModal: true });
    }

    render() {
        const { isOpen } = this.state;



        return (
            <div>
                <Summarybar />
                <div className="license-inner-card">
                    <div className="license-inner-head">
                        <h4><Button color="primary" ><Icon icon="Left-arrow" color="#FFFFFF" size={14} /></Button> License No. ADSL01872020</h4>
                    </div>
                    <Form>
                        <div className="license-inner-content no-background">
                            <h4>Bundle licenses</h4>
                            <FormGroup row className="align-items-center">
                                <Label for="licenses" className="pr-lg-0" sm={4}>Affiliate name</Label>
                                <Col sm={8}>
                                    <Input type="text" name="licenses" placeholder="-" value="Acme Dental Supplies Ltd." />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="licenses" className="pr-lg-0" sm={4}>Used by</Label>
                                <Col sm={8}>
                                    <Input type="text" name="licenses" placeholder="-" value="Rosetown Dental" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="licenses" className="pr-lg-0" sm={4}>Country</Label>
                                <Col sm={8}>
                                    <Input type="text" name="licenses" placeholder="-" value="United States" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="licenses" className="pr-lg-0" sm={4}>License type</Label>
                                <Col sm={8}>
                                    <Input type="text" name="licenses" placeholder="-" value="Bundle" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="StartDate" className="pr-lg-0" sm={4}>Start date</Label>
                                <Col sm={8}>
                                    <DatePicker className="form-control"
                                        selected={this.state.holdDate}
                                        dateFormat="MMMM d, yyyy"
                                        className="custom-datepicker form-control mb-2"
                                        placeholderText="Click the icon to select a date"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                                <Label for="StartDate" className="pr-lg-0" sm={4}>Expiry date</Label>
                                <Col sm={8}>
                                    <DatePicker className="form-control"
                                        selected={this.state.holdDate}
                                        dateFormat="MMMM d, yyyy"
                                        className="custom-datepicker form-control mb-2"
                                        placeholderText="Click the icon to select a date"
                                    />
                                </Col>
                            </FormGroup>
                            <FormText><span className="text-danger">*</span>Licenses with an expiry date can be extended for a year.</FormText>
                        </div>
                        <div className="request-licenses-button">
                            <Button type="button" color="primary" className="mw205" outline onClick={this.showModal.bind(this, 'RequestExtension')} >Request Extension</Button>
                        </div>
                    </Form>
                </div>
                <Modal isOpen={this.state.RequestExtension} toggle={this.closeModal.bind(this, 'RequestExtension')} wrapClassName="licenses-modal">
                    <ModalBody>
                        <h4>License extension request.</h4>
                        <p>A sales person will review your request to extend the selected license for a year, starting from the date the license expires. Once a decision has been made, you’ll be notified via email.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.showModal.bind(this, 'RequestExtensionSend')}>Request Extension</Button>
                        <Button color="danger" outline onClick={this.closeModal.bind(this, 'RequestExtension')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default RequestExtension;