import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";

const baseUrl = global.domain;

export const createUser = async (fname, lname, email, username) => {
  try {
    const reqBody = {
      first_name: fname,
      last_name: lname,
      email,
      username,
    };
    const url = baseUrl + "/affiliate/user";
    const { data } = await axios.post(url, reqBody);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const deleteUser = async (userId) => {
  try {
    const url = baseUrl + `/affiliate/user/${userId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const editUserDetails = async (id, fname, lname, email, username) => {
  try {
    const reqbody = { first_name: fname, last_name: lname, email, username };
    const url = baseUrl + `/affiliate/user/${id}`;
    let response = await axios.post(url, reqbody);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getAllUsers = async () => {
  try {
    const url = baseUrl + `/affiliate/user`;
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getAllModules = async () => {
  try {
    const url = baseUrl + "/affiliate/modules";
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const updateUserRights = async (reqbody) => {
  try {
    const url = baseUrl + "/affiliate/user-access-rights";
    const  response  = await axios.post(url, reqbody);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const userChangePassword = async (userId) => {
  try {
    const url = baseUrl + `/affiliate/user-change-password/${userId}`;
    const  response  = await axios.post(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};