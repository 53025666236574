import React from "react";
import { InputField } from "../../components/InputField";
import "./style.scss";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CustomInput,
} from "reactstrap";
import BrandIcon from "../../img/logo.svg";
import { useHistory } from "react-router";
import { sendLoginEmail , userForgetPassword} from "../../service/login";
// import MainProfileAvtar from "../../img/UserProfileMain.svg";
// import UserProfileAvtar from "../../img/UserProfileAvtar.svg";

function PasswordRecoveryEmailPage() {
  const [resendPasswordData, setResendPasswordData] = React.useState({
    email: "",
  });
  const [disablebtn, setDisableBtn] = React.useState(true);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleChange = (e) => {
    setResendPasswordData({
      ...resendPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    if (resendPasswordData.email.length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [resendPasswordData]);

  const handleResetBtn = async () => {
    try {
      setLoading(true);
      const data = await userForgetPassword(resendPasswordData.email);
      setLoading(false);
      history.push(
        `/password-recovery-email-reset?email=${resendPasswordData.email}`
      );
    } catch (err) {
      setError(Object.values(err.errors)[0][0]);
      setLoading(false);
    }
  };

  return (
    <div className="login__main__screen">
      <div className="login__inner">
        <div className="login__inner__wc-text col">
          <div className="wc-text-box">
            Welcome to the <span>Affiliate Partner Programs</span>
          </div>
        </div>
        <div className="login__inner__form">
          <div className="brand-icon">
            <img src={BrandIcon}></img>
          </div>
          <div className="form-body">
            <h4>Please enter your registered email</h4>
            <Row>
              <Col md={12}>
                <div className="user-profile-row align-items-center justify-content-center">
                  {/* <div className="user-profile-item mr-0">
                    <div className="user-profile-box">
                      <img src={UserProfileAvtar}></img>
                    </div>
                    <h4>Jean Paul Dupont</h4>
                  </div> */}
                  <Col md={12}>
                    <FormGroup className="noboldlabel">
                      <InputField
                        type="email"
                        name="email"
                        className={``}
                        value={resendPasswordData.email}
                        onChange={handleChange}
                        label="Email"
                        isError={error === "" ? "" : error}
                      />
                    </FormGroup>
                    <Button
                      type="button"
                      className="w-100"
                      color="black"
                      disabled={disablebtn || loading}
                      onClick={handleResetBtn}
                    >
                      Send a Password Reset Email
                    </Button>
                  </Col>
                  <Col
                    md={12}
                    // className="mt-8"
                    style={{ marginTop: "100px" }}
                  >
                    <Button
                      type="button"
                      className="w-100"
                      color="black"
                      onClick={() => history.goBack()}
                    >
                      Return
                    </Button>
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRecoveryEmailPage;
