import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";
import { PER_PAGE } from "./common";

const baseUrl = global.domain;

export const getActiveDomains = async (PAGE_NUM, searchedText = "", authorization_year='' ,authorization_month='', sort_by='', order_by='' ) => {
  try {
    const url =
      baseUrl +
      `/affiliate/webstream?per_page=${PER_PAGE}&page=${PAGE_NUM}&search=${searchedText}&authorization_year=${authorization_year}&authorization_month=${authorization_month}&sort_by=${sort_by}&order_by=${order_by}`;
    const data = await axios.get(url);
    return data.data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getInactiveDomains = async (PAGE_NUM, searchedText , deactivation_year='' ,deactivation_month='' , authorization_year='' ,authorization_month='', sort_by='', order_by='' ) => {
  try {
    const url =
      baseUrl +
      `/affiliate/webstream?inactive&per_page=${PER_PAGE}&page=${PAGE_NUM}&search=${searchedText}&authorization_year=${authorization_year}&authorization_month=${authorization_month}&deactivation_month=${deactivation_month}&deactivation_year=${deactivation_year}&sort_by=${sort_by}&order_by=${order_by}`;
    const { data } = await axios.get(url);
    return data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const getOneDomainDetails = async (id) => {
  try {
    const url = baseUrl + `/affiliate/webstream/${id}`;
    const { data } = await axios.get(url);
    return data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const activateDeactivateDomain = async (action, date, webstreamIds) => {
  try {
    const url = baseUrl + "/affiliate/webstream/multi-selection";
    const reqbody = { action, date, webstream_id: [webstreamIds] };
    const { data } = await axios.post(url, reqbody);
    return data.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};

export const authorizeMultipleDomains = async (forms) => {
  try {
    const reqBody = { webstream_domain: forms };
    const url = baseUrl + "/affiliate/webstream";
    const response = await axios.post(url, reqBody);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
