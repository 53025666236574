import inActiveWebstreamSlice from "./slice";

export const {
  loadingInActiveWebStream,
  inActiveWebStreamFailure,
  inActiveWebStreamsSuccess,
  reset,
  setSelectedDomain,
  loadingSelectedDomain,
} = inActiveWebstreamSlice.actions;

export default inActiveWebstreamSlice.reducer;
