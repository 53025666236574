import getReferralsSlice from "./slice";

export const {
  getReferrals,
  getReferralsFailure,
  getReferralsSuccess,
  reset,
} = getReferralsSlice.actions;

export default getReferralsSlice.reducer;
