

import React from 'react';
import Countryselect from '../Countryselect';
import AppDropdown from '../AppDropDown'

export class FilterInput extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render(){
        const { id, label, type, placeholder, onChange } = this.props;
        return(
            <>
            {
                type == "countrydropDown" ?
                    <div className="form-group col mb-0">
                        <label htmlFor="inputCountry">{label}</label>
                        {/* <Countryselect {...this.props}/> */}
                        <AppDropdown 
                            className='react-select-container'
                            classNamePrefix="react-select"
                            options={this.props.options}
                            placeholder={placeholder}
                            getOptionLabel={ x => `${x.name}`}
                            getOptionValue={ x => `${x.id}`}
                            onChange={onChange}
                            // menuIsOpen={true}   
                            {...this.props}
                        />
                    </div>
                :    
                (
                    type == "dropDown" ?    
                    <div className="form-group col mb-0">
                        <label htmlFor={id}>{label}</label>
                        <AppDropdown 
                            className='react-select-container'
                            classNamePrefix="react-select"
                            options={this.props.options}
                            placeholder={placeholder}
                            onChange={onChange}
                            // menuIsOpen={true}   
                            {...this.props}
                        />
                    </div>
                :
                    <div className="form-group col mb-0">
                        <label htmlFor={id}>{label}</label>
                        <input type={type} className="form-control " id={id} placeholder={placeholder} onChange={this.props.onChange ? this.props.onChange: null}/>
                    </div>
                )
            }
            </>
        );
    }
}
