import React from "react";
import Loader from "react-loaders";
import './style.scss';

const index = () => {
  return (
    <div style={{position:'fixed',left: '0', right: '0', bottom: '0', top: '0',zIndex: '9'}} className="">
    <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>
    </div>
  );
};

export default index;
