import loginSlice from "./slice";

export const {
  loginLoding,
  reset,
  loginFailure,
  loginSuccess,
  setToken,
  setPwdData,
  setPwdError,
  setPwdLoading,
  setResetPwdLoading,
  logout,
  updateUserProfileLoading,
  updateUserProfileSuccess,
  updateTosDataSuccess,
  updateTosDataFailure,
  updateusertos
} = loginSlice.actions;

export default loginSlice.reducer;
