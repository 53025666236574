import React from "react";
import { Link } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Filter, FilterInput } from "../../../components/Filter";
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "./Redux";
import { months , years, payment_method , payment_status , ACTIVEFORMAT , getFilterStartEndDate} from '../../../data/comman';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import './style.scss';
import DefaultPlaceholder from '../../../components/DefaultPlaceholder'

const GeneralReport = (props) => {

  const [streamState, setStreamState] = React.useState({
    active: 0,
    isOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
  });

  
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.reports.data);
  const { data } = reportData;
  const [searchedText, setSearchedText] = React.useState("");
  const [sortBy, setsortBy] = React.useState('');
  const [orderBy, setorderBy] = React.useState('desc');
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [isError, setError] = React.useState("");

  React.useEffect(() => {
    dispatch(getReports({pagenum: 1, searchedText , sortBy , orderBy }));
  }, []);
  
  React.useEffect(() => {
    dispatch(getReports({ pagenum: 1, searchedText , sortBy , orderBy }));
  }, [searchedText , sortBy , orderBy]);

  const handleNext = () => {
    const nextUrl = reportData.next_page_url;
    if (nextUrl !== null) {
      const pagenum = parseInt(nextUrl.split("=").pop());
      dispatch(getReports({ pagenum, searchedText  }));
    }
  };

  const handlePrev = () => {
    const prevUrl = reportData.prev_page_url;
    if (prevUrl !== null) {
      const pagenum = parseInt(prevUrl.split("=").pop());
      dispatch(getReports({ pagenum, searchedText }));
    }
  };

  const onSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  };

  const onChangesortBy = async (value) =>{
      if(sortBy == value){
        setsortBy('')
      }else{
        setsortBy(value)
      }
        
  }
  
  const onChangeorderBy = async (e) =>{
    var orderBy  = e.value;
    setorderBy(orderBy)
  }
  
  const handleSearch = () => {
    dispatch(getReports({ pagenum: 1, searchedText }));
  };

  const onDownloadInvoices =  async (download_type) =>{
    // try {

    //   const response = await downloadInvoices(selectedInvoices,'webstream', download_type );

    //   if(download_type == 1){
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download',`${moment().unix()}.${response.headers['content-type'] == "application/octet-stream" ? "zip" : "pdf"}`);
    //     document.body.appendChild(link);
    //     link.click();
    //     toggleModal(false);
    //   }
     
    // } catch (err) {
    //       setError(err);
    // }
  }

  const getStatus = (id) => {
    switch (id) {
      case 1:
        return ["color-yellow", "Pending"];
      case 2:
        return ['color-green', "Paid"];
      case 3:
        return ['color-red', "Declined"];
      default:
        return ['color-green', "Pending"];
    }
  }

  const toggleChange = (e , invoice) => {
    var checkedBoxes = []
    checkedBoxes = [...selectedInvoices];
    if(e.target.checked) {
      checkedBoxes.push(invoice.id)
    } else {
      const index = checkedBoxes.findIndex((ch) => ch.id === invoice.id);
      checkedBoxes.splice(index, 1);
    }
    setSelectedInvoices(checkedBoxes);
  }

  return (
    <>
      <Summarybar />
      <div className="card flex-fill">
        <div className="card-box">
          <div className="card__header card__header__filter lieacence-header flex">
            <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
              <div className="card__title d-flex align-items-center w-auto">
                <h3 className="m-0 mr-4">Invoices</h3>
                {selectedInvoices.length > 0 &&
                  <>
                  <Button  type="button" color="primary link" outline className="mx-3 px-5 link-btn"  onClick={() => onDownloadInvoices(1)} ><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Button>
                  </>
                }
                </div>

              <div className="ml-auto d-flex align-items-center w-auto flex-wrap">
                <div className="help__inner__search mb-0 webstream-search mx-4">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by invoice no."
                      onChange={onSearchTextChange}
                    ></input>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={handleSearch}
                    >
                      <Icon icon="search-icon" color="#357BCF" size={18} />
                    </Button>
                  </div>
                </div>

                <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5 flex-wrap">
                {/* <h3 className="mb-0">2020</h3> */}
                  <p className="m-0">
                    <strong>
                      Showing {reportData.from}-{reportData.to} of{" "}
                      {reportData.total}
                    </strong>
                  </p>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item  border-0">
                      <a className="disabled" href="#">
                        <Icon
                          icon="Left-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handlePrev}
                        />
                      </a>
                    </li>
                    <li className="list-group-item  border-0">
                      <a className="" href="#">
                        <Icon
                          icon="Right-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handleNext}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-page__table">
          <PerfectScrollbar>
            <div className="px-5">
              <table className="table mb-0">
                <thead className="sticky">
                  <tr>
                    <th className="pl-0 w-50 sticky-column">
                        Report no.
                        <i className={`ms-3 ${sortBy === 'report_no' ? 'activearrow': ''}`} onClick={() => onChangesortBy('report_no')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Payment freq.
                        <i className={`ms-3 ${sortBy === 'payment_type' ? 'activearrow': ''}`} onClick={() => onChangesortBy('payment_type')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Amount due
                        <i className={`ms-3 ${sortBy === 'total' ? 'activearrow': ''}`} onClick={() => onChangesortBy('total')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column">
                        Due date
                        <i className={`ms-3 ${sortBy === 'due_date' ? 'activearrow': ''}`} onClick={() => onChangesortBy('due_date')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column col-1">
                        Payment method
                        <i className={`ms-3 ${sortBy === 'payment_method' ? 'activearrow': ''}`} onClick={() => onChangesortBy('payment_method')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    <th className="pl-0 sticky-column col-1">
                        Payment status
                        <i className={`ms-3 ${sortBy === 'payment_status' ? 'activearrow': ''}`} onClick={() => onChangesortBy('payment_status')}>
                            <Icon
                            icon="Down-arrow"
                            color="#157CF7"
                            disableFill="true"
                            size={15}
                            />
                        </i>{" "}
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(reportData).length > 0 &&
                   data.length > 0 ? data.map((item, index) => {
                      const { report_no , payment_type ,total , payment_method , due_date , payment_status , id} = item;
                      const status = getStatus( payment_status )
                      return (
                        <tr key={index} className="">
                            <td>
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <Input type="checkbox" className="custom-control-input ml-0"   name={'check'+item.id}  onChange={(event)=>toggleChange(event,item)}  id={'check'+item.id}/>
                                    <Link to={`/app/reports/webstreams/${id}`} htmlFor={'check'+item.id} className="link-black custom-control-label reports-checkbox pl-5">{report_no}</Link>
                                </div> 
                            </td>
                            
                            <td className="pl-0">{payment_type}</td>
                            <td className="pl-0">${total}</td>
                            <td className="pl-0">{moment(due_date).format(ACTIVEFORMAT)}</td>
                            <td className="pl-0">{payment_method}</td>
                            <td className={`pl-0 ${status[0]}`}>{status[1]}</td>
                        </tr>
                      );
                    })
                    :<tr style={{border:'none'}}><td colSpan={6}><DefaultPlaceholder text={'Webstreams and Licenses reports will show up here.'}/></td></tr>
                    }
                </tbody>
              </table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export default GeneralReport;
