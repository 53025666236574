import React from "react";

import { InputField } from "../../components/InputField";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CustomInput,
} from "reactstrap";
import BrandIcon from "../../img/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginLoding, logout, setToken } from "./Redux";
import LoadingButton from '../../components/LoadingButton';
import {loginUser} from '../../service/login'
import { clearStorage, setToken as localToken } from "../../helper/LocalStorage";
import Loader from "../../components/Loader";

var secret_token;

function LoginPage() {
  const history = useHistory();
  const [loginData, setLoginData] = React.useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const btnLoading = useSelector((state) => state.login.btnLoading1);
  const error = useSelector((state) => state.login.error);
  const login = useSelector((state) => state.login.data);
  const [loading, setloading] = React.useState(true);

  // const loading = useSelector((state) => state.login.loading);
  const dispatch = useDispatch();

  const handleLoginData = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    dispatch(logout())
    const query = new URLSearchParams(history.location.search);
    secret_token = query.get('secret')
    if(secret_token){
      // localToken(secret_token);
      fetchMyAPI();
    }
}, []);

  async function fetchMyAPI() {
    var loginData ={
      secret: secret_token
    }
    dispatch(loginLoding(loginData));
    setToken(secret_token)
    history.push('/app/dashboard')
  }

  const handleSubmit = () => {
    dispatch(loginLoding(loginData));
  };

  return (
    <>
     {btnLoading && secret_token ? (
       <div className="card py-5 d-flex align-items-center justify-content-center" style={{minHeight:'100vh'}}>
            <Loader />
        </div>
      ) :(
    <div className="login__main__screen">
      <div className="login__inner d-flex">
        <div className="login__inner__wc-text col">
          <div className="wc-text-box">
            Welcome to the <span>Affiliate Partner Programs</span>
          </div>
        </div>
        <div className="login__inner__form">
          <div className="brand-icon">
            <a href="https://dental.thalamusmedia.com" target="_blank" rel="noreferrer">
              <img src={BrandIcon} alt="Dental videos for patient education"></img>
            </a>
          </div>
          <div className="form-body">
            <h4>Please sign in with your credentials</h4>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <InputField
                    type="text"
                    label="Username"
                    name="username"
                    onChange={handleLoginData}
                    isError={error && error.errors && error.errors['username'] && error.errors['username'][0] || error.message}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <label>Password</label>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "HIDE" : "SHOW"}
                    </Button>
                  </div>
                  <InputField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    isError={error && error.errors && error.errors['password'] && error.errors['password'][0]}
                    onChange={handleLoginData}
                  />
                </FormGroup>
                {console.log(error,'error')}
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <CustomInput
                  className="sm"
                  type="checkbox"
                  id="exampleCustomCheckbox"
                  label="Remember me"
                />
              </Col>
              <Col md={7}>
                <p className="forgot-pw">
                  Forgot your password?{" "}
                  <Link to="/password-recovery-email">Click here.</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-5">
                <LoadingButton type="button"  disabled={btnLoading} label="Sign In" color="black" onClick={() => handleSubmit()} className="w-100" loading={btnLoading}  />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-5 pt-5">
                <label className="mb-3">Not an Affiliate Partner?</label>
                <a target="_blank"  rel="noreferrer" href="https://dental.thalamusmedia.com/affiliate/#affiliate-form-wrap" className="w-100 btn btn-black" >Sign Up</a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default LoginPage;
