import invitationSlice from "./slice";

export const {
  loadingInvitation,
  invitationFailure,
  invitationSuccess,
  reset,
} = invitationSlice.actions;

export default invitationSlice.reducer;
