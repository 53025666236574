import axios from "../utilities/interceptor/axiosconfig";
import global from "./global";

const baseUrl = global.domain;

export const getDashboardDetails = async () => {
  try {
    const url = baseUrl + "/affiliate/dashboard";
    let response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    }
  }
};
