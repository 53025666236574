import React, { Component } from 'react';
import Select , { components }from 'react-select';
import Icon from "../../icon";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="Down" color="#157CF7" disableFill="true" size={9} />
        </components.DropdownIndicator>
    );
};

class AppDropDown extends React.Component{
    constructor(props) {
        super(props)
    }
        
    render(){
        return(
            <Select 
                {...this.props}
                components={{  DropdownIndicator }} 
            />
        );
    }
}

export default AppDropDown;
