import { createSlice } from "@reduxjs/toolkit";
import { getReportsInitialState } from "./initialState";

const getReports = (state) => {
  state.loading = true;
};

const getReportsSuccess = (state, action) => {
  state.loading = false;
  state.data = action.payload;
};

const getReportsFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const setSelectedReport = (state, action) => {
  state.selectedReport = action.payload;
  state.loadingSelectedReport = false;
};
const setDownloadInvoice = (state, action) => {
  state.downloadInvoices = action.payload;
};

const loadingSelectedReport = (state) => {
  state.loadingSelectedReport = true;
};

const loadingDownloadInvoice = (state) => {
  state.loadingDownloadInvoice = true;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const getReportsSlice = createSlice({
  name: "getReportsSlice",
  initialState: getReportsInitialState,
  reducers: {
    getReports,
    getReportsSuccess,
    getReportsFailure,
    reset,
    setSelectedReport,
    loadingSelectedReport,
    loadingDownloadInvoice,
    setDownloadInvoice
  },
});

export default getReportsSlice;
