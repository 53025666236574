import React from 'react'


class Invoices extends React.Component{

  render(){
    return(
        <div>
            
        </div>
  );
}
}
export default Invoices;
