import pagesSlice from "./slice";

export const {
  loadingPages,
  paymentMethodsSuccess,
  errorPages,
  reset,
  countrySuccess,
  industrySuccess,
} = pagesSlice.actions;

export default pagesSlice.reducer;
