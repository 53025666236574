import overViewSlice from "./slice";

export const {
  loadingOverview,
  overViewFailure,
  overviewSuccess,
  reset,
  editOverViewFailure,
  editOverViewSuccess,
  loadingEditOverview,
  gethtmlcodeSuccess,
  gethtmlcodeloading,
  gethtmlcodeFailure,
  getExpiredPromocodeLoading,
  getExpiredPromocodeSuccess,
  getExpiredPromocodeFailure
} = overViewSlice.actions;

export default overViewSlice.reducer;
