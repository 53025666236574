import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Summarybar from '../../../components/Summarybar';
import Icon from "../../../icon";
import  { Filter, FilterInput} from '../../../components/Filter';
import ReactCountryFlag from "react-country-flag";
import { Button, Input, Label } from 'reactstrap';

const Authyear = [
    { value: 'All', label: 'All' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
  ]
const Authmonth = [
  { value: 'All', label: 'All' },
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
]
const  Deactiveyear= [
    { value: 'All', label: 'All' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
  ]
const Deactivemonth = [
  { value: 'All', label: 'All' },
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
]

let practiceId;
class LicensesReport extends React.Component{

    constructor(props) {
        super(props)
        
        this.state = {
            
          active: 0,
          isOpen:false,
          activeButtonTab:'1',
          ticketsData: this.props.tickets,
          isChecked: false,
          selectAll: false,
          ShowPutonhold:false
            
        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
    }
    
    toggleChange (event, ticket) {
      var ticketState = this.state.ticketsData;
      var ticketIndex = ticketState.indexOf(ticket);
      
      ticket.isChecked = event.target.checked;
      ticketState[ticketIndex] = ticket
      this.setState({ 
        ticketsData: ticketState
      });
      
      var selectedinvices = Object.keys(ticketState).filter(k => ticketState[k].isChecked);
      if(selectedinvices.length == Object.keys(ticketState).length){
        this.selectAll(null, true);
      }
      else{
        this.selectAll(null, false);
      }
      this.setState( {
          ShowPutonhold:false
      } )
      if(event.target.checked){
          this.setState( {
              ShowPutonhold:true
          } )
      }
  }
  toggleCollapse() {
      this.setState({ isOpen: !this.state.isOpen });
  }
  toggleButtons(tab) {
      if (this.state.activeButtonTab !== tab) {
          this.setState({
              activeButtonTab: tab,
          });
      }
  }

  render(){
    const { isOpen } = this.state;
    return(
        <div>
            <Summarybar />
            <div className="card">
              <div className="card-box">
              <div className="card__header card__header__filter lieacence-header ">
                  <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button py-4 pb-0">
                      <div className="card__title d-flex align-items-center w-auto">
                          <h3 className="m-0 mr-4">Licenses Reports</h3>
                          <Link className="link-btn outline-primary mx-3 px-5" to='/Activewebstream/DomainAuthrization'><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Link>
                      </div>
                      
                      <div className="ml-auto d-flex align-items-center w-auto">
                        <div className="help__inner__search mb-0 webstream-search mx-4">
                          <div className="search-box">
                            <input type="search" className="form-control" placeholder="Search by keyword"></input>
                            <Button color="link primary" className="p-0"><Icon icon="search-icon" color="#357BCF" size={18} /></Button>
                          </div>
                        </div>
                          <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5">
                                <h3 className="mb-0">2020</h3>
                                <ul className="list-group list-group-horizontal">
                                    <li className="list-group-item  border-0"><a className="disabled" href="#" ><Icon  icon="Left-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                    <li className="list-group-item  border-0"><a className="" href="#"><Icon icon="Right-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                </ul>
                            </div>
                      </div>
                  </div>
              </div>
              </div>
              <div className="p-5 px-5 w-100">
                  <div className="px-5 table-responsive">
                    <table className="table mb-0">
                        <thead className="sticky">
                            <tr>
                                <th className="pl-0">Report no.</th>
                                <th className="pl-0">Payment freq.</th>
                                <th className="pl-0">Amount due</th>
                                <th className="pl-0">Due date</th>
                                <th className="pl-0">Payment method</th>
                                <th className="pl-0">Payment status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                            <tr className="">
                                <td>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <Input type="checkbox" className="custom-control-input ml-0"  id="customecheck2"/>
                                        <Link to='/app/GeneralReport/ReportsGeneralInvoice' className="link-black custom-control-label reports-checkbox pl-5">ADSL082020</Link>
                                    </div> 
                                </td>
                                <td className="pl-0">Monthly</td>
                                <td className="pl-0">$7,968</td>
                                <td className="pl-0">1 Oct 2020</td>
                                <td className="pl-0">Wire transfer</td>
                                <td className="pl-0">Pending</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
              </div>
            </div>
        </div>
  );
}
}
export default LicensesReport;
