import { createSlice } from "@reduxjs/toolkit";
import { getReportsInitialState } from "./initialState";

const getReports = (state) => {
  state.loading = true;
};

const getReportsSuccess = (state, action) => {
  state.data = action.payload;
};

const getReportsFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const setSelectedInvoice = (state, action) => {
  state.selectedReport = action.payload;
  state.loadingSelectedInvoice = false;
};
const setDownloadInvoice = (state, action) => {
  state.downloadInvoices = action.payload;
};

const loadingSelectedInvoice = (state) => {
  state.loadingSelectedInvoice = true;
};

const loadingDownloadInvoice = (state) => {
  state.loadingDownloadInvoice = true;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const getReportsSlice = createSlice({
  name: "getReportsSlice",
  initialState: getReportsInitialState,
  reducers: {
    getReports,
    getReportsSuccess,
    getReportsFailure,
    reset,
    setSelectedInvoice,
    loadingSelectedInvoice,
    loadingDownloadInvoice,
    setDownloadInvoice
  },
});

export default getReportsSlice;
