import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../../icon";

function Overview(props) {
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
    const { setDefaultId } = props;
  return (
    <div className="help__inner__questions">
        <div className="d-flex align-items-center mb-5 pb-4 justify-content-between">
            <Button className="px-2 py-1 mr-auto" color="primary" onClick={()=>{ setDefaultId() }} >
            <Icon
                icon="back"
                disableFill="true"
                size={14}
                color="#fff"
            ></Icon>
            </Button>
            <h4 className="m-0 d-flex align-items-center"><Icon icon="home" className="me-4" disableFill="true" size={24} color="#343434" ></Icon>Overview</h4>
            <div></div>
        </div>
        <div className="question-row">
            <Card>
            <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                <h5>What can I find on the Overview page?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                <CardBody>
                <p>The overview page is where you can access information about your account. Information like your business details, contact information, and billing can be found here, among other useful information regarding your affiliate history.</p>
                </CardBody>
            </Collapse>
            </Card>

            <Card>
            <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
                <h5>How do I edit my payment information?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                <CardBody>
                <p>Payment information can be edited from the overview page. Simply press the ‘edit’ button beside the business details title.</p>
                </CardBody>
            </Collapse>
            </Card>

        </div>
    </div>
  );
}

export default Overview;