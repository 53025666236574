import React, { useState, useEffect } from 'react';
import {
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import Logo from '../../images/affilate-logo.svg'


const Invitation =  () => {
  const history = useHistory();
  return (
    <>
    <div className="min-vh-100 d-flex flex-column justify-content-center">
      <div className='invite_header p-5 text-center'>
        <img src={Logo}/>
      </div>
      <div className='flex-grow-1 justify-content-center d-flex flex-column align-items-center'>
        <h1 className='mb-5'>Welcome to Thalamus Affiliates</h1>
        {/* <h3 className="mb-5">Your username and password have been sent to your email address</h3> */}
        <Button size="md" color="black" onClick={() => history.push('/')}  >Sign in</Button>
      </div>
        <div className='invite_footer p-5 border-top'>
          <div class="foot-legal text-center">
            <p className='mb-0'>Copyright © 2022 Thalamus Medical Media Inc. &nbsp;|&nbsp; <a target="_blank" rel="noreferrer" href="https://dental.thalamusmedia.com/legal">Legal</a></p>
          </div>
        </div>
    </div>
    
    </>
  );
};
export default Invitation;
