const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const domainRgx = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
const numberrgx = /^[0-9]*$/;

export const handleValidations = (value, type) => {
  if (type === "email") {
    return emailRgx.test(String(value).toLowerCase());
  }
  if (type === "domain") {
    return domainRgx.test(value);
  }
  if (type === "name") {
    return value.length > 4;
    // && !numberrgx.test(value);
  }
  if (type === "phone") {
    return numberrgx.test(value);
  }
};
