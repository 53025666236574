import contactUsSlice from "./slice";

export const {
  sendingContactInfo,
  sendingContactInfoFailure,
  sendingContactInfoSuccess,
  reset,
} = contactUsSlice.actions;

export default contactUsSlice.reducer;
