import React from "react";
import ReactCountryFlag from "react-country-flag";
import UserProfileModal from '../UserProfileModal';
import Icon from "../../icon";
import {CheckPermission , USERPROFILE} from '../../data/comman'
import { useDispatch, useSelector } from "react-redux";

function Summarybar() {

  const login = useSelector((state) => state.login.data);

//   const data = useSelector((state) => state);
//   console.log(data,'data')
  return (
    <div className="status-bar">
        <div className="card bg--white mb-5 flex-row   flex-wrap px-5">
            <div className="col  d-flex align-items-center">
                <div className="card__box border--right">
                    <div className="card__body">
                        <div className="card__heading mb-2">Affiliate Name</div>
                        <div className="card__text">{login.first_name } {login.last_name }<ReactCountryFlag className="flag-img ml-4" countryCode={login.affiliate.head_office_address && login.affiliate.head_office_address.country.code} svg  style={{ width: '15px', height: '15px', }} title={login.affiliate.head_office_address && login.affiliate.head_office_address.country.code} /></div>
                    </div>
                </div>
                <div className="card__box">
                    <div className="card__body">
                        <div className="card__heading mb-2">Account Number</div>
                        <div className="card__text">{login.affiliate && login.affiliate.account_code}</div>
                    </div>
                </div>
            </div>
            <div className="col  d-flex align-items-center">
                
            </div>
            
            <div className="col border--left d-flex justify-content-center align-items-center">
                <div className="card__box ml-auto">
                    <div className="card__body d-flex">
                        <Icon className="mr-4" icon="user-icon" disableFill="true" size={24}></Icon>
                        <div>
                            <div className="card__heading mb-3 mt-2">{login.first_name }{" "}{login.last_name }</div>
                            {CheckPermission(USERPROFILE.id,'read') &&
                                <div className="card__text"><UserProfileModal /></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Summarybar;
