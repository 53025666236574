import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { helperApi } from "../../../service/common";
import {
  getOverviewDetails,
  editUserDetails,
  updateOverviewData,
  getOverviewHtmlCode,
  getExpiredPromocodeApiCall
} from "../../../service/overview";
import { EDIT_USER } from "./actions";
import {
  editOverViewFailure,
  editOverViewSuccess,
  loadingOverview,
  overViewFailure,
  overviewSuccess,
  reset,
  loadingEditOverview,
  gethtmlcodeloading,
  gethtmlcodeSuccess,
  gethtmlcodeFailure,
  getExpiredPromocodeLoading,
  getExpiredPromocodeSuccess,
  getExpiredPromocodeFailure
} from "./index";

export function* overViewWatcher(params) {
  try {
    const data = yield call(getOverviewDetails);
    yield put(overviewSuccess(data.data));
    yield put(reset());
  } catch (err) {
    yield put(overViewFailure(err.response.data));
  }
}

export function* editWatcher(params) {
  try {
    const data = yield call(updateOverviewData, params.payload);
    yield put(editOverViewSuccess(data.data));
    yield put(loadingOverview());
  } catch (err) {
    yield put(editOverViewFailure(err.errors));
  }
}

export function* gethtmlcode(params) {
  try {
    const data = yield call(getOverviewHtmlCode);
    yield put(gethtmlcodeSuccess(data.data));
  } catch (err) {
    yield put(gethtmlcodeFailure(err.errors));
  }
}

export function* getExpiredPromocode(params) {
  const { pagenum , sortBy , orderBy} = params.payload;
  try {
    const data = yield call(getExpiredPromocodeApiCall, pagenum , sortBy , orderBy);
    yield put(getExpiredPromocodeSuccess(data.data));
  } catch (err) {
    yield put(getExpiredPromocodeFailure(err.errors));
  }
}

export default function* overViewSaga() {
  yield all([
    takeLatest(loadingOverview.type, overViewWatcher),
    takeLatest(loadingEditOverview.type, editWatcher),
    takeLatest(gethtmlcodeloading.type, gethtmlcode),
    takeLatest(getExpiredPromocodeLoading.type, getExpiredPromocode),
  ]);
}
