import React, { useState, useEffect} from 'react'


import './style.scss';
import { InputField } from '../../components/InputField';
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Form, Col, Row, } from 'reactstrap';
import MainProfileAvtar from '../../img/UserProfileMain.svg'
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_USER , updateUserProfileLoading } from "../../pages/login/Redux";
import { CheckPermission , USERPROFILE } from '../../data/comman';
import { updateUserLoginProfile } from "../../service/login";
import LoadingButton from '../../components/LoadingButton';

const UserProfileModal = (props) => {
  const {
    modalClassName = 'profile-modal',
  } = props;


  const [modal, setModal] = useState(false);
  const [modalFinal, setFinalModal] = useState(false);
  const login = useSelector((state) => state.login.data);
  const error = useSelector((state) => state.login.error);
  const [dataState, setDataState] = React.useState({
    isOpen: false,
  });
  const [isError, setError] = React.useState("");
  const toggle = () => setModal(!modal);
  const toggleFinalModal = () => setFinalModal(!modalFinal);
  const dispatch = useDispatch();
  const [btnLoading, setbtnLoading] = React.useState(false);

  const [userData, setuserData] = React.useState({
    first_name:login.first_name || '',
    last_name:login.last_name || ''
  })

  const handleChange = (e) => {
    setuserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    // dispatch({type: 'UPDATE_USER', payload: userData})
      // dispatch(updateUserProfileLoading(userData));
    
      try {
        setbtnLoading(true);
        const data = await updateUserLoginProfile(userData);
        if(data.status === 200){
          setbtnLoading(false);
          dispatch(updateUserProfileLoading(data.data));
          setFinalModal(true);
          toggle();
        }
      } catch (err) {
       
        setError(err)
      }
      setbtnLoading(false);
  };



  return (
    <div>
      <Button Button color="link primary" className="p-0" onClick={toggle}>View Profile</Button>
      <Modal isOpen={modal} toggle={toggle} centered={true} wrapClassName={modalClassName}>
        <ModalBody>
          <div className="user-profile-form">
            <div className="user-profile-row">
              <div className="user-profile-item">
                <div className="user-profile-box main-profile">
                  <img src={MainProfileAvtar} alt=""></img>
                  <p>Admin</p>
                </div>
                <h4>{login.first_name}{" "}{login.last_name }</h4>
              </div>
            </div>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <InputField type="text" label="Name"  className={isError && isError.errors && isError.errors['first_name'] && isError.errors['first_name'][0] ? 'invalid-field' : ''} name="first_name" isError={isError && isError.errors && isError.errors['first_name'] &&  isError.errors['first_name'][0] } onChange={handleChange} value={userData.first_name}/>
                </FormGroup>
                <FormGroup>
                  <InputField type="text"   className={isError && isError.errors && isError.errors['last_name'] && isError.errors['last_name'][0] ? 'invalid-field' : ''} name="last_name" isError={isError && isError.errors && isError.errors['last_name'] && isError.errors['last_name'][0] } onChange={handleChange} value={userData.last_name}/>
                </FormGroup>
              </Col>
            </Row>
            <div className="user-profile-note">
                <p><span className="text-danger">*</span>The Admin profile uses the main affiliate password only, which can be updated while visiting the Business Details page.</p>
            </div>
          </div> 
        </ModalBody>
        <ModalFooter>
        {CheckPermission(USERPROFILE.id,'write') &&
          <LoadingButton type="button" size="md" disabled={btnLoading} label="Save changes" color="black" onClick={() => handleSubmit()} className="" loading={btnLoading}  />
        }
          <Button color="danger" outline onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
      <Modal className="" size="sm" isOpen={modalFinal} onClick={toggleFinalModal} >
          <ModalBody className="text-center">
            <p className='mb-0'><strong>The Admin name has been updated.</strong></p>
            <ModalFooter className="justify-content-center pt-5 px-5 pb-0">
              <Button color='primary' size='sm' className='py-3' outline>Close</Button>
            </ModalFooter>
          </ModalBody>
        </Modal>
    </div>
  );
}

export default UserProfileModal;
export { default as UserProfileModal } from "./index";