export const prepareReqBodyForEditOverView = (overViewData) => {
  console.log(overViewData,'overViewData')

  let overViewReqBody = {
    accounting_email: overViewData.accounting_email || '',
    territories_id: overViewData.territories_id && overViewData.territories_id.map((item) =>
      item.value.toString()
    ),
    banking_detail_file:overViewData.banking_detail_file,
    password:overViewData.password || '',
    name: overViewData.name,
    company_name: overViewData.company_name || "",
    email: overViewData.email,
    phone: overViewData.phone,
    username: overViewData.username,
    referral_link: overViewData.referral_link,
    pre_live_url: overViewData.pre_live_url,
    industries_id: overViewData.industries && overViewData.industries.map((item) => item.value.toString()),
    affiliate_type: 1,
    address: {
      opration_office: {
        country_id:  overViewData.operatioOffice.country && overViewData.operatioOffice.country[0] && overViewData.operatioOffice.country[0].value ,
        address_line_1: overViewData.operatioOffice.address_line1,
        address_line_2: overViewData.operatioOffice.address_line2,
        zip: overViewData.operatioOffice.zip,
        city: overViewData.operatioOffice.city,
        state_id: overViewData.operatioOffice.selectedState && overViewData.operatioOffice.selectedState.id,
      },
      head_office: {
        country_id: overViewData.headOffice.country && overViewData.headOffice.country[0] && overViewData.headOffice.country[0].value,
        address_line_1: overViewData.headOffice.address_line1,
        address_line_2: overViewData.headOffice.address_line2,
        zip: overViewData.headOffice.zip,
        city: overViewData.headOffice.city,
        state_id:  overViewData.headOffice.selectedState && overViewData.headOffice.selectedState.id,
      },
    },
    payment_method: overViewData.payment_method && overViewData.payment_method.value,
    commission_tier_id:overViewData.commission_tier_id,
    program_id:overViewData.program_id
  };

  return overViewReqBody;
};
