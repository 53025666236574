import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Summarybar from "../../components/Summarybar";
import { CheckPermission, REFERRALS , WEBSTREAMS } from "../../data/comman";
import Icon from "../../icon";
import { loadingDashboard } from "./Redux";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard.data);
  const dashboardDataLength = Object.keys(dashboardData).length;

  React.useEffect(() => {
    dispatch(loadingDashboard());
  }, []);

  return (
    <div>
      <Summarybar />
      {dashboardDataLength > 0 && (
        <div className="dashboard-sec mw-80">
          <ul className="dashboard-list">
          {CheckPermission(REFERRALS.id,'read') &&
            <li>
              <div className="dashboard-header">
                <div className="d-flex flex-wrap justify-content-center align-items-center mb-3">
                  <Icon
                    icon="referrals"
                    color="#343434"
                    size={24}
                    className="mr-4"
                  />
                  <h4 className="mb-0">Referrals</h4>
                </div>
                <p>
                  <strong>Referral Link</strong>
                  <br />
                  <a target="_blank" href={dashboardData.referral_link}  >
                    {dashboardData.referral_link}
                  </a>
                </p>
              </div>
              <div className="dashboard-body-sec">
                <div className="dashboard-body">
                  <h2 className="color-orange large-heading">
                    {dashboardData.referrals_counts}
                  </h2>
                  <p className="mb-0">Total Practices Referred</p>
                </div>
                
                  <div className="dashboard-body-footer">
                    <Link to="/app/Sales" className="btn link-btn btn-md outline-primary">
                      {" "}
                      <Icon
                        icon="report-icon"
                        color="#343434"
                        size={16}
                        className="mr-4"
                      />{" "}
                      View Referrals
                    </Link>
                  </div>
                
              </div>
            </li>
          }
          {CheckPermission(REFERRALS.id,'read') &&
            <li>
              <div className="dashboard-header">
                <div className="d-flex flex-wrap justify-content-center align-items-center mb-3">
                  <Icon
                    icon="chart-icon1"
                    color="#343434"
                    size={24}
                    className="mr-4"
                  />
                  <h4 className="mb-0">Commissions</h4>
                </div>
                <p>
                  Currency | <Link to="">{dashboardData.currency}</Link>
                </p>
              </div>
              <div className="dashboard-body-sec">
                <div className="dashboard-body">
                  <h2 className="large-heading">
                    <span className="color-orange mr-3">$</span>
                    {dashboardData.commissions_count}
                  </h2>
                  <p className="mb-0">Total Earned</p>
                </div>
                {CheckPermission(REFERRALS.id,'read') &&
                  <div className="dashboard-body-footer">
                    <Link to="/app/Sales" className="btn link-btn btn-md outline-primary">
                      {" "}
                      <Icon
                        icon="report-icon"
                        color="#343434"
                        size={16}
                        className="mr-4"
                      />{" "}
                      View Referrals
                    </Link>
                  </div>
                }
              </div>
            </li>
          }
          {CheckPermission(WEBSTREAMS.id,'read') &&
            <li>
              <div className="dashboard-header">
                <div className="d-flex flex-wrap justify-content-center align-items-center mb-3">
                  <Icon
                    icon="video-icon"
                    color="#343434"
                    size={24}
                    className="mr-4"
                  />
                  <h4 className="mb-0">Webstreams</h4>
                </div>
                <p>
                  <strong>Pre-Live URL</strong>
                  <br />
                  <Link target="_blank" to={`//${dashboardData.pre_live_url}`}>
                    {dashboardData.pre_live_url || ''}
                  </Link>
                </p>
              </div>
              <div className="dashboard-body-sec">
                <div className="dashboard-body">
                  <h2 className="color-orange large-heading">
                    {dashboardData.webstreams_count}
                  </h2>
                  <p className="mb-0">Total Domains Authorized</p>
                </div>
                {CheckPermission(WEBSTREAMS.id,'write') &&
                <div className="dashboard-body-footer">
                  <Link to="/app/webstreams/domainAuthrization" className="btn link-btn btn-md outline-primary">
                    {" "}
                    <Icon
                      icon="Plus-square"
                      color="#343434"
                      size={16}
                      className="mr-4"
                    />{" "}
                    Add Domains
                  </Link>
                </div>
                }
              </div>
            </li>
          }
          </ul>
          <div className="active-promocode-sec">
            <div className="active-promocode-header">
              <h4>
                <Icon
                  icon="promocode-icon"
                  color="#343434"
                  size={24}
                  className="mr-4"
                />{" "}
                Active Promo Codes
              </h4>
            </div>
            <div className="promocode-table">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th className="text-center">Description</th>
                    <th className="text-right">Expiry date</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData.promo_codes.map((promodata, index) => {
                    return (
                      <tr key={index}>
                        <td className="color-orange">
                          <strong>{promodata.code}</strong>
                        </td>
                        <td className="text-center">
                          <span dangerouslySetInnerHTML={{__html: promodata.description}}></span>
                        </td>
                        <td className="text-right">{promodata.expiry_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
