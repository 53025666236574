import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../../icon";

function Licenses(props) {
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
    const { setDefaultId } = props;
  return (
    <div className="help__inner__questions">
        <div className="d-flex align-items-center mb-5 pb-4 justify-content-between">
            <Button className="px-2 py-1 mr-auto" color="primary" onClick={()=>{ setDefaultId() }} >
            <Icon
                icon="back"
                disableFill="true"
                size={14}
                color="#fff"
            ></Icon>
            </Button>
            <h4 className="m-0 d-flex align-items-center"><Icon icon="help-licenses" className="me-4" disableFill="true" size={24} color="#343434" ></Icon>Licenses</h4>
            <div></div>
        </div>
        <div className="question-row">
            <Card>
            <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                <h5>What can I find in the licences page?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                <CardBody>
                <p>The licences page shows the licences that were purchased. Along with the number, details about the practice that used the licence can also be found.</p>
                <p>Additional licences can also be purchased, as well as extended.</p>
                </CardBody>
            </Collapse>
            </Card>

            {/* <Card>
            <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
                <h5>How much do Thalamus licences cost?</h5>
                <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
            </CardHeader>
            <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                <CardBody>
                <p></p>
                </CardBody>
            </Collapse>
            </Card> */}

          
        </div>
    </div>
  );
}

export default Licenses;