import moment from 'moment';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {store} from '../Redux/store'
export const months = [
    { value: "", label: 'All months' },
    { value: 1, label: 'Janaury' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
]

export const ACTIVEFORMAT = "DD MMM YYYY";
export const DateFormat = "YYYY-MM-DD";
export const DateDDMMMYYY = "dd MMM yyyy";
export const DateYYYYMMMDD = "YYYY, MMM DD";
export const DateDDMMMYYYYHHMMAZ = "DD MMM YYYY, HH:mm A z";
export const DateMMMMYYYY = "MMMM, YYYY";
export const DateYYYYMMMDDHHMMA = "YYYY MMM DD, HH:mm A";
export const timeZone = "America/New_York";
export const EDITORDEFAULVALUE = "<p><br></p>";
export const TimeHHMMAZ = "HH:mm A z";

export const startYear = 2020;
export const SOURCE = "Website form";
export const TICKETMAXLENGTH = 30720; // ticket length (KB)

function getYear(){
    var endYear = parseInt(new Date().getFullYear());
    var yearArray = [];
    yearArray.push({ value: "", label: "All years" });
    for(var i = startYear; i <= endYear; i++){
        yearArray.push({ value: i, label: i })
    }
    return yearArray;
}

export const getFilterStartEndDate = function(selectedMonth, selectedYear){
        var year  = selectedYear || moment().format('YYYY');
        var month = selectedMonth ? selectedMonth : 1;  //|| (moment().get('month') + 1);
        var dayWrapper = moment(year + "-" + month + "-01", DateFormat);
        var startDate  = dayWrapper.clone().startOf('month').format(DateFormat);
        var endDate = "";
        if(!selectedMonth){
            endDate = dayWrapper.clone().endOf('year').format(DateFormat);//moment().format(DateFormat);
        }else{
            endDate = dayWrapper.clone().endOf('month').format(DateFormat);
        }
        return { startDate: startDate, endDate: endDate };
}

export const years = getYear();

export const status = [
    { value: 'Approved', label: 'Approved' },
    { value: 'On hold', label: 'On hold' },
    { value: 'Declined', label: 'Declined' },
]

export const payment_method = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Credit card' },
    { value: 2, label: 'Direct deposit' },
    { value: 3, label: 'Cheque' },
]

export const payment_status = [
    // { value: null, label: 'All' },
    // { value: 0, label: 'Cancelled' },
    { value: 1, label: 'Pending' },
    { value: 2, label: 'Paid' },
    { value: 3, label: 'Declined' },
    // { value: 4, label: 'Refunded' },
]


export const days = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
]
  
export const subscriptionData =[
    {value:'Monthly $249.99/mo', label:'Monthly $249.99/mo'},
    {value:'Monthly $242.99/mo', label:'Monthly $249.99/mo'},
    {value:'Monthly $243.99/mo', label:'Monthly $249.99/mo'},
    {value:'Monthly $244.99/mo', label:'Monthly $249.99/mo'},
    {value:'Monthly $245.99/mo', label:'Monthly $249.99/mo'},
]

export const roles =[
    {value:'Owner', label:'Owner'},
    {value:'Manager', label:'Manager'},
    {value:'Authorized user', label:'Authorized user'},
    {value:'Dentist', label:'Dentist'},
    {value:'Dental assistant', label:'Dental assistant'},
]

export const EditorButtonType = {
    All: 'All',
    HighlightColor: 'HighlightColor',
    TextFormat: "textFormat",
    File: 'File',
    AlignLeft: 'AlignLeft',
    AlignCenter: 'AlignCenter',
    AlignRight: 'AlignRight',
    Link: 'Link',
    Indent: 'Indent',
    Bullet: 'Bullet',
    Bold: 'Bold',
    Italic: 'Italic',
    Underline: 'Underline',
    TextColor: 'TextColor'
}

export const leadstage = [
    {value:'All lead stages',label:'All lead stages'},
    {value:'First contact',label:'1  First contact'},
    {value:'Follow up',label:'2  Follow up'},
    {value:'Trial period',label:'3  Trial period'},
    {value:'Second follow up',label:'4  Second follow up'}
]
export const Ownership = [
    { label: 'Individual', value: 0},
    { label: 'Corporate', value: 1},
];

export const HardwareQTY = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
]

export const HardwareWarranty =[
    {value: 0, label:'None'},
    // {value:'3 un. $137.40', label:'3 un. $137.40'},
];

export const DefaultLanguage = [
    { label: 'English (US)', value: 1},
    { label: 'English (AU)', value: 2},
    { label: 'English (UK)', value: 3},
    { label: 'Español', value: 4},
    { label: 'Français', value: 5},
    { label: 'Deutsche', value: 6},
    { label: 'عربى', value: 7},
    { label: 'Other', value: 8},
];

export const videoAudio =[
    { label: 'English (US)', value: 1},
    { label: 'English (AU)', value: 2},
    { label: 'English (UK)', value: 3},
    { label: 'Español', value: 4},
    { label: 'Français', value: 5},
    { label: 'Deutsche', value: 6},
    { label: 'عربى', value: 7},
    { label: 'Other', value: 8},
]

export const getFileName = function(path){
    return path.split('\\').pop().split('/').pop();
}

const ThalamusPlans = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Bundle' },
    { value: '3', label: 'Waiting Room' },
]

export const ModalTitle = {
    ticketBackTitle: "The reply hasn’t been sent yet,",
    ticketNoteBackTitle: "The note hasn't been added yet.",
    ticketNormalToUrgentTitle: 'The ticket will change from "Normal" to "Urgent".',
    ticketUrgentToNormalTitle: 'The ticket will change from "Urgent" to "Normal".',
    ticketFlagTitle: 'The ticket will be flagged.',
    ticketUnFlagTitle: 'The ticket will be unflagged.',
    ticketCloseTitle: "Closed tickets are no longer available",
    ticketLinkRedirect: "This hyperlink redirects you to a new domain",
    ticketHoldTitle: "On hold tickets are no longer interactive.",
    ticketOpenTitle: "Opening on hold tickets will make them interactive again.",
    ticketClosedOpenTitle: "Opening closed tickets will make them interactive again",
    ticketCloseDeleteTitle: "Deleting closed tickets make them unretrievable."
}

export const ModalDes = {
    ticketBackDesc: "Are you sure you want to leave this page?",
    ticketProrityDesc: "Would you like to proceed?",
    ticketCloseDesc: "Would you like to close this ticket?",
    ticketHoldDesc: "Would you like to put selected tickets on hold?",
    ticketOpenDesc: "Would you like to open this ticket?",
    ticketCloseDeleteDesc: "Would you like to permanently remove this ticket?",
}

export const ModalType = {
    back: "back",
    priority: "priority",
    flag: "flag",
    unFlag: "unFlag",
    closeTicket: "close",
    linkClick: "link",
    hold: "hold",
    open: "open",
    openCloseTicket: "openCloseTicket",
    delete: "delete"
}

export const TicketStatus = {
    MyTicket: 1,
    Open: 2,
    Unassigned: 3,
    Spam: 4,
    OnHold: 5,
    Closed: 6
}

export const TicketPriority = {
    Normal: 1,
    Urgent: 2
}

export const TicketFilterStatus = [
    { label: 'All status', value: 0},
    { label: 'Normal', value: 1},
    { label: 'Urgent', value: 2},
    { label: 'Flaged', value: 3},
    { label: 'Urgent & Flaged', value: 4},
]

export const TicketFilterType = [
    { label: 'All types', value: "" },
    { label: 'Open tickets', value: 2 },
    { label: 'Unassigned tickets', value: 3 },
    { label: 'On hold tickets', value: 5 },
    { label: 'Spam', value: 4 },
]

export const NOTETYPEACCOUNT = 1;
export const NOTETYPETICKET = 2;

export const BillingStatus = {
    archive: 0,
    active: 1,
    expired: 2,
    declined: 3,
}

export const BillingFilterCategories = [
    { value: 0, label: 'All categories' },
    { value: 1, label: 'Monthly subscription'},
    { value: 2, label: 'Yearly subscription' },
]

export const BillingFiletrStatus = [
    { value: 0, label: 'All status' },
    { value: 1, label: 'Paid'},
    { value: 2, label: 'Declined' },
]

export const MainRoute = {
    customerRoute: `/Customer/:practiceId`,
    customer: '/Customer',
    users: '/Users',
    accountoverview: '/Accountoverview',
    practiceinfo: '/Practiceinfo',
    billinginfo: '/Billinginfo',
    invoices: '/Invoices',
    subscription: '/Subscription',
    notes: '/Notes',
    files: '/Files',
    usage: '/Usage',
    tos: '/TOS',
    logs: '/Logs',
}

export const CustomerRoute = {
    users: `${MainRoute.customerRoute}${MainRoute.users}`,
    accountoverview: `${MainRoute.customerRoute}${MainRoute.accountoverview}`,
    practiceinfo: `${MainRoute.customerRoute}${MainRoute.practiceinfo}`,
    billinginfo: `${MainRoute.customerRoute}${MainRoute.billinginfo}`,
    invoices: `${MainRoute.customerRoute}${MainRoute.invoices}`,
    subscription: `${MainRoute.customerRoute}${MainRoute.subscription}`,
    notes: `${MainRoute.customerRoute}${MainRoute.notes}`,
    files: `${MainRoute.customerRoute}${MainRoute.files}`,
    usage: `${MainRoute.customerRoute}${MainRoute.usage}`,
    tos: `${MainRoute.customerRoute}${MainRoute.tos}`,
    logs: `${MainRoute.customerRoute}${MainRoute.logs}`,
}

export const DomainStatus = {
    onHold: 0,
    approved: 1,
    declined: 2,
    blacklisted: 3,
}
// superAdmin 


// permission
// user permission
export const USERPROFILE = { key: "UserProfile", id: 1 }
export const DASHBOARD = { key: "Dashboard", id: 2 }
export const OVERVIEW = { key: "Overview", id: 3 }
export const REFERRALS = { key: "Referrals", id: 4 }
export const WEBSTREAMS = { key: "Webstreams", id: 5 }
export const WEBSTREAMS_ACTIVE = { key: "Webstreams", id: 12 }
export const WEBSTREAMS_INACTIVE = { key: "Webstreams", id: 13 }

export const LICENSES = { key: "Licenses", id: 6 }
export const REPORTS = { key: "Reports", id: 7 }
export const INVOICES = { key: "Invoices", id: 8 }
export const CONTACTUS = { key: "ContactUs", id: 9 }
export const HELP = { key: "Help", id: 10 }
export const USERACCESSRIGHTS = { key: "UserAccessRights", id: 11 }



export const  CheckPermission = (modualId, permission) => {
    // const userPermission =  useSelector((state) => state.login.data);
     const userPermission = store.getState() && store.getState().login && store.getState().login.data ? store.getState().login.data.permissions : []
    if (userPermission.length > 0 ) {
        var isPemission =  userPermission.find(x => x.module_id == modualId);
        // console.log(isPemission,'isPemission')
        if(permission === 'read'){
            if(isPemission?.is_read == 1){
                return true;
            }else if(isPemission?.is_write == 1){
                return true;
            }
        }else{
            if(isPemission?.is_write == 1){
                return true;
            }
        }
    }
    return false
}
