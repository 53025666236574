import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Summarybar from "../../components/Summarybar";
import Icon from "../../icon";
import {
  Button,
  Input,
  Label,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingOverview , gethtmlcodeloading} from "./Redux";
import moment from "moment";
import {createContact,deleteContact,updateContact,downloadTos, sendIndividualVideoCode} from "../../service/overview";
import Loader from "../../components/Loader";
import { sendLoginEmail } from "../../service/login";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import htmlcode from '../../img/htmlcode.svg'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.scss'
import { ACTIVEFORMAT, CheckPermission, OVERVIEW, WEBSTREAMS } from '../../data/comman';
import ReactCountryFlag from 'react-country-flag';
import { Document, Page, pdfjs } from "react-pdf";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { saveAs } from "file-saver";
import LoadingButton from '../../components/LoadingButton';
import { handleValidations } from '../../utilities/validations';
import { InputField } from '../../components/InputField';
import AppDropDown from '../../components/AppDropDown';
import AppDropDownEmail from '../../components/AppDropDown/AppDropDownEmail';

var decode = require('decode-html');



export const getDataAccordingToID = (commonData, ids) => {
  const num_ids = ids.map((item) => parseInt(item));

  const filteredData = commonData.filter((item) => {
    if (num_ids.includes(item.id)) return item;
  });
  return filteredData;
};

const updateData = async (userId, name, title, phone, email) => {
  try {
    const data = await updateContact(userId, name, title, phone, email);
    return { status: true, data: data };
  } catch (err) {
    return { status: false, err };
  }
};

const deleteUserProfile = async (id) => {
  try {
    const data = await deleteContact(id);
    return { status: true, data: data };
  } catch (err) {
    return { status: false, err };
  }
};

// COMPONENT FOR ADD SECTION
const AddSection = () => {
  const [newUserDetails, setNewUserDetails] = React.useState({
    name: "",
    title: "",
    phone: "",
    email: "",
  });

  const dispatch = useDispatch();

  const [error, setError] = React.useState("");
  const [showAddButton, setShowAddButton] = React.useState(true);
  const [loadingbtn, setLoadingbtn] = React.useState(false);

  const handleNewUserDetails = (e) => {
    setNewUserDetails({ ...newUserDetails, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (field , val ) =>{
    var phn = `${val.split(" ")[0]}-${(val.replace(val.split(" ")[0],'')).replace(/[^\w]/gi, '')}`
    setNewUserDetails({
      ...newUserDetails,
      [field]:phn
    })
  }

  const onClickCancle = () =>{
    setError('');
    setShowAddButton(true);
  }

  const handleCreateUser = async () => {
    var errors = [];
    var isError = false;
    if (newUserDetails) {
      if (newUserDetails.name == "") {
        errors['name'] = ["The name field is required."];
        isError = true;
      }

      if (newUserDetails.title == "") {
        errors['title'] = ["The title field is required."];
        isError = true;
      }

      if (newUserDetails.phone == "") {
        errors['phone'] = ["The phone field is required."];
        isError = true;
      }
      const numberrgx = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      console.log(numberrgx.test(newUserDetails.phone),'newUserDetails.phone')


      if(newUserDetails.phone !== "" && numberrgx.test(newUserDetails.phone) == false){
        errors['phone'] = ["The phone format is invalid."];
        isError = true;
      }

      if (newUserDetails.email == "") {
        errors['email'] = ["The email field is required."];
        isError = true;
      }

      if(newUserDetails.email !== "" && handleValidations(newUserDetails.email, "email") == false){
        errors['email'] = ["The email must be a valid email address."];
        isError = true;
      }

    }
    if (isError) {
      setError({errors});
      return false
    }
    try {
      setLoadingbtn(true);
      const response = await createContact(
        newUserDetails.name,
        newUserDetails.title,
        newUserDetails.phone,
        newUserDetails.email
      );
      setLoadingbtn(false);
      dispatch(loadingOverview());
      setShowAddButton(true);
      setNewUserDetails({
        ...newUserDetails,
        name: "",
        title: "",
        email: "",
        phone: "",
      });
      setError("");
    } catch (err) {
      setLoadingbtn(false);
      setError(err);
    }
  };

  if (showAddButton) {
    return (
      <Col
        lg="4"
        className="d-flex justify-content-center align-items-center "
        style={{ minHeight: "200px" }}
      >
        {CheckPermission(OVERVIEW.id,'write') &&
        <Button
          size="sm"
          outline
          color="primary"
          onClick={() => setShowAddButton(false)}
          style={{ padding: "5px 10px" }}
        >
          New Contact
        </Button>
        }
      </Col>
    );
  } else {
    return (
      <Col lg="4" className="">
        {/* {error !== "" && <span className="text-danger">{error}</span>} */}
          <div className="py-4">
            <Row className="align-items-center mb-3">
              <Label className="pr-lg-0" md={2}>
                Name
              </Label>
              <Col md={7}>
                <div className="">
                  {console.log(error,'error')}
                  <span className="text-danger">{error && error.errors && error.errors['name'] && error.errors['name'][0]}</span>
                  <Input
                    type="text"
                    name="name"
                    value={newUserDetails.name}
                    className={`${error && error.errors && error.errors['name'] ? 'invalid-field' : ''}  py-0`}
                    onChange={handleNewUserDetails}
                  />
                </div>
              </Col>
              <Col md={3}>
                <Button type="button" color='link' className="mx-1 p-0" onClick={() => handleCreateUser()}>
                  <Icon
                    icon="tik-correct"
                    color="#007bff"
                    disableFill="true"
                    size={16}
                  />
                </Button>
                <Button type="button" color='link' className="mx-1 p-0" onClick={() => onClickCancle()}>
                  <Icon
                    icon="tik-wrong"
                    color="#007bff"
                    disableFill="true"
                    size={16}
                  />
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Label className="pr-lg-0" md={2}>
                Title
              </Label>
              <Col md={7}>
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['title'] && error.errors['title'][0]}</span>
                  <Input
                    name="title"
                    type="text"
                    value={newUserDetails.title}
                    onChange={handleNewUserDetails}
                    className={`${error && error.errors && error.errors['title'] ? 'invalid-field' : ''}  py-0`}
                  />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Label className="pr-lg-0" md={2}>
                Phone
              </Label>
              <Col md={7}>
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['phone'] && error.errors['phone'][0]}</span>
                  <PhoneInput defaultMask={'-...........'} countryCodeEditable={false} alwaysDefaultMask={true}  inputProps={{ name: 'phone',required: true, }}  preferredCountries={['ca','us','gb','au','de']} onChange={(value, country, e, formattedValue) => { handlePhoneChange("phone" , formattedValue, country, e, value)}} value={newUserDetails.phone} />
                </div>
                {/* <Input
                  type="tel"
                  name="phone"
                  value={newUserDetails.phone}
                  onChange={handleNewUserDetails}
                /> */}
              </Col>
            </Row>
            <Row className="align-items-center mb-3">
              <Label className="pr-lg-0" md={2}>
                Email
              </Label>
              <Col md={7}>
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['email'] && error.errors['email'][0]}</span>
                  <Input
                    type="email"
                    name="email"
                    value={newUserDetails.email}
                    onChange={handleNewUserDetails}
                    className={`${error && error.errors && error.errors['email'] ? 'invalid-field' : ''}  py-0`}
                  />
                </div>
              </Col>
            </Row>
          </div>
      </Col>
    );
  }
};

//CHILD COMPONENT OF CONTACTSECTION FOR HANDLING EVERY USER CRUD OPERATION
const UserSection = ({ user }) => {
  const [userDetails, setUserDetails] = React.useState({
    name: user.name,
    email: user.email,
    phone: user.phone,
    title: user.title,
  });
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");

  const [edit, setEdit] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);

  const toggleModal = (state) => {
    setOpenModal(state);
  };

  const handleEdit = (state) => {
    setEdit(state);
  };

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (field , val ) =>{
    var phn = `${val.split(" ")[0]}-${(val.replace(val.split(" ")[0],'')).replace(/[^\w]/gi, '')}`
    setUserDetails({
      ...userDetails,
      [field]:phn
    })
  }

  const handleUserUpdate = async () => {
    const response = await updateData(
      user.id,
      userDetails.name,
      userDetails.title,
      userDetails.phone,
      userDetails.email,
    );
    if (response.status) {
      setEdit(false);
    } else {
      // console.log(response.err.message,'response')
      setError(response.err);
    }
  };

  const handleRemoveContact = async () => {
    const response = await deleteUserProfile(user.id);
    if (response.status) {
      toggleModal(false);
      setEdit(false);
      dispatch(loadingOverview());
    } else {
      setError(response.err.message);
    }
  };

  return (
    <Col lg={4} className="">
      <div className="py-4">
      {/* {error !== "" && <span className="text-danger">{error}</span>} */}
        <div className="right-gray-border">
          <Row className="align-items-center mb-3">
            <Label className="pr-lg-0" md={2}>
              Name
            </Label>
            
            <Col md={7}>
              {edit ? (
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['name'] && error.errors['name'][0]}</span>
                  <Input
                    type="text"
                    name="name"
                    placeholder="-"
                    value={userDetails.name}
                    onChange={handleChange}
                    className={`${error && error.errors && error.errors['name'] ? 'invalid-field' : ''}  py-0`}
                  />
                </div>
              ) : (
                <span className="text--gray">{userDetails.name}</span>
              )}
            </Col>
            

            {/* LINK SECTION START */}
            {CheckPermission(OVERVIEW.id,'write') &&
            <Col md={3} className="p-0">
              {!edit ? (
                <Button type="button" color='link' className="mx-1 p-0" onClick={() => handleEdit(true)}>
                  <Icon
                    icon="edit"
                    color="#007bff"
                    disableFill="true"
                    size={18}
                  />
                </Button>
              ) : (
                <>
                  <Button type="button" color='link' className="mx-1 p-0" onClick={() => handleUserUpdate()}>
                    <Icon
                      icon="tik-correct"
                      color="#007bff"
                      disableFill="true"
                      size={16}
                    />
                  </Button>

                  <Button type="button" color='link' className="mx-1 p-0" onClick={() => handleEdit(false)}>
                    <Icon
                      icon="tik-wrong"
                      color="#007bff"
                      disableFill="true"
                      size={16}
                    />
                  </Button>

                  <Button type="button" color='link' className="mx-1 p-0" onClick={() => toggleModal(true)}>
                    <Icon
                      icon="trash-o"
                      color="#BA4640"
                      disableFill="true"
                      size={16}
                    />
                  </Button>
                </>
              )}
            </Col>
            }
            {/* LINK SECTION END */}
          </Row>
          <Row className="align-items-center mb-3">
            <Label className="pr-lg-0" md={2}>
              Title
            </Label>
            <Col md={7}>
              {edit ? (
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['title'] && error.errors['title'][0]}</span>
                  <Input
                    type="text"
                    name="title"
                    placeholder=""
                    value={userDetails.title}
                    onChange={handleChange}
                    className={`${error && error.errors && error.errors['title'] ? 'invalid-field' : ''}  py-0`}
                  />
                </div>
              ) : (
                <span className="text--gray">{userDetails.title}</span>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Label className="pr-lg-0" md={2}>
              Phone
            </Label>
            <Col md={7}>
              {edit ? (
                <div className="">
                  <span className="text-danger">{error && error.errors && error.errors['phone'] && error.errors['phone'][0]}</span>
                  {/* <Input
                    type="tel"
                    name="phone"
                    placeholder=""
                    value={userDetails.phone}
                    onChange={handleChange}
                    className="py-0"
                  /> */}
                  <PhoneInput defaultMask={'-...........'} countryCodeEditable={false} alwaysDefaultMask={true}  inputProps={{ name: 'phone',required: true, }}  preferredCountries={['ca','us','gb','au','de']} onChange={(value, country, e, formattedValue) => { handlePhoneChange("phone" , formattedValue, country, e, value)}} value={userDetails.phone} />

                </div>
                
              ) : (
                <span className="text--gray">{userDetails.phone}</span>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Label className="pr-lg-0" md={2}>
              Email
            </Label>
            <Col md={7}>
              {edit ? (
                <div className="">
                <span className="text-danger">{error && error.errors && error.errors['email'] && error.errors['email'][0]}</span>
                <Input
                  type="email"
                  name="email"
                  placeholder="-"
                  value={userDetails.email}
                  onChange={handleChange}
                  className={`${error && error.errors && error.errors['email'] ? 'invalid-field' : ''}  py-0`}
                />
                </div>
              ) : (
              <span className="text--gray">{userDetails.email}</span>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={openModal} toggle={() => toggleModal(false)}>
        <ModalBody className="text-center">
          <strong>
            {userDetails.name} will be removed from your contacts.
          </strong>
          <p>Would you like to proceed?</p>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <button
            type="button"
            className="btn btn-md outline-primary bg-white mr-4"
            onClick={() => handleRemoveContact()}
          >
            Removed Contact
          </button>
          <button
            type="button"
            className="btn btn-md outline-danger bg-white"
            onClick={() => toggleModal(false)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

// ALL CONTACTS AND ADMIN USER CONTACT
const ContactSection = ({ showModal, adminUser, users }) => {
  const { first_name, phone, email, id } = adminUser;

  const handleUserSection = () => {
    return users.map((user, i) => {
      if (i < 3) {
        return <UserSection user={user} />;
      }
    });
  };

  return (
    <>
      <div className="py-4 px-4 bg--gray">
        <div className="py-4 px-4">
          <h3 className="mr-4 mb-4">Contact</h3>
          {users.length <= 0 && 
            <p style={{color:'#767676'}}>Please add contact information for your practice.</p>
          }
          <Row className="contact_section">
            {handleUserSection()}
            {users.length < 3 && <AddSection />}
          </Row>
        </div>
      </div>
    </>
  );
};

const Overview = (props) => {
  const [dataState, setDataState] = React.useState({
    active: 0,
    isOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
    ShowTosModal:false,
    displayConfirmation: false,
  });
  const { isOpen } = dataState;
  const statadata = useSelector((state) => state);

  const [copyMessage, setCopyMessage] = useState({
    message: 'Copy',
  });
  const [btnEmailState, setBtnEmailState] = React.useState({
    color:'primary',
    message: 'Send Email',
    loading:false
  });
  const overviewdata = useSelector((state) => state.overview.data);
  const loading = useSelector((state) => state.overview.loading);
  const laodingHelperData = useSelector((state) => state.pagesData.loading);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [audioLanguage, setAudioLanguage] = useState(null);
  const [captionLanguage, setCaptionLanguage] = useState(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [isEmailError, setEmailError] = useState("")

  const html = useSelector(
    (state) => state.overview.getHtmlCode
  );

  const industryData = useSelector(
    (state) => state.pagesData.data.industries.industries
  );
  const countryData = useSelector(
    (state) => state.pagesData.data.countries.country
  );
  const paymentMethodData = useSelector(
    (state) => state.pagesData.data.paymentMethods["affiliate_payment_method"]
  );
  const audioLangunageData = useSelector(
    state => state.pagesData.data.industries.media_language
  );

  const [loadingbtn, setLoadingbtn] = useState(false);

  const {
    admin_user,
    accounting_email,
    company_name,
    address,
    created_at,
    industries_id,
    commission_tier_id,
    referral_link,
    payment,
    payment_method,
    banking_detail,
    banking_detail_file,
    territories_id,
    affiliate_tos,
    user,
    promo_code,
    authorized_domains_count,
    licenses,
    contacts,
    last_payment_amount,
    pre_live_url,
    general_count,
    program_id,
  } = overviewdata;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadingOverview());
    // dispatch(gethtmlcodeloading());
  }, []);

  React.useEffect(() => {
  }, []);
  

  // BUSSINESS DETAILS SECTION FUNCTIONS STARTS
  const handleEmailSending = async () => {
    try {
      setBtnEmailState({
        message: 'Send Email',
        color:'primary',
        loading:true
      })
      const data = await sendLoginEmail(admin_user.email);
      if(data.status === 200){
        setBtnEmailState({
          message:'Email Sent',
          color:'secondry',
          loading:false
        })
      }
      
    } catch (err) {
      closeModal("resetpassword");
    }
    setTimeout(() => {
      closeModal("resetpassword");
        setBtnEmailState({
          color:'primary',
          message: 'Send Email',
          loading:false
        })
    }, 1500);
  };

  function showModal(modal) {
    setDataState({ ...dataState, [modal]: true });
  }
  function closeModal(tabId) {
    setDataState({ ...dataState, [tabId]: false });
  }

  const changeColor = () => {
		
    setCopyMessage({
			message: 'Copied',
		});
    
    setTimeout(() => {
      setCopyMessage({
        message: 'Copy',
      });
    }, 3000);
	};

  const onClickgetCode = async () =>{
    dispatch(gethtmlcodeloading());
    showModal("getthecode");
  }

  function get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }


  // BUSSINESS DETAILS SECTION FUNCTIONS ENDS
  const adminDetailsObj =
    Object.keys(overviewdata).length > 0
      ? [
          {
            label: "Affiliate name",
            value: `${admin_user.first_name}  ${
              admin_user.last_name !== null ? admin_user.last_name : ""
            }`,
          },
          {
            label: "Company name",
            value: `${company_name}`,
          },
          {
            label: "Industries",
            value: getDataAccordingToID(industryData, [
              ...industries_id.map((item) => parseInt(item)),
            ])
              .map((item) => item.name)
              .toString(),
          },
          { label: "Phone", value: admin_user.phone },
          { label: "Email", value: admin_user.email },
          { label: "Accounting email", value: accounting_email },
          {
            label: "Head Office address",
            value: (
              `${address[0] && address[0].address_line_1 || ''},
              ${address[0] && address[0].address_line_2 || ''}${address[1] && address[1].address_line_2 ? ',' : ''}`
            ),
          },
          {
            label: "",
            value: (
              `${address[0] && address[0].city}, ${address[0] && address[0].zip},`
            ),
          },
          {
            label: "",
            value: (
              `${address[0] && address[0]?.country?.name}`
            ),
          },
          {
            label: "Operations Office address",
            value: `${address[1] && address[1].address_line_1 || ''}${address[1] && address[1].address_line_1 ? ',' : ''} ${address[1] && address[1].address_line_2 || ''}${address[1] && address[1].address_line_2 ? ',' : ''}`,
          },
          {
            label: "",
            value: `${address[1] && address[1].city && address[1].city || ''}${address[1] && address[1].city ? ',' : ''} ${address[1] && address[1].zip && address[1].zip || ''},`,
          },
          {
            label: "",
            value: (
              `${address[1] && address[1].country && address[1].country.name || ''}`
            ),
          },
        ]
      : [];

  const rightSideAdminDetails =
    Object.keys(overviewdata).length > 0
      ? [
          {
            label: "Username",
            value: admin_user.username,
          },
          {
            label: "password",
            value: (
              <>
                <span>******* |</span>
                <div
                  onClick={() => showModal("resetpassword")}
                  className="btn btn-link color-primary"
                >
                  Send a password{" "}
                </div>
              </>
            ),
          },
          { label: "Referral link", value: `${referral_link}` },
          {
            label: "Webstream Pre-live Domain" , value: pre_live_url
          },
          CheckPermission(WEBSTREAMS.id,'read') &&
          {
            label: "Embed Code" , value: (
              <>
                  <Link className="btn btn-link color-primary p-0"  onClick={() => onClickgetCode()}>Click to get the code{""}  
                    <i className="ms-2">
                    <Icon
                      icon="url"
                      color="#007bff"
                      disableFill="true"
                      size={15}
                    />
                    </i>
                    </Link>
              </>
            )
          },
          {
            label: "Territories",
            value: getDataAccordingToID(countryData, territories_id)
              .map((item) => item.name)
              .join(", "),
          },
          {
            label: "Affiliate since",
            value: moment(created_at).format("DD-MM-YYYY"),
          },
          { label: "Commissions", value: (
            <>
              <Link  to="/app/GeneralReport" target="_blank" className="btn btn-link color-primary p-0">Click to view{""}
              <i className="ms-2">
                <Icon
                  icon="external-link-symbol"
                  color="#007bff"
                  disableFill="true"
                  size={15}
                />
                </i>
              </Link>
            </>
          )},
          { label: "Last commission payment", value: last_payment_amount },
          { label: "Commission payment freq.", value: payment },
          {
            label: "Commission payment method",
            value: getDataAccordingToID(paymentMethodData, [payment_method])
              .map((item) => item.name)
              .toString(),
          },
          { label: "Affiliate banking details", value: (
            <>
            {banking_detail_file ?
              <a href={`${banking_detail_file}`} target="_blank" className="btn btn-link color-primary p-0">Click to view{""}
              </a>
              :
              <div className="" dangerouslySetInnerHTML={{__html: banking_detail}}></div>
            }
            </>
          ) },
        ]
      : [];

    const saveFile = async () => {
      setLoadingbtn(true);
      if(affiliate_tos.tos_list.file_content_type !== 'text'){
        saveAs(
          affiliate_tos.tos_list.file_content,
          `${moment().unix()}.${get_url_extension(affiliate_tos.tos_list.file_content)}`
        );
      }else{
        try {
          const response = await downloadTos();
          const linkSource = `data:application/pdf;base64,${response}`;
          const downloadLink = document.createElement("a");
          const fileName = `${moment().unix()}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } catch (err) {

        }
      }
      setLoadingbtn(false);
    };

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

  const handleOverViewChangeData = (e) => {
    var {name, value } = e.target;
    setEmail(value);
  }

  const validateEmail = (email) => {
    var emilValidate = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)
    if (!emilValidate.test(email))
        return true; //not valid
    else
        return false;
  }

  const sendEmail = async() => {
    if(email == ""){
      setEmailError("Please enter email address");
    } else if(validateEmail(email)){
      setEmailError("Please enter valid email address");
    } else {
      await setSendEmailLoading(true);
      setEmailError("")
      var reqBody = {
        email: email
      }

      if(emails.length > 0){
        reqBody["link_emails"] = emails.map(x => x.label)
      }
      if(audioLanguage){
        reqBody["audio_language"] = audioLanguage.locale
      }
      if(captionLanguage){
        reqBody["media_captions"] = captionLanguage.sort_name
      }

      var response = await sendIndividualVideoCode(reqBody)
      if(response.status === 200){
        showModal("displayConfirmation");
        setSendEmailLoading(false);
        setEmail("");
        setEmails([]);
        setAudioLanguage(null);
        setCaptionLanguage(null);
      } else {
        setSendEmailLoading(false);
      }
    }
  }

  return (
    <div>
      {Object.keys(overviewdata).length > 0 ? (
        <>
          <Summarybar />
          {loading && (
              <Loader />
          )}
            <div>
              <div className="py-4 px-4 card">
                <div className="py-4 px-4">
                  <div className="d-flex align-items-center">
                    <h3 className="mr-4">Business details</h3>
                    {CheckPermission(OVERVIEW.id,'write') &&
                    <Link to="/app/editoverview" className="btn outline-primary py-2 px-5 d-flex align-items-center">
                      <Icon
                        icon="edit"
                        disableFill="true"
                        size={16}
                        className="mr-3"
                      ></Icon>
                      Edit
                    </Link>
                  }
                  </div>
                  <div className="py-4">
                    <div className="py-4">
                      <Row>
                        <Col lg="5" className="right-gray-border ">
                          {adminDetailsObj.map((details,index) => {
                            return (
                              <Row key={index} className="align-items-center mb-3">
                                <Label className="pr-lg-0" lg={5}>
                                  {details.label}
                                </Label>
                                <Col lg={7}>
                                  <span className="text--gray">
                                    {details.value}
                                  </span>
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>
                        <Col lg="7" className="px-5">
                          {rightSideAdminDetails.map((item,index) => {
                            return (
                              <>
                              {item.label &&
                                <Row key={index} className="mb-2">
                                  <Label className="pr-lg-0" lg={4}>
                                    {item.label}
                                  </Label>
                                  <Col lg={8}>
                                    <span className="text--gray">
                                      {item.value}
                                    </span>
                                  </Col>
                                </Row>
                              }
                              </>
                            );
                          })}
                          {/* <Row className="mb-2">
                            <Label className="pr-lg-0" lg={4}>
                              Credit card (for Invoices)
                            </Label>
                            <Col lg={8}>
                              <span className="text--gray">
                                <img
                                  src={Card}
                                  width="35px"
                                  alt="card"
                                  className="mr-4"
                                />{" "}
                                <strong className="mr-4">James Carlton</strong>{" "}
                                <span className="mr-4">EXP 09/22</span>{" "}
                                <span className="mr-4">
                                  XXXX XXXX XXXX 7483
                                </span>{" "}
                              </span>
                            </Col>
                          </Row> */}
                          <Row className="mb-2">
                            <Label className="pr-lg-0" lg={4}>
                            {affiliate_tos.tos_list.tos_type == 2 ?
                              'Custom TOS'
                            :
                              'Standard TOS'
                            }
                            </Label>
                            <Col lg={8}>
                              {/* <span className="text--gray">
                                <a
                                  href={affiliate_tos.tos_list.file_content}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-link color-primary p-0"
                                >
                                  Click to view
                                </a>
                              </span> */}
                              <Button className="p-0" color="link primary" p-0 onClick={() => showModal("showtosmodal")}>Click to view</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <ContactSection
                showModal={showModal}
                adminUser={admin_user}
                users={contacts}
              />
              <div className="py-4 px-4 card">
                <div className="py-4 px-4">
                  <Row>
                    <Col >
                      <div className="h-100 right-gray-border">
                      <h3 className="mr-4">General</h3>
                      <p style={{color:'#767676'}}>The number of practices you refer will show up here.</p>
                        <div className="py-4">
                          {general_count.map((item,index) => {
                            return (
                              <Row key={index} className="align-items-center mb-3">
                                <Label className="pr-lg-0 d-flex align-items-center" lg={8}>
                                  <ReactCountryFlag className="flag-img mr-3" countryCode={item.country_id.code} svg  style={{ width: '28px', height: '20px', }} title={item.country_id.code} /> {item.country_name}
                                </Label>
                                <Col lg={4}>
                                  <span className="text--gray">{item.customers_per_country_count}</span>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col className="">
                      <div className={`${program_id.find((x)=>x == '3') ? 'right-gray-border' : ''} h-100`}>
                        <h3 className="mr-4">WEBSTREAMS</h3>
                        <div className="py-4">
                          <Row className="align-items-center mb-3">
                            <Label className="pr-lg-0" lg={8}>
                              Authorized domains
                            </Label>
                            <Col lg={4}>
                              <span className="text--gray">
                                {authorized_domains_count}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    {program_id.find((x)=>x == '3') &&
                    <Col  className="">
                      <div className="h-100">
                      <h3 className="mr-4">Licenses</h3>
                        <div className="py-4">
                          {Object.entries(licenses).map((item,index) => {
                            return (
                              <Row key={index} className="align-items-center mb-3">
                                <Label className="pr-lg-0" lg={8}>
                                  {item[0]}
                                </Label>
                                <Col lg={4}>
                                  <span className="text--gray">{item[1]}</span>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    }
                  </Row>
                </div>
              </div>
              <div className="py-4 px-5 card bg--gray">
                <div className="py-4 px-5">
                  <Row>
                    <Col lg="12">
                      <div className="d-flex align-items-center">
                        <h3 className="mr-4 mb-0">Promo Codes</h3>
                        <Link
                          to="/app/overview/ExpiredPromoCodes"
                          className="btn outline-primary py-2 px-4"
                        >
                          <Icon
                            icon="Clock"
                            disableFill="true"
                            size={16}
                            className="mr-3"
                          ></Icon>
                          Expired Codes
                        </Link>
                      </div>
                      <div className="py-4">
                        <div className="py-4">
                          <table className="table mb-0 w-100">
                            <thead>
                              <tr>
                                <th className="pl-0">Name</th>
                                <th>Code</th>
                                <th>Plans</th>
                                {/* <th>Program</th> */}
                                <th>Description</th>
                                <th>Start date</th>
                                <th>Expiry date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {promo_code.map((code,index) => {
                                console.log(code,'code')
                                return (
                                  <tr key={index}>
                                    <td className="pl-0">{code.title}</td>
                                    <td>{code?.code}</td>
                                    <td>{code?.plugins && code?.plugins.map((x, index) => `${x.name}${index != code.plugins.length - 1 ? ", " : ""}`)}</td>
                                    {/* <td>-</td> */}
                                    <td><span dangerouslySetInnerHTML={{ __html: code.description }}/></td>
                                    <td>{moment(code.start_date).format(ACTIVEFORMAT)}</td>
                                    <td>{moment(code.end_date).format(ACTIVEFORMAT)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              { CheckPermission(WEBSTREAMS.id,'read') && 
                <div className="py-4 px-4 card">
                  <div className="py-4 px-4">
                    <Row>
                      <Col>
                        <div className="col-lg-5 pr-lg-5">
                          <h3 className="mr-4 mb-0">Individual video code</h3>
                          <FormGroup row className="align-items-center mb-3">
                            <Label className="pr-lg-0 mt-3" lg={8}>Send code to email</Label>
                            <div className="form-group-with-error">
                              <InputField type="text"  
                                className={isEmailError ? 'invalid-field' : ''} name="email" 
                                isError={isEmailError} 
                                onChange={handleOverViewChangeData} value={email}
                            /></div>
                          </FormGroup>
                          <FormGroup row className="align-items-center mb-3">
                            <Label className="pr-lg-0 mt-0" lg={8}>Also send to the following email(s)</Label>
                            <div className="form-group-with-error">
                              <AppDropDownEmail 
                                onChange={(value) => setEmails(value)} 
                                value={emails}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup row className="align-items-center">
                            <Label lg={8}>Audio language</Label>
                              <div className="form-group-with-error">
                                <AppDropDown
                                  className={`react-select-container}`}
                                  classNamePrefix="react-select"
                                  options={audioLangunageData}
                                  getOptionLabel={option =>`${option.name}`}
                                  getOptionValue={option =>`${option.id} ${option.name}`}
                                  placeholder={"-"}
                                  value={audioLanguage}
                                  onChange={(val) => setAudioLanguage(val)}
                                />
                              </div>
                          </FormGroup>
                          <FormGroup row className="align-items-center">
                            <Label lg={8}>Caption language</Label>
                              <div className="form-group-with-error">
                                <AppDropDown
                                  className={`react-select-container}`}
                                  classNamePrefix="react-select"
                                  options={audioLangunageData}
                                  getOptionLabel={option =>`${option.name}`}
                                  getOptionValue={option =>`${option.id} ${option.name}`}
                                  placeholder={"-"}
                                  value={captionLanguage}
                                  onChange={(val) => setCaptionLanguage(val)}
                                />
                              </div>
                          </FormGroup>
                          <FormGroup row className="align-items-center float-right">
                            <div className="float-right">
                              <Button size="sm" className="align-items-center send-email" outline color="primary" disabled={sendEmailLoading} onClick={sendEmail}>
                                {sendEmailLoading ? 
                                  <Spinner className="" size="md" color="dark" /> 
                                :
                                  <><Icon icon="Paper-plane" className="mr-3" disableFill="true"size={16}/>Send Email</>
                                }
                              </Button>
                            </div>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            </div>

          <Modal
            isOpen={dataState.resetpassword}
            toggle={() => closeModal("resetpassword")}
          >
            <ModalBody className="text-center">
              <strong>
                An email containing a link to reset your password will be sent
                to the affiliate’s registered email.
              </strong>
              <p>Would you like to proceed?</p>
            </ModalBody>
            <ModalFooter className="justify-content-between">
              {/* <Button type="button" size="md" color={`${btnEmailState.color}`} className={` mr-4`} outline onClick={() => handleEmailSending()}
              >
                {btnEmailState.message}
              </Button> */}
              <LoadingButton type="button" size="md" disabled={btnEmailState.loading} label={btnEmailState.message} color={btnEmailState.color} onClick={() => handleEmailSending()} className="mr-4" loading={btnEmailState.loading}  outline/>
              <button
                type="button"
                className="btn btn-md outline-danger bg-white"
                onClick={() => closeModal("resetpassword")}
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
          <Modal className="get_code_modal"
            isOpen={dataState.getthecode}
            toggle={() => closeModal("getthecode")}
          >
            <ModalBody className="text-center p-0">
              <h2 className="d-flex align-items-center justify-content-center mb-4"><img className="me-4" src={htmlcode} alt=""/> Embed Code</h2>
              <p className="">*Please add this to your content management system.</p>
              <Input className="mt-5" type="textarea" value={html}></Input>
              <ModalFooter className="justify-content-between mt-5 pt-5 px-5 pb-0">
                <CopyToClipboard
                text={html ? html : null}
                // onCopy={codeCopied}
                >
                <button className='btn btn-sm btn-outline-secondry  htmlcode__main__button' onClick={() => changeColor()} >
                    {copyMessage.message}
                </button>
              </CopyToClipboard>
              <button
                type="button"
                className="btn btn-sm outline-danger bg-white"
                onClick={() => closeModal("getthecode")}
              >
                Close
              </button>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <Modal className="tos__modal" size="lg" isOpen={dataState.showtosmodal} toggle={() => closeModal("showtosmodal")} >
            <ModalBody className="">
            <div className='' style={{height:'calc(100vh - 20rem)',padding: '2rem 2rem 2rem 2rem'}}>
            <PerfectScrollbar>
              {affiliate_tos.tos_list.file_content_type === 'file' ?
                  get_url_extension(affiliate_tos.tos_list.file_content) === 'pdf' ?
                    <Document
                        file={affiliate_tos.tos_list.file_content}
                        onLoadSuccess={({ numPages })=>setNumPages(numPages)}
                        renderMode={'svg'}

                    >
                        {Array.apply(null, Array(numPages))
                      .map((x, i)=>i+1)
                      .map(page =>  <Page pageNumber={page} />)}
                    </Document>
                  :
                  <img className='mx-auto d-block' src={affiliate_tos.tos_list.file_content} alt=""/>
                  :
                <div id='tos__content' className="tos__content" dangerouslySetInnerHTML={{__html: decode(affiliate_tos.tos_list.file_content)}}></div>
              }
            </PerfectScrollbar>
            </div>
              <ModalFooter className="justify-content-center mt-5 pt-5 px-5 pb-0">
                {/* <button type="button" className="btn btn-sm outline-primary bg-white me-5"  onClick={() => saveFile()} >
                  Download
                </button> */}
                <LoadingButton type="button" size="sm" disabled={loadingbtn} label="Download" color="primary" onClick={() => saveFile()} className="" loading={loadingbtn} outline />
                <button type="button" className="btn btn-sm outline-danger bg-white ms-5"  onClick={() => closeModal("showtosmodal")} >
                  Close
                </button>
              </ModalFooter>
            </ModalBody>
          </Modal>
          <Modal className="" isOpen={dataState.displayConfirmation} toggle={() => closeModal("displayConfirmation")} >
            <ModalBody className="text-center py-5">
              <p> <strong>Code sent successfully.</strong></p>
            </ModalBody>
            <ModalFooter className="justify-content-center mb-4">
              <button
                type="button"
                className="btn btn-sm outline-danger bg-white"
                onClick={() => closeModal("displayConfirmation")}
              >
                Close
              </button>
            </ModalFooter>
          </Modal>
        </>
      ):
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    }
    </div>
  );
};
export default Overview;
