import React, { useState, useEffect,useRef } from 'react';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input, Modal, ModalBody, ModalFooter,Popover, PopoverBody
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingInvitation} from "./Redux";
import {getInviteDetails, checkUserInfo} from '../../service/invitation'
import AppDropDown from "../../components/AppDropDown";
import Summarybar from "../../components/Summarybar";
import MyEditor from "../../components/MyEditor/MyEditor"
import LoadingButton from '../../components/LoadingButton';

import Logo from '../../images/affilate-logo.svg'
import HttpUtility, { HELPERURI } from "../../utilities/HttpUtility";
import DatePicker from "react-datepicker";
import Icon from "../../icon";
import { prepareReqBodyForEditOverView } from "./helper";
import { sendLoginEmail } from "../../service/login";
import { updateInviteDetails , uploadBankingDetailFile} from "../../service/invitation";
import { EditorButtonType} from "../../data/comman";
import { InputField } from "../../components/InputField";
import moment from "moment";
import Loader from "../../components/Loader";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContactUs from '../ContactUs';
import { logout } from '../login/Redux';
import { Document, Page, pdfjs } from "react-pdf";

var decode = require('decode-html');

var invitation_token;
const paymentfrq = [
  { id: 1, name: "Monthly" },
  { id: 2, name: "Yearly" },
];

const getDataAccordingToID = (commonData, ids) => {
  const num_ids = ids.map((item) => parseInt(item));

  const filteredData = commonData.filter((item) => {
    if (num_ids.includes(item.id)) return item;
  });
  return filteredData;
};


const convertDataIntoDropDownForm = (dropDownData) => {
  return dropDownData.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};

const Invitation =  () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const overViewData = useSelector((state) => state.invitationData.data);
  const [overViewData, setoverViewData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.invitationData.error);
  const overViewstate = useSelector((state) => state);
  const [operatioOfficeState, setOperatioOfficeState] = React.useState("");
  const [headOfficeState, setheadOfficeState] = React.useState("");
  const [btnLoading, setbtnLoading] = React.useState(false);
  const [isError, setError] = React.useState("");
  const [iscopyAddress, setCopyAddress] = React.useState(false);
  const [isShowPassword, setShowPassword] = React.useState(false);
  const [isAcceptTos, setIsAcceptTos] = React.useState(false);
  const [showContactModal, setShowContactModal] = React.useState(false);
  const [isOpenpopover, setPopover] = React.useState(false);
  const [isOpenReferralLinkpopover, setReferralLinkPopover] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [sendCopyTos, setSendCopyTos] = useState(false);
  const [fileStates, setState] = React.useState({
    src: '',
    bfile: '',
    errors:''
  });

  const searchInput = useRef(null)
  const fieldRef = useRef(null)


  const industryData = useSelector(
    (state) => state.pagesData.data.industries.industries
  );
  const countryData = useSelector(
    (state) => state.pagesData.data.countries.country
  );
  const paymentMethodData = useSelector(
    (state) => state.pagesData.data.paymentMethods["affiliate_payment_method"]
  );

  React.useEffect(() => {
      const query = new URLSearchParams(history.location.search);
      invitation_token = query.get('invitation_token')
      // dispatch(loadingInvitation(invitation_token));
      fetchMyAPI()
  }, []);


  async function fetchMyAPI() {
    dispatch(logout());
    setLoading(true);
     try {
      const response = await getInviteDetails(invitation_token);
      if(response.status=== 200){
        setoverViewData(response.data);
      }
      setError("");
    } catch (err) {
        history.push('/link-expired');
    }
    setLoading(false);
  }
  

  React.useEffect(() => {
    
    setOverViewReqData({ ...overviewReqData , overViewData });

    // async function fetchState() {
    //   if(overViewData.address && overViewData.address[0]){
    //     getState(overViewData.address[0].country_id , false);
    //   }
    //   if(overViewData.address && overViewData.address.find((x)=>x.address_type == 'head_office').country_id){
    //     getState(overViewData.address.find((x)=>x.address_type == 'head_office').country_id , true);
    //   }
    // }
    // fetchState()
  }, [])

  const {
    admin_user,
    industries_id,
    accounting_email,
    referral_link,
    pre_live_url,
    territories_id,
    created_at,
    address,
    payment_method,
    payment_type,
    last_payment_amount,
    country_id,
    tier,
    commission_tier_id,
    program_id,
    password,
  } = overViewData;

  React.useEffect(() => {
    setOverViewReqData({
      name: '',
      industries: industries_id ? convertDataIntoDropDownForm( getDataAccordingToID(industryData, [...industries_id])) : '',
      phone: admin_user && admin_user.phone ? admin_user.phone : '',
      email: admin_user && admin_user.email ? admin_user.email : '',
      accounting_email: accounting_email,
      username: admin_user && admin_user.username ? admin_user.username : '',
      referral_link: referral_link,
      pre_live_url: pre_live_url,
      password:password,
      territories_id: territories_id && countryData ? convertDataIntoDropDownForm(
        getDataAccordingToID(countryData, [...territories_id])
      ): '',
      affiliateDate: moment(created_at).toDate(),
      operatioOffice: { 
        country: address &&  countryData ? convertDataIntoDropDownForm( getDataAccordingToID(countryData, [address && address.find((x)=>x.address_type == 'operations_office') && address.find((x)=>x.address_type == 'operations_office').country_id])) : '',
        zip: address && address[0].zip,
        city:address&& address[0].city,
        address_line1: address && address[0].address_line_1,
        address_line2: address && address[0].address_line_2,
        selectedState: address && address[0].state
      },
      headOffice: {
        country: address &&  countryData ? convertDataIntoDropDownForm( getDataAccordingToID(countryData, [address && address.find((x)=>x.address_type == 'head_office') && address.find((x)=>x.address_type == 'head_office').country_id])) : '',
        zip: address && address[1] ? address[1].zip : '',
        city: address && address[1] ? address[1].city : '',
        address_line1: address && address[1] ? address[1].address_line_1 : '',
        address_line2: address && address[1] ? address[1].address_line_2 : '',
        selectedState: address && address[1] ? address[1].state : ''
      },
      payment_method: payment_method && convertDataIntoDropDownForm(
        getDataAccordingToID(paymentMethodData, [payment_method])
      ),
      payment_type: payment_type && convertDataIntoDropDownForm(
        getDataAccordingToID(paymentfrq, [payment_type])
      ),
      last_payment_amount:last_payment_amount,
      commission_tier_id:commission_tier_id,
      program_id:program_id
    })

    if(overViewData.address && overViewData.address.find((x)=>x.address_type == 'operations_office')){
      getState(overViewData.address.find((x)=>x.address_type == 'operations_office').country_id , false);
    }

    if(overViewData.address && overViewData.address.find((x)=>x.address_type == 'head_office')){
      getState(overViewData.address.find((x)=>x.address_type == 'head_office').country_id , true);
    }
}, [overViewData , countryData]);

  const [overviewReqData, setOverViewReqData] = React.useState({
    name: admin_user && admin_user.first_name ? admin_user.first_name : '',
    industries: industries_id ? convertDataIntoDropDownForm( getDataAccordingToID(industryData, [...industries_id])) : '',
    phone: admin_user && admin_user.phone ? admin_user.phone : '',
    email: admin_user && admin_user.email ? admin_user.email : '',
    accounting_email: accounting_email,
    username: admin_user && admin_user.username ? admin_user.username : '',
    referral_link: referral_link,
    password:password,
    pre_live_url: pre_live_url,
    territories_id: territories_id && countryData ? convertDataIntoDropDownForm(
      getDataAccordingToID(countryData, [...territories_id])
    ): '',
    affiliateDate: moment(created_at).toDate(),
    operatioOffice: {
      country: address &&  countryData ? convertDataIntoDropDownForm( getDataAccordingToID(countryData, [address && address.find((x)=>x.address_type == 'operations_office') && address.find((x)=>x.address_type == 'operations_office').country_id])) : '',
      zip: address && address[0].zip,
      city:address&& address[0].city,
      address_line1: address && address[0].address_line_1,
      address_line2: address && address[0].address_line_2,
      selectedState: address && address[0].state
    },
    headOffice: {
      country: address &&  countryData ? convertDataIntoDropDownForm( getDataAccordingToID(countryData, [address && address.find((x)=>x.address_type == 'head_office').country_id])) : '',
      zip: address && address[1] ? address[1].zip : '',
      city: address && address[1] ? address[1].city : '',
      address_line1: address && address[1] ? address[1].address_line_1 : '',
      address_line2: address && address[1] ? address[1].address_line_2 : '',
      selectedState: address && address[1] ? address[1].state : ''
    },
   
    payment_method: payment_method && convertDataIntoDropDownForm(
      getDataAccordingToID(paymentMethodData, [payment_method])
    ),
    payment_type: payment_type && convertDataIntoDropDownForm(
      getDataAccordingToID(paymentfrq, [payment_type])
    ),
    last_payment_amount:last_payment_amount,
    commission_tier_id:commission_tier_id,
    program_id:program_id
  });

  const handleOverViewChangeData = (e) => {
    setOverViewReqData({ ...overviewReqData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const reqbody = prepareReqBodyForEditOverView(overviewReqData);
    var errors = {};
    var isError = false;
    if (reqbody) {
      if (reqbody.name == "") {
        errors['name'] = "The affiliate is required.";
        isError = true;
      }

      if(reqbody.company_name.trim() == ""){
        errors['company_name'] = "The company name is required.";
        isError = true;
      }

      if (reqbody.industries_id == "") {
        errors['industries_id'] = "Please select any of these industries.";
        isError = true;
      }

      if (reqbody.phone == "") {
        errors['phone'] = "Please enter phone number.";
        isError = true;
      }

      if (reqbody.phone.length < 11  ) {
        errors['phone'] = "Please enter valid phone number.";
        isError = true;
      }



      if (reqbody.username == "") {
        errors['username'] = "Please enter username.";
        isError = true;
      }

      if (reqbody.password == "") {
        errors['password'] = "Please enter the desired password.";
        isError = true;
      }else{
        var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if(reqbody.password.match(decimal) == null) { 
          errors['password'] = "Please include a minimum of 8 and maximum  of15 characters, one upper case, one lower case, one symbol, and one number.";
          isError = true;
        }
      }
      if (reqbody.address.head_office.country_id == "") {
        errors['address.head_office.country_id'] = "Please select head office country.";
        isError = true;
      }

      if (reqbody.address.head_office.city == "") {
        errors['address.head_office.city'] = "Please select head office city/town.";
        isError = true;
      }

      if (reqbody.address.head_office.address_line_1 == "") {
        errors['address.head_office.address_line_1'] = "Please enter head office street and no.";
        isError = true;
      }

      if (reqbody.address.head_office.state_id == "") {
        errors['address.head_office.state_id'] = "Please select head office state/province.";
        isError = true;
      }

      if (reqbody.address.head_office.zip == "") {
        errors['address.head_office.zip'] = "Please enter head office zip / postal code.";
        isError = true;
      }
    }
    if (isError) {
      setError(errors);
      document.getElementsByName(Object.keys(errors)[0])[0].focus();
      window.scrollTo(0, 0)
      return false
    }
    checkUser();
    // setbtnLoading(true)
    // try {
    //   const response = await updateInviteDetails(reqbody , invitation_token );
    //   if(response.status=== 200){
    //     setbtnLoading(false);
    //     history.push('/thankyou')
    //   }
    //   setError("");
    // } catch (err) {
    //     setbtnLoading(false)
    //     setError(err.errors);
    // }
  };

  const checkUser = async () => {
    try {
      setError("");
      setbtnLoading(true);
      var params = {
        username: overviewReqData.username,
        accounting_email:overviewReqData.accounting_email,
        phone:overviewReqData.phone
      }
      const response = await checkUserInfo(params, invitation_token);
      if(response.status=== 200){
        setbtnLoading(false);
        setIsAcceptTos(true);
      }
    } catch (err) {
        setbtnLoading(false)
        setError(err.errors);
    }
  }

  const handleIndustries = (val) => {
    setOverViewReqData({ ...overviewReqData, industries: val });
  };

  
  const handleTerritories = (val) => {
    setOverViewReqData({ ...overviewReqData, territories_id: val });
  };

  const handleChangeAddress = (e, isHeadOffice = false) => {
    if (!isHeadOffice) {
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleChangeCountryForAddress = (val, isHeadOffice = false) => {
    if (val && val.value) getState(val.value , isHeadOffice);
    if (!isHeadOffice) {
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          country: [val],
        },
      });
      
    } else {
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          country: [val],
        },
      });
    }
  };

  const handleChange = (field , val) =>{
    if(field === 'headOfficeStates'){
      setOverViewReqData({
        ...overviewReqData,
        headOffice: {
          ...overviewReqData.headOffice,
          selectedState:val
        },
      });
    }else if(field === 'operatioOffice'){
      setOverViewReqData({
        ...overviewReqData,
        operatioOffice: {
          ...overviewReqData.operatioOffice,
          selectedState:val
        },
      });
    }else if(field === 'phone'){
      var phn = `${val.split(" ")[0]}-${(val.replace(val.split(" ")[0],'')).replace(/[^\w]/gi, '')}`
      setOverViewReqData({
        ...overviewReqData,
        [field]: phn,
      });
    }
    else{
      setOverViewReqData({
        ...overviewReqData,
        [field]: val,
      });
    }
  }
  
  const copyAddress = async () =>{
    setCopyAddress(!iscopyAddress);
    setOverViewReqData({
      ...overviewReqData,
      operatioOffice: {
        ...overviewReqData.headOffice,
      },
    });
  }

  const handleEmailSending = async () => {
    try {
      const data = await sendLoginEmail(overviewReqData.email);
      alert(`Email Successfully sent to ${overviewReqData.email}`);
    } catch (err) {
      alert("Email Sending Failed");
    }
  };

  const getState = (countryId , isHeadOffice)  =>{
    HttpUtility.getRequest(HELPERURI + countryId + "/state").then(
      (response) => {
        if (response.status == 200) {
          var countryState = response.data
          if (!isHeadOffice) {
            setOperatioOfficeState(countryState)
          }else{
            setheadOfficeState(countryState)
          }
        }
      }
    );
  }

  
  const passwordToggle =() =>{
    setShowPassword(!isShowPassword)
  }

  const validateFile = (file)=> {
    var isError = false;
    var errors = ''

    const validTypes = ['image/jpeg', 'image/jpg', 'image/png' , 'application/pdf'];
    
    if (validTypes.indexOf(file.type) === -1) {
        isError = true;
        errors = {
            fileType: 'Format not accepted',
        }
        
    }else if(file.size > 10485760){
        isError = true;
        errors = {
            fileSize: 'Max 10Mb',
        }
    }
    if(isError){ 
        setState({errors});
        return;
    }else{
        return true;
    }
  }

  const onChangeFile = async (e) =>{
   
    if (validateFile(e.target.files[0])) {
      if (e.target.files && e.target.files.length > 0) {
        setState({
          bfile:e.target.files[0].name
        });
        const formData = new FormData();
        formData.append( "banking_detail_file", e.target.files[0]);
        try {
          const response = await uploadBankingDetailFile(formData , invitation_token );
          if(response.status=== 200){
            setOverViewReqData({
              ...overviewReqData,
              ['banking_detail_file']:response.data.file_name
            });
          }
          setError("");
        } catch (err) {
            setError(err.errors);
        }
        
      }
    }
  }

  const onAgree = async () => {
    const reqbody = prepareReqBodyForEditOverView(overviewReqData);
    reqbody.tos_status = 'agree';
    reqbody.need_send_email = sendCopyTos ? 1 : 0
    setbtnLoading(true);

    try {
      const response = await updateInviteDetails(reqbody , invitation_token );
      if(response.status=== 200){
        setbtnLoading(false);
        history.push('/thankyou')
      }
      setError("");
    } catch (err) {
        setbtnLoading(false)
        setError(err.errors);
    }
  }

  const onDecline = async () => {
    let params={
      tos_status: 'decline',
      need_send_email: sendCopyTos ? 1 : 0
    }
    try {
      const response = await updateInviteDetails(params , invitation_token );
      if(response.status=== 200){
        setShowContactModal(true)
      }
      setError("");
    } catch (err) {
        setbtnLoading(false)
        setError(err.errors);
    }
  }

  const togglePopovers = () =>{
    setPopover({
      isOpenReferralLinkpopover: !isOpenReferralLinkpopover
    });
  } 

  const onReferralLinkHover = () => {
    setReferralLinkPopover( true )
  }

  const onReferralLinkHoverLeave = () => {
    setReferralLinkPopover(false)
  }

  const toggleUrlPopovers = () =>{
    setPopover({
        isOpenpopover: !isOpenpopover
    });
  } 

  const onLiveUrlHover = () => {
    setPopover( true )
  }

  const onLiveUrlHoverLeave = () => {
    setPopover(false)
  }

  function get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onClickSendCopyTos = () => {
    setSendCopyTos(!sendCopyTos)
  }
  
  return (
    <div>
      {loading  ? (
          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
      <>
      <div className='invite_header p-5 text-center'>
        <img src={Logo}/>
      </div>
      {!isAcceptTos ?
        <>
        <form className='invite_form' autoComplete="on" onSubmit={handleSubmit}>
          <div className="card py-4">
            <div className="card-box px-5">
              <div className="card__body invitation_form  px-5">
                <h2>Affiliate Account Creation</h2>
                <p>Please fill out the form below to complete the account creation process.</p>
                <div className="card__title d-flex align-items-center py-4 mb-4">
                  <h3 className="m-0 mr-4">Business Details</h3>
                  {error.errors && Object.values(error.errors)[0] && <span className="text-danger">{Object.values(error.errors)[0]}</span>}
                </div>
                <Row>
                  <div className="col-lg-6 pr-lg-5">
                    <FormGroup row className="align-items-center">
                      <Label for="name" className="" sm={3}>
                        Affiliate Name
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          <InputField type="text"  className={isError && isError['name'] ? 'invalid-field' : ''} name="name" isError={isError && isError['name'] } onChange={handleOverViewChangeData} /*value={overviewReqData.name}*/ />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="name" className="" sm={3}>
                        Company name
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          <InputField type="text"  className={isError && isError['company_name'] ? 'invalid-field' : ''} name="company_name" isError={isError && isError['company_name'] } onChange={handleOverViewChangeData} /*value={overviewReqData.name}*/ />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="industries_id" className="" sm={3} name={'industries_id'}>
                        Industries
                      </Label>
                      <Col sm={9}>
                      <div className="form-group-with-error">
                      {isError && isError['industries_id'] && <span className="text-danger text-right">{isError['industries_id']}</span>}
                      <AppDropDown
                        className={`react-select-container ${isError && isError['industries_id'] ? 'select-border-danger' : ''}`}
                        classNamePrefix="react-select"
                        options={industryData && convertDataIntoDropDownForm(industryData)}
                        isMulti={true}
                        placeholder={"-"}
                        defaultValue={overviewReqData.industries}
                        onChange={(val) => handleIndustries(val)}
                        // ref={fieldRef}
                        // isFocused={fieldRef ? true : false}
                      />
                      </div>
                      </Col>
                    </FormGroup>

                    <FormGroup row className="align-items-center">
                      <Label for="phone" className="" sm={3}>
                        Phone
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                        {isError && isError['phone'] && <span className="text-danger text-right">{isError['phone']}</span>}
                          {/* <InputField type="text"  className={isError && isError['phone'] ? 'invalid-field' : ''} name="phone" isError={isError && isError['phone'] } onChange={handleOverViewChangeData} value={overviewReqData.phone}/> */}
                          <PhoneInput defaultMask={'-...........'}  country={'ca'} countryCodeEditable={false} alwaysDefaultMask={true}  inputProps={{ name: 'phone',required: true, }}  preferredCountries={['ca','us','gb','au','de']} onChange={(value, country, e, formattedValue) => { handleChange("phone" , formattedValue, country, e, value)}} /*value={overviewReqData.phone}*/ />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="email" className="" sm={3}>
                        Email
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          <InputField disabled type="email"  className={isError && isError['email'] ? 'invalid-field' : ''} name="email" isError={isError && isError['email'] } onChange={handleOverViewChangeData} value={overviewReqData.email}/>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="accounting_email" className="" sm={3}>
                        Accounting email
                        <div className='notes'>(if required)</div>
                      </Label>
                      
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          <InputField type="email"  className={isError && isError['accounting_email'] ? 'invalid-field' : ''} name="accounting_email" isError={isError && isError['accounting_email'] } onChange={handleOverViewChangeData} /*value={overviewReqData.accounting_email}*/ />
                        </div>
                      </Col>
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 pl-lg-5">
                    <FormGroup row className="align-items-center">
                      <Label for="username" className="" sm={3}>
                        Create your Username
                        <div className='notes'>(Used for Account Login)</div>
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          <InputField type="text"  className={isError && isError['username'] ? 'invalid-field' : ''} name="username" isError={isError && isError['username'] } onChange={handleOverViewChangeData} /*value={overviewReqData.username}*/ />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="password" className="" sm={3}>
                      Create your Password
                      </Label>
                      <Col sm={9}>
                      <div className="form-group-with-error">
                        {isError && isError['password'] && <span style={{width:'105%'}} className="text-danger text-right">{isError['password']}</span>}
                        <div className="input-group mb-3">
                          <Input type={!isShowPassword? 'password' : 'text'} className={`${isError && isError['password'] ? 'invalid-field' : ''} border-end-0`}  name="password" placeholder="-" value={overviewReqData.password} onChange={handleOverViewChangeData} aria-describedby="button-addon1"/>
                          {/* <InputField type={!isShowPassword? 'password' : 'text'} className={isError && isError['password'] ? 'invalid-field border-end-0' : ' border-end-0'} name="password" isError={isError && isError['password'] } onChange={handleOverViewChangeData} value={overviewReqData.password} aria-describedby="button-addon1"/> */}
                          <span className={`${isError && isError['password'] ? 'invalid-field' : ''} input-group-text`} id="basic-addon2">
                            <Button onClick={() => passwordToggle()} color='link' className='p-0' type="button" id="button-addon1">
                              <Icon
                                className="mx-3"
                                icon={!isShowPassword ? 'eye-blocked' : 'eye'}
                                color="#357BCF"
                                disableFill="true"
                                size={15}
                              />
                            </Button>
                          </span>
                        </div>
                      </div>
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row className="align-items-center">
                      <Label for="referral_link" className="" sm={3}>
                        Referral link
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error d-flex align-items-center">
                          <InputField type="text" disabled  className={isError && isError['referral_link'] ? 'invalid-field' : ''} name="referral_link" isError={isError && isError['referral_link'] } onChange={handleOverViewChangeData}  value={`${overviewReqData.referral_link}`} />
                          <Button color="link" className="btn btn-link p-0 px-3 addonBtn" onMouseEnter={onReferralLinkHover} onMouseLeave={onReferralLinkHoverLeave}  id="qreferral_link">
                              <Icon className="ml-1" icon="question-circle" size={15} />
                          </Button>
                          <Popover placement="right"  isOpen={isOpenReferralLinkpopover} target="qreferral_link" toggle={togglePopovers}>
                              <PopoverBody> 
                                    <h4>What is a referral link?</h4>
                                    <p>When a new customer wants to sign up, they register using your referral code, so that we can tie the sale to your affiliate account.</p>
                              </PopoverBody>
                          </Popover>
                        </div>
                      </Col>
                    </FormGroup> */}
                    {/* <FormGroup row className="align-items-center">
                      //value={overviewReqData.pre_live_url}
                      <Label for="pre_live_url" className="" sm={3}>
                        Web Pre-live Domain
                        <div className='notes'>(Can be added later)</div>
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error d-flex align-items-center">
                          <InputField type="text"  className={`${isError && isError['pre_live_url'] ? 'invalid-field' : ''} `}  placeholder="Available when Webstreams is added" name="pre_live_url" isError={isError && isError['pre_live_url'] } onChange={handleOverViewChangeData}   />
                          <Button color="link" className="btn btn-link p-0 px-3 addonBtn" onMouseEnter={onLiveUrlHover} onMouseLeave={onLiveUrlHoverLeave}  id="qpre_live_url">
                            <Icon className="ml-1" icon="question-circle" size={15} />
                          </Button>
                          <Popover placement="right"  isOpen={isOpenpopover} target="qpre_live_url" toggle={toggleUrlPopovers}>
                                  <PopoverBody> 
                                    <h4>What is a pre-live domain?</h4>
                                    <p>Your webstream pre-live domain is a staging area for your video player if needed. Sometimes a website is in the process of being built. This enables your developer to install the player and see what it looks like before the site is live. Once the website is made live then the real URL becomes active.</p>
                              </PopoverBody>
                          </Popover>
                        </div>
                      </Col>
                    </FormGroup> */}
                    <FormGroup row className="align-items-center">
                      <Label for="territories" className="" sm={3}>
                        Territories
                      </Label>
                      <Col sm={9}>
                        <div className="form-group-with-error">
                          {isError && isError['territories_id'] && <span className="text-danger text-right">{isError['territories_id']}</span>}
                          <AppDropDown
                            className={`react-select-container ${isError && isError['territories_id'] ? 'select-border-danger' : ''}`}
                            classNamePrefix="react-select"
                            options={countryData && convertDataIntoDropDownForm(countryData)}
                            placeholder={"-"}
                            isMulti={true}
                            value={overviewReqData.territories_id}
                            onChange={(val) => handleTerritories(val)}
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row className="align-items-center">
                      <Label for="tostype" className="" sm={3}>
                        Affiliate Start Date
                      </Label>
                      <Col sm={9}>
                        <DatePicker
                          className="custom-datepicker form-control mb-2"
                          selected={overviewReqData.affiliateDate}
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Click the icon to select a date"
                          disabled
                        />
                      </Col>
                    </FormGroup> */}
                  </div>
                  {/* <div className="col-lg-2 pr-lg-2">
                    <FormGroup row className="align-items-center">
                      <Label for="username" className="" sm={12}></Label>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Link
                        sm={12}
                        className="btn outline-primary py-2 px-5"
                        onClick={handleEmailSending}
                      >
                        Send a password reset email
                      </Link>
                    </FormGroup>
                  </div> */}
                </Row>
              </div>
            </div>
          </div>
          <div className="affilate-address-sec px-5 py-4">
            <div className="px-5 py-4">
              <Row>
                <div className='col-lg-12'>
                  <FormGroup row className="align-items-center">
                    <Label for="affilatename" className="" sm={4}>
                      Head Office address
                    </Label>
                  </FormGroup>
                </div>
                <div className="col-lg-6 pr-lg-5">
                  
                  <FormGroup row className="align-items-center">
                    <Label for="affilatename" className="" sm={3}>
                      Country
                    </Label>
                    <Col sm={9}>
                      <div className="country-select">
                        <div className="form-group-with-error">
                          {isError && isError['address.head_office.country_id'] && <span className="text-danger text-right">{isError['address.head_office.country_id']}</span>}
                          <AppDropDown
                            className={`react-select-container ${isError && isError['address.head_office.country_id'] ? 'select-border-danger' : ''}`}
                            classNamePrefix="react-select"
                            options={countryData && convertDataIntoDropDownForm(countryData)}
                            placeholder={"-"}
                            isMulti={false}
                            value={overviewReqData.headOffice && overviewReqData.headOffice.country}
                            onChange={(val) =>
                              handleChangeCountryForAddress(val, true)
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="city" className="" sm={3}>
                      City / Town
                    </Label>
                    <Col sm={9}>
                    <div className="form-group-with-error">
                      <InputField type="text"  className={isError && isError['address.head_office.city'] ? 'invalid-field' : ''}  name="city" isError={isError && isError['address.head_office.city'] } onChange={(e) => handleChangeAddress(e, true)} /*value={overviewReqData.headOffice.city}*/ />
                    </div>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="align-items-center">
                    <Label for="address_line1" className="" sm={3}>
                      Street and No.
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.head_office.address_line_1'] ? 'invalid-field' : ''}  name="address_line1" isError={isError && isError['address.head_office.address_line_1'] } onChange={(e) => handleChangeAddress(e, true)} /*value={overviewReqData.headOffice.address_line1}*/ />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="address_line2" className="" sm={3}>Apartment, Suite, Unit, Building, Floor</Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.head_office.address_line_2'] ? 'invalid-field' : ''}  name="address_line2" isError={isError && isError['address.head_office.address_line_2'] } onChange={(e) => handleChangeAddress(e, true)} /*value={overviewReqData.headOffice.address_line2}*/ />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="citytown" className="" sm={3}>
                      State / Province
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                          {isError && isError['address.head_office.state_id'] && <span className="text-danger text-right">{isError['address.head_office.state_id']}</span>}
                          <AppDropDown
                            className={`react-select-container ${isError && isError['address.head_office.state_id'] ? 'select-border-danger' : ''}`}
                            classNamePrefix="react-select"
                            options={headOfficeState}
                            getOptionLabel={(x) => `${x.name}`}
                            getOptionValue={(x) => `${x.name} ${x.id}`}
                            defaultValue={overviewReqData.headOffice.selectedState}
                            placeholder={"-"}
                            onChange={(val)=> handleChange('headOfficeStates', val) }
                          />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="zip" className="" sm={3}>
                      Zip / Postal code
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.head_office.zip'] ? 'invalid-field' : ''}  name="zip" isError={isError && isError['address.head_office.zip'] } onChange={(e) => handleChangeAddress(e, true)} /*value={overviewReqData.headOffice.zip}*/ />
                      </div>
                    </Col>
                  </FormGroup>
                </div>
                <div className="col-lg-6 pr-lg-5">
                  
                  {/* <FormGroup row className="align-items-center">
                    <Label for="affilatename" className="" sm={4}>
                      Operations Office address
                    </Label>
                    <Col sm={8} className="text-right">
                      <div className="custom-control custom-checkbox custom-control-inline ml-4">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="sam-address"
                          checked={iscopyAddress}
                          onChange={copyAddress} 
                        />
                        <Label className="custom-control-label" for="sam-address">
                          Same as Head Office
                        </Label>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="affilatename" className="" sm={3}>
                      Country
                    </Label>
                    <Col sm={9}>
                      <div className="country-select">
                        <div className="form-group-with-error">
                          {isError && isError['address.opration_office.country_id'] && <span className="text-danger text-right">{isError['address.opration_office.country_id']}</span>}
                          <AppDropDown
                            className={`react-select-container ${isError && isError['address.opration_office.country_id'] ? 'select-border-danger' : ''}`}
                            classNamePrefix="react-select"
                            options={countryData && convertDataIntoDropDownForm(countryData)}
                            placeholder={"-"}
                            isMulti={false}
                            value={overviewReqData.operatioOffice.country}
                            onChange={(val) =>
                              handleChangeCountryForAddress(val, false)
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="city" className="" sm={3}>
                      City / Town
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.opration_office.city'] ? 'invalid-field' : ''}  name="city" isError={isError && isError['address.opration_office.city'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.city}/>
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="align-items-center">
                    <Label for="address_line1" className="" sm={3}>
                      Street and No.
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.opration_office.address_line_1'] ? 'invalid-field' : ''}  name="address_line1" isError={isError && isError['address.opration_office.address_line_1'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.address_line1}/>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="address_line2" className="" sm={3}>Apartment, Suite, Unit, Building, Floor</Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.opration_office.address_line_2'] ? 'invalid-field' : ''}  name="address_line2" isError={isError && isError['address.opration_office.address_line_2'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.address_line2}/>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="citytown" className="" sm={3}>
                      State / Province
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        {isError && isError['address.opration_office.state_id'] && <span className="text-danger text-right">{isError['address.opration_office.state_id']}</span>}
                        <AppDropDown
                          className={`react-select-container ${isError && isError['address.opration_office.state_id'] ? 'select-border-danger' : ''}`}
                          classNamePrefix="react-select"
                          getOptionLabel={(x) => `${x.name}`}
                          getOptionValue={(x) => `${x.name} ${x.id}`}
                          options={operatioOfficeState}
                          value={overviewReqData.operatioOffice.selectedState}
                          onChange={(val)=> handleChange('operatioOffice', val) }
                          placeholder={""}
                          isDisabled={overviewReqData.operatioOffice.country && overviewReqData.operatioOffice.country.length > 0 ? false : true}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="align-items-center">
                    <Label for="zip" className="" sm={3}>
                      Zip / Postal code
                    </Label>
                    <Col sm={9}>
                      <div className="form-group-with-error">
                        <InputField type="text"  className={isError && isError['address.opration_office.zip'] ? 'invalid-field' : ''}  name="zip" isError={isError && isError['address.opration_office.zip'] } onChange={(e) => handleChangeAddress(e, false)} value={overviewReqData.operatioOffice.zip}/>
                      </div>
                    </Col>
                  </FormGroup> */}
                </div>
              </Row>
            </div>
          </div>
          {/* <div className="card py-4">
            <div className="card-box py-4 px-5">
              <div className="card__body py-4 px-5">
                <Row>
                  {tier && tier.map((item)=>{
                  return  <Col lg={4}>
                    <label className="mb-4">
                      <strong style={{textTransform:'capitalize'}}>{item.tier_type}</strong>
                    </label>
                    <div className="tier-header">
                      <h4>{item.title}</h4>
                    </div>
                    <div className="tier-desc">
                      <ul>
                        {item.value.map((value)=>
                          <li>
                            <p>{value.title}</p>
                            { value.include.map((fee)=>
                                <div>
                                  <h6>{fee.text}</h6>
                                  <span>{fee.charge_type === 'fixed' && '$'}{fee.charge}{fee.charge_type === 'percentage' && '%'}</span>
                                </div>
                              )}
                          </li>
                        )}
                      </ul>
                    </div>
                    </Col>
                  })}
                </Row>
              </div>
            </div>
          </div> */}
          {/* <div className="py-4">
            <div className="py-4 px-4">
              <div className="py-4 px-4">
                <div className="d-flex align-items-center mb-5">
                  <h3 className="m-0 mr-4">Commissions</h3>
                </div>
                <Row>
                  <Col lg="6">
                    <FormGroup row className="align-items-center">
                      <Label for="Payment method" className="" lg={4}>
                        Payment method
                      </Label>
                      <Col lg={8}>
                        <AppDropDown
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={paymentMethodData && convertDataIntoDropDownForm(paymentMethodData)}
                          placeholder={""}
                          disabled={true}
                          value={overviewReqData.payment_method}
                          onChange={(val)=> handleChange('payment_method', val, "dropdown") }
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup row className="align-items-start">
                      <Label  className="" lg={4}>
                        Banking details
                        <div className='notes'>(Optional)</div>
                      </Label>
                      <Col lg={8}>
                        <div className='cusome-file-upload form-control mb-4 d-flex align-items-center justify-content-center' style={{color:'#A9A9A9'}}>
                        <label htmlFor="uploadFiles">
                          <Icon 
                            className="mx-3"
                            icon={'cloud-upload'}
                            color="#9D9D9D"
                            disableFill="true"
                            size={30}
                          />
                        </label>
                          {fileStates.bfile == ''?
                          <> Drop files to attach, or <label className="btn-link m-0 ps-2 primary" htmlFor="uploadFiles">Browse</label>. </>
                          :
                          <>{fileStates.bfile}</>
                          }
                        <input  onClick={e => (e.target.value = null)} type="file" id="uploadFiles"  style={{position: 'absolute' , top: '0' , bottom: '0' ,right: '0' ,left: '0' ,opacity: '0', zIndex:'-1'}}   onChange={(e)=>{onChangeFile(e)}} />
                        </div>
                        <p className='note' style={{color:'#A9A9A9'}}>*Please attach a PDF with direct deposit info and/or wire transfer info. You can enter this information now or update it later.</p>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </div> */}
          <div className="mb-5 py-4 px-4">
            <Col lg="6" className="px-4">
              <LoadingButton type="submit" size="md" disabled={btnLoading} label="Save Changes" color="primary" className="mr-4" loading={btnLoading} outline />
            </Col>
          </div>
        </form>
        </>
      :
      <div className={`${'tos__main__screen d-flex flex-column w-100 fullHeight'}`}>
        <div className=" flex-grow-1 mt-5">
          <div className="tos__body px-0 "> 
              <PerfectScrollbar>
                  <div className="tos__inner">
                  {overViewData.tos_file_content_type === 'file' ?
                    get_url_extension(overViewData.tos_data) === 'pdf' ?
                      <Document
                          file={overViewData.tos_data}
                          onLoadSuccess={({ numPages })=>setNumPages(numPages)}
                          renderMode={'svg'}
                      >
                         {Array.apply(null, Array(numPages))
                      .map((x, i)=>i+1)
                      .map(page =>  <Page pageNumber={page} />)}
                      </Document>
                    :
                      <img className='mx-auto d-block' src={overViewData.tos_data} alt="" />
                    :
                    <div className="tos__content" dangerouslySetInnerHTML={{__html: decode(overViewData.tos_data)}}></div>
                    }
                  </div>
              </PerfectScrollbar>
          </div>
        </div>
        <div style={{position: 'relative'}} className="tos__footer justify-content-center d-flex my-5 align-items-center">
            <LoadingButton className="me-5 " type="button" size="sm" onClick={() => onAgree('agree')} color="primary" loading={btnLoading}>Agree</LoadingButton>
            <Button type="button" className='ms-5' size="sm" color="danger" outline onClick={() => onDecline('decline')} >Decline</Button>
            <div style={{position:'absolute',right:'15px'}} className='ms-5 me-5'>
              <div className="form-check">
                <Input type="radio" className="form-check-input"  name="flexRadioDefault1"  checked={sendCopyTos} onClick={() => { onClickSendCopyTos()}}  id="flexRadioDefault1"/>
                <Label className="form-check-label" for="flexRadioDefault1">
                  Send a copy to email
                </Label>
              </div>
            </div>
        </div>
      </div>
      }
      <div className='invite_footer p-5 border-top'>
        <div className="foot-legal text-center">
          <p className='mb-0'>Copyright © 2022 Thalamus Medical Media Inc. &nbsp;|&nbsp; <a target="_blank" rel="noreferrer" href="https://dental.thalamusmedia.com/legal">Legal</a></p>
        </div>
      </div>
      </>
      )}
      <Modal isOpen={showContactModal} toggle={() => setShowContactModal(false)} >
        <ModalBody className="text-center">
        <p><strong> If you decline the terms and confitions you will not be able<br/> to participate in the Thalamus Affiliate Program.
        </strong></p>
        <p>Please contact us if you have any concerns. </p>
        </ModalBody>
        <ModalFooter className="justify-content-between">
            <Button type="button" size="md" onClick={() => setShowContactModal(false)} color="primary" className={`mr-4`} outline >
                Back
            </Button>
            {/* onClick={() => history.push('/app/contact-us')} */}
            <Button type="button"  size="md"  color="danger" onClick={() =>{window.open("https://dental.thalamusmedia.com/contact-us", "_blank")}} outline>
              Contact Us
            </Button>
        </ModalFooter>
    </Modal>
    </div>
  );
};
export default Invitation;
