import activeWebstreamSlice from "./slice";

export const {
  loadingActiveWebStream,
  activeWebStreamFailure,
  activeWebStreamsSuccess,
  reset,
  setSelectedDomain,
  loadingSelectedDomain,
} = activeWebstreamSlice.actions;

export default activeWebstreamSlice.reducer;
