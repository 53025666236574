import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import { Filter, FilterInput } from "../../../components/Filter";
import ReactCountryFlag from "react-country-flag";
import { Button, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingInActiveWebStream } from "./Redux";
import { CheckPermission, months , WEBSTREAMS, years,} from '../../../data/comman';
import DefaultPlaceholder from '../../../components/DefaultPlaceholder'
import Loader from "../../../components/Loader";

const Authyear = [
  { value: "All", label: "All" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
];
const Authmonth = [
  { value: "All", label: "All" },
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
];

let practiceId;
const Inactivewebstream = (props) => {
  const [streamState, setStreamState] = React.useState({
    active: 0,
    isOpen: false,
    activeButtonTab: "1",
    ticketsData: props.tickets,
    isChecked: false,
    selectAll: false,
    ShowPutonhold: false,
  });

  const [searchedText, setSearchedText] = React.useState("");
  const [deactivation_year, setDeactivationYear] = React.useState('');
  const [deactivation_month, setDeactivationMonth] = React.useState('');
  const [authorization_year, setAuthorizationYear] = React.useState('');
  const [authorization_month, setAuthorizationMonth] = React.useState('');
  const [sortBy, setsortBy] = React.useState('authorization_at');
  const [orderBy, setorderBy] = React.useState('desc');
  const dispatch = useDispatch();

  const activeStreamData = useSelector((state) => state.inactiveWebStream.data);
  const loading = useSelector((state) => state.inactiveWebStream.loading);

  const { data } = activeStreamData;

  const toggleCollapse = () => {
    setStreamState({ ...streamState, isOpen: !streamState.isOpen });
  };

  React.useEffect(() => {
    dispatch(loadingInActiveWebStream({ pagenum: 1, searchedText }));
  }, []);

  React.useEffect(() => {
    if(authorization_year != '' || sortBy!= 'authorization_at' || orderBy!= 'desc' || authorization_month != '' || searchedText != '' || deactivation_month!= '' || deactivation_year != ''){
      dispatch(loadingInActiveWebStream({ pagenum: 1, searchedText , deactivation_year , deactivation_month , authorization_year , authorization_month , sortBy , orderBy}));
    }
  }, [deactivation_year , deactivation_month , searchedText , authorization_year , authorization_month, sortBy , orderBy]);

  const handleNext = () => {
    const nextUrl = activeStreamData.next_page_url;
    if (nextUrl !== null) {
      const pagenum = parseInt(nextUrl.split("=").pop());
      dispatch(loadingInActiveWebStream({ pagenum, searchedText }));
    }
  };

  const handlePrev = () => {
    const prevUrl = activeStreamData.prev_page_url;
    if (prevUrl !== null) {
      const pagenum = parseInt(prevUrl.split("=").pop());
      dispatch(loadingInActiveWebStream({ pagenum, searchedText }));
    }
  };
  const onSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  };

  const handleSearch = () => {
    dispatch(loadingInActiveWebStream({ pagenum: 1, searchedText }));
  };

  const onChangeYear = async (e) =>{
    var deactivation_year  = e.value;
    setDeactivationYear(deactivation_year)
  }

  const onChangeMonth = async (e) =>{
    var deactivation_month  = e.value;
    setDeactivationMonth(deactivation_month)
  }

  const onChangeAuthorizationMonth = async (e) =>{
    var authorization_month  = e.value;
    setAuthorizationMonth(authorization_month)
  }

  const onChangeAuthorizationYear = async (e) =>{
    var authorization_month  = e.value;
    setAuthorizationYear(authorization_month)
  }

  const onChangeorderBy = async (value) =>{
    if(orderBy == value){
      setorderBy('')
    }else{
      setorderBy(value)
    }
  }

  const onChangesortBy = async (value, order) =>{
    if(sortBy == value && orderBy == order){
      setsortBy(value)
      setorderBy('desc')
    }else{
      setsortBy(value)
      setorderBy('asc')
    }
}
  

  return (
    <>
      <Summarybar />
      {loading &&  (
          <Loader />
      )}
      <div className="card flex-fill">
        <div className="card-box">
          <div className="card__header card__header__filter lieacence-header flex">
            <div className="w-100 d-flex justify-content-between align-items-center card__header__filter--button">
              <div className="card__title d-flex align-items-center w-auto">
                <h3 className="m-0 mr-4">Webstreams</h3>
                {CheckPermission(WEBSTREAMS.id,'write') &&
                <Link
                  className="link-btn outline-primary mx-3 px-5"
                  to="/app/webstreams/domainAuthrization"
                >
                  <Icon
                    className="mr-4"
                    icon="Plus-square"
                    disableFill="true"
                    size={16}
                  ></Icon>
                  Add Domains
                </Link>
                }
              </div>

              <div className="ml-auto d-flex align-items-center w-auto flex-wrap">
                <div className="help__inner__search mb-0 webstream-search mx-4">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by keyword"
                      onChange={onSearchTextChange}
                    ></input>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={handleSearch}
                    >
                      <Icon icon="search-icon" color="#357BCF" size={18} />
                    </Button>
                  </div>
                </div>

                <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5 flex-wrap">
                  <p className="m-0">
                    <strong>
                      Showing {activeStreamData.from}-{activeStreamData.to} of{" "}
                      {activeStreamData.total}
                    </strong>
                  </p>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item  border-0">
                      <a className="disabled" href="#">
                        <Icon
                          icon="Left-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handlePrev}
                        />
                      </a>
                    </li>
                    <li className="list-group-item  border-0">
                      <a className="" href="#">
                        <Icon
                          icon="Right-arrow"
                          color="#357BCF"
                          disableFill="true"
                          size={16}
                          onClick={handleNext}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  className={`btn-filter btn  ml-auto collapsed`}
                  type="button"
                  onClick={toggleCollapse}
                >
                  <i className="mr-3">
                    <Icon
                      icon="Down-arrow"
                      color="#157CF7"
                      disableFill="true"
                      size={15}
                    />
                  </i>{" "}
                  Filter
                </button>
              </div>
            </div>
            <Filter isOpen={streamState.isOpen}>
              <FilterInput
                type="dropDown"
                label="Authorization year"
                options={years}
                placeholder="-"
                onChange={onChangeAuthorizationYear}
              />
              <FilterInput
                type="dropDown"
                label="Authorization month"
                options={months}
                onChange={onChangeAuthorizationMonth}
                placeholder="-"
              />
               <FilterInput
                type="dropDown"
                label="Deactivation year"
                options={years}
                placeholder="-"
                onChange={onChangeYear}
              />
              <FilterInput
                type="dropDown"
                label="Deactivation month"
                options={months}
                onChange={onChangeMonth}
                placeholder="-"
              />
            </Filter>
          </div>
        </div>
        <div className="sub-page__table">
          <div className="px-5">
            <table className="table mb-0">
              <thead className="sticky">
                <tr>
                  <th className="pl-0 w-50 sticky-column">
                    Authorized domains
                    <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'domain' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('domain', 'asc')}>
                        <Icon
                        icon="Down-arrow"
                        color="#157CF7"
                        disableFill="true"
                        size={15}
                        />
                    </i>{" "}
                  </th>
                  <th className="pl-0 sticky-column">
                    Practice name
                    <i className={`ms-3 ${orderBy === 'asc'  && sortBy === 'practice_name' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('practice_name', 'asc')}>
                        <Icon
                        icon="Down-arrow"
                        color="#157CF7"
                        disableFill="true"
                        size={15}
                        />
                    </i>{" "}
                  </th>
                  <th className="pl-0 sticky-column">
                    Authorization date
                    <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'authorization_at' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('authorization_at','asc')}>
                        <Icon
                        icon="Down-arrow"
                        color="#157CF7"
                        disableFill="true"
                        size={15}
                        />
                    </i>{" "}
                  </th>
                  <th className="pl-0">
                    Inactive since
                    <i className={`ms-3 ${orderBy === 'asc' && sortBy === 'deactivation_at' ? 'activearrow': ''} shortingArrow`} onClick={() => onChangesortBy('deactivation_at','asc')}>
                        <Icon
                        icon="Down-arrow"
                        color="#157CF7"
                        disableFill="true"
                        size={15}
                        />
                    </i>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(activeStreamData).length > 0 &&
                  data.length > 0 ?
                  data.map((item, index) => {
                    const {
                      practice_name,
                      domain,
                      authorization_at,
                      id,
                      deactivation_at,
                    } = item;

                    return (
                      <tr className="">
                        <td className="pl-0">
                          <Link
                            className="link-black"
                            to={`/app/webstreams/inactive/${id}`}
                          >
                            {domain}
                          </Link>
                        </td>
                        <td className="pl-0">{practice_name}</td>
                        <td className="pl-0">{authorization_at}</td>
                        <td className="pl-0 color-red">{deactivation_at}</td>
                      </tr>
                    );
                  })
                  :<tr style={{border:'none'}}><td colSpan={6}><DefaultPlaceholder text={'The domains that you de-authorize will show up here.'}/></td></tr>
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inactivewebstream;
