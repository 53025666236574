import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Summarybar from '../../components/Summarybar';
import { Button, Form, Col, FormGroup, Label, Row, CustomInput, Input ,Modal, ModalBody, ModalFooter } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContactUs from '../ContactUs'
import { Link, useHistory } from "react-router-dom";
import {updateTos} from '../../service/login'
import {updateUserProfileWatcher} from '../login/Redux/saga'
import { updateusertos } from "../login/Redux";
import Logo from '../../images//affilate-logo.svg'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';
import LoadingButton from '../../components/LoadingButton';

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var decode = require('decode-html');


const Tos = (props) => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login.data);
  const [isError, setError] = React.useState("");
  const [modalDecline, setDeclineModal] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [dataType, setDataType] = useState('');
  const history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAggreeLoading, setIsAggreeLoading] = useState(false);
  const [sendCopyTos, setSendCopyTos] = useState(false);


  const toggleDeclineModal = (state) => {
    setDeclineModal(state);
  }

    React.useEffect(() => {
        if(login.tos_status == 1){ 
            history.push('/');
        }
       var fileExtension = login.tos_data.split('.').pop()
        if(fileExtension === 'pdf'){
            setDataType('pdf')
        }else if(fileExtension === 'jpg' || fileExtension === 'png'){
            setDataType('img')
        }else{
            setDataType('pdf')
        }
    }, [])

    React.useEffect(() => {
        // if(login.tos_status == 1){
        //     history.push('/');
        // }
    }, [login])

  const onHandalSubmit = async (status) => {
      let params = {
        status:status,
        need_send_email:sendCopyTos ? 1 : 0 
      }
    try {
        if(status != 'decline'){
            setIsAggreeLoading(true);
        }
        const response = await updateTos(params);
        if(status != 'decline'){
            setIsAggreeLoading(false);
        }
        if(response.status=== 200){
            dispatch(updateusertos(response));
            setError("");
            if(response.data.tos_status == 1){
                history.push('/');
            }
        }
      } catch (err) {
            setError(err);
      }
  }
  const onDecline = async (status) => {
    toggleDeclineModal(true);
    onHandalSubmit(status)
  }

  const showContactUs = async (status) =>{
    setShowContact(true)
    setDeclineModal(false);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onClickSendCopyTos = () => {
    setSendCopyTos(!sendCopyTos)
  }

  function get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  return (
      
    <div className="d-flex flex-column min-vh-100 justify-content-center w-100">
         <div className='invite_header p-5 text-center'>
            <img src={Logo}/>
        </div>
        {/* <Summarybar /> */}
        {showContact ? 
        <div className="tos__contact__screen w-100">
            <ContactUs isFromTos={true} />
        </div>
        :
            <div className={`${'tos__main__screen d-flex flex-column w-100'} ${dataType === 'pdf' || dataType === 'img' ? 'fullHeight' : ''}`}>
                <div className=" flex-grow-1 mt-5">
                        <div className="tos__body px-0 "> 
                        <PerfectScrollbar>
                            <div className="tos__inner">
                            {login.tos_file_content_type === 'file' ?
                                get_url_extension(login.tos_data) === 'pdf' ?
                                    <Document
                                        file={login.tos_data}
                                        onLoadSuccess={({ numPages })=>setNumPages(numPages)}
                                        renderMode={'svg'}
                                    >
                                        {Array.apply(null, Array(numPages))
                                    .map((x, i)=>i+1)
                                    .map(page =>  <Page pageNumber={page} />)}
                                    </Document>
                                    :
                                        <img className='mx-auto d-block' src={login.tos_data} alt="" />
                                    :
                                    <div className="tos__content" dangerouslySetInnerHTML={{__html: decode(login.tos_data)}}></div>
                                }
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
                <div style={{position: 'relative'}} className="tos__footer justify-content-center d-flex my-5 align-items-center">
                    <LoadingButton type="button" className="me-5 " size="sm" onClick={() => onHandalSubmit('agree')} color="primary" loading={isAggreeLoading}>Agree</LoadingButton>
                    <Button type="button" className='ms-5' size="sm" color="danger" outline onClick={() => onDecline('decline')} >Decline</Button>
                    <div style={{position:'absolute',right:'15px'}} className='ms-5 me-5'>
                        <div class="form-check">
                            <Input type="radio" className="form-check-input"  name="flexRadioDefault1"  checked={sendCopyTos} onClick={() => { onClickSendCopyTos()}}  id="flexRadioDefault1"/>
                            <Label class="form-check-label" for="flexRadioDefault1">
                            Send a copy to email
                            </Label>
                        </div>
                    </div>
                </div>
            </div>
        }
        <Modal isOpen={modalDecline} toggle={() => toggleDeclineModal(false)} >
            <ModalBody className="text-center">
            <p><strong> If you decline the terms and confitions you will not be able<br/> to participate in the Thalamus Affiliate Program.
            </strong></p>
            <p>Please contact us if you have any concerns. </p>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button type="button" size="md" onClick={() => toggleDeclineModal(false)} color="primary" className={`mr-4`} outline >
                    Back
                </Button>
                {/* onClick={() => history.push('/app/contact-us')} */}
                <Button type="button"  size="md"  color="danger" onClick={() => showContactUs(true)}  className="" outline>
                    Contact Us
                </Button>
            </ModalFooter>
        </Modal>
        <div className='invite_footer p-5 border-top'>
            <div class="foot-legal text-center">
            <p className='mb-0'>Copyright © 2022 Thalamus Medical Media Inc. &nbsp;|&nbsp; <a target="_blank" rel="noreferrer" href="https://dental.thalamusmedia.com/legal">Legal</a></p>
            </div>
        </div>
    </div>
  );
}

export default Tos;