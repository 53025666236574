import React from 'react'
import { Link } from 'react-router-dom';
import Summarybar from '../../components/Summarybar';
import Icon from "../../icon";
import  { Filter, FilterInput} from '../../components/Filter';
import { Button, } from 'reactstrap';

const Paymentmonth = [
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
]

class Webstream extends React.Component{

    constructor(props) {
        super(props)
        
        this.state = {
            
          active: 0,
          isOpen:false,
          activeButtonTab:'1',
          ticketsData: this.props.tickets,
          isChecked: false,
          selectAll: false,
          ShowPutonhold:false
            
        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
    }
    
    toggleChange (event, ticket) {
      var ticketState = this.state.ticketsData;
      var ticketIndex = ticketState.indexOf(ticket);
      
      ticket.isChecked = event.target.checked;
      ticketState[ticketIndex] = ticket
      this.setState({ 
        ticketsData: ticketState
      });
      
      var selectedinvices = Object.keys(ticketState).filter(k => ticketState[k].isChecked);
      if(selectedinvices.length == Object.keys(ticketState).length){
        this.selectAll(null, true);
      }
      else{
        this.selectAll(null, false);
      }
      this.setState( {
          ShowPutonhold:false
      } )
      if(event.target.checked){
          this.setState( {
              ShowPutonhold:true
          } )
      }
  }
  toggleCollapse() {
      this.setState({ isOpen: !this.state.isOpen });
  }
  toggleButtons(tab) {
      if (this.state.activeButtonTab !== tab) {
          this.setState({
              activeButtonTab: tab,
          });
      }
  }

  render(){
    const { isOpen } = this.state;
    return(
        <div>
            <Summarybar />
            <div className="card">
              <div className="card-box">
              <div className="card__header card__header__filter lieacence-header">
                  <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                      <div className="card__title d-flex align-items-center w-auto">
                          <h3 className="m-0 mr-4">Webstreams</h3>
                          <Link className="link-btn outline-primary mx-3 px-5" to='/Activewebstream/DomainAuthrization'><Icon className="mr-4" icon="Plus-square" disableFill="true" size={16}></Icon>Add Domains</Link>
                      </div>
                      
                      <div className="ml-auto d-flex align-items-center w-auto">
                        <div className="help__inner__search mb-0 webstream-search mx-4">
                          <div className="search-box">
                            <input type="search" className="form-control" placeholder="Search by keyword"></input>
                            <Button color="link primary" className="p-0"><Icon icon="search-icon" color="#357BCF" size={18} /></Button>
                          </div>
                        </div>
                     
                          <div className="card__pagination align-items-center d-flex ml-auto  pr-xl-5">
                                <p className="m-0"><strong>Showing 1-50, of 120<span> of 450</span></strong></p>
                                    <ul className="list-group list-group-horizontal">
                                        <li className="list-group-item  border-0"><a className="disabled" href="#" ><Icon  icon="Left-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                        <li className="list-group-item  border-0"><a className="" href="#"><Icon icon="Right-arrow" color="#357BCF" disableFill="true" size={16} /></a></li>
                                    </ul>
                            </div>
                          <button className={`btn-filter btn  ml-auto collapsed`} type="button" onClick={this.toggleCollapse} >
                              <i className="mr-3"><Icon icon="Down-arrow" color="#157CF7" disableFill="true" size={15} /></i> Filter
                          </button>
                      </div>
                  </div>
                  <Filter isOpen={isOpen} >
                    <FilterInput type="text" id="leadname" label="Lead name"  placeholder=" " />
                    <FilterInput type="text" id="Practicename" label="Practice name" placeholder=" "/>
                    <FilterInput type="dropDown"  label="Due month" options={Paymentmonth} placeholder="-" />
                </Filter>
              </div>
              </div>
              <div className="p-5 w-100">
                  <table className="table mb-0">
                      <thead className="sticky">
                          <tr>
                              <th className="pl-0 w-50">Authorized domains</th>
                              <th className="pl-0">Practice name</th>
                              <th className="pl-0">Authorization date</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                          <tr className="">
                              <td className="pl-0">bellanodental.com</td>
                              <td className="pl-0">Bellano Dental</td>
                              <td className="pl-0">20 Aug 2020</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
        </div>
  );
}
}
export default Webstream;
