import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Summarybar from "../../components/Summarybar";
import Icon from "../../icon";

import {
  UncontrolledCollapse,
  Button,
  FormGroup,
  Input,
  Label,
  Collapse,
  CardHeader,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadingUserRights, setPermissions } from "./Redux";
import {
  createUser,
  deleteUser,
  editUserDetails,
  updateUserRights,
  userChangePassword
} from "../../service/userRights";
import DeleteModal from "./deleteModal";
import { GETMODULES } from "./Redux/saga";
import Loader from "../../components/Loader";
import { InputField } from "../../components/InputField";
import LoadingButton from '../../components/LoadingButton';
import './style.scss'

const NewProfileButton = ({ newprofile }) => {
  // const users = useSelector((state) => state.userRights.data);
  const [newUserModalShow, setNewUserModalShow] = React.useState(false);
  const history = useHistory();
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const [newUserData, setNewUserData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
  });
  const [subCreateModal, setSubCreateModal] = React.useState(false);
  const [finalCreateModal, setFinalCreateModal] = React.useState(false);

  const toggleNewUserModal = (state) => {
    setNewUserModalShow(state);
  };

  const handleChange = (e) => {
    setNewUserData({ ...newUserData, [e.target.name]: e.target.value });
    setError("")
  };
  const resetData = () => {
    setNewUserData({ ...newUserData, firstName: "", lastName: "", email: "" });
  };

  const onCreateProfile = async () =>{
    var errors = {};
    var isError = false;
    if (newUserData) {

      if (newUserData.firstName == "") {
        errors['first_name'] = "The first name field is required.";
        isError = true;
      }

      if (newUserData.lastName == "") {
        errors['last_name'] = "The last name field is required.";
        isError = true;
      }

      if (newUserData.email == "") {
        errors['email'] = "The email field is required.";
        isError = true;
      }

      if (newUserData.username.replace(/\s+/, "") == "") {
        errors['username'] = "The username field is required.";
        isError = true;
      }

    }
    if (isError) {
      setError(errors);
      return false
    }
      setSubCreateModal(true);
      setNewUserModalShow(false);
  }

  const [btnLoading, setbtnLoading] = React.useState(false);

  const handleSubmit = async () => {
    setbtnLoading(true)
    try {
      const data = await createUser(
        newUserData.firstName,
        newUserData.lastName,
        newUserData.email,
        newUserData.username
      );
      if(data.status=== 200){
        setbtnLoading(false);
      }
      setSubCreateModal(false);
      setFinalCreateModal(true);
      
    } catch (err) {
      setError(err.errors);
      setbtnLoading(false)
      setSubCreateModal(false);
      setNewUserModalShow(true);
    }
  };

  return (
    <div
      className="access-info text-center right-gray-border"
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="my-5"
        style={{
          // width: "fit-content",
          // border: "1px solid #357BCF",
          // borderRadius: "5px",
          // padding: "20px",
        }}
      >
        <div className="btn-group-outline-primary mb-4">
          <Icon
            icon="user-icon"
            color="#357BCF"
            disableFill="true"
            size={"10.3rem"}
          />
        </div>
        <Link type="button" className="d-flex align-items-center justify-content-center" onClick={() => toggleNewUserModal(true)}>
        <Icon
            icon="Plus-square"
            color="#357BCF"
            disableFill="true"
            size={"1.5rem"}
          /><div className="ms-2">New Profile</div>
        </Link>
      </div>
      <Modal isOpen={subCreateModal} toggle={() => setSubCreateModal(false)}>
        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
          <div style={{ marginBottom: "5px" }}>
            {newUserData.firstName + " " + newUserData.lastName} will be
            created.
          </div>
          <br />
          <p>
            A temporary password will be generated and sent to the provided
            email. The user will be required to update their password when
            signing in for the first time.
          </p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <LoadingButton type="button" size="md" disabled={btnLoading} label="Create User" color="primary" onClick={handleSubmit} className="mr-4" loading={btnLoading} outline />
          <button
            type="button"
            onClick={() => setSubCreateModal(false)}
            className="btn btn-md outline-danger bg-white"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={finalCreateModal}
        toggle={() => setFinalCreateModal(false)}
      >
        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
          <div style={{ marginBottom: "5px" }}>
            "{newUserData.firstName + " " + newUserData.lastName}" has been
            created.
          </div>
          <br />
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            type="button"
            onClick={() => {
              setFinalCreateModal(false);
              resetData();
              dispatch(loadingUserRights());
            }}
            className="btn btn-md outline-primary bg-white"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={newUserModalShow} toggle={() => toggleNewUserModal(false)}>
        <ModalBody
          className="text-center d-flex justify-content-evenly"
          style={{ flexDirection: "column", width: "100%" }}
        >
          {/* {error && <span className="text-danger">{error}</span>} */}
          <div className="user-profile">
            <div style={{ marginBottom: "20px" }}>
              <Icon
                icon="user-icon"
                color="#343434"
                disableFill="true"
                size={"10rem"}
              />
            </div>
            <h4>New Profile</h4>
          </div>
          <div>
            <FormGroup className="px-4 text-left">
              <InputField type="text" label="Name" className={error && error['first_name'] ? 'invalid-field mb-3' : 'mb-4'}  name="firstName" isError={error && error['first_name']}   onChange={handleChange}  value={newUserData.firstName} />
              <InputField type="text" label="" className={error && error['last_name'] ? 'invalid-field  ' : ' '}  name="lastName" isError={error && error['last_name']}   onChange={handleChange}  value={newUserData.lastName} />
            </FormGroup>
            <FormGroup className=" px-4 text-left">
              <InputField type="email" label="Email" className={error && error['email'] ? 'invalid-field  ' : ' '}  name="email" isError={error && error['email']}   onChange={handleChange}  value={newUserData.email} />
            </FormGroup>
            <FormGroup className=" px-4 text-left">
              <InputField type="text" label="Username" className={error && error['username'] ? 'invalid-field ' : ' '}  name="username" isError={error && error['username']}   onChange={handleChange} value={newUserData.username}/>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            type="button"
            onClick={() => {
              onCreateProfile()
              // setSubCreateModal(true);
              // setNewUserModalShow(false);
            }}
            className="btn btn-md outline-primary bg-white mr-4"
          >
            Create Profile
          </button>
          <button
            type="button"
            onClick={() => toggleNewUserModal(false)}
            className="btn btn-md outline-danger bg-white"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

// PROFILE SECTION
const ProfileSection = ({ user }) => {

  const [profileData, setProfileData] = React.useState({
    fname: user.first_name,
    lname: user.last_name,
    email: user.email,
    username: user.username,
  });

  const [error, setError] = React.useState("");
  const [resetSuccessMessage, setResetSuccessMessage] = React.useState("");
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [afterDeleteModal, setAfterDeleteModal] = React.useState(false);
  const [resetEmailModal, setResetEmailModal] = React.useState(false);
  const [resetEmailSuccessModal, setResetEmailSuccessModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [btnSaveState, setBtnSaveState] = React.useState({
    color:'primary',
    message: 'Save Changes',
  });
  const toggleUserModal = (state) => {
    setOpenUserModal(state);
  };

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setError('')
  };
  const dispatch = useDispatch();

  const handleEditChange = async () => {
    setIsLoading(true)
    try {
      const data = await editUserDetails(
        user.id,
        profileData.fname,
        profileData.lname,
        profileData.email,
        profileData.username
      );
      if(data.status === 200){
        setIsLoading(false);
        setError("");
        setBtnSaveState({
          message:'Changes Saved',
          color:'green bg-green'
        })
        setTimeout(() => {
          toggleUserModal(false);
          setBtnSaveState({
            message:'Save Changes',
            color:'primary'
          })
        }, 1500);
        // dispatch(loadingUserRights());
      }
    } catch (err) {
      setIsLoading(false)
      setError(err.errors);
    }
  };

  const afterDelete = async () => {
    setAfterDeleteModal(false);
    dispatch(loadingUserRights());
  };

  const onClickResetEmail = () =>{
    toggleUserModal(false)
    setResetEmailModal(true)
  }

  const handleResetEmail = async () =>{
    setIsLoading(true)
    try {
      const data = await userChangePassword(user.id);
      if(data.status === 200){
        setIsLoading(false)
        setResetEmailModal(false)
        setResetSuccessMessage(data.message);
        setResetEmailSuccessModal(true);
      }
    } catch (err) {
      setError(err.errors);
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      const data = await deleteUser(user.id);
      setOpenUserModal(false);
      setDeleteModal(false);
      setAfterDeleteModal(true);
    } catch (err) {
      setError(Object.values(err.errors));
    }
  };

  return (
    <div
      className="access-info text-center right-gray-border"
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="px-5 py-5">
        <div className="px-5 ">
          <div className="user-profile">
            <Link
              type="button"
              className="mb-3"
              onClick={() => toggleUserModal(true)}
            >
              View Profile
            </Link>
            <div>
              <Icon
                icon="user-icon"
                color="#357BCF"
                disableFill="true"
                size={"10.3rem"}
              />
            </div>
            <Label className="mt-4">
              <strong>{profileData.fname + " " + profileData.lname}</strong>
            </Label>
          </div>
        </div>
      </div>
      <Modal isOpen={openUserModal} toggle={() => toggleUserModal(false)}>
        <ModalBody className="text-center">
          <div className="user-profile">
            <div style={{ position: "relative" }}>
              <Icon
                icon="trash-o"
                color="#BA4640"
                size={18}
                style={{
                  float: "right",
                  maxWidth:'16px',
                  position: "absolute",
                  top: 2,
                  right: 5,
                  cursor: "pointer",
                  display:'block'
                }}
                onClick={() => setDeleteModal(true)}
              />
              <Icon
                icon="user-icon1"
                color="#343434"
                disableFill="true"
                size={"10rem"}
              />
            </div>
            <Label className="mt-4">
              <strong>{profileData.fname + " " + profileData.lname}</strong>
            </Label>
          </div>
          <FormGroup className=" px-4 text-left">
            <InputField type="text" label="Name" className={error && error['first_name'] ? 'invalid-field  mb-4' : 'mb-4 '}  name="fname" isError={error && error['first_name']}   onChange={handleChange}  value={profileData.fname} />
            <InputField type="text" label="" className={error && error['last_name'] ? 'invalid-field  ' : ' '}  name="lname" isError={error && error['last_name']}   onChange={handleChange}  value={profileData.lname} />
          </FormGroup>
          <FormGroup className=" px-4 text-left">
            <InputField type="email" label="Email" className={error && error['email'] ? 'invalid-field  ' : ' '}  name="email" isError={error && error['email']}   onChange={handleChange}  value={profileData.email} />
          </FormGroup>
          <FormGroup className="px-4 text-left">
            <Label className="text-left">
              <strong>Username</strong>
            </Label>
            <Input type="text"  name="username" value={profileData.username} disabled={true} />
          </FormGroup>
          <FormGroup className="px-4 mb-0">
            <LoadingButton className="w-100" type="button"  disabled={false} label={'Send Password Reset Email'} color={'primary'} onClick={() => onClickResetEmail()} loading={false} outline />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <FormGroup className=" px-4 text-left w-100 d-flex justify-content-between">
              <LoadingButton className="mr-4 col" type="button" disabled={isLoading} label={btnSaveState.message} color={`${btnSaveState.color}`} onClick={() => handleEditChange()} loading={isLoading} outline />
              <button type="button" onClick={() => toggleUserModal(false)} className="btn col outline-danger bg-white" >
                Close
              </button>
          </FormGroup>
        </ModalFooter>
      </Modal>
      <Modal isOpen={afterDeleteModal} toggle={() => setAfterDeleteModal(false)}  >
        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
          {profileData.fname} has been removed , and its session will be
          terminated immediately
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button type="button" onClick={afterDelete} className="btn btn-md outline-primary bg-white" >
            Close
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={resetEmailModal} toggle={() => setResetEmailModal(false)}  >
        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
        <FormGroup className="px-4 mb-0">
          <p className="mb-1"><strong>An email containing a link to reset this user’s password will be sent to their registered email.</strong></p>
          <p>Would you like to proceed?</p>
        </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <FormGroup className=" px-4 text-left w-100 d-flex justify-content-between">
              <LoadingButton className="mr-4 col" type="button" disabled={isLoading} label={'Send Email'} color={`${btnSaveState.color}`} onClick={() => handleResetEmail()} loading={isLoading} outline />
              <button type="button" onClick={() => setResetEmailModal(false)} className="btn col outline-danger bg-white" >
                Cancel
              </button>
          </FormGroup>
        </ModalFooter>
      </Modal>
      <Modal isOpen={resetEmailSuccessModal} toggle={() => setResetEmailSuccessModal(false)}  >
        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
        <FormGroup className="px-4 mb-0">
          <p className="mb-1"><strong>{resetSuccessMessage}</strong></p>
        </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <button type="button" onClick={() => setResetEmailSuccessModal(false)} className="btn btn-md  outline-danger bg-white" >
              Close
            </button>
        </ModalFooter>
      </Modal>
      <DeleteModal
        isOpen={deleteModal}
        toggleFunction={() => setDeleteModal(false)}
        handleRemove={handleDelete}
        username={profileData.fname}
      />
    </div>
  );
};

//MAIN USER RUGHT PAGE
const UserRights = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userRights.data);
  const loading = useSelector((state) => state.userRights.loading);
  const userPermissions = useSelector((state) => state.userRights.permissions);
  const [oldUserPermissions, setOldUserPermissions] = React.useState([]);
  const [uniPermissions, setUniPermissions] = React.useState([]);
  const [isbtnLoading, setbtnLoading] = React.useState(false);
  const [btnSaveState, setBtnSaveState] = React.useState({
    color:'primary',
    message: 'Save',
  });
  const [collaps, setCollaps] = React.useState([]);

  React.useEffect(() => {
    dispatch(loadingUserRights());
    dispatch(GETMODULES());
  }, []);

  React.useEffect(() => {
    setUniPermissions(userPermissions);
    setOldUserPermissions(userPermissions);
  }, [userPermissions]);

  const handleProfileSection = () => {
    return [0, 1].map((ad, index) => {
      if (users.length > ad) {
        return <ProfileSection user={users[ad]} />;
      } else {
        return <NewProfileButton />;
      }
    });
  };

  const handleEditClick = async () => {
    setbtnLoading(true)
    let permissions = [];
    let oldpermissions = [];

    uniPermissions.map((item, index) => {
      item.data.map((subitem, userIndex) => {
        const { userId, moduleId, isRead, isWrite } = subitem;
        permissions.push({
          user_id: userId,
          module_id: moduleId,
          is_read: isRead,
          is_write: isWrite,
        });
      });
      item.data.map((childPermssions, childIndex) =>{
         item.data[childIndex].children.map((per)=>{
          const { userId, moduleId, isRead, isWrite } = per;
          permissions.push({
                user_id: userId,
                module_id: moduleId,
                is_read: isRead,
                is_write: isWrite,
              });
         })
      })
    });

    oldUserPermissions.map((item, index) => {
      item.data.map((subitem) => {
        const { userId, moduleId, isRead, isWrite } = subitem;
        oldpermissions.push({
          user_id: userId,
          module_id: moduleId,
          is_read: isRead,
          is_write: isWrite,
        });
      });
      item.data.map((childPermssions, childIndex) =>{
        item.data[childIndex].children.map((per)=>{
         const { userId, moduleId, isRead, isWrite } = per;
         oldpermissions.push({
               user_id: userId,
               module_id: moduleId,
               is_read: isRead,
               is_write: isWrite,
             });
        })
     })
    });

    const uniqueElements = permissions.filter((item, i) => {
      let olditem = oldpermissions[i];
      if (JSON.stringify(item) !== JSON.stringify(olditem)) return item;
    });

    const reqbody = { permissions: uniqueElements };

    try {
      const data = await updateUserRights(reqbody);

      if(data.status === 200){
        setbtnLoading(false)
        
        setBtnSaveState({
          message:'Saved',
          color:'green bg-green'
        })
      }
    } catch (err) {
      setbtnLoading(false)
    }

    setTimeout(() => {
      setBtnSaveState({
        message:'Save',
        color:'primary'
      })
    }, 1000);
  };

  const handleEdit = (e, data, permissionIndex, userIndex, childPermissions, childIndex) => {

    const newUniPermissions = JSON.parse(JSON.stringify(uniPermissions));
    const propertyName = e.target.name;
    const propertyValue = e.target.checked ? 1 : 0;
    
    if(childPermissions){
      if(propertyName === 'isWrite' &&  propertyValue === 1){
        newUniPermissions[userIndex].data[permissionIndex].children[childIndex]['isRead'] = 1  
        newUniPermissions[userIndex].data[permissionIndex]['isRead'] = 1
      }
      newUniPermissions[userIndex].data[permissionIndex].children[childIndex][propertyName] = propertyValue  
      newUniPermissions[userIndex].data[permissionIndex][propertyName] = 1

    } else{
      if(propertyName === 'isWrite' && propertyValue === 1){
        newUniPermissions[userIndex].data[permissionIndex]['isRead'] = 1
      }
      newUniPermissions[userIndex].data[permissionIndex][propertyName] = propertyValue
    }
    setUniPermissions(newUniPermissions);
  };

  const toggleCollaps = (index) => {
    let collapseCopy = [...collaps];
    collapseCopy[index] = !collapseCopy[index];
    setCollaps(collapseCopy);
  }

  const HandlePermissionSection = () => {
    if (users.length > 0) {
      return uniPermissions.map((item , index) => {
        return (
          <li key={index}>
              <div className="user-acces-info right-gray-border">
                <div className="px-4 py-4 user-rights-label">
                  <div onClick={() => toggleCollaps(index)}>
                    <span className="modulename"> <strong>{item.name}</strong></span>
                    {item.data[0].children.length > 0 &&
                      <Icon className={`${collaps[index] ? 'activeindex' : ''} user-access-arrow`} 
                        icon="Down-arrow"
                        color="#357BCF"
                        disableFill="true"
                        size={16}
                      ></Icon>
                    }
                  </div>
                </div>
              </div>
           
            {item.data.map((permissionData, i) => {
              return (
                <>
                <div key={i} className="access-info text-center right-gray-border">
                  <div className="px-3 py-3">
                    <div className="custom-control custom-checkbox custom-control-inline mr-4">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="isRead"
                        id={`isRead_parent_${index}_${i}`}
                        onChange={(e) => handleEdit(e, permissionData, i, index)}
                        defaultChecked={permissionData.isRead ? true : false}
                      />
                      <Label htmlFor={`isRead_parent_${index}_${i}`} className="custom-control-label">Read</Label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="isWrite"
                        id={`isWrite_parent_${index}_${i}`}
                        onChange={(e) => handleEdit(e, permissionData, i, index)}
                        defaultChecked={permissionData.isWrite ? true : false}
                      />
                      <Label htmlFor={`isWrite_parent_${index}_${i}`} className="custom-control-label">Write</Label>
                    </div>
                  </div>
                  <Collapse isOpen={ collaps[index] } className="" >
                  {permissionData.children.map((childPermissions, childIndex)=>
                    <>
                    <span className="childLabel px-3 py-3 ">{childPermissions.moduleName}</span>
                    <div className="px-3 py-3 " key={index}>
                      <div className="custom-control custom-checkbox custom-control-inline mr-4">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="isRead"
                          id={`isRead${childIndex}_${index}_${i}`}
                          onChange={(e) => handleEdit(e, permissionData, i, index, childPermissions, childIndex)}
                          defaultChecked={childPermissions.isRead ? true : false}
                        />
                        <Label htmlFor={`isRead${childIndex}_${index}_${i}`} className="custom-control-label">Read</Label>
                      </div>
                      <div className="custom-control custom-checkbox custom-control-inline">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="isWrite"
                          id={`isWrite${childIndex}_${index}_${i}`}
                          onChange={(e) => handleEdit(e, permissionData, i, index, childPermissions, childIndex)}
                          defaultChecked={childPermissions.isWrite ? true : false}
                        />
                        <Label htmlFor={`isWrite${childIndex}_${index}_${i}`} className="custom-control-label">Write</Label>
                      </div>
                    </div>
                    </>
                  )}
                  </Collapse>
                </div>
                </>
              );
            })}
          </li>
        );
      });
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      <Summarybar />
      {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
      <ul className="user-right-list">
          <li>
            <div className="user-acces-info right-gray-border">
              <div className="px-5 py-5">
                <div className="px-5 ">
                  <h3 className="mb-5">Users & Access Rights</h3>
                  <p>
                    <strong>
                      Each affiliate account can add up to two extra profiles.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            {handleProfileSection()}
          </li>
          {loading ? <></> : <HandlePermissionSection />}
      </ul>
      )}
      
      {!loading && users.length > 0 && (
        <div className="d-flex justify-content-center align-items-center" style={{ margin: "20px" }} >
          <LoadingButton type="button" size="md" disabled={isbtnLoading} label={btnSaveState.message} color={`${btnSaveState.color}`} onClick={() => handleEditClick()} loading={isbtnLoading} outline />
        </div>
      )}
    </div>
  );
};

export default UserRights;