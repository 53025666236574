import React,{ Component }  from 'react';
import Select, { components } from "react-select";
import { List } from 'react-virtualized';
import countryList from 'react-select-country-list';
import ReactCountryFlag from "react-country-flag";
import SearchIcon from '../images/search-icon.svg';
import HttpUtility, { COUNTRYURI } from '../utilities/HttpUtility';

import Icon from "../icon";
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={SearchIcon} alt="search" />
        </components.DropdownIndicator>
    );
};

const customOpetionLabel = ({name, code  }) => {
   return ( <div className="custome__opetion d-flex ">
        <div className="flag__img">
        <ReactCountryFlag
                countryCode={code}
                aria-label={name}
                svg
                style={{
                    width: '3.5rem',
                    height: '2rem',
                    margin: '0 0px 0 0px',
                    borderRadius: '2px'
                }}
            />
        </div>
        <div className="select__label">{ name }</div>
    </div>)
};

const CountryList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
      rows[index] ? <div key={key} style={style}>{rows[index]}</div> : <div />
    );
  
    return (
      <List
        style={{ width: '100%', flexGrow: true }}
        width={props.width}
        height={300}
        rowHeight={45}
        rowCount={rows.length ? rows.length : 0}
        rowRenderer={rowRenderer}
      />
    )
  }


  export function CountryFlag(props) {
    return (
      <span
        className={"flag-icon flag-icon-" + props.code}
        style={{ fontSize: props.size || "60px" }}
      />
    );
  }
  
  export const CountryFlagSelectOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
        <ReactCountryFlag
                countryCode={props.value.toLowerCase()}
                aria-label={props.label}
                svg
                style={{
                    width: '3.5rem',
                    height: '2rem',
                    margin: '0 0px 0 0px',
                    borderRadius: '2px'
                }}
            />
          {props.label}
        </div>
      </components.Option>
    );
  };
  
  export const CountryFlagValueContainer = ({ children, ...props }) => {
    const code = (props.hasValue && props.getValue()[0].value) || false;
  
    return (
      <div style={{ display: "flex", flexGrow: 1 }}>
        {(code && <CountryFlag code={code.toLowerCase()} />) || null}
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </div>
    );
  };
  
  const styles = {
    valueContainer: (base, state) => {
      const height = "60px";
      return { ...base, height };
    }
  };
  
  export default class CountrySelector extends Component {
    constructor(props) {
      super(props);
      this.state = {
        options: countryList().getData(),
        value: null
      };
    }
  
    changeHandler = value => {
      this.setState({ value });
    };
  
    render() {
      return (
        <Select
          styles={styles}
          className='react-select-country-container'
          classNamePrefix="react-select"
          options={this.state.options}
          value={this.state.value}
          onChange={this.changeHandler}
          components={{
            Option: CountryFlagSelectOption,
            ValueContainer: CountryFlagValueContainer
          }}
        />
      );
    }
  }
