import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  getActiveDomains,
  getOneDomainDetails,
  getActiveDomainsBySearch,
} from "../../../../service/webstream";
import {
  loadingActiveWebStream,
  activeWebStreamFailure,
  activeWebStreamsSuccess,
  reset,
  setSelectedDomain,
  loadingSelectedDomain,
} from "./index";

export function* activeWebStreamWatcher(params) {
  try {
    const { pagenum, searchedText, authorization_year, authorization_month,sortBy ,orderBy} = params.payload;
    const data = yield call(getActiveDomains, pagenum, searchedText ,authorization_year ,authorization_month, sortBy, orderBy);
    yield put(activeWebStreamsSuccess(data));
    yield put(reset());
  } catch (err) {
    yield put(activeWebStreamFailure(err));
  }
}

export function* selectedDomain(param) {
  try {
    const data = yield call(getOneDomainDetails, param.payload);
    yield put(setSelectedDomain(data));
    yield put(reset());
  } catch (err) {
    yield put(activeWebStreamFailure(err));
  }
}

export default function* activeWebStreamSaga() {
  yield all([
    takeLatest(loadingActiveWebStream.type, activeWebStreamWatcher),
    takeLatest(loadingSelectedDomain.type, selectedDomain),
  ]);
}
