import React from 'react';
import CreatableSelect from 'react-select/creatable';


const validateEmail = (email) => {
    var emilValidate = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)
    if (!emilValidate.test(email))
        return true; //not valid
    else
        return false;
}

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
  });

class AppDropDownEmail extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            value: props.value || [],
            emailError: null
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onBlureHandle = this.onBlureHandle.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.value != this.props.value){
            this.setState({ value: this.props.value });
        }
      }

    handleInputChange( inputValue ) {
        this.setState({ inputValue, emailError: null });
    }

    onBlureHandle(event){
        const { inputValue, value } = this.state;
        if(!validateEmail(inputValue)){
            this.setState({
            inputValue: '',
            value: [...value, createOption(inputValue)],
            }, () => {
                if(this.props.onChange)
                    this.props.onChange(this.state.value);
            });
        } else {
            this.setState({ emailError: `Please enter valid email`})
        }
        event.preventDefault();
    }

    handleKeyDown(event) {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            if(!validateEmail(inputValue)){
                this.setState({
                inputValue: '',
                value: [...value, createOption(inputValue)],
                }, () => {
                    if(this.props.onChange)
                        this.props.onChange(this.state.value);
                });
            } else {
                this.setState({ emailError: `Please enter valid email`})
            }
            event.preventDefault();
        }
    }

    handleChange( value, actionMeta) {
       this.setState({ value }, () =>{
            if(this.props.onChange && (value == null || value.length == 0)){
                this.props.onChange([]);
                this.setState({ value: [] });
            }else{
                if(this.props.onChange)
                    this.props.onChange(value);
            }
       });
    };
        
    render(){
        const { inputValue, value } = this.state;
        return(<>
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                className='react-select-container w-100'
                classNamePrefix="react-select"
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={this.props.placeholder || "-"}
                value={value}
                onBlur={this.onBlureHandle}
            />
            {this.state.emailError && <span className="text-danger">{this.state.emailError}</span>}
        </>);
    }
}

export default AppDropDownEmail;
