import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../../icon";

function Dashboard(props) {
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
    const { setDefaultId } = props;
  return (
    <div className="help__inner__questions">
        <div className="d-flex align-items-center mb-5 pb-4 justify-content-between">
        <Button className="px-2 py-1 mr-auto" color="primary" onClick={()=>{ setDefaultId() }} >
        <Icon
            icon="back"
            disableFill="true"
            size={14}
            color="#fff"
        ></Icon>
        </Button>
        <h4 className="m-0 d-flex align-items-center"><Icon icon="help-dashboard" className="me-4" disableFill="true" size={24} color="#343434" ></Icon>Dashboard</h4>
        <div></div>
        </div>
    <div className="question-row">
        <Card>
        <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
            <h5>What can I find in the Dashboard page?</h5>
            <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
        </CardHeader>
        <Collapse isOpen={toggleQuestion === 1 ? true : false}>
            <CardBody>
            <p>The dashboard page provides you with information that you might want to quickly access. On this page, you can find your Thalamus referral link, your total money earned in commissions, your webstream pre-live URL (?), and promo codes you can distribute.</p>
            </CardBody>
        </Collapse>
        </Card>

        <Card>
        <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
            <h5>What is a referral link?</h5>
            <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
        </CardHeader>
        <Collapse isOpen={toggleQuestion === 2 ? true : false}>
            <CardBody>
            <p>Your referral link is an important tool that you will need to use to earn commissions. When a new customer wants to sign up, they register using your referral code, so that we can tie the sale to your affiliate account. This will ensure that you earn commission for your sale. Signing up a customer using your promo codes will also tie the sale to your account.</p>
            </CardBody>
        </Collapse>
        </Card>

        <Card>
        <CardHeader onClick={() => setToggequestion(3)} className={toggleQuestion === 3 ? 'question-open' : ''}>
            <h5>What is your webstream pre-live domain?</h5>
            <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
        </CardHeader>
        <Collapse isOpen={toggleQuestion === 3 ? true : false}>
            <CardBody>
            <p>Your webstream pre-live domain is basically a staging area for your video player if needed. Sometimes a website is in the process of being built so this enables your developer to install the player and see what it looks like before the site is live. Once the website is live then the real domain becomes live.</p>
            </CardBody>
        </Collapse>
        </Card>
    </div>
    </div>
  );
}

export default Dashboard;