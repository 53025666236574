import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  getReportsApiCall,
  getReportDetails,
  downloadInvoices
} from "../../../../service/reports";
import {
  getReports,
  getReportsFailure,
  getReportsSuccess,
  reset,
  setSelectedReport,
  loadingSelectedReport,
  loadingDownloadInvoice,
  setDownloadInvoice
} from "./index";

export function* getReportsWatcher(params) {
  try {
    const { pagenum, searchedText , sortBy , orderBy, reportType } = params.payload;
    const data = yield call(getReportsApiCall ,  pagenum, searchedText,  sortBy , orderBy, reportType);
    yield put(getReportsSuccess(data));
    yield put(reset());
  } catch (err) {
    yield put(getReportsFailure(err));
  }
}

export function* selectedReport(param) {
  try {
    const data = yield call(getReportDetails, param.payload);
    yield put(setSelectedReport(data));
    yield put(reset());
  } catch (err) {
    yield put(getReportsFailure(err));
  }
}

export function* downloadInvoice(params) {
  const { invoice_id, type , download_type } = params.payload;
  try {
    const data = yield call(downloadInvoices, invoice_id, type , download_type);
    yield put(setDownloadInvoice(data));
    yield put(reset());
  } catch (err) {
    yield put(getReportsFailure(err));
  }
}


export default function* activeWebStreamSaga() {
  yield all([
    takeLatest(getReports.type, getReportsWatcher),
    takeLatest(loadingSelectedReport.type, selectedReport),
    takeLatest(loadingDownloadInvoice.type, downloadInvoice),
  ]);
}
