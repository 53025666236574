import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { helperApi } from "../../service/common";
import {
  loadingPages,
  errorPages,
  industrySuccess,
  countrySuccess,
  paymentMethodsSuccess,
  reset,
} from "./index";
import helperConstants from "./constants";

export function* pagesWatcher(params) {
  try {
    let allData = yield all([
      call(helperApi, `${helperConstants.industry},${helperConstants.mediaLanguage}`),
      call(helperApi, helperConstants.country),
      call(helperApi, helperConstants.paymentMethod),
    ]);

    const [industryData, countryData, paymentData ] = allData;
    var unitedStateIndex = countryData.country.findIndex((item) => item.name === 'United States');
    if(unitedStateIndex > -1){
      countryData.country.splice(0, 0, countryData.country.splice(unitedStateIndex, 1)[0]);
    }

    var canadaIndex = countryData.country.findIndex((item) => item.name === 'Canada');
    if(canadaIndex > -1){
      countryData.country.splice(1, 0, countryData.country.splice(canadaIndex, 1)[0])
    }

    var australiaIndex = countryData.country.findIndex((item) => item.code === 'AU');
    if(australiaIndex > -1){
      countryData.country.splice(2, 0, countryData.country.splice(australiaIndex, 1)[0])
    }
    var ukIndex = countryData.country.findIndex((item) => item.name === 'United Kingdom');
    if(ukIndex > -1){
      countryData.country.splice(3, 0, countryData.country.splice(ukIndex, 1)[0]);
    }

    var germanyIndex = countryData.country.findIndex((item) => item.code === 'DE');
    if(germanyIndex > -1){
      countryData.country.splice(4, 0, countryData.country.splice(germanyIndex, 1)[0]);
    }
    
    const dataReducerArray = [
      { data: industryData, reducer: industrySuccess },
      { data: countryData, reducer: countrySuccess },
      { data: paymentData, reducer: paymentMethodsSuccess },
    ];
    yield all(
      dataReducerArray.map((item) => {
        const { reducer, data } = item;
        return put(reducer(data));
      })
    );
    yield put(reset());
  } catch (err) {
    yield put(errorPages(err.response.data));
  }
}

export default function* pagesSaga() {
  yield takeLatest(loadingPages.type, pagesWatcher);
}
