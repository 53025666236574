import React from "react";
import { InputField } from "../../components/InputField";
import "./style.scss";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CustomInput,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import BrandIcon from "../../img/logo.svg";
import { logout, setPwdLoading } from "./Redux";
import { useHistory } from "react-router";

function NewPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [passwordData, setPasswordData] = React.useState({
    password: "",
    repassword: "",
    passwordError: "",
    rePasswordError: "",
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);
  const pwdErrorStatus = useSelector((state) => state.login.pwdError);
  const pwdData = useSelector((state) => state.login.pwdData);
  const login = useSelector((state) => state.login.data);

  React.useEffect(() => {
    if (Object.keys(pwdErrorStatus).length > 0) {
      if (pwdErrorStatus.status === 401) {
        dispatch(logout());
      }
    }
  }, [pwdErrorStatus]);

  React.useEffect(() => {
    if (pwdData?.status === 200) {
      if(login.signup_tos_status == 0){
        history.push("/signup-tos");
      }else if(login.tos_status == 0 || login.tos_status == 2){
        history.push("/tos");
      }else{
        history.push("/app/dashboard");
      }
      
    }
  }, [pwdData]);

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validatePassword = () => {
    // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var strongRegex =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

    if (passwordData.password.match(strongRegex) == null) {
      return {
        valid: false,
        errormsg: "Password must be at least 8 and maximum 15 characters, please include one upper case letter, one small case letter, one symbol, and one number.",
        passwordField: true,
      };
    }
    // if(!strongRegex.test(passwordData.password)){
    //   return {
    //     valid: false,
    //     errormsg: "Please include at least one upper case, one lower case letter, one symbol, and one number",
    //     passwordField: true,
    //   };
    // }
    if (passwordData.password !== passwordData.repassword) {
      return {
        valid: false,
        errormsg: "Both passwords must match",
        passwordField: false,
      };
    }
    return { valid: true, errormsg: "", passwordField: false };
  };

  const handleSubmit = () => {
    const isValid = validatePassword();
    if (isValid.valid) {
      const pwdData = {
        password: passwordData.password,
        repassword: passwordData.repassword,
      };
      dispatch(setPwdLoading(pwdData));
      setPasswordData({
        ...passwordData,
        passwordError: "",
        rePasswordError: "",
      });
    } else {
      if (isValid.passwordField) {
        setPasswordData({
          ...passwordData,
          passwordError: isValid.errormsg,
          rePasswordError: "",
        });
      } else {
        setPasswordData({
          ...passwordData,
          rePasswordError: isValid.errormsg,
          passwordError: "",
        });
      }
    }
  };

  return (
    <div className="login__main__screen">
      <div className="login__inner">
        <div className="login__inner__wc-text col">
          <div className="wc-text-box">
            Welcome to the <span>Affiliate Partner Programs</span>
          </div>
        </div>
        <div className="login__inner__form">
          <div className="brand-icon">
            <img src={BrandIcon}></img>
          </div>
          <div className="form-body">
            <h4>Please create a new password</h4>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <label>Password (8 Characters or more)</label>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "HIDE" : "SHOW"}
                    </Button>
                  </div>
                  <InputField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    value={passwordData.password}
                    onChange={handleChange}
                    isError={
                      passwordData.passwordError
                        ? passwordData.passwordError
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <label>Repeat password</label>
                    <Button
                      color="link primary"
                      className="p-0"
                      onClick={() => setShowRePassword(!showRePassword)}
                    >
                      {showRePassword ? "HIDE" : "SHOW"}
                    </Button>
                  </div>
                  <InputField
                    type={showRePassword ? "text" : "password"}
                    name="repassword"
                    className="form-control"
                    value={passwordData.repassword}
                    onChange={handleChange}
                    isError={
                      passwordData.rePasswordError
                        ? passwordData.rePasswordError
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-3">
                <Button
                  type="button"
                  className="w-100"
                  color="black"
                  onClick={handleSubmit}
                >
                  Create Password & Sign In
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
