import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Webstream from "../pages/webstream";
import ExpiredPromoCodes from "./Overview/ExpiredPromoCodes";
import Reports from "./Reports";
import GeneralReport from "./Reports/General";
import WebstreamReport from "./Reports/Webstream";
import LicensesReport from "./Reports/Licenses";
import Invoices from "./Invoices";
import InvoicesWebstreams from "./Invoices/Webstreams";
import InvoicesLicenses from "./Invoices/Licenses";
import ReportsGeneralInvoice from "./Reports/General/ReportsGeneralInvoice";
import WebstreamInvoice from "./Invoices/Webstreams/WebstreamInvoice";
import Sidebar from "../components/Sidebar";
import "../scss/pages/_app_main.scss";
import "../scss/components/_statusbar.scss";

import NewPassword from "../pages/login/NewPassword";
import PasswordRecovery from "../pages/login/PasswordRecovery";
import PasswordRecoveryEmail from "../pages/login/PasswordRecoveryEmail";
import PasswordRecoveryEmailReset from "../pages/login/PasswordRecoveryEmailReset";
import ResetPassword from "../pages/login/ResetPassword";

// import Referral from "../pages/Referrals";

import Help from "../pages/Help";
import CategoryDetails from "../pages/Help/CategoryDetails";

import ContactUs from "../pages/ContactUs";
import axios from "axios";
import LicensesActive from "../pages/Licenses";
import LicensesInActive from "../pages/Licenses/Inactive";
import LicensesUnsued from "../pages/Licenses/Unused";
import LicensesExtension from "../pages/Licenses/LicenseExtensionDetails";
import LicensesRequest from "./Licenses/LicensesRequestDetails";
import PublicRoute from "../components/Routes/PublicRoute";
import PrivateRoute from "../components/Routes/PrivateRoute";
import DashboardRoutes from "./dashboardRoutes";
import { useSelector } from "react-redux";
import Invitation from '../pages/invitation'
import Thankyou from '../pages/invitation/thankyou'
import LinkExpired from '../pages/invitation/linkExpired'
import { useDispatch } from "react-redux";
import { loadingPages } from "./Redux";
import Tos from '../pages/Tos'
import SignUpTos from "./Tos/signupTos";
// export const history = createBrowserHistory({ forceRefresh: true });

function Main() {
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(loadingPages());
  }, []);

  return (
    <Router path="/" >
      {/* <Sidebar /> */}
      <main className="main-content">
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PrivateRoute path="/app" component={DashboardRoutes} />
          <PrivateRoute exact path="/new-password" component={NewPassword} />
          <PublicRoute
            exact
            path="/password-recovery"
            component={PasswordRecovery}
          />
          <PublicRoute
            exact
            path="/user-reset-password"
            component={ResetPassword}
          />
          <PublicRoute
            exact
            path="/password-recovery-email"
            component={PasswordRecoveryEmail}
          />
          <PublicRoute
            exact
            path="/password-recovery-email-reset"
            component={PasswordRecoveryEmailReset}
          />
          {/* <Route exact path="/webstream" component={Webstream} /> */}
          <Route
            path="/invitation"
            component={Invitation}
          />
          <Route
            path="/thankyou"
            component={Thankyou}
          />
          <Route
            path="/link-expired"
            component={LinkExpired}
          />
          <Route
            path="/signup-tos"
            component={SignUpTos}
          />
          <Route
            path="/tos"
            component={Tos}
          />
          {/* <Route
            exact
            path="/Overview/ExpiredPromoCodes"
            component={ExpiredPromoCodes}
          /> */}
          {/* <Route exact path="/Reports" component={Reports} /> */}
          {/* <Route exact path="/GeneralReport" component={GeneralReport} /> */}
          {/* <Route exact path="/WebstreamReport" component={WebstreamReport} /> */}
          {/* <Route exact path="/LicensesReport" component={LicensesReport} /> */}
          {/* <Route
            exact
            path="/GeneralReport/ReportsGeneralInvoice"
            component={ReportsGeneralInvoice}
          /> */}
          {/* <Route exact path="/Invoices" component={Invoices} /> */}
          {/* <Route
            exact
            path="/Invoices/InvoicesWebstreams"
            component={InvoicesWebstreams}
          /> */}
          {/* <Route
            exact
            path="/Invoices/InvoicesLicenses"
            component={InvoicesLicenses}
          /> */}
          {/* <Route
            exact
            path="/Invoices/InvoicesLicenses/WebstreamInvoice"
            component={WebstreamInvoice}
          /> */}
          {/* <Route exact path="/Sales/Myleads" component={Referral} /> */}
          {/* <Route exact path="/help" component={Help} /> */}
          <Route
            exact
            path="/help-category-details"
            component={CategoryDetails}
          />
          {/* <Route exact path="/contact-us" component={ContactUs} /> */}
          {/* <Route exact path="/licenses-active" component={LicensesActive} /> */}
          {/* <Route exact path="/licenses-inactive" component={LicensesInActive} /> */}
          {/* <Route exact path="/licenses-unused" component={LicensesUnsued} /> */}
          <Route exact path="/licenses-request" component={LicensesRequest} />
          <Route
            exact
            path="/licenses-extension"
            component={LicensesExtension}
          />
        </Switch>
      </main>
    </Router>
  );
}

export default Main;
