import React from "react";
import { Link, NavLink , useLocation} from "react-router-dom";
import { clearStorage } from '../../helper/LocalStorage';

import "./Style.scss";
import logo from "../../images/logo.svg";
import logo_text from "../../images/logo_text.svg";
import Icon from "../../icon";
import PerfectScrollbar from "react-perfect-scrollbar";
import AffilateLogo from "../../images/affilate-logo.svg";
import { useDispatch } from "react-redux";
import { logout , LOUGOUT} from "../../pages/login/Redux";
import {logoutUser} from '../../service/login'

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import {CheckPermission , USERPROFILE, DASHBOARD , OVERVIEW, REFERRALS, WEBSTREAMS,WEBSTREAMS_ACTIVE,WEBSTREAMS_INACTIVE, LICENSES, REPORTS, INVOICES, CONTACTUS, HELP, USERACCESSRIGHTS} from '../../data/comman'

const Sidebar = () => {
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const [showSignoutModal, setShowSignoutModal] = React.useState(false);

  const dispatch = useDispatch();

  const onLogout = async () =>{
    const data = await logoutUser();
    dispatch(logout())
  }
   //assigning location variable
   const location = useLocation();

   //destructuring pathname from location
   const { pathname } = location;

   //Javascript split method to get the name of the path in array
   const splitLocation = pathname.split("/");

  return (
    <PerfectScrollbar>
      <aside
        id="sidebar"
        className="sidebar active sidebar--dark d-flex flex-column h-100"
      >
        <div className="sidebar__logo justify-content-center p-3 my-4">
          <Link to="/" className="logo">
            <span className="logo__text">
              <img src={AffilateLogo} />
            </span>
          </Link>
        </div>
        <div className="sidebar__navigation d-flex flex-column h-100">
          <nav className="sidebar__navigation__nav">
            <ul className="sidebar__navigation__list">
              {CheckPermission(DASHBOARD.id,'read') &&
              <li
                className={`sidebar__navigation__item `}
                onClick={() => {
                  setSelectedMenu(0);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link `}
                  activeClassName="sidebar__navigation__link--active"
                  to="/app/dashboard"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="dashboard" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">Dashboard</span>
                </NavLink>
              </li>
              }
              {CheckPermission(OVERVIEW.id,'read') &&
              <li
                className={`sidebar__navigation__item `}
                onClick={() => {
                  setSelectedMenu(1);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link `}
                  activeClassName="sidebar__navigation__link--active"
                  to="/app/overview"
                >
                  <i className="sidebar__navigation__icon icon-help-icon">
                    <Icon icon="home" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">Overview</span>
                </NavLink>
              </li>
              }
              {CheckPermission(REFERRALS.id,'read') &&
              <li
                className={`sidebar__navigation__item `}
                onClick={() => {
                  setSelectedMenu(2);
                }}
              >
                <NavLink
                  activeClassName="sidebar__navigation__link--active"
                  className={`sidebar__navigation__link `}
                  to="/app/Sales"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="Referrals" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">Referrals</span>
                </NavLink>
              </li>
              }
              {CheckPermission(WEBSTREAMS.id,'read') &&
              <li
                className={`sidebar__navigation__item`}
                onClick={() => {
                  setSelectedMenu(3);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link ${splitLocation[2] === "webstreams" ? "sidebar__navigation__link--active" : ""}`}
                  to="/app/webstreams/active"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="webstreams" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">Webstreams</span>
                </NavLink>
                <ul className="sidebar__navigation__submenu">
                {CheckPermission(WEBSTREAMS.id,'read') &&
                  <li>
                    <NavLink activeClassName="active" to="/app/webstreams/active">
                      Active
                    </NavLink>
                  </li>
                }
                {CheckPermission(WEBSTREAMS.id,'read') &&
                  <li>
                    <NavLink activeClassName="active" to="/app/webstreams/inactive">
                      Inactive
                    </NavLink>
                  </li>
                }
                </ul>
              </li>
              }
              {/* <li
                className={`sidebar__navigation__item  `}
                onClick={() => {
                  setSelectedMenu(4);
                }}
              >
                <NavLink
                  href="#"
                  className={`sidebar__navigation__link ${splitLocation[2] === "licenses" ? "sidebar__navigation__link--active" : ""}`}
                  // activeClassName="sidebar__navigation__link--active"
                  to="/app/licenses/active"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="badge-icon" color="#fff" size={18} />
                  </i>
                  <span className="sidebar__navigation__text">Licenses</span>
                </NavLink>
                <ul className="sidebar__navigation__submenu">
                  <li>
                    <NavLink activeClassName="active" to="/app/licenses/active">
                      Active
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/app/licenses/unused">
                    Unused
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/app/licenses/inactive">
                      Inactive
                    </NavLink>
                  </li>
                </ul>
              </li> */}
              {CheckPermission(REPORTS.id,'read') &&
              <li
                className={`sidebar__navigation__item  `}
                onClick={() => {
                  setSelectedMenu(5);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link ${splitLocation[2] === "reports" ? "sidebar__navigation__link--active" : ""}`}
                  // activeClassName="sidebar__navigation__link--active"
                  to="/app/reports/referral"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="help-reports" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">Reports</span>
                </NavLink>
                <ul className="sidebar__navigation__submenu">
                  <li>
                    <NavLink activeClassName="active" to="/app/reports/referral">
                      Referral
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/app/reports/webstreams">
                      Webstreams
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink activeClassName="active" to="/app/reports/licenses">
                      Licenses
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              }
              {CheckPermission(INVOICES.id,'read') &&
              <li
                className={`sidebar__navigation__item `}
                onClick={() => {
                  setSelectedMenu(6);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link ${splitLocation[2] === "invoices" ? "sidebar__navigation__link--active" : ""}`}
                  activeClassName="sidebar__navigation__link--active"
                  to="/app/invoices/webstreams"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="card-icon" color="#fff" size={18} />
                  </i>
                  <span className="sidebar__navigation__text">Invoices</span>
                </NavLink>
                <ul className="sidebar__navigation__submenu">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/app/invoices/webstreams"
                    >
                      Webstreams
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      to="/app/invoices/licenses"
                    >
                      Licenses
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              }
            </ul>
          </nav>

          <nav className="sidebar__navigation__nav sidebar__navigation__bottom  mt-auto mb-5">
            <ul className="sidebar__navigation__list">
            {CheckPermission(USERACCESSRIGHTS.id,'read') &&
              <li
                className={`sidebar__navigation__item`}
                onClick={() => {
                  setSelectedMenu(7);
                }}
              >
                <NavLink
                  className={`sidebar__navigation__link `}
                  activeClassName="sidebar__navigation__link--active"
                  to="/app/userRights"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="user-icon" color="#fff" size={19} />
                  </i>
                  <span className="sidebar__navigation__text">
                    Users & Rights
                  </span>
                </NavLink>
              </li>
            }
            {CheckPermission(CONTACTUS.id,'read') &&
              <li className="sidebar__navigation__item">
                <NavLink
                  href="#"
                  className="sidebar__navigation__link"
                  activeClassName="sidebar__navigation__link--active"
                  to="/app/contact-us"
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="mail-icon" color="#fff" size={15} />
                  </i>
                  <span className="sidebar__navigation__text">Contact Us</span>
                </NavLink>
              </li>
            }
            {CheckPermission(HELP.id,'read') &&
              <li className="sidebar__navigation__item">
                <NavLink href="#" activeClassName="sidebar__navigation__link--active" className="sidebar__navigation__link" to="/app/help">
                  <i className="sidebar__navigation__icon">
                    <Icon icon="help-icon" color="#fff" size={15} />
                  </i>
                  <span className="sidebar__navigation__text">Help</span>
                </NavLink>
              </li>
            }
              <li className="sidebar__navigation__item">
                <Link
                  href="#"
                  className="sidebar__navigation__link"
                  // to={(location) => ({ ...location, pathname: "/#" })}
                  onClick={() => setShowSignoutModal(true)}
                >
                  <i className="sidebar__navigation__icon">
                    <Icon icon="sign-out" color="#fff" size={15} />
                  </i>
                  <span className="sidebar__navigation__text">Sign Out</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <Modal
          isOpen={showSignoutModal}
          toggle={() => setShowSignoutModal(false)}
        >
          <ModalBody
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "2rem" }}>Sign out</div>
            <span>
              Once signed out , your username and password will be requested
              again before you're able to access your affiliate account
            </span>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button
              type="button"
              onClick={() => onLogout()}
              className="btn btn-md outline-primary bg-white"
            >
              Sign out
            </button>
            <button
              type="button"
              onClick={() => setShowSignoutModal(false)}
              className="btn btn-md outline-danger bg-white"
              style={{ marginLeft: "20px" }}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
        {/* <div className="toggle-btn">
          <span className="toggle_arrow">
            <Icon icon="Left" color="#343434" disableFill="true" size={9} />
          </span>
        </div> */}
      </aside>
    </PerfectScrollbar>
  );
};

export default Sidebar;
