import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Summarybar from '../../../components/Summarybar';
import Icon from "../../../icon";
import  { Filter, FilterInput} from '../../../components/Filter';
import US from '../../../images/flags/unitedstates.svg'
import ReactCountryFlag from "react-country-flag";
import DatePicker from "react-datepicker";
import right_arrow from '../../../images/right_arrow.svg';
import thalamuslogo from '../../../images/thalamus-logo.png';
import callicon from '../../../images/call-icon.svg';
import mailicon from '../../../images/mail-icon.svg';
import locationicon from '../../../images/location-icon.svg';
import clockicon from '../../../images/clock-icon.svg';import { Alert, Button, Input, Label, FormGroup,Col,Row,Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
let practiceId;

class ReportsGeneralInvoice extends React.Component{

    constructor(props) {
        super(props)
        
        this.state = {
            
          active: 0,
          isOpen:false,
          activeButtonTab:'1',
          ticketsData: this.props.tickets,
          isChecked: false,
          selectAll: false,
          ShowPutonhold:false
            
        }
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
    }
    
    toggleChange (event, ticket) {
      var ticketState = this.state.ticketsData;
      var ticketIndex = ticketState.indexOf(ticket);
      
      ticket.isChecked = event.target.checked;
      ticketState[ticketIndex] = ticket
      this.setState({ 
        ticketsData: ticketState
      });
      
      var selectedinvices = Object.keys(ticketState).filter(k => ticketState[k].isChecked);
      if(selectedinvices.length == Object.keys(ticketState).length){
        this.selectAll(null, true);
      }
      else{
        this.selectAll(null, false);
      }
      this.setState( {
          ShowPutonhold:false
      } )
      if(event.target.checked){
          this.setState( {
              ShowPutonhold:true
          } )
      }
  }
  
  toggleCollapse() {
      this.setState({ isOpen: !this.state.isOpen });
  }
  
  toggleButtons(tab) {
      if (this.state.activeButtonTab !== tab) {
          this.setState({
              activeButtonTab: tab,
          });
      }
  }

  render(){
    const { isOpen } = this.state;
    return(
        <div>
            <Summarybar />
            <Row>
                <Col lg="9">
                <div className="card py-5">
                    <div className="card-box">
                        <div className="card__header card__header__filter">
                            <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                                <div className="card__title d-flex align-items-center">
                                    <Link className="btn p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Link>
                                    <h3 className="m-0 mr-4">Report no. ADSL102020 </h3>
                                    <Link className="link-btn outline-primary mx-3 px-5" to='/Activewebstream/DomainAuthrization'><Icon className="mr-4" icon="Download-Icons" disableFill="true" size={16}></Icon>Download</Link>
                                </div>
                            </div>
                        </div>
                        <div className=" px-5 card-body">
                            <div className="pl-5">
                                <div className="affilate-report-bg">
                                    <div className="affilate-report-sec">
                                        <Row>
                                            <Col lg="4">
                                                <div className="affilate-report-left px-5 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <img  src={thalamuslogo}/>
                                                        </div>
                                                        <div className="py-4">
                                                            <ul className="thalasum-info">
                                                                <li>
                                                                    <img src={locationicon}/>
                                                                    <span>249-67 Mowat Ave, Toronto, ON, M6K 3L5</span>
                                                                </li>
                                                                <li>
                                                                    <img src={callicon}/>
                                                                    <span><a className="link-black" href='tel:1(888)575-7932'>1(888) 575-7932</a></span>
                                                                </li>
                                                                <li>
                                                                    <img src={mailicon}/>
                                                                    <span><a className="link-black" href="mailto:sales@thalamusmedia@.com">sales@thalamusmedia@.com</a></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul className="thalasum-info">
                                                            <li>
                                                                <img src={clockicon}/>
                                                                <span>
                                                                    <h3 className="mb-3">Payment due date</h3>
                                                                    <p>31 Oct 2020</p>
                                                                    <p>Amount to be paid in CA dollers.</p>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="affilate-report-right px-4 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <div className="affilate-report-header d-flex justify-content-between">
                                                                <h3>Report no. ADSL102020</h3>
                                                                <h3>ACME DENTAL</h3>
                                                            </div>
                                                            <div className="py-5 d-flex justify-content-between">
                                                                <div>
                                                                    <p>Issued on Oct 2020</p>
                                                                    <p>From 01 Jan 2020 to 30 Mar 2020</p>
                                                                </div>
                                                                <div>
                                                                    <p className="text-right">200 - 1560 Younge St</p>
                                                                    <p className="text-right">Toronto, ON, M4T 2S9</p>
                                                                    <p className="text-right">Canada</p>
                                                                </div>
                                                            </div>
                                                            <div className="divider my-5"></div>
                                                            <div className="d-flex justify-content-between">
                                                                <h3>Practice names</h3>
                                                                <h3>Total received</h3>
                                                            </div>
                                                            <ul className="affilate-commison-list">
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">1</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">2</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">3</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">4</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">5</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">6</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">7</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">8</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">9</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">10</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">11</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">12</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">13</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">14</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">15</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">16</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">17</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">18</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">19</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">20</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="affilate-report-footer">
                                                        <p className="text-right">Page 1 of 2</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="affilate-report-bg">
                                    <div className="affilate-report-sec">
                                        <Row>
                                            <Col lg="4">
                                                <div className="affilate-report-left px-5 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <img  src={thalamuslogo}/>
                                                        </div>
                                                        <div className="py-4">
                                                            <ul className="thalasum-info">
                                                                <li>
                                                                    <img src={locationicon}/>
                                                                    <span>249-67 Mowat Ave, Toronto, ON, M6K 3L5</span>
                                                                </li>
                                                                <li>
                                                                    <img src={callicon}/>
                                                                    <span><a href='tel:1(888)575-7932'>1(888) 575-7932</a></span>
                                                                </li>
                                                                <li>
                                                                    <img src={mailicon}/>
                                                                    <span><a href="mailto:sales@thalamusmedia@.com">sales@thalamusmedia@.com</a></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul className="thalasum-info">
                                                            <li>
                                                                <img src={clockicon}/>
                                                                <span>
                                                                    <h3 className="mb-3">Payment due date</h3>
                                                                    <p>31 Oct 2020</p>
                                                                    <p>Amount to be paid in CA dollers.</p>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="affilate-report-right pr-4 py-5">
                                                    <div>
                                                        <div className="py-5">
                                                            <div className="d-flex justify-content-between">
                                                                <h3>Practice names</h3>
                                                                <h3>Total received</h3>
                                                            </div>
                                                            <ul className="affilate-commison-list">
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">21</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">22</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">23</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">24</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">25</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">26</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">27</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">28</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">29</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">30</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">31</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <span className="sr-no">32</span>
                                                                        <img src={US} alt="US"/>
                                                                        <span>Bellano Dental</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>$249.00</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="affilate-report-footer">
                                                        <div className="total-footer d-flex justify-content-between">
                                                            <p>TOTAL</p>
                                                            <p>$7,968.00</p>
                                                        </div>
                                                        <div className="divider mt-3 mb-5"></div>
                                                        <div className="my-4">
                                                            <h3>Commision due</h3>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p>Accesible on your affilate account</p>
                                                                <p>Tax included</p>
                                                            </div>
                                                            <div>
                                                                <p>$1,593.60</p>
                                                            </div>
                                                        </div>
                                                        <p className="text-right">Page 2 of 2</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Col>
                <Col lg="3">
                    <div className="py-5">
                        <div className="py-5">
                            <h3>Summary</h3>
                        </div>
                        <ul className="summary-list pb-3 border-none">
                            <li>
                                <span><strong>Report No.:</strong></span>
                                <span>ADSL172020</span>
                            </li>
                            <li>
                                <span><strong>Program:</strong></span>
                                <span>General</span>
                            </li>
                            <li>
                                <span><strong>Payment type:</strong></span>
                                <span>Monthly</span>
                            </li>
                            <li>
                                <span><strong>Amount due:</strong></span>
                                <span>$7,968.00</span>
                            </li>
                            <li>
                                <span><strong>Due date:</strong></span>
                                <span>1 Oct 2020</span>
                            </li>
                            <li>
                                <span><strong>Payment method:</strong></span>
                                <span>Wire transfer</span>
                            </li>
                            <li>
                                <span><strong>Payment status:</strong></span>
                                <span>Pending</span>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
  );
}
}
export default ReportsGeneralInvoice;
