import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill'; // ES6
import './MyEditor.css'
import Icon from "../../icon";
import { Tooltip,UncontrolledTooltip } from 'reactstrap';
import { EditorButtonType, TICKETMAXLENGTH } from '../../data/comman';


/*
 * Custom "star" icon for the toolbar using an Octicon
 * https://octicons.github.io
 */
var inputFile = null;
const CustomButton = () => <button className="btn btn-link py-0 ql-file d-flex align-items-center justify-content-center" onClick={(e)=>{inputFile.click();}}/>;    //ql-insertStar

/*
 * Event handler to be attached using Quill toolbar module (see line 73)
 * https://quilljs.com/docs/modules/toolbar/
 */
function insertStar() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "★");
  this.quill.insertEmbed(10, 'image','https://www.cbronline.com/wp-content/uploads/2016/06/what-is-URL-770x503.jpg')
  this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
var quick = ReactQuill;
var icons = ReactQuill.Quill.import('ui/icons');
// console.log(icons);
icons['color'] = ReactDOMServer.renderToString(<Icon id="ql-color" className="" icon="text-color" color="#157CF7" disableFill="true" size={16} />);
icons['file'] = ReactDOMServer.renderToString(<Icon className="" icon="paperclip" color="#157CF7" disableFill="true" size={16} />);
icons['link'] = ReactDOMServer.renderToString(<Icon className="" icon="hyperlink" color="#157CF7" disableFill="true" size={16} />);
icons['indent'] = ReactDOMServer.renderToString(<Icon className="" icon="Insert" color="#157CF7" disableFill="true" size={16} />);
// icons['background'] = ReactDOMServer.renderToString(<Icon id="ql-background" className="" icon="Highlight-icon"   size={18} />);
icons['background'] = ReactDOMServer.renderToString(<Icon id="ql-background" className="" icon="Colour-icon"   size={18} />);
// icons['align'] = ReactDOMServer.renderToString(<Icon className="" icon="align-left-icon" color="#157CF7" disableFill="true" size={16} />);
// icons['align'] = ReactDOMServer.renderToString(<Icon className="" icon="align-center-icon" color="#157CF7" disableFill="true" size={16} />);
icons.align = null; // or `delete icons.bold`

icons.bold = null; // or `delete icons.bold`
icons.underline = null; // or `delete icons.bold`
icons.italic = null; // or `delete icons.bold`

const highlightColorButton = (<> 
<button type="button"  className="btn btn-link py-0 ">
  <select className="ql-background">
    <option value="#343434" />
    <option value="#757575" />
    <option value="#ffffff" />
    <option value="#ff3f34" />
    <option value="#ffa400" />
    <option value="#59a600" />
    <option value="#157cf7" />
    <option value="#b620e0" />
    <option selected />
  </select>
</button>
<UncontrolledTooltip placement="bottom" target="ql-background">
  Highlight colours
</UncontrolledTooltip>
</>)

const highlightBackgroundButton = (isTextFormat = true) => (<>
  <button type="button" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ">
  <select className="ql-background">
    <option value="#343434" />
    <option value="#757575" />
    <option value="#ffffff" />
    <option value="#ff3f34" />
    <option value="#ffa400" />
    <option value="#59a600" />
    <option value="#157cf7" />
    <option value="#b620e0" />
    <option selected />
  </select>
  </button>
  <UncontrolledTooltip placement="bottom" target="ql-background">
    Highlight colours
  </UncontrolledTooltip>
</>)

const fileButton = <CustomButton />
const textFormatButton = (setTextFormat, isTextFormat) => <button type="button" className="btn btn-link py-0" onClick={()=> setTextFormat(!isTextFormat)}>
      {!isTextFormat ? <Icon icon="A" color="#157CF7" disableFill="true" size={16} /> :
      <Icon icon="Highlight-icon" size={18} />
      }
  </button> 

const alignLeftButton = (isTextFormat = false) => (<>
    <button id="ql-align-left" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-align" value="" ><Icon className="" icon="align-left-icon" color="#157CF7" disableFill="true" size={16} /></button>
    <UncontrolledTooltip placement="bottom" target="ql-align-left">
      Align Left
    </UncontrolledTooltip>
</>)
const alignCenterButton = (isTextFormat = false) => (<>
    <button id="ql-align-center" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-align" value="center" ><Icon className="" icon="align-center-icon" color="#157CF7" disableFill="true" size={16} /></button>
    <UncontrolledTooltip placement="bottom" target="ql-align-center">
      Align Center
    </UncontrolledTooltip>
</>)
const alignRightButton = (isTextFormat = false) => (<>
    <button id="ql-align-right" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-align" value="right" ><Icon className="" icon="align-right-icon" color="#157CF7" disableFill="true" size={16} /></button>
    <UncontrolledTooltip placement="bottom" target="ql-align-right">
      Align Right
    </UncontrolledTooltip>
</>)
const linkButton = (<>
  <button id="ql-link" className="btn btn-link py-0 ql-link" />
  <UncontrolledTooltip placement="bottom" target="ql-link">
      link
  </UncontrolledTooltip>
</>)
const indentButton = (<>
    <button id="ql-indent" className="btn btn-link py-0 ql-indent" value="+1" />
    <UncontrolledTooltip placement="bottom" target="ql-indent">
      Insert
    </UncontrolledTooltip></>)
const bulletButton = (isTextFormat = false) => (<>
    <button id="ql-bullet-points" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-list" value="right" ><Icon className="" icon="list-icon" color="#157CF7" disableFill="true" size={16} /></button>
    <UncontrolledTooltip placement="bottom" target="ql-bullet-points">
      Bullet points
    </UncontrolledTooltip>
    </>)
const boldButton = (isTextFormat = false) => (<>
    <button id="ql-bold" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-bold" value="right" ><Icon className="" icon="bold-icon" color="#157CF7" disableFill="true" size={13} /></button>
    <UncontrolledTooltip placement="bottom" target="ql-bold">
      Bold
    </UncontrolledTooltip>
    </>)
const italicButton = (isTextFormat = false) => (<>
  <button id="ql-italic" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-italic" value="right" ><Icon className="" icon="Italic-icon" color="#157CF7" disableFill="true" size={13} /></button>
  <UncontrolledTooltip placement="bottom" target="ql-italic">
      italic
  </UncontrolledTooltip>
</>)
const underLineButton  = (isTextFormat = false) => (<>
  <button id="ql-underline" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ql-underline " value="right" ><Icon className="" icon="underline-icon" color="#157CF7" disableFill="true" size={13} /></button>
  <UncontrolledTooltip placement="bottom" target="ql-underline">
    Underline
  </UncontrolledTooltip>
</>)
const textColorButton = (isTextFormat = false) => (<>
  <button type="button" style={{display: isTextFormat ? 'block' : 'none' }} className="btn btn-link py-0 ">
      <select className="ql-color">
        <option value="#343434" />
        <option value="#757575" />
        <option value="#ffffff" />
        <option value="#ff3f34" />
        <option value="#ffa400" />
        <option value="#59a600" />
        <option value="#157cf7" />
        <option value="#b620e0" />
        <option selected />
      </select>
  </button>
  <UncontrolledTooltip placement="bottom" target="ql-color">
    Text colours
  </UncontrolledTooltip>
</>)

const CustomToolbar = ({buttonType, setTextFormat, isTextFormat}) =>  (<div id="toolbar" className="d-flex ">
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.HighlightColor)) && highlightColorButton}
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.TextFormat)) && textFormatButton(setTextFormat, isTextFormat)}
    {
      [alignLeftButton(isTextFormat), alignCenterButton(isTextFormat), alignRightButton(isTextFormat), bulletButton(isTextFormat), boldButton(isTextFormat), italicButton(isTextFormat), underLineButton(isTextFormat), textColorButton(isTextFormat), highlightBackgroundButton(isTextFormat) ]
    }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.File)) && fileButton }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.AlignLeft)) && alignLeftButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.AlignCenter)) && alignCenterButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.AlignRight)) && alignRightButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Link)) && linkButton }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Indent)) && indentButton }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Bullet)) && bulletButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Bold)) && boldButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Italic)) && italicButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.Underline)) && underLineButton() }
    { (buttonType.includes(EditorButtonType.All) || buttonType.includes(EditorButtonType.TextColor)) && textColorButton() }
  </div>
)

/* 
 * Editor component with custom toolbar and content containers
 */
class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: "", files: [], buttonType: props.buttonType, isTextFormat: false };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  handleChangeEditor(content, delta, source, editor) {
    // console.log(editor.getHTML()); // rich text
		// console.log(editor.getText()); // plain text
		// console.log(editor.getLength()); // number of characters
    this.setState({ editorHtml: editor });
    // console.log(html);
    // this.props.onChange && this.props.onChange(html)
  }

  handleDrop(e){
    e.preventDefault();
    const files = e.dataTransfer.files;
    // var url = URL.createObjectURL(e.dataTransfer.files[0])
    this.addFileToEditor(files);
  }

  handleDragOver(e){
    e.preventDefault();
  }

  handleDragEnter(e){
    e.preventDefault();
  
  }

  handleDragLeave(e){
    e.preventDefault();
  
  }

  onFileSelect(e){
    const files = e.target.files;
    this.addFileToEditor(files);
  }

  addFileToEditor(files){
    var qq = this.quillRef.getEditor()
    var otherFiles = [];
    for(var i = 0; i < files.length; i++){
      if(files[i]['type'].split('/')[0] === 'image'){
        let reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = function () {
            // console.log(reader.result)
          
          qq.insertEmbed(10, 'image', reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
      }else{
        var fileSize = Math.round(files[i].size / 1024);
        if(fileSize > TICKETMAXLENGTH){
          alert("Max file size 30MB");
          return;
        }
        otherFiles.push(files[i]);
      }
    }

    if(otherFiles.length > 0){
      this.setState(prevState => ({
            files : [...prevState.files, ...otherFiles]
          }),()=>{
            if(this.props.onFileSelect){
              this.props.onFileSelect(this.state.files);
            }
          })
    }
    // if(files[0]['type'].split('/')[0] === 'image'){
    //   let reader = new FileReader();
    //   reader.readAsDataURL(files[0]);
    //   reader.onload = function () {
    //       // console.log(reader.result)
        
    //     qq.insertEmbed(10, 'image', reader.result);
    //   };
    //   reader.onerror = function (error) {
    //       console.log('Error: ', error);
    //   };
    // }
    // else{
    //   this.setState(prevState => ({
    //     files : [...prevState.files, ...files]
    //   }),()=>{
    //     if(this.props.onFileSelect){
    //       this.props.onFileSelect(this.state.files);
    //     }
    //   })
    // }
  }

  removeFile(index){
    var files = this.state.files;
    files.splice(index, 1);
    this.setState({ files });
  }

  render() {
    return (
      <div className="text-editor">
        <ReactQuill
          ref={(el) => this.quillRef = el}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          modules={Editor.modules}
          formats={Editor.formats}
          theme={"snow"} // pass false to use minimal theme
          value={this.props.value}
        />
        {this.state.files.map((file, index)=>
            <div key={index} className="attached__document mt-5 ml-5">
              {file.name} 
              <a id="imagethumbnailCancel" className="clear__icon ml-5">
                  <Icon className="" icon="clear" color="#157CF7" disableFill="true" size={14} onClick={()=>{this.removeFile(index)}}/>
              </a>
            </div>
          )
        } 
        <CustomToolbar buttonType={this.state.buttonType} setTextFormat={(isTextFormat)=> this.setState({ isTextFormat })} isTextFormat={this.state.isTextFormat}/>
        {/* <div style={{padding: '2rem',textAlign: 'center', borderRadius: '0.5rem', boxShadow: '5px 5px 10px #C0C0C0'}}
            onDrop={e => this.handleDrop(e)}
            onDragOver={e => this.handleDragOver(e)}
            onDragEnter={e => this.handleDragEnter(e)}
            onDragLeave={e => this.handleDragLeave(e)}>
            <p>Drag files here to upload</p>
          </div> */}
          <input id="myInput"
            type="file"
            ref={(ref) => inputFile = ref}
            style={{display: 'none'}}
            onChange={e => this.onFileSelect(e)}
            onClick={e => e.currentTarget.value = null }
            multiple={this.props.isMultipleFile}
          />
         
          </div>
    );
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    container: "#toolbar",
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      [{ 'color': [] }, { 'background': [] }],
      ['clean'],
    ],
    handlers: {
      insertStar: insertStar
    },
  },
  clipboard: {
    matchVisual: false,
  }
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  'align',
];

/* 
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.any,
  buttonType: PropTypes.array,
};

Editor.defaultProps = {
  buttonType: ['All'],
};
export default Editor;

/* 
 * Render component on page
 */
// ReactDOM.render(
//   <Editor placeholder={"Write something or insert a star ★"} />,
//   document.querySelector(".app")
// );
