import {
  loadingUserRights,
  successUserRights,
  reset,
  failureUserRights,
  setModuleData,
  setModuleError,
  setPermissions,
} from ".";
import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import { getOverviewDetails, editUserDetails } from "../../../service/overview";
import { actionCreator } from "../../../Redux/rootReducer";
import {
  createUser,
  getAllModules,
  getAllUsers,
} from "../../../service/userRights";

export const GETMODULES = actionCreator("GET_MODULES");

const handleData = (users) => {
  if (users.length > 0) {
    let uniquePermissionArray = [];
    let children = []
    users.map((user, index) => {
      
      user.permissions.map((item) => {
        const name = item.module.name;
        const isRead = item.is_read;
        const isWrite = item.is_write;
        const userId =  user.id;
        const moduleId = item.module_id;
        const parentId = item.module.parent_id;
        children = user.permissions.filter((x) => x.module.parent_id == item.module_id);
        var childObject = [];
              children.map((childPermission) => {
                childObject.push({
                  isWrite: childPermission.is_write, 
                  isRead: childPermission.is_read, 
                  userId: userId, 
                  moduleId: childPermission.module_id, 
                  parentId: childPermission.module.parent_id,
                  moduleName: childPermission.module.name
                })
              })
        if (item.module.parent_id===0){
          if (index === 0) {
              const dataObj = { isWrite, isRead, userId, moduleId , parentId, children: childObject };
              uniquePermissionArray.push({ name, data: [dataObj] });
          }else{
            uniquePermissionArray.forEach((item) => {
              if (item.name === name) {
                let newData = [...item.data];
                newData.push({ isWrite, isRead, userId, moduleId , parentId, children: childObject });
                item.data = newData;
              }
            });
          }
        }
        // else{
        //   uniquePermissionArray.forEach((child) => {
        //       if (item.name === name) {
        //           let newData = [...item.data];
        //             newData.push({ isWrite, isRead, userId, moduleId , parentId });
        //           item.data = newData;
        //         }
        //   });
        // }
      });
    });
    return uniquePermissionArray;
  } else {
    return [];
  }
};

export function* userRightWatcher(params) {
  try {
    const alluserData = yield call(getAllUsers);
    const permissionsData = handleData(alluserData.data);
    yield put(setPermissions(permissionsData));
    yield put(successUserRights(alluserData.data));
    yield put(reset());
  } catch (err) {
    yield put(failureUserRights(err));
  }
}

export function* modulesWatcher(params) {
  try {
    const modulesData = yield call(getAllModules);
    yield put(setModuleData(modulesData.data));
  } catch (err) {
    yield put(setModuleError(err.response.data));
  }
}

export default function* userRightSaga() {
  yield all([
    takeLatest(loadingUserRights.type, userRightWatcher),
    takeLatest(GETMODULES.type, modulesWatcher),
  ]);
}
