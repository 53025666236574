import { createSlice } from "@reduxjs/toolkit";
import { userRightsInitialState } from "./initialState";

const loadingUserRights = (state) => {
  state.loading = true;
};
const successUserRights = (state, action) => {
  state.data = action.payload;
};

const failureUserRights = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const reset = (state) => {
  state.loading = false;
  state.error = "";
};

const setModuleData = (state, action) => {
  state.modulesData = action.payload;
  state.moduleError = {};
};

const setModuleError = (state, action) => {
  state.moduleError = action.payload;
};

const setPermissions = (state, action) => {
  state.permissions = action.payload;
};

const userRightSlice = createSlice({
  name: "userRightSlice",
  initialState: userRightsInitialState,
  reducers: {
    loadingUserRights,
    successUserRights,
    failureUserRights,
    reset,
    setModuleData,
    setModuleError,
    setPermissions,
  },
});

export default userRightSlice;
