import { createSlice } from "@reduxjs/toolkit";
import { activeWebStreamInitialState } from "./initialState";

const loadingActiveWebStream = (state) => {
  state.loading = true;
};

const activeWebStreamsSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;
};

const activeWebStreamFailure = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

const setSelectedDomain = (state, action) => {
  state.selectedDomain = action.payload;
  state.loadingSelectedDomain = false;
};

const loadingSelectedDomain = (state) => {
  state.loadingSelectedDomain = true;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
};

const activeWebstreamSlice = createSlice({
  name: "activeWebstreamSlice",
  initialState: activeWebStreamInitialState,
  reducers: {
    loadingActiveWebStream,
    activeWebStreamsSuccess,
    activeWebStreamFailure,
    reset,
    setSelectedDomain,
    loadingSelectedDomain,
  },
});

export default activeWebstreamSlice;
