import axios from 'axios';
import { clearStorage, getToken } from '../helper/LocalStorage';

export const APIBASEURL = process.env.REACT_APP_BASE_URL; //"https://api.thalamusmedia.com/";//"https://localhost:9000";
export const WISTIAUPLOADURI = "https://upload.wistia.com";
export const WISTIAMEDIA = "https://api.wistia.com/v2/medias.json";


export const LOGIN = APIBASEURL + "user/login";
export const DOMAINURI = APIBASEURL + "practice/domain/dashboard";
export const TOSURI = APIBASEURL + "tos/dashboard";
export const SIGNUPURI = APIBASEURL + "practice/dashboard";
export const STATISTICSURI = APIBASEURL + "dashboard";
export const COUNTRYURI =  APIBASEURL + "helper/country";
export const HELPERURI = APIBASEURL + "helper/";
export const LOGOUTURI = APIBASEURL + "user/logout";
export const INVOICEURI = APIBASEURL + "invoice";
export const USERURI = APIBASEURL + "user";
export const SUBSCRIPTIONURI = APIBASEURL + "config/subscription-settings";
export const HARDWARELIST = APIBASEURL + "hardware"
export const COUNTRYHELPERURI = APIBASEURL + "country/";
export const ADDONPRICEURI = APIBASEURL + "config/addon-price";
export const ALLTAXURI = APIBASEURL + "config/tax";
export const PERMISSIONURI = APIBASEURL + "permission";
export const DEPARTMENT = APIBASEURL + "department";
export const DEPARTMENTPERMISSIONURI = APIBASEURL + "department/permission";
export const SUPPERADMINTOS = APIBASEURL + "tos";
export const EMPLOYMENTTYPE =  APIBASEURL + "helper?types=employment_type";
export const GENDER = APIBASEURL + "helper?types=gender";
export const HELPERTYPE = APIBASEURL + "helper?types="
export const USERPROFILE = APIBASEURL + "user/profile";
export const PROFILESTASTIC = APIBASEURL + "user/dashboard";
export const SUPPERADMINPROFILES = APIBASEURL + "practice/user/profile";
export const PRACTICEUSER = APIBASEURL + "practice/";
export const PRACTICE = APIBASEURL + "practice";
export const PRATICEDOMAIN = PRACTICEUSER + "domain";
export const NOTE = APIBASEURL + "account/note";
export const INVOICE = APIBASEURL + "invoice";

export const WAITINGROOMMEDIALIST = APIBASEURL + "waiting-room-media";
export const WAITINGROOMSTOCKLIBRARY = WAITINGROOMMEDIALIST + "/stock-library";
export const WAITINGROOMMEDIACATEGORY = WAITINGROOMMEDIALIST +"/category";

export const OPERATORYROOMMEDIALIST = APIBASEURL + "operatory-media";
export const OPERATORYROOMCATEGORY = OPERATORYROOMMEDIALIST +"/language/category";
export const OPERATORYROOMLANGUAGELIST= OPERATORYROOMMEDIALIST +"/language";


export const WEBSITEMEDIALIST = APIBASEURL + "website-media";
export const WEBSITEMEDIACATEGORY = WEBSITEMEDIALIST +"/category";

export const CHAIRSIDEMEDIALIST = APIBASEURL + "chairside-media";
export const CHAIRSIDEMEDIACATEGORY = CHAIRSIDEMEDIALIST +"/category";
export const VIDEOSECTIONTYPE = "cs_video_section_types"

export const TYPE_GENDER = "gender";
export const TYPE_PREACTICEUSER = "practice_user_type";
export const TYPE_SPECIALIZATION = "specialization";
export const TYPE_COUNTRY = "country";
export const TYPE_PRACTICE_STATUS = "practice_status";
export const TYPE_LEAD_SOURCE = "lead_source_types";
export const TYPE_PRACTICE_CUSROMER_TYPES = "practice_customer_types";
export const TYPE_MEDIA_LANGUAGE = "media_language"
export const TYPE_TICKET_REASON = "ticket_reason";
export const TYPE_TICKET_PRIORITY = "ticket_priority";

export const TICKET = APIBASEURL + "ticket";
export const NOTES = APIBASEURL + "note";

export default class HttpUtility {
   static  checkRequest(error){
        let response = error.response;
        if (response && response.status == 401) {//403 
            // let error = new Error("Current Session Expired. Login Again..");
            // error.response = error;
            // throw error;
            clearStorage();
            return response;// redirect login page
        }
        else {
            // let error = new Error(error);
            // throw error;
            return response;
        }
    }

    static parseJson(response){
        let jsonResonse = {};
        jsonResonse.status = response.status;
        jsonResonse.data = response.data.data;
        return jsonResonse;
    }

    static getHeaders() {
        var headers = {};
        // headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
         var accessToken = getToken();
        if (accessToken != "") {
            headers['Authorization'] =  accessToken;
        }
        return headers;
    }

    static queryStringBuilder(params) {
        if (typeof params === 'undefined' || typeof params !== 'object') {
            params = {};
            return params;
        }
        var query = '?'; let index = 0;
        for (var param in params) {
            index++;
            var value = params[param];
            if (index == 1) {
                query += param + '=' + value;
            } else {
                query += '&' + param + '=' + value;
            }
        }
        return query;
    }

    static getRequest(uri, param) {
        if(param){
            uri = uri + this.queryStringBuilder(param);
        }
        var headers = this.getHeaders();
         return axios.get(uri, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static postRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.post(uri,param,{
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest)
        
    }

    static patchRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.patch(uri,param,{
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest)
    }


    static putRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.put(uri, param, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static deleteRequest(uri) {
        var headers = this.getHeaders();
        return axios.delete(uri, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static getHelperType(typeArray){
        var uri = HELPERTYPE + typeArray.toString();
        return this.getRequest(uri)//.then((response)=>{ return response });
    }
}

