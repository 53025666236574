import {
  call,
  put,
  takeLatest,
  select,
  all,
  fork,
  join,
} from "redux-saga/effects";
import {
  getReferralApiCall,
} from "../../../service/referral";
import {
  getReferrals,
  getReferralsFailure,
  getReferralsSuccess,
  reset,
} from "./index";

export function* getReferralsWatcher(params) {
  try {
    const { pagenum, searchedText , sortBy , orderBy, selectedCountry, selectedPlan,selectedYear,selectedMonth } = params.payload;
    const data = yield call(getReferralApiCall ,  pagenum, searchedText,selectedCountry,selectedPlan,selectedYear,selectedMonth,  sortBy , orderBy);
    yield put(getReferralsSuccess(data));
    yield put(reset());
  } catch (err) {
    yield put(getReferralsFailure(err));
  }
}

export default function* activeWebStreamSaga() {
  yield all([
    takeLatest(getReferrals.type, getReferralsWatcher),
  ]);
}
