import { createSlice } from "@reduxjs/toolkit";
import { overvierInitialState } from "./initialState";

const loadingOverview = (state) => {
  state.loading = true;
};

const loadingEditOverview = (state) => {
  state.btnLoading = false
}

const overviewSuccess = (state, action) => {
  state.data = action.payload;
  state.loading = false;

};

const gethtmlcodeloading = (state) => {
  state.btnLoading = false
}

const gethtmlcodeSuccess = (state, action) => {
  state.getHtmlCode = action.payload;
};

const gethtmlcodeFailure = (state, action) => {
  state.error = action.payload;
  // state.loading = false;
};

const getExpiredPromocodeLoading = (state) => {
  state.expiredPromocode.loading = true;
  state.expiredPromocode.btnLoading = true;
}

const getExpiredPromocodeSuccess = (state, action) => {
  state.expiredPromocode.loading = false;
  state.expiredPromocode.btnLoading = false;
  state.expiredPromocode = action.payload;
};

const getExpiredPromocodeFailure = (state, action) => {
  state.error = action.payload;
  state.expiredPromocode.loading = false;
  state.expiredPromocode.btnLoading = false;
};


const overViewFailure = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const editOverViewSuccess = (state, action) => {
  state.editOverView = action.payload;
  state.btnLoading = false;
  state.errorEditOverView = {};
};

const editOverViewFailure = (state, action) => {
  state.btnLoading = false
  state.errorEditOverView = action.payload;
};

const reset = (state) => {
  state.error = {};
  state.loading = false;
  state.btnLoading = false
};

const overViewSlice = createSlice({
  name: "overViewSlice",
  initialState: overvierInitialState,
  reducers: {
    loadingOverview,
    overviewSuccess,
    overViewFailure,
    reset,
    editOverViewSuccess,
    editOverViewFailure,
    loadingEditOverview,
    gethtmlcodeloading,
    gethtmlcodeSuccess,
    gethtmlcodeFailure,
    getExpiredPromocodeLoading,
    getExpiredPromocodeSuccess,
    getExpiredPromocodeFailure
  },
});

export default overViewSlice;
