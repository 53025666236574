import React from "react";

import { InputField } from "../../components/InputField";

import "./style.scss";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CustomInput,
} from "reactstrap";

import BrandIcon from "../../img/logo.svg";
import MainProfileAvtar from "../../img/UserProfileMain.svg";
import UserProfileAvtar from "../../img/UserProfileAvtar.svg";

function PasswordRecoveryPage() {
  return (
    <div className="login__main__screen">
      <div className="login__inner">
        <div className="login__inner__wc-text col">
          <div className="wc-text-box">
            Welcome to the <span>Affiliate Partner Programs</span>
          </div>
        </div>
        <div className="login__inner__form">
          <div className="brand-icon">
            <img src={BrandIcon}></img>
          </div>
          <div className="form-body">
            <h4>Who are you?</h4>
            <Row>
              <Col md={12}>
                <div className="user-profile-row">
                  <div className="user-profile-item">
                    <div className="user-profile-box main-profile">
                      <img src={MainProfileAvtar}></img>
                      <p>Admin</p>
                    </div>
                    <h4>James Carlton</h4>
                  </div>
                  <div className="user-profile-item">
                    <div className="user-profile-box">
                      <img src={UserProfileAvtar}></img>
                    </div>
                    <h4>Carla Carlton</h4>
                  </div>
                  <div className="user-profile-item">
                    <div className="user-profile-box">
                      <img src={UserProfileAvtar}></img>
                    </div>
                    <h4>Jean Paul Dupont</h4>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-3">
                <Button type="button" className="w-100" color="black">
                  Return
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRecoveryPage;
