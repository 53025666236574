import React , {useEffect}from "react";
import { Link, useHistory } from "react-router-dom";
import Summarybar from "../../../components/Summarybar";
import Icon from "../../../icon";
import {
  Button,
  Input,
  Label,
  FormGroup,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleValidations } from "../../../utilities/validations";
import { authorizeMultipleDomains } from "../../../service/webstream";
import moment from "moment";
import { InputField } from '../../../components/InputField';
import { DateDDMMMYYY } from "../../../data/comman";
import LoadingButton from '../../../components/LoadingButton';
const FormComponent = ({ index, onFormChange , isError , formError }) => {

  const [formState, setFormState] = React.useState({
    name: "",
    email: "",
    domain: "",
    date: new Date(),
  });

  const [errorClass, seterrorClass] = React.useState("");

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    onFormChange(
      index,
      formState.name,
      formState.email,
      formState.domain,
      formState.date
    );
  }, [formState]);


  return (
    <div className={index % 2 === 0 ? "px-5 py-4" : "px-5 py-4 card "}>
      <div className="px-5 py-4">
        <div className="px-3 py-3">
          <Row>
            <div className="col-lg-6 pr-lg-6">
              <FormGroup row className="align-items-center">
                <Label for="practiceename" className="pr-lg-0" sm={4}>
                  <strong>Practice name</strong>
                </Label>
                <Col sm={8}> 
                  <InputField type="text"  className={isError && isError['webstream_domain.'+index+'.practice_name'] && isError['webstream_domain.'+index+'.practice_name'][0] ? 'invalid-field' : ''} name="name" isError={isError && isError['webstream_domain.'+index+'.practice_name'] && isError['webstream_domain.'+index+'.practice_name'][0]} onChange={handleChange}/>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="practiceemail" className="pr-lg-0" sm={4}>
                  <strong>Practice email</strong>
                </Label>
                <Col sm={8}>
                  <InputField  className={isError && isError['webstream_domain.'+index+'.practice_email'] && isError['webstream_domain.'+index+'.practice_email'][0] ? 'invalid-field' : ''}  isError={isError && isError['webstream_domain.'+index+'.practice_email'] && isError['webstream_domain.'+index+'.practice_email'][0]} type="email"  name="email"  onChange={handleChange}/>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="domainurl" className="pr-lg-0" sm={4}>
                  <strong>Domain URL</strong>
                </Label>
                <Col sm={8}>
                  <InputField className={isError && isError['webstream_domain.'+index+'.domain'] && isError['webstream_domain.'+index+'.domain'][0] ? 'invalid-field' : ''}  isError={isError && isError['webstream_domain.'+index+'.domain'] && isError['webstream_domain.'+index+'.domain'][0]} type="text" name="domain"  onChange={handleChange}/>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center mb-0">
                <Label for="authdate" className="pr-lg-0" sm={4}>
                  <strong>Authorization date</strong>
                </Label>
                <Col sm={8}>
                  <DatePicker
                    className="custom-datepicker form-control mb-2"
                    selected={formState.date}
                    minDate={new Date()}
                    dateFormat={DateDDMMMYYY}
                    placeholderText="06 Dec 2020"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={5}
                    scrollableYearDropdown
                    onChange={(date) =>
                      setFormState({ ...formState, date: date })
                    }
                  />
                </Col>
              </FormGroup>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

const formState = {
  practice_name: "",
  practice_email: "",
  domain: "",
  authorization_at: new Date(),
};

const DomainAuthrization = () => {
  const history = useHistory();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [backModal, setBackModal] = React.useState(false);
  const [numofDomains, setNumOfDomains] = React.useState({ num: 0, error: "" });
  const [finalNumOfDomains, setFinalNumOfDomains] = React.useState(0);
  const [btnLoading, setbtnLoading] = React.useState(false);

  const [formActualState, setFormActualState] = React.useState(
    Array.apply(null, { length: finalNumOfDomains }).map(function () {
      return formState;
    })
  );
  const [formError, setFormError] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(true);

  useEffect(()=>{
    window.history.pushState(null , null ,window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate' , onBackButtonEvent);
  },[]);

  const onBackButtonEvent = (event) =>{
    event.preventDefault();
    goBackButtonHandler();
  }

  const goBackButtonHandler = () =>{
    if(showAlert){
      toggleBackModal(true);
    }else{
      history.goBack()
    }
  }

  const [isError, setError] = React.useState({});

  React.useEffect(() => {
    setFormActualState(
      Array.apply(null, { length: finalNumOfDomains }).map(function () {
        return formState;
      })
    );
  }, [finalNumOfDomains]);

  const toggleModal = (state) => {
    setIsOpen(state);
  };

  const toggleBackModal = (state) => {
    setBackModal(state);
  };

  const onFormChange = (index, name, email, domain, date) => {
    let newFormArray = [...formActualState];
    const isValidated =
      // handleValidations(name, "name") &&
      handleValidations(email, "email") &&
      handleValidations(domain, "domain");
    // if (isValidated) {
      newFormArray[index] = {
        practice_name: name,
        practice_email: email,
        domain,
        authorization_at: moment(date).format("YYYY-MM-DD"),
      };
      setFormActualState([...newFormArray]);
      setFormError("");
      setIsDisabled(false)
    // } 
    // else if (isValidated == "undefined") {
    //   setFormError("");
    //   setError({})
    // } else if (name === "" && email === "" && domain === "") {
    //   setFormError("");
    //   setError({})
    //   setIsDisabled(true)
    // } else {
    //   setError({})
    //   setFormError("");
    //   setIsDisabled(true)
    // }

  };

  const handleNumOfDomains = (e) => {
    if (e.target.value > 100) {
      setNumOfDomains({
        ...numofDomains,
        error: "Value should be between 1-100",
      });
    } else {
      setNumOfDomains({ ...numofDomains, error: "", num: e.target.value });
    }
  };

  const handleRefresh = () => {
    setFinalNumOfDomains(0);
    setIsRefresh(false);
    setError('');
  };

  const handleLoadBtn = () => {
    setIsRefresh(true);
    setError('');
    setFinalNumOfDomains(numofDomains.num);
  };

  

  const handleAuthorizeDomains = async () => {
    
    setbtnLoading(true);
      try {
        const response = await authorizeMultipleDomains(formActualState);
        if(response.status === 200){
            setShowAlert(false)
            setbtnLoading(false);
            toggleModal(false);
            history.push("/app/webstreams/active");
        }else{
            setShowAlert(true)
            toggleModal(false);
        }
      } catch (err) {
        if(err.errors.length){
          setFormError(Object.values(err.errors)[0][0]);
        }else{
          setFormError(err.message);
        }
        setError(err.errors);
        toggleModal(false);
      }
      setbtnLoading(false);
  };

  const onAuthorizeDomain = () =>{
    var errors ={}
    var isError = false;

    formActualState.map((item, i ) => {
      if (item.practice_name == "") {
        errors['webstream_domain.'+i+'.practice_name'] = ["The practice name field is required."];
        isError = true;
      }
      console.log( handleValidations(item.practice_email, "email"),' handleValidations(item.practice_email, "email")')
      if (item.practice_email == "" ) {
        errors['webstream_domain.'+i+'.practice_email'] = ["The practice email field is required."];
        isError = true;
      }

      if(item.practice_email !== "" && handleValidations(item.practice_email, "email") == false){
        errors['webstream_domain.'+i+'.practice_email'] = ["The email must be a valid email address."];
        isError = true;
      }

      if (item.domain == "" ) {
        errors['webstream_domain.'+i+'.domain'] = ["The domain field is required."];
        isError = true;
      }

      if(item.domain !== "" && handleValidations(item.domain, "domain") == false){
        errors['webstream_domain.'+i+'.domain'] = ["The domain must be a valid domain."];
        isError = true;
      }

    })
    if (isError) {
      setError(errors);
      return false
    }
    toggleModal(true);
  }


  return (
    <>
      <Summarybar />
      <div className="card">
        <div className="card-box">
          <div className="card__body">
            <div className="card__header card__header__filter pt-0 pb-0 pr-0">
              <div className="row m-0 w-100 d-flex justify-content-between align-items-center card__header__filter--button">
                <div className="w-100">
                  <Row>
                    <Col sm="9" className="py-4">
                      <div className="card__title d-flex align-items-center py-4">
                        <Button  type="button" color="primary link" onClick={() => history.goBack()} className=" p-0 mr-4 btn-prv"><Icon icon="back" disableFill="true" size={16}></Icon><span>Back</span></Button>
                        <h3 className="m-0 mr-4">Domain Authorization</h3>
                        
                        {formError && (
                          <h3 style={{ color: "red" }}>{formError}</h3>
                        )}
                      </div>
                      <p className="mt-5"><strong>How many domains would you like to authorize?</strong></p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card ">
        <div className="card-box">
          <div className="card__body">
            <div className="px-5">
              <div className="px-5">
                <div className="px-3 py-3">
                  <Row>
                    <div className="col-lg-6 pr-lg-6">
                      <FormGroup row className="align-items-center">
                        <Label for="no-of-domains" className="pr-lg-0" sm={4}>
                          <strong>No. of domains</strong>
                        </Label>
                        <Col sm={8}>
                          <div className="form-group-with-error">
                          {numofDomains.error && (
                            <p className="mb-0 text-right text-danger">
                              {numofDomains.error}
                            </p>
                          )}
                          <Input
                            type="number"
                            name="no-of-domains"
                            placeholder="Enter a number between 1 and 100"
                            onChange={handleNumOfDomains}
                            disabled={isRefresh}
                          />
                          </div>
                          {/* add class "error" to input enable above ptag */}
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="col-lg-6 pr-lg-6">
                        {isRefresh &&
                        <Button color=" link-btn "
                          type="button"
                          outline
                          className="px-0 mh-40"
                          onClick={handleRefresh}
                        >
                          <Icon
                            icon="Plus-square"
                            disableFill="true"
                            size={27}
                            className="mr-3"
                            color="#4390FC"
                          ></Icon>{" "}
                        </Button>
                      }
                      {!isRefresh &&
                      <Button color=" link-btn "
                        type="button"
                        className="px-0 mh-40"
                        onClick={handleLoadBtn}
                      >
                        <Icon
                          icon="Plus-square"
                          disableFill="true"
                          size={27}
                          className="mr-3"
                          color="#4390FC"
                        ></Icon>{" "}
                      </Button>
                      }
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Array.apply(null, { length: finalNumOfDomains })
        .map(function () { return 0; })
        .map((item, i ) => {
          return (
            <FormComponent index={i} key={i} isError={isError} formError={formError} onFormChange={onFormChange} />
          );
        })}
        {finalNumOfDomains > 0 &&
          <div className="py-4 px-5 card">
            <Button  className="btn btn-black btn-md"  onClick={() => onAuthorizeDomain()} >
              Authorize domain(s)
            </Button>
          </div>
      }
      <Modal isOpen={isOpen} toggle={() => toggleModal(false)}>
        <ModalBody className="text-center">
          <p className="mb-0">
            <strong>
              Added domains will be authorized on the dates you have selected,
              and available on these practices’ websites.
            </strong>
          </p>
          <p>Would you like to proceed?</p>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          {/* <Link
            type="button"
            className="btn btn-md outline-primary bg-white mr-4"
            onClick={() => handleAuthorizeDomains()}
          >
            Authorize Domains
          </Link> */}
          <LoadingButton type="button" size="md" disabled={btnLoading} label="Authorize Domains" color="primary" onClick={() => handleAuthorizeDomains()} className="mr-4" loading={btnLoading} outline />
          <button
            type="button"
            className="btn btn-md outline-danger bg-white"
            onClick={() => {
              toggleModal(false);
              // toggleBackModal(true);
            }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={backModal} toggle={() => toggleBackModal(false)}>
        <ModalBody className="text-center">
          <p className="mb-0">
            <strong>You haven’t authorized any domain yet.</strong>
          </p>
          <p>Are you sure you want to go back?</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button  type="button" size="md" outline color="primary link" onClick={() => history.goBack(1)} className="mr-4">Go Back</Button>
          <button
            type="button"
            className="btn btn-md outline-danger bg-white"
            onClick={() => toggleBackModal(false)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DomainAuthrization;
