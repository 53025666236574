import { createSlice } from "@reduxjs/toolkit";
import { pagesInitialState } from "./initialState";

const loadingPages = (state, action) => {
  state.loading = true;
};
const industrySuccess = (state, action) => {
  state.data.industries = action.payload;
};

const countrySuccess = (state, action) => {
  state.data.countries = action.payload;
};
const paymentMethodsSuccess = (state, action) => {
  state.data.paymentMethods = action.payload;
};

const errorPages = (state, action) => {
  state.error = action.payload;
  state.loading = false;
};

const reset = (state) => {
  state.error = "";
  state.loading = false;
};

const pagesSlice = createSlice({
  name: "pagesSlice",
  initialState: pagesInitialState,
  reducers: {
    loadingPages,
    industrySuccess,
    countrySuccess,
    paymentMethodsSuccess,
    errorPages,
    reset,
  },
});

export default pagesSlice;
