import React, {useState, useEffect} from 'react';
import Summarybar from '../../components/Summarybar';
import DropDown from '../../components/AppDropDown'
import { Link, useHistory } from "react-router-dom";

import './style.scss';
import { Button, Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import Icon from "../../icon";
import listOfComponent from "./component.json";
import Fuse from 'fuse.js';
import Dashboard from './Pages/dashboard'
import Overview from './Pages/overview'
import Referrals from './Pages/referrals'
import Webstreams from './Pages/webstreams'
import Licenses from './Pages/licenses'
import Reports from './Pages/reports'
import Invoices from './Pages/invoices'
function Index() {
  const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [setedId, setSetedId] = useState('') 
  const [menuOpen, setmenuOpen] = useState(false) 
  const history = useHistory();

  const fuse = new Fuse(listOfComponent,{
    shouldSort: true,
    // threshold: 0.1,
    // location: 0,
    // distance: 100,
    keys: ["name", "tags","content"]
  });

  useEffect(()=>{
    setSearchData([])
  },[])

  const searchItem = (event) => {
    if(event.target.value){
      setmenuOpen(true)
      setSearchInput(event.target.value);
      setSearchData(fuse.search(event.target.value));
    }else{
      setSetedId('')
      setmenuOpen(false)
      setSearchInput(event.target.value);
    }
  
  };

  const setDefault = (test) =>{
    setSetedId('')
  }

  const selectedComponent = (event) => {
  if (event&&event.item.component === 'Dashboard') {
    setSetedId(<Dashboard setDefaultId={setDefault}/>);
  } else if(event&&event.item.component === 'Overview'){
    setSetedId(<Overview setDefaultId={setDefault} />);
  }else if(event&&event.item.component === 'Referrals'){
    setSetedId(<Referrals setDefaultId={setDefault} />);
  }else if(event&&event.item.component === 'Webstreams'){
    setSetedId(<Webstreams setDefaultId={setDefault} />);
  }else if(event&&event.item.component === 'Licenses'){
    setSetedId(<Licenses setDefaultId={setDefault} />);
  }else if(event&&event.item.component === 'Reports'){
    setSetedId(<Reports setDefaultId={setDefault} />);
  }else if(event&&event.item.component === 'Invoices'){
    setSetedId(<Invoices setDefaultId={setDefault} />);
  }
  else{
    setSetedId('')
  }
  setmenuOpen(false)
};
  
  return (
    <div>
      <Summarybar />
      <div className="help__main__screen">
        <div className="help__inner">
          <div className="help__inner__search">
            <h4>How can we help you?</h4>
            <div className="search-box">
              <input type="search" className="form-control" onChange={(e)=>searchItem(e)} value={searchInput} placeholder="Search by keyword"></input>
              <Button color="link primary" className="p-0"><Icon icon="search-icon" color="#357BCF" size={18} /></Button>
            </div>
            <DropDown
                className='react-select-container react-select__multi'
                classNamePrefix="react-select"
                options={searchData}
                getOptionLabel={ x => `${x.item.name}`}
                getOptionValue={ x => `${x.item.component} ${x.item.name}`}
                onChange={(e)=>selectedComponent(e)}
                noOptionsMessage={() => null}
                // closeMenuOnSelect={true}
                menuIsOpen={menuOpen}
                // isSearchable={true}
                placeholder={'Search'}
                value={searchInput}
            />
          </div>
          {setedId !== '' && searchData !== '' ?
          <>{setedId}</>
          :
          <>
            <div className="help__inner__category">
              <h4>Browse by categories</h4>
              <div className="category-row justify-content-center">
                <div onClick={(e)=> selectedComponent({item:{component:'Dashboard'}})} className="category-item d-flex flex-column align-items-center justify-content-center">
                  <div>
                    <div className="category-item-icon">
                      <Icon icon="help-dashboard" color="#343434" size={44} />
                    </div>
                    <h5>Dashboard</h5>
                  </div>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Overview'}})} className="category-item d-flex flex-column align-items-center justify-content-center">
                  <div className="category-item-icon">
                    <Icon icon="home" color="#343434" size={44} />
                  </div>
                  <h5>Overview</h5>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Referrals'}})} className="category-item">
                  <div className="category-item-icon">
                    <Icon icon="Referrals" color="#343434" size={44} />
                  </div>
                  <h5>Referrals</h5>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Webstreams'}})} className="category-item">
                  <div className="category-item-icon">
                    <Icon icon="webstreams" color="#343434" size={44} />
                  </div>
                  <h5>Webstreams</h5>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Licenses'}})} className="category-item">
                  <div className="category-item-icon">
                    <Icon icon="help-licenses" color="#343434" size={44} />
                  </div>
                  <h5>Licenses</h5>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Reports'}})} className="category-item">
                  <div className="category-item-icon">
                    <Icon icon="help-reports" color="#343434" size={44} />
                  </div>
                  <h5>Reports</h5>
                </div>
                <div onClick={(e)=> selectedComponent({item:{component:'Invoices'}})} className="category-item">
                  <div className="category-item-icon">
                    <Icon icon="card-icon" color="#343434" size={44} />
                  </div>
                  <h5>Invoices</h5>
                </div>
              </div>
            </div>
            <div className="help__inner__questions">
              <h4 className="mb-5 pb-4">Popular Questions</h4>
              <div className="question-row">
                <Card>
                  <CardHeader onClick={() => setToggequestion(1)} className={toggleQuestion === 1 ? 'question-open' : ''}>
                    <h5>How much commission do I receive?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                    <CardBody>
                      <p>Affiliates receive $30 a month for each customer that subscribes to Thalamus, and will continue to receive these payments for the duration of that customer's subscription. Other programs often have a ‘cap’ on payments, one year for example - but with Thalamus you will receive on-giong payments for as long as the customer is a paid subscriber.</p>
                      <p>Commision starts in the first month and doesn’t include any setup or hardware costs.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(2)} className={toggleQuestion === 2 ? 'question-open' : ''}>
                    <h5>How long will I be receiving commission?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                    <CardBody>
                    <p>Affiliates will receive commissions for the duration of the customer’s subscription. If the customer were to cancel their subscription, the affiliate will stop receiving payments.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(3)} className={toggleQuestion === 3 ? 'question-open' : ''}>
                    <h5>How often will I receive commission payments?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 3 ? true : false}>
                    <CardBody>
                      <p>Commission payments will be transferred via a method an affiliate has chosen on a quarterly basis on the 1st of the month. An affiliate partner can always track their commissions and referrals in the dashboard.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(4)} className={toggleQuestion === 4 ? 'question-open' : ''}>
                    <h5>How can I help attract customers as an affiliate?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 4 ? true : false}>
                    <CardBody>
                      <p>To help attract new customers, affiliates will be given promo codes to distribute, which offer new customers extra incentive to sign up. These codes can offer price discounts, free months, or other benefits. You can embed your own person affiliate program weblink (e.g. www.ThalamusMedia.com/partner_462326) on your website and when traffic comes via this link we know that the lead has come from you. We also have a library of marketing content you can use on your site.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(5)} className={toggleQuestion === 5 ? 'question-open' : ''}>
                    <h5>How will the Thalamus Affiliate program benefit my clients?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 5 ? true : false}>
                    <CardBody>
                      <p>If you’re a dental company and become a Thalamus Affiliate, you will be able to provide all of your customers with access to 50 of Thalamus’ patient education videos.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(6)} className={toggleQuestion === 6 ? 'question-open' : ''}>
                    <h5>How do Thalamus Licences work?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 6 ? true : false}>
                    <CardBody>
                      <p>Purchasing a licence is essentially purchasing a Thalamus subscription for a practice. As an affiliate, when you purchase a license, you are given a numerical code that the receiver can enter on the subscribe page of the Thalamus website. Once entered, they can continue the subscription process as if they purchased it themselves.</p>
                    </CardBody>
                  </Collapse>
                </Card>

                <Card>
                  <CardHeader onClick={() => setToggequestion(7)} className={toggleQuestion === 7 ? 'question-open' : ''}>
                    <h5>What can I do in the User & Access Rights section?</h5>
                    <div className="icon-box"><Icon icon="Down-arrow" color="#343434" size={14} /></div>
                  </CardHeader>
                  <Collapse isOpen={toggleQuestion === 7 ? true : false}>
                    <CardBody>
                      <p>This is where you are able to add other people or colleagues to your account so that you can share the same affiliate partner account.</p>
                    </CardBody>
                  </Collapse>
                </Card>

              </div>
            </div>
          </>
          }
          <div className="help__inner__contact-us">
            <h4>Can’t find what you need?</h4>
            <Button onClick={()=> history.push('/app/contact-us')} color="primary" outline>Contact Us</Button>
          </div>
        </div>
      </div>

    </div>

  );
}


export default Index;