import { Route, Redirect, useHistory } from "react-router";
import React, { ReactElement } from "react";
import { shallowEqual, useSelector , useDispatch } from "react-redux";
import { clearStorage } from "../../helper/LocalStorage";
import { loginLoding, logout } from "../../pages/login/Redux";
var token;
var secret_token;
const  GetRouteAccordingToFlag = (flag) => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(history.location.search);
    token = query.get('token')
  if(token){
    dispatch(logout());
    return `/user-reset-password/?token=${token}`;
  }else if (flag.need_profile_setup == 1) {
    return "/new-password";
  }else if(flag.signup_tos_status == 0 ){
    return "/signup-tos";
  }else if(flag.tos_status == 0 || flag.tos_status == 2){
    return "/tos";
  }
  return "/app/dashboard";
};



const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const query = new URLSearchParams(history.location.search);
  secret_token = query.get('secret')
  if(secret_token){
    dispatch(logout());
  }

  const usertoken = useSelector((state) => state.login.token);
  const flag = useSelector((state) => state.login.data);
  const redirectPath = usertoken === "" ? "/" : GetRouteAccordingToFlag(flag);

  return (
    <Route
      {...rest}
      render={(props) =>
        usertoken === "" ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  );
};

export default PublicRoute;
